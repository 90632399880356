import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase, Stack} from "@mui/material"
import AudioFileIcon from '@mui/icons-material/AudioFile';
import Chip from '@mui/material/Chip';
export const FreeSoundModal = (props) => {
    const {stockData} = props;
    const renderDuration = (duration) => {
      let subtitle = "";
      const minute = Math.floor(duration / 60);
      const second = Math.round((duration - minute * 60));
      subtitle += ` ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`
      return subtitle;
    }
    return (
        <Box>
          <Grid container spacing={3}>
            <Grid item md={4} xs={12}>
              <Typography id="spring-modal-title" variant="h6" component="h2">
                Freesound Stock Audio
              </Typography>
              <Typography variant="overline" component={'p'}>
                Name: <b>{stockData?.name}</b>
              </Typography>
              <Typography variant="overline" component={'p'}>
                Tags: <b>{ stockData?.tags.toString()}</b>
              </Typography>
            </Grid>
            <Grid item md={8}  xs={12}>
              <div style={{position: 'relative'}}>
                <img 
                  src={stockData?.images['waveform_m']}
                  style={{width: '100%'}}
                />
                <div className='stockMediaBar'>
                  <Stack direction='row' justifyContent='start' alignItems='center'>
                    <AudioFileIcon sx={{ color: 'white' }}/>
                    <Typography color={'white'} variant='span'>{renderDuration(stockData?.duration)}</Typography>
                  </Stack>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
    )
}