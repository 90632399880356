import { v4 as uuidv4 } from 'uuid';
import React, {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import {Box, Grid, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Button} from "@mui/material"
import { changedJSON_Store, addedTemplate_store, ProjectJSON_Store } from '../../../store/actions/projectdata';
import { SectionHeader } from './common/section.header';
import { GetProjectUUID } from '../../../assets/script/commonFunction';
import emtpyThumb from '../../../assets/image/emptyThumb.jpg';

import { getTemplates, addTemplateProject } from '../../../axios/ApiProvider';

const BASE_BACKEND_URL = process.env.REACT_APP_BASE_BACKEND_URL

export const TooltapTemplate = () => {
  const dispatch = useDispatch()
  const projectJSON = useSelector(store => store.projectdata.projectJSON);
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const Time_Count = useSelector((store) => store.videodata.Time_Count);
  const [templateArr, setTemplateArr] = useState([]);
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [nextloadTemplageUrl, setNextLoadTemplateUrl] = useState(null);
  const [templateType, setTemplateType] = useState(0);

  const [addOption, setAddOption] = useState('intro');

  const onChangeAddOption = (e) => {
    console.log(e.target.value);
    setAddOption(e.target.value);
    
  }

  const projectUUID = GetProjectUUID();

  const HeaderInfo = { title: languageStrs.templates, className: 'pl-3' };
  useEffect(()=>{
    firstTemplatesLoadFunc(templateType);
  }, [templateType])

  const firstTemplatesLoadFunc = async (templateType)=>{
    setTemplatesLoading(true);
    const templateUrl = BASE_BACKEND_URL + '/templateproject/?visibility='+templateType
    let result = await getTemplates(templateUrl);
    if (result.state === 'success') {
      setTemplatesLoading(false);
      setTemplateArr(result.data.results);
      setNextLoadTemplateUrl(result.data.next)
    } else {
      setTemplatesLoading(false);
      setTemplateArr([]);
    }
  }

  const loadMoreTemplates = async (nextPageUrl) => {
    setTemplatesLoading(true);
    //const templateUrl = BASE_BACKEND_URL + '/templateproject/?page='+pageNum
    let result = await getTemplates(nextPageUrl);
    if(result.state == 'success'){
      setTemplatesLoading(false);
      const combined = templateArr.concat(result.data.results);
      setTemplateArr(combined);
      setNextLoadTemplateUrl(result.data.next)
    }else{
      setTemplatesLoading(false);
    }
  }

  const templateOnClick = async (item) => {
    const templProjectJson = {...projectJSON};
    const response = await addTemplateProject(projectUUID, item.uuid);
    
    if(response.state != "success") return;
    if(response.data?.code == "Access Denied") return;
    const addedTemplate = response.data['Template Manifest'];
    let addingTime = 0;
    console.log('checkingOpting', addedTemplate, addOption, templProjectJson, Time_Count.getTime())
    switch(addOption){
      case 'intro':
        addingTime = 0;
        const existMovieKeys = Object.keys(templProjectJson.videos);
        if(existMovieKeys){
          existMovieKeys.map(keyItem => {
            templProjectJson.videos[keyItem].timeline_start = templProjectJson.videos[keyItem].timeline_start + addedTemplate.duration;
            templProjectJson.videos[keyItem].timeline_end = templProjectJson.videos[keyItem].timeline_end + addedTemplate.duration;
          })
        }
        const existImgKeys = Object.keys(templProjectJson.images);
        if(existImgKeys){
          existImgKeys.map(keyItem => {
            templProjectJson.images[keyItem].timeline_start = templProjectJson.images[keyItem].timeline_start + addedTemplate.duration;
            templProjectJson.images[keyItem].timeline_end = templProjectJson.images[keyItem].timeline_end + addedTemplate.duration;
          })
        }
        const existAudioKeys = Object.keys(templProjectJson.audios);
        if(existAudioKeys){
          existAudioKeys.map(keyItem => {
            templProjectJson.audios[keyItem].timeline_start = templProjectJson.audios[keyItem].timeline_start + addedTemplate.duration;
            templProjectJson.audios[keyItem].timeline_end = templProjectJson.audios[keyItem].timeline_end + addedTemplate.duration;
          })
        }
        const existTextKeys = Object.keys(templProjectJson.text);
        if(existTextKeys){
          existTextKeys.map(keyItem => {
            templProjectJson.text[keyItem].timeline_start = templProjectJson.text[keyItem].timeline_start + addedTemplate.duration;
            templProjectJson.text[keyItem].timeline_end = templProjectJson.text[keyItem].timeline_end + addedTemplate.duration;
          })
        }
        
        break;
      case 'outro':
        addingTime = templProjectJson.duration;
        break;
      case 'current':
        addingTime = Time_Count.getTime();
        break;
    }
    let changedData = { videos: {}, audios: {}, images: {}, text: {} }
    const addedMovieKeys = Object.keys(addedTemplate.videos);
    if(addedMovieKeys){
      addedMovieKeys.map(keyItem => {
        addedTemplate.videos[keyItem].timeline_start = addedTemplate.videos[keyItem].timeline_start + addingTime;
        addedTemplate.videos[keyItem].timeline_end = addedTemplate.videos[keyItem].timeline_end + addingTime;

        const newVUUID = uuidv4();
        const videoItem = {[newVUUID]: addedTemplate.videos[keyItem]};
        changedData = { ...changedData, videos: {...changedData.videos, ...videoItem} };
      })
    }
    const addedImgKeys = Object.keys(addedTemplate.images);
    if(addedImgKeys){
      addedImgKeys.map(keyItem => {
        addedTemplate.images[keyItem].timeline_start = addedTemplate.images[keyItem].timeline_start + addingTime;
        addedTemplate.images[keyItem].timeline_end = addedTemplate.images[keyItem].timeline_end + addingTime;
        const newIUUID = uuidv4();
        const imgItem = {[newIUUID]: addedTemplate.images[keyItem]};
        changedData = { ...changedData, images: { ...changedData.imgItem, ...imgItem} };
      })
    }
    const addedAudioKeys = Object.keys(addedTemplate.audios);
    if(addedAudioKeys){
      addedAudioKeys.map(keyItem => {
        addedTemplate.audios[keyItem].timeline_start = addedTemplate.audios[keyItem].timeline_start + addingTime;
        addedTemplate.audios[keyItem].timeline_end = addedTemplate.audios[keyItem].timeline_end + addingTime;
        const newAUUID = uuidv4();
        const audioItem = {[newAUUID]: addedTemplate.audios[keyItem]};
        changedData = { ...changedData, audios: { ...changedData.audioItem, ...audioItem} };
      })
    }
    const addedTextKeys = Object.keys(addedTemplate.text);
    if(addedTextKeys){
      addedTextKeys.map(keyItem => {
        addedTemplate.text[keyItem].timeline_start = addedTemplate.text[keyItem].timeline_start + addingTime;
        addedTemplate.text[keyItem].timeline_end = addedTemplate.text[keyItem].timeline_end + addingTime;
        const newTUUID = uuidv4();
        const textItem = {[newTUUID]: addedTemplate.text[keyItem]};
        changedData = { ...changedData, text: {...changedData.text, ...textItem} };
      })
    }
    // changedData = {...changedData, images: { ...addedTemplate.images }, audios: {...addedTemplate.audios}, videos: {...addedTemplate.videos}, text: {...addedTemplate.text} };
    const newProjectJSON = {
      ...templProjectJson,
      audios: { ...templProjectJson.audios, ...changedData.audios },
      videos: { ...templProjectJson.videos, ...changedData.videos },
      images: { ...templProjectJson.images, ...changedData.images },
      text: { ...templProjectJson.text, ...changedData.text },
    }
    // let existVideoTags = document.getElementsByClassName("myElement_videos");
    // const existVideoElements = Array.from(existVideoTags)
    // existVideoElements.map(vEle => vEle.remove());
    dispatch(addedTemplate_store(true));
    
    // dispatch(ProjectJSON_Store(newProjectJSON));
    dispatch(changedJSON_Store(newProjectJSON));
  
  }

  const CalcuDifferentDay = (day) => {
    let day1 = new Date();
    let day2 = new Date(day);
    let dif_day = day1.getTime() - day2.getTime();
    let TotalDays = Math.ceil(dif_day / (1000 * 3600 * 24));
    return TotalDays ? TotalDays : '0';
  }

  const CustomDivider = () => (
    <Box
      sx={{
        height: 2, // Specify the thickness of the divider
        backgroundColor: 'black', // Choose a color that stands out
        width: '100%', // Ensure the divider spans the full width
      }}
    />
  );

  return (
    <>
       <Grid container sx={{p: 1}}>
          <SectionHeader {...HeaderInfo} />
          <CustomDivider />
          <Grid item xs={12} className='text-left px-2'>
            <FormControl className='text-left'>
              <RadioGroup 
                row
                name='checkTemplateType'
                value={templateType}
                onChange={(e) => { setTemplateType(e.target.value) }}
              >
                <FormControlLabel value={1} control={<Radio />} label="Custom" />
                <FormControlLabel value={0} control={<Radio />} label="Public" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <CustomDivider />
          <Grid item xs={12} className='text-center py-4' >
            <FormControl className='text-center'>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={addOption}
                onChange={(e) => {onChangeAddOption(e)}}
              >
                <FormControlLabel value="intro" control={<Radio />} label="Add as intro" />
                <FormControlLabel value="outro" control={<Radio />} label="Add as outro" />
                <FormControlLabel value="current" control={<Radio />} label="Add current moment" />
              </RadioGroup>
            </FormControl>
          </Grid>
          <CustomDivider />
        </Grid>
        
        <Grid container sx={{overflow: "auto", position: "relative"}}>
          
          <Grid item xs={12} sx={{position: "relative", minHeight: "280px"}}>
            <ImageList  variant="quilted" cols={2} gap={8}>
              {templateArr.map((item, index) => (
                <ImageListItem key={index}
                  sx={{cursor: "pointer", display: 'inline-block'}} 
                  onClick={() => { templateOnClick(item) }}
                >
                  {!item?.project_thumbnail && (
                    <img alt='' 
                      src={emtpyThumb} 
                      style={{
                        minWidth: "184px",
                        minHeight: "103px",
                        flexGrow: "unset"
                      }}
                    />
                  )}

                  {!!item?.project_thumbnail && (
                    <img alt='' 
                      src={item.project_thumbnail} 
                      style={{
                        minWidth: "184px",
                        minHeight: "103px",
                        flexGrow: "unset"
                      }}
                    />
                  )}
                  <ImageListItemBar
                    subtitle={<>
                      <h1 className="TileInfoWrapper-header">{item.title}</h1>
                      <div className="TileInfoWrapper-TileInfoTime">{CalcuDifferentDay(item.updated_at)} days ago</div>
                    </>}
                  />

                  {/* <Box className="template-item-image">
                    

                    <Box className="templateTitle-ThumbnailImageBackgroundOverlay" />
                  </Box>
                  <Box className="templateTitle-TileInfoWrapper">
                    <h1 className="TileInfoWrapper-header">{item.title}</h1>
                    <div className="TileInfoWrapper-TileInfoTime">{CalcuDifferentDay(item.updated_at)} days ago</div>
                  </Box> */}
                </ImageListItem >
              ))}
            </ImageList>
            {templatesLoading && <Box sx={{background: "rgba(0,0,0,0)", display: "flex", zIndex: 100000, flexDirection: "column", justifyContent: "center", height: "300px", width: "100%"}}>
            <Box sx={{ display: 'flex', position: "absolute", justifyContent: "center", width: "100%" }}>
                <CircularProgress />
              </Box>
            </Box>}

           {(nextloadTemplageUrl && !templatesLoading) && <Box className='py-3 text-center' style={{width: '100%'}}>
              <Button className='m-auto' variant="outlined" onClick={() => { loadMoreTemplates(nextloadTemplageUrl); }}>{languageStrs.load_more}</Button>
            </Box>}
          </Grid>
        </Grid>
    </>
  )
}