export const fsShader = `
uniform sampler2D uSampler;

varying highp vec2 vTextureCoord;
varying highp mat4 vFilterPreset_m;
varying highp vec4 vFilterPreset_v;

void main(void) {
  gl_FragColor = texture2D(uSampler, vTextureCoord) * vFilterPreset_m + vFilterPreset_v;

  // gl_FragColor = vec4(
  //   texture2D(uSampler, vec2(vTextureCoord.x, vTextureCoord.y)).rgb,
  //   texture2D(uSampler, vec2(vTextureCoord.x, vTextureCoord.y)).a
  // ) * vFilterPreset_m + vFilterPreset_v;
}
`;

export const fsFilterShader = `
  precision mediump float;
  
  varying vec2 vTextureCoord;

  uniform sampler2D uSampler;
  uniform sampler2D textGround;
  uniform float isGreen;
  uniform float transparency;
  uniform vec3 edit_values;
  uniform float blackwhite;

  vec4 adjust_color(vec4 texColor)
  {
    vec4 tmpFreg = vec4( texColor.rgb * texColor.a, texColor.a );
    return vec4( vec3(tmpFreg.r * edit_values.r, 
      tmpFreg.g * edit_values.g, 
      tmpFreg.b * edit_values.b
    ), tmpFreg.a );
  }
  vec3 blackwhitecolor(vec4 texColor, float value)
  {
    //return (texColor.r + texColor.g + texColor.b) / value;
    
    if(value > 0.4){
      float luma = 0.1 * (texColor.r + texColor.g + texColor.b);
      
     // float l= mix(0.0, 1.0, step(0.5, luma));
     float l = luma;
      return vec3(l);
    }else{
      float luma = 0.3 * (texColor.r + texColor.g + texColor.b);
      float l = luma;
      return vec3(l);
    }
  }

  vec4 ProcessChromaKey(){
    vec4 rgba = texture2D(uSampler, vTextureCoord);
    vec2 sPoint = vec2(
      rgba.r * -0.169 + rgba.g * -0.331 + rgba.b *  0.5    + 0.5,
      rgba.r *  0.5   + rgba.g * -0.419 + rgba.b * -0.081  + 0.5
    );
    vec3 keyColor = vec3(0, 1, 0);
    vec2 ePoint = vec2(
      keyColor.r * -0.169 + keyColor.g * -0.331 + keyColor.b *  0.5    + 0.5,
      keyColor.r *  0.5   + keyColor.g * -0.419 + keyColor.b * -0.081  + 0.5
    );
    float chromaDist = distance(sPoint, ePoint);
    float baseMask = chromaDist - 0.3;
    float fullMask = pow(clamp(baseMask / 0.2, 0.0, 1.0), 1.5);
    rgba.a = fullMask;
    float spillVal = pow(clamp(baseMask / 0.2, 0.0, 1.0), 1.5);
    float desat = clamp(rgba.r * 0.2126 + rgba.g * 0.7152 + rgba.b * 0.0722, 0., 1.);
    rgba.rgb = mix(vec3(desat, desat, desat), rgba.rgb, spillVal);
    return rgba;
  }

  void main()
  {
      vec4 rgb_vals = texture2D(uSampler, vTextureCoord);  
      if(blackwhite > 0.0){
        vec3 c = blackwhitecolor(rgb_vals, blackwhite);

        gl_FragColor = vec4(c.rgb, transparency);
        
      }else{
        //vec3 new_color = adjust_color(rgb_vals);

        //gl_FragColor = vec4(new_color.rgb, 1.0);
       // gl_FragColor = texture2D(uSampler, vTextureCoord);
        //gl_FragColor = vec4( gl_FragColor.rgb * gl_FragColor.a, gl_FragColor.a );
        //gl_FragColor = vec4(new_color.rgb, transparency);
        float x_offset = mod( gl_FragCoord.y ,2.0  ) < 1.0 ? 0.5 : 0.0 ;
        vec4 y4 =  texture2D(uSampler, vec2 ( vTextureCoord.x / 1.0  , vTextureCoord.y / 1.5  )   )  * 2.0 ;
        vec4 u4 = (texture2D(uSampler, vec2 ( x_offset + vTextureCoord.x  / 2.0  ,2.0/3.0 +  vTextureCoord.y / 6.0  )   ) -0.50 ) * 2.0 ;
        vec4 v4 = (texture2D(uSampler, vec2 ( x_offset + vTextureCoord.x / 2.0  , 5.0/6.0 + vTextureCoord.y / 6.0  )   ) -0.50 ) * 2.0 ;   
        float y = y4[0] ;
        float u = u4[0] ;
        float v = v4[0] ;

        float R = clamp(y + 1.13 * v, 0.0, 1.0) ;
        float G = clamp(y - 0.39 * u - 0.58 * v, 0.0, 1.0) ;
        float B = clamp(y + 2.03 * u, 0.0, 1.0) ;
        if(G > (R+B)){
          if(isGreen == 1.0){
            //gl_FragColor = vec4(rgb_vals.r, rgb_vals.g, rgb_vals.b, rgb_vals.g > 0.5 && rgb_vals.r < 0.4? 0.0 : 1.0);
            gl_FragColor = ProcessChromaKey();
          }else{
            gl_FragColor = adjust_color(rgb_vals);
          }
        }else{
          gl_FragColor = adjust_color(rgb_vals);
          
        }
        
      }
  }
  `;

export const fsGrShader = `precision highp float;

uniform sampler2D u_image;
uniform sampler2D u_image_frame;
uniform vec2 u_resolution;
varying vec2 v_texCoord;

void main() {
  float x_offset = mod( gl_FragCoord.y ,2.0  ) < 1.0 ? 0.5 : 0.0 ;
  vec4 y4 =  texture2D(u_image_frame, vec2 ( v_texCoord.x / 1.0  , v_texCoord.y / 1.5  )   )  ;
  vec4 u4 = (texture2D(u_image_frame, vec2 ( x_offset + v_texCoord.x  / 2.0  ,2.0/3.0 +  v_texCoord.y / 6.0  )   ) -0.50 ) * 2.0 ;
  vec4 v4 = (texture2D(u_image_frame, vec2 ( x_offset + v_texCoord.x / 2.0  , 5.0/6.0 + v_texCoord.y / 6.0  )   ) -0.50 ) * 2.0 ;   
  
  float y = y4[0] ;
  float u = u4[0] ;
  float v = v4[0] ;   

  float R = clamp(y + 1.13 * v, 0.0, 1.0) ;
  float G = clamp(y - 0.39 * u - 0.58 * v, 0.0, 1.0) ;
  float B = clamp(y + 2.03 * u, 0.0, 1.0) ;

  vec4 video_pixel = vec4( vec3(R,G,B), 1.0) ;
  gl_FragColor =  G > (R+B) ?  texture2D(u_image, v_texCoord) : video_pixel  ;
}`;