const fadeGL = {
    type: 'fade',
    glsl: `vec4 transition (vec2 uv) {
        return mix(
        getFromColor(uv),
        getToColor(uv),
        progress
        );
    }`,
    defaultParams: {},
    paramsTypes: {},
  }
export default fadeGL