import { useDispatch, useSelector } from "react-redux"
import { memo, useContext, useState, useEffect, useRef } from "react";
import onScrollIcon from "../../../assets/image/icons/onScroll.png"
import offScrollIcon from "../../../assets/image/icons/offScroll.png"
import './timeline.scss';
import { Legend } from "./common/Legend";
import { Indicator } from "./common/Indicator";
import { TimeLineContext } from "./timeline.config";
import { ElementsContainer } from './container/ElementsContainer';
import { GetTimelineGridInfo, GetFieldWidth, dragImgDenable } from "./common/commonFunction";
import { SaveZoomRate_Store } from "../../../store/actions/videoPlayAction";
import usePinchZoom from "../../../hooks/usePinchZoom";

export const TimelineModule = memo(() => {
  const dispatch = useDispatch();

  const TimelineRef = useRef();
  const ElementsRef = useRef();
  const IndicatorRef = useRef();
  const timeContainerRef = useRef();
  const [timelineData, SetTimelineData] = useContext(TimeLineContext);
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const timelineFit = useSelector(store => store.projectdata.timelineFit);
  const { pinching, delta } = usePinchZoom(TimelineRef);
  const zoomRate = useSelector((store) => store.videodata.zoomRate);
  const isTimelineScroll = useSelector((store) => store.editdata.isTimelineScroll);

  useEffect(() => {
    if(!isTimelineScroll){
TimelineRef.current.style.overflowX = 'hidden'
    }else{
      TimelineRef.current.style.overflowX = 'auto'
    }
    
  }, [isTimelineScroll])

  useEffect(() => {
    if (pinching && delta < -30) {
      dispatch(SaveZoomRate_Store(zoomRate - 1));
    }
    if (pinching && delta > 30) {
      dispatch(SaveZoomRate_Store(zoomRate + 1));
    }
  }, [delta, pinching])

  useEffect(() => {
    SetTimelineData({
      ...timelineData,
      timeline: TimelineRef.current,
      elements: ElementsRef.current,
      indicator: IndicatorRef.current,
      container: timeContainerRef.current
    });
  }, []);


  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (loaded || !ProjectJSON?.duration) return;

    setLoaded(true);
    let zoomRate = getZoomFitValue();
    dispatch(SaveZoomRate_Store(zoomRate));
  }, [ProjectJSON])

  useEffect(() => {
    if (!ProjectJSON?.duration) return;
    let zoomRate = getZoomFitValue();
    dispatch(SaveZoomRate_Store(zoomRate));
  }, [timelineFit])

  const getZoomFitValue = () => {
    let fitZoomRate = 0;
    let tempLegend = [], startLeft = -1;
    let duration = ProjectJSON?.duration;
    let tempArray = new Array(21).fill(0);

    if (duration === 0) return fitZoomRate;
    tempArray.forEach((v, key) => {
      let zoom = key - 10;
      const legendInfo = GetTimelineGridInfo(zoom);
      const { SecondW, startL } = GetFieldWidth(legendInfo);
      let tempData = { zoom: zoom, SecondW: SecondW }
      if (startLeft === -1) startLeft = startL;
      tempLegend.unshift(tempData);
    })

    let totalWidth = TimelineRef.current.offsetWidth - startLeft;
    let activeItem = tempLegend.find((legend) => { return legend.SecondW * duration / 1000 <= totalWidth; })
    if (typeof activeItem?.zoom !== 'number') return fitZoomRate;
    fitZoomRate = activeItem?.zoom;
    return fitZoomRate;
  }

  return (
    <>
      <div className='v-timeline-container' ref={TimelineRef} >  {/*scroll-hide*/}
        <Indicator ref={IndicatorRef} />

        <div className="timeline-elements-wrapper" ref={ElementsRef}>
          <Legend />
          <div className="timeline-elements-container" ref={timeContainerRef}>
            <ElementsContainer type='videos' />
            <ElementsContainer type='images' />
            <ElementsContainer type='text' />
            <ElementsContainer type='audios' />
          </div>
        </div>
      </div>
    </>

  );
});