import { v4 as uuidv4 } from 'uuid';
import { ReactSVG } from 'react-svg'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Box, Typography } from '@mui/material';

import { changeZeroSecond, getMaxZIndex } from '../commonFunction';
import { SectionHeader } from './common/section.header';
import { getImageInformation } from '../webGL/commonFunc';
import { thumbNails_Store } from '../../../store/actions/projectdata';
import { GetProjectUUID } from '../../../assets/script/commonFunction';
import { changedJSON_Store } from '../../../store/actions/projectdata';
import { getProjectJSON, SERVER_STATUS } from '../../../axios/ApiProvider';
import { EmojiCommonUrl, EmojiNames, showingCount } from './tooltap.config';
import { dataLoaded_Store, imageElements_Store } from '../../../store/actions/webgldata';

let tempProjectJSON = null;
//const HeaderInfo = { title: 'Emojis', className: 'pl-3' };
export const TooltapElements = (props) => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const EmojiRef = useRef();
  const EmojiTypes = Object.keys(EmojiNames);
  console.log('emoj types', EmojiTypes);
  const [toEmj, setToEmj] = useState(200);
  const [allEmjList, setAllEmjList] = useState([]);
  const [showEmjs, setShowEmjs] = useState([]);

  const [activeType, setActiveType] = useState('all');

  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const HeaderInfo = { title: languageStrs.emojis, className: 'pl-3' };

  const dispatch = useDispatch();
  const projectUUID = GetProjectUUID();
  const thumbNails = useSelector(store => store.projectdata.thumbNails);
  const imageElements = useSelector(store => store.webgldata.imageElements);
  const Time_Count = useSelector((store) => store.videodata.Time_Count);


  const onScroll = (e) => {
    const target = EmojiRef.current;
    let scrollT = target.scrollTop;
    console.log("scrollingEvents", scrollT, e, (target.clientHeight+scrollT), target.clientHeight, target.scrollHeight);
    // if (scrollT === 0 && scrollCount > 5) setScrollCount(scrollCount - 1);
    if((target.clientHeight+scrollT) > (target.scrollHeight - 1)){
      setToEmj(toEmj + 100)
    }
    // if (scrollT === 0) { setToEmj(toEmj + 40)}
  }
  // useEffect(() => {
  //   loadAllEmjs();
  // }, [])

  useEffect(() => {
    // let tempEmojis = [...allEmjList.slice(0, toEmj)];
    // setShowEmjs(tempEmojis);
    loadAllEmjs();
  }, [toEmj])

  useEffect(() => { 
    tempProjectJSON = { ...ProjectJSON } 
  }, [ProjectJSON]);
  const loadAllEmjs = () => {
    let allEmjSvgs = [];
    EmojiTypes.map(emItemTy => {
      EmojiNames[emItemTy].map(emjSVG => {
        allEmjSvgs.push(emjSVG);
      })
    });
    setAllEmjList(allEmjSvgs);
    let tempEmojis = [...allEmjSvgs.slice(0, toEmj)];
    setShowEmjs(tempEmojis);
  }
  // const print_type = (typeKey) => {
  //   return languageStrs['emj_'+typeKey];
  // }
  const onClick = async (name) => {
    const newUUID = uuidv4();
    let fileUrl = EmojiCommonUrl + name;
    dispatch(dataLoaded_Store(false));
    const mimeType = "image/svg"

    let maxZIndex = getMaxZIndex(ProjectJSON);
    let props = [ProjectJSON, name, mimeType, fileUrl, newUUID, projectUUID, 'emoji']
    await getImageInformation(...props, maxZIndex, async (img, imageInfo) => {
      let imgThumbNail = [{ uuid: uuidv4(), content_type: 'blob', signed_url: fileUrl }];
      dispatch(thumbNails_Store({ ...thumbNails, [imageInfo.asset_uuid]: imgThumbNail }));
      let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      let startTime = Time_Count.getTime()
      const imgKeys = Object.keys(ProjectJSON.images);
      if(imgKeys.length > 0){
        // imgGroupId = imgKeys[0];
        imgKeys.map(keyItem => {
          if(startTime < ProjectJSON.images[keyItem].timeline_end){
            startTime = ProjectJSON.images[keyItem].timeline_end;
          }
        })
      }
      imageInfo = {
        ...imageInfo,
        timeline_start: startTime,
        timeline_end: imageInfo.duration + startTime
      }

      changedJSON.images = {
        [newUUID]: imageInfo
      }

      dispatch(imageElements_Store({ ...imageElements, [newUUID]: img }));
      dispatch(changedJSON_Store(changedJSON));
      changeZeroSecond(imageInfo, dispatch)
    }, async (assetUUID, newAssets) => {
      let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      if (!tempProjectJSON.images[newUUID]) return;
    //  changedData.images[newUUID] = tempProjectJSON.images[newUUID]

      const JSONdata = await getProjectJSON(projectUUID);
      const projectData = JSONdata?.data[projectUUID];

      if (JSONdata.state !== 'success' || SERVER_STATUS === 'localhost')
        changedData = { ...changedData, asset: { ...newAssets } };
      else changedData = { ...changedData, asset: projectData.asset };
      dispatch(changedJSON_Store(changedData));
    }, () => { dispatch(dataLoaded_Store(true)); });
  }

  return (
    <>
      <SectionHeader {...HeaderInfo} />

      <Box md={12} className='v-Element-wrapper pt-2 pb-2'>
        <Box className='v-boxgroup-wrapper v-scroll px-10 gap-10' ref={EmojiRef} onScroll={onScroll}>
          <Box className='v-BoxGroup-container'>
            {showEmjs.map(({ url }, key) =>
              <ReactSVG src={EmojiCommonUrl + url} key={key}
                className='v-Element-item v-center v-hoverborder v-radius'
                onClick={(e) => { onClick(url); }}
              />
            )}
          </Box>
          {/* <Box className='load-more-btn' component='span'>
            <Typography component='h2'
              onClick={() => { setToEmj(toEmj + 12); }}
            >{languageStrs.load_more}</Typography>
          </Box> */}
        </Box>
        
        
        {/* {activeType === 'all' && (
          <Box className='v-boxgroup-wrapper v-scroll px-10 gap-10'>
            {EmojiTypes.map((type, key) => (
              <div className='flex flex-col' key={key}>
                <Box className='v-block-header px-10'>
                  <Typography className='v-block-header-left'>{print_type(type)}</Typography>
                  <Typography onClick={() => { setActiveType(type); }}
                    className='v-block-header-right v-hoverbackcolor cursor-pointer'
                  >{languageStrs.view_all}</Typography>
                </Box>

                <EmojisComponent type={type} from={0} to={4} />
              </div>
            ))}
          </Box>
        )}

        {activeType !== 'all' && (
          <EmojisAllContainer type={activeType} setActiveType={setActiveType} />
        )} */}
      </Box>
    </>
  );
}

const EmojisComponent = ({ type, from, to }) => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const dispatch = useDispatch();
  const projectUUID = GetProjectUUID();
  const thumbNails = useSelector(store => store.projectdata.thumbNails);
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const imageElements = useSelector(store => store.webgldata.imageElements);
  const Time_Count = useSelector((store) => store.videodata.Time_Count);

  const [EmojiInfo, setEmojiInfo] = useState([]);
  useEffect(() => {
    let tempEmojis = [];
    let typeEmojis = EmojiNames[type];
    if (typeof typeEmojis === 'object') tempEmojis = [...typeEmojis.slice(from, to)];
    setEmojiInfo(tempEmojis);
  }, [type, from, to]);

  const onClick = async (name) => {
    const newUUID = uuidv4();
    let fileUrl = EmojiCommonUrl + name;
    dispatch(dataLoaded_Store(false));
    const mimeType = "image/svg"

    let maxZIndex = getMaxZIndex(ProjectJSON);
    let props = [ProjectJSON, name, mimeType, fileUrl, newUUID, projectUUID, 'emoji']
    await getImageInformation(...props, maxZIndex, async (img, imageInfo) => {
      let imgThumbNail = [{ uuid: uuidv4(), content_type: 'blob', signed_url: fileUrl }];
      dispatch(thumbNails_Store({ ...thumbNails, [imageInfo.asset_uuid]: imgThumbNail }));
      let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      let startTime = Time_Count.getTime()
      const imgKeys = Object.keys(ProjectJSON.images);
      if(imgKeys.length > 0){
        // imgGroupId = imgKeys[0];
        imgKeys.map(keyItem => {
          if(startTime < ProjectJSON.images[keyItem].timeline_end){
            startTime = ProjectJSON.images[keyItem].timeline_end;
          }
        })
      }
      imageInfo = {
        ...imageInfo,
        timeline_start: startTime,
        timeline_end: imageInfo.duration + startTime
      }

      changedJSON.images = {
        [newUUID]: imageInfo
      }

      dispatch(imageElements_Store({ ...imageElements, [newUUID]: img }));
      dispatch(changedJSON_Store(changedJSON));
      changeZeroSecond(imageInfo, dispatch)
    }, async (assetUUID, newAssets) => {
      let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      if (!tempProjectJSON.images[newUUID]) return;
    //  changedData.images[newUUID] = tempProjectJSON.images[newUUID]

      const JSONdata = await getProjectJSON(projectUUID);
      const projectData = JSONdata?.data[projectUUID];

      if (JSONdata.state !== 'success' || SERVER_STATUS === 'localhost')
        changedData = { ...changedData, asset: { ...newAssets } };
      else changedData = { ...changedData, asset: projectData.asset };
      dispatch(changedJSON_Store(changedData));
    }, () => { dispatch(dataLoaded_Store(true)); });
  }

  return (
    <Box className='v-BoxGroup-container'>
      {EmojiInfo.map(({ url }, key) =>
        <ReactSVG src={EmojiCommonUrl + url} key={key}
          className='v-Element-item v-center v-hoverborder v-radius'
          onClick={(e) => { onClick(url); }}
        />
      )}
    </Box>
  )
}

const EmojisAllContainer = ({ setActiveType, type }) => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const EmojiRef = useRef();
  const [from, setFrom] = useState(0);
  const [to, setTo] = useState(showingCount);
  const [scrollCount, setScrollCount] = useState(1);
  useEffect(() => {
    let count = scrollCount > 5 ? scrollCount - 5 : 0;
    let tempTo = showingCount * scrollCount;
    let tempFrom = showingCount * count;
    if (tempFrom !== from) setFrom(tempFrom);
    if (tempTo !== to) setTo(tempTo);
  }, [scrollCount]);

  const onScroll = (e) => {
    const target = EmojiRef.current;
    let scrollT = target.scrollTop;

    if (scrollT === 0 && scrollCount > 5) setScrollCount(scrollCount - 1);
    if (scrollT === 0 && scrollCount > 5) target.scrollTop = 80 * (showingCount / 4);
  }

  return (
    <>
      <Box className='v-block-header px-10'>
        <Typography className='v-block-header-left'>{type}</Typography>
        <Typography onClick={() => { setActiveType('all'); }}
          className='v-block-header-right v-hoverbackcolor cursor-pointer pr-10'
        >{languageStrs.all_types}</Typography>
      </Box>

      <Box className='v-boxgroup-wrapper v-scroll px-10 gap-10' ref={EmojiRef} onScroll={onScroll} >
        <EmojisComponent type={type} from={from} to={to} />

        {showingCount * scrollCount < EmojiNames[type].length && (
          <Box className='load-more-btn' component='span'>
            <Typography component='h2'
              onClick={() => { setScrollCount(scrollCount + 1); }}
            >{languageStrs.load_more}</Typography>
          </Box>
        )}
      </Box>
    </>
  )
}