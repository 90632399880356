import ReactLoading from 'react-loading';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Menu, Slider, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import Swal from 'sweetalert2';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { ProjectJSON_Store } from '../../../store/actions/projectdata'
import { copyObject, getWebGlSizeLevel } from '../commonFunction'
import { projectExportApi,getWebHookData, getCustomerPlan } from '../../../axios/ApiProvider';

import PricingModal from '../../../pages/Pricing/pricingModal';
import { SaveVideoPlaying_Store, SaveVideoPlayStarted_Store } from '../../../store/actions/videoPlayAction';

import { createPrettyXMLFromJSON } from './adobexmlformat_media';
import { formatXml } from './adobexmlformat_helper';

import { createEDLFromJSON } from './adobeedlformat_media';

const HOME_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_HOME_URL
let resolutionMarks = [
  { value: 1, },
  { value: 3, },
  { value: 5, },
  { value: 8, },
]

const bitrateMarks = [
  { value: 1, },
  { value: 2, },
  { value: 3, }
]

const fpsMarks = [
  { value: 1, },
  { value: 3, },
  { value: 5, },
  { value: 7, },
  { value: 9, },
]

const resolutionValue = {
  Portrait: {
    1: { h: 640, w: 360 },
    3: { h: 1280, w: 720 },
    5: { h: 1920, w: 1080 },
    8: { h: 3840, w: 2160 },
  }, Square: {
    1: { h: 360, w: 360 },
    3: { h: 720, w: 720 },
    5: { h: 1080, w: 1080 },
    8: { h: 1440, w: 1440 },
  }, Youtube: {
    1: { h: 360, w: 640 },
    3: { h: 720, w: 1280 },
    5: { h: 1080, w: 1920 },
    8: { h: 2160, w: 3840 },
  }, Landscape: {
    1: { h: 360, w: 640 },
    3: { h: 720, w: 1280 },
    5: { h: 1080, w: 1920 },
    8: { h: 2160, w: 3840 },
  }, _Portrait: {
    1: { h: 640, w: 360 },
    3: { h: 1280, w: 720 },
    5: { h: 1920, w: 1080 },
    8: { h: 3840, w: 2160 },
  }, _Landscape: {
    1: { h: 360, w: 640 },
    3: { h: 720, w: 1280 },
    5: { h: 1080, w: 1920 },
    8: { h: 2160, w: 3840 },
  }, InstagramStory: {
    1: { h: 640, w: 360 },
    3: { h: 1280, w: 720 },
    5: { h: 1920, w: 1080 },
    8: { h: 3840, w: 2160 },
  }, TikTok: {
    1: { h: 640, w: 360 },
    3: { h: 1280, w: 720 },
    5: { h: 1920, w: 1080 },
    8: { h: 3840, w: 2160 },
  }, Pinterest: {
    1: { h: 640, w: 360 },
    3: { h: 1280, w: 720 },
    5: { h: 1920, w: 1080 },
    8: { h: 3840, w: 2160 },
  }
}

const bitRateValue = {
  1: "0",
  3: "1",
  5: "2",
}

const fpsValues = {
  1: 24,
  3: 25,
  5: 30,
  7: 50,
  9: 60,
}

export const ExportPage = (props) => {
  const { projectName } = props;
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const history = useNavigate();
  const dispatch = useDispatch();
  const projectUUID = useSelector((store) => store.projectdata.projectUUID);
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const loginState = useSelector((store) => store.maindata.loginState);
  const uploadingFiles = useSelector((store) => store.projectdata.uploadingFiles);
  const subcreating = useSelector((store) => store.projectdata.subcreating);
  const [showPricingPageModal, setShowPricingPageModal] = useState(false)

  const [userPlan, setUserPlan] = useState(0)
  useEffect(() => {
    if (!loginState) return;

    (async () => {
      let activePlan = 0
      let result = await getCustomerPlan()

      if (result.state === 'success') {
        let userPlan = result.data
        activePlan = userPlan['product.plan']
      }

      setUserPlan(activePlan || 0)
    })()
  }, [loginState])

  let [firstLoad, setFirstLoad] = useState(false)
  useEffect(() => {
    if (!!firstLoad) return
    if (!ProjectJSON?.fps) return

    let fps = ProjectJSON?.fps
    let fpsKeys = Object.keys(fpsValues)
    let activeKey = fpsKeys.find((key) => fpsValues[key] === fps)
    if (!activeKey) return

    activeKey = Number(activeKey) || 1
    setFrameRate(activeKey)
    setFirstLoad(true)
  }, [ProjectJSON])

  useEffect(() => {
    if (!!firstLoad) return

    let bitrateKeys = Object.keys(bitRateValue)
    let activeBitrateKeys = bitrateKeys.find((key) => bitRateValue[key] === bitrate)
    if (!activeBitrateKeys) return

    activeBitrateKeys = Number(activeBitrateKeys)
    setBitrate(activeBitrateKeys)
    setFirstLoad(true)
  }, [ProjectJSON])
  const [uploading, setUploading] = useState([])
  useEffect(() => {
    let processing = [];
    let keys = Object.keys(uploadingFiles);
    keys.forEach((key) => {
      let file = uploadingFiles[key];
      if (!file.state) processing.push(file);
    })

    setUploading(processing);
  }, [uploadingFiles])

  
  const [frameRate, setFrameRate] = useState(1);
  const [bitrate, setBitrate] = useState(2);
  const [resolution, setResolution] = useState(3);

  const [exportEL, setExportEL] = useState(null);
  const closeExportMenu = () => { setExportEL(null); };
  const openExportMenu = (e) => { setExportEL(e.currentTarget); };

  const [exporting, setExporting] = useState(false);
  const onClickExportBtn = () => {
    if(uploading.length > 0 || subcreating){
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "There is an ongoing upload or an AI auto subtitles in progress, are you sure you want to continue with exporting your project?",
        showConfirmButton: true,
        confirmButtonText: "Export",
        showCancelButton: true,
        cancelButtonText: "Cancel"
      }).then((result) => {
        if (result.isConfirmed) {
          exportBtnClick();
        }
      });      
    }else{
      exportBtnClick();
    }
  }
  const [webhookUrl, setWebhookUrl] = useState('');
  const [ctTime, setCtTime] = useState(0);
  const [isProcessing, setIsProgressing] = useState(false);

  const exportBtnClick = async () => {
    let { label } = getWebGlSizeLevel(ProjectJSON)
    label = label.replaceAll(' ', '').replaceAll('Twitter&Facebook', '_')

    let temp = resolutionValue[label]
    if (!temp || !temp[resolution]) return

    let msg = "";
    if (userPlan == 0) {
      if(resolution > 3) {
        msg = 'To be able export a 1080 or 4K video, please upgrade your subscription';
      }
      if(frameRate > 1){
        msg = 'To be able export with a frame rate higher than 24 fps, please upgrade your subscription';
      }
      if(ProjectJSON.duration > 600000){
        msg = 'To be able export longer than 10 minutes, please upgrade your subscription';
      }
    } else if ((userPlan == 1) || (userPlan == 4)) {
      if(resolution > 5) {
        msg = 'To be able export a 4K video, please upgrade your subscription';
      }
      if(frameRate > 3) {
        msg = 'To be able export with a frame rate higher than 25 fps, please upgrade your subscription';
      }
      if(ProjectJSON.duration > 1500000){
        msg = 'To be able export longer than 25 minutes, please upgrade your subscription';
      }
    } else if ((userPlan == 2) || (userPlan == 3) || (userPlan == 5) || (userPlan == 6)) {
      if(ProjectJSON.duration > 7200000) {
        msg = 'To be able export longer than 2 hours, please contact info@videoo.io';
      }
    }

    if (msg) {
      window.toastr.info(msg)
      setShowPricingPageModal(true)
      return
    }
    dispatch(SaveVideoPlayStarted_Store(false));
    dispatch(SaveVideoPlaying_Store(false));
    const props = [projectUUID, {w: temp[resolution].w, h: temp[resolution].h}, bitrate ];
    setExporting(true);
    projectExportApi(...props).then(response => {
      if (response.state !== 'success') {  return; };
      const exportData = response.data;
      const ifState = exportData.uuid !== projectUUID
      if (ifState || !exportData.asset_uuid) {  return; };
      console.log(response);
      setWebhookUrl(exportData.webhook_url);
      window.location.href=`${HOME_FRONTEND_URL}/view/${projectUUID}/${exportData.asset_uuid}`;
    })
  }
  
  const bitrateChange = (e) => {
    let value = e.target.value
    let check = bitrateMarks.find((mark) => {
      return mark.value === value
    })

    if (!check) return
    setBitrate(value);
  }

  const resoLutionChange = (e) => {
    let value = e.target.value
    let check = resolutionMarks.find((mark) => {
      return mark.value === value
    })

    if (!check) return
    setResolution(value)

  }


  const fpsChange = (e) => {
    let value = e.target.value
    let check = fpsMarks.find((mark) => {
      return mark.value === value
    })

    if (!check) return
    setFrameRate(value)
  }

  useEffect(() => {
    let tempJSON = copyObject(ProjectJSON)
    if (!tempJSON?.fps) return

    let tempfps = tempJSON?.fps
    let fpsValue = fpsValues[frameRate]
    if (tempfps === fpsValue) return

    tempJSON.fps = fpsValue
    dispatch(ProjectJSON_Store(tempJSON))
  }, [frameRate])

  const handleClosePricingModal = () => {
    setShowPricingPageModal(false)
  }

  const downloadAdobeXMLFile = () => {
    console.log('downloadAdobeXMLFile', ProjectJSON);
  
    let xmlData = createPrettyXMLFromJSON(ProjectJSON);
    let xmlString = formatXml(xmlData);
    let blob = new Blob([xmlString], { type: 'text/xml' });
    let url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = `${projectName}.xml`;
    a.click();
  
    // Use Set to ensure uniqueness of asset filenames
    let list_of_assets = new Set();
  
    // Iterate over videos
    Object.values(ProjectJSON.videos).forEach((video) => {
      let assets = Array.isArray(ProjectJSON.asset) ? ProjectJSON.asset : Object.values(ProjectJSON.asset || {});
  
      // Find matching original filenames
      assets.forEach((asset) => {
        console.log("assets ", asset);
        console.log("video.asset_filename: ", video.asset_filename);
  
        if (String(video.asset_filename) === String(asset.filename)) {
          list_of_assets.add(asset.original); // Add only if it matches
        }
      });
    });
  
    console.log("list_of_assets", [...list_of_assets]); // Convert Set to array for logging
  
    // Join the assets with <br> for new lines in Swal
    Swal.fire({
      position: "center",
      icon: "warning",
      title: "Your project file has been downloaded to your default Downloads folder.<br> Please make sure to download all the assets used in the project:<br>" + [...list_of_assets].join('<br>'),
      showConfirmButton: true,
      confirmButtonText: "Ok",
    }).then((result) => {
      // Handle result if necessary
    });
  }

  const downloadAdobeEDLFile = () => {
    console.log('downloadAdobeXMLFile', ProjectJSON);
  
    let edlString = createEDLFromJSON(ProjectJSON);
    let blob = new Blob([edlString], { type: 'text/edl' });
    let url = URL.createObjectURL(blob);
    let a = document.createElement('a');
    a.href = url;
    a.download = `${projectName}.edl`;
    a.click();
  
    // Use Set to ensure uniqueness of asset filenames
    let list_of_assets = new Set();
  
    // Iterate over videos
    Object.values(ProjectJSON.videos).forEach((video) => {
      let assets = Array.isArray(ProjectJSON.asset) ? ProjectJSON.asset : Object.values(ProjectJSON.asset || {});
  
      // Find matching original filenames
      assets.forEach((asset) => {
        console.log("assets ", asset);
        console.log("video.asset_filename: ", video.asset_filename);
  
        if (String(video.asset_filename) === String(asset.filename)) {
          list_of_assets.add(asset.original); // Add only if it matches
        }
      });
    });
  
    console.log("list_of_assets", [...list_of_assets]); // Convert Set to array for logging
  
    // Join the assets with <br> for new lines in Swal
    Swal.fire({
      position: "center",
      icon: "warning",
      title: "Your EDL project file has been downloaded to your default Downloads folder.<br> Please make sure to download all the assets used in the project:<br>" + [...list_of_assets].join('<br>'),
      showConfirmButton: true,
      confirmButtonText: "Ok",
    }).then((result) => {
      // Handle result if necessary
    });
  }  

  return (
    <>
      <Box className='v-headerExport v-spacebetween v-font-2-4 v-font-bold pl-4' onClick={openExportMenu}>
        <span className="v-headerExportText">{languageStrs.export}</span> <FileUploadOutlinedIcon className='v-headerExportIcon v-font-4 md:mr-[20px] md:ml-[15px]' />
      </Box>

      <Menu
        anchorEl={exportEL}
        open={Boolean(exportEL)}
        onClose={closeExportMenu}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        className='export-menu-wrapper'
      >
        <Box className='export-menu-container bg-exportBg text-white gap-10'>
          <CloseOutlinedIcon
            onClick={closeExportMenu}
            className='menu-close-btn text-white'
          />

          <div className='text-16 font-[700] overflow-ellipsis overflow-hidden whitespace-nowrap pr-50'>
            {projectName}
          </div>

          <div className='flex flex-col gap-30 mt-20'>
            <div className='flex flex-col'>
              <div className='text-15 font-[600]'>{languageStrs.resolution}</div>

              <div className='grid grid-cols-4 pt-15'>
                <div className='text-13 text-start'>SD</div>
                <div className='text-13 text-start'>HD</div>
                <div className='text-13 text-start'>Full HD</div>
                <div className='text-13 text-end'>Ultra HD</div>
              </div>

              <div className='flex flex-col w-[95%] m-auto'>
                <Slider value={resolution}
                  step={1} min={1} max={8}
                  aria-label="Custom marks"
                  className='white-span-slider'
                  onChange={resoLutionChange}
                  marks={resolutionMarks}
                />
              </div>

              <div className='grid grid-cols-4'>
                <div className='text-13 text-start'>360p</div>
                <div className='text-13 text-start'>720p</div>
                <div className='text-13 text-start'>1080p</div>
                <div className='text-13 text-end'>2160p - 4K</div>
              </div>
            </div>

            <div className='flex flex-col'>
              <div className='text-15 font-[600]'>{languageStrs.bitrate}</div>

              <div className='flex flex-col w-[95%] m-auto pt-15'>
                <Slider
                  value={bitrate}
                  aria-label="Volume"
                  step={1} min={1} max={3}
                  className='white-span-slider'
                  onChange={bitrateChange}
                  marks={bitrateMarks}
                />
              </div>

              <div className='grid grid-cols-3'>
                <div className='text-13 text-start'>Smaller Size</div>
                <div className='text-13 text-center'>Standard</div>
                <div className='text-13 text-end'>Better Quality</div>
              </div>
            </div>

            <div className='flex flex-col'>
              <div className='text-15 font-[600]'>{languageStrs.frame_rate_fps}</div>

              <div className='flex flex-col w-[95%] m-auto pt-15'>
                <Slider
                  value={frameRate}
                  aria-label="Volume"
                  step={1} min={1} max={9}
                  className='white-span-slider'
                  onChange={fpsChange}
                  marks={fpsMarks}
                />
              </div>

              <div className='grid grid-cols-5 gap-50'>
                <div className='text-13 text-start'>24</div>
                <div className='text-13 text-start'>25</div>
                <div className='text-13 text-center'>30</div>
                <div className='text-13 text-end'>50</div>
                <div className='text-13 text-end'>60</div>
              </div>
            </div>
          </div>

          <div className='flex flex-col mt-20'>
            {!exporting ? (
              <div onClick={() => {onClickExportBtn()}}
                className="flex flex-col justify-center items-center bg-activeBlue py-15 px-20 rounded-lg cursor-pointer"
              >
                <span className='text-20 font-[700] text-white'>{languageStrs.produce}</span>
              </div>
            ) : (
              <div className='flex flex-col justify-center items-center
              bg-activeBlue rounded-lg cursor-pointer'
              >
                <ReactLoading className='flex'
                  type='cylon' color={'white'}
                  height='50px' width='200px'
                />
              </div>
            )}
          </div>

          <div className='flex flex-col mt-20'>
            {!exporting ? (
              <div onClick={() => {downloadAdobeXMLFile()}}
                className="flex flex-col justify-center items-center bg-activeBlue py-15 px-20 rounded-lg cursor-pointer"
              >
                <span className='text-14 font-[700] text-white'>Adobe Pro Premiere Project</span>
              </div>
            ) : (
              <div className='flex flex-col justify-center items-center
              bg-activeBlue rounded-lg cursor-pointer'
              >
                <ReactLoading className='flex'
                  type='cylon' color={'white'}
                  height='50px' width='200px'
                />
              </div>
            )}
          </div>

          <div className='flex flex-col mt-20'>
            {!exporting ? (
              <div onClick={() => {downloadAdobeXMLFile()}}
                className="flex flex-col justify-center items-center bg-activeBlue py-15 px-20 rounded-lg cursor-pointer"
              >
                <span className='text-14 font-[700] text-white'>Davinci Resolve & Final Cut Pro Project</span>
              </div>
            ) : (
              <div className='flex flex-col justify-center items-center
              bg-activeBlue rounded-lg cursor-pointer'
              >
                <ReactLoading className='flex'
                  type='cylon' color={'white'}
                  height='50px' width='200px'
                />
              </div>
            )}
          </div>

          <div className='flex flex-col mt-20'>
            {!exporting ? (
              <div onClick={() => {downloadAdobeEDLFile()}}
                className="flex flex-col justify-center items-center bg-activeBlue py-15 px-20 rounded-lg cursor-pointer"
              >
                <span className='text-14 font-[700] text-white'>EDL Project File for Avid & Adobe Premiere</span>
              </div>
            ) : (
              <div className='flex flex-col justify-center items-center
              bg-activeBlue rounded-lg cursor-pointer'
              >
                <ReactLoading className='flex'
                  type='cylon' color={'white'}
                  height='50px' width='200px'
                />
              </div>
            )}
          </div>

        </Box>
      </Menu>
      <PricingModal open={showPricingPageModal} onCloseModal={handleClosePricingModal} />

    </>
  )
}