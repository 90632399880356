const zoomoutGl =  {
    type: 'zoomout',
    glsl: `uniform float zoom_quickness; // = 0.8
    uniform bool fade; // = true
    float nQuick = clamp(zoom_quickness,0.2,1.0);
    
    vec2 zoom(vec2 uv, float amount) {
      return 0.5 + ((uv - 0.5) * (1.0-amount));	
    }
    
    vec4 transition (vec2 uv) {
      return mix(
        getFromColor(uv),
        getToColor(zoom(uv,1.-smoothstep(1.-nQuick, 1., progress))),
       fade?smoothstep(1.0-nQuick, 1., progress):(progress<1.0-nQuick?0.0:1.0)
      );
    }`,
    defaultParams: {zoom_quickness: 0.8, fade: true},
    paramsTypes: {zoom_quickness: 'float', fade: 'bool'}
  }

  export default zoomoutGl