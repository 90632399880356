import { TOOLKEY, SUBTITLESTATEKEY, ELEMENTSTATEKEY, 
  DRAWSTATEKEY, MENUBARREF, CAPTUREDIALOG, LANGUAGESTRS, 
  ISELEMENTDRAGING, ISCHANGSEGMENT, STARTSEGMENT, ENDSEGMENT, ISTIMELINESCROLL } from "./../constants/editConst";

export const menubarRef_Store = (params) => {
  return (dispatch) => { dispatch({ type: MENUBARREF, payload: params, }); }
}

export const SelectedEditTool_Store = (params) => {
  return (dispatch) => { dispatch({ type: TOOLKEY, payload: params, }); }
}

export const SubtitleState_Store = (params) => {
  return (dispatch) => { dispatch({ type: SUBTITLESTATEKEY, payload: params, }); }
};

export const ElementState_Store = (params) => {
  return (dispatch) => { dispatch({ type: ELEMENTSTATEKEY, payload: params }); }
};

export const DrawState_Store = (params) => {
  return (dispatch) => { dispatch({ type: DRAWSTATEKEY, payload: params, }); }
};

export const CaptureDialog_Store = (params) => {
  return (dispatch) => { dispatch({ type: CAPTUREDIALOG, payload: params, }); }
};

export const languageStrs_Store = (params) => {
  return (dispatch) => { dispatch({ type: LANGUAGESTRS, payload: params, }); }
}

export const isElementDraging_Store = (params) => {
  return (dispatch) => { dispatch({ type: ISELEMENTDRAGING, payload: params }); }
}

export const isChangeSegment_Store = (params) => {
  return (dispatch) => { dispatch({ type: ISCHANGSEGMENT, payload: params }); }
}

export const startSegment_Store = (params) => {
  return (dispatch) => { dispatch({ type: STARTSEGMENT, payload: params }); }
}

export const endSegment_Store = (params) => {
  return (dispatch) => { dispatch({ type: ENDSEGMENT, payload: params }); }
}

export const isTimelineScroll_Store = (params) => {
  return (dispatch) => { dispatch({ type: ISTIMELINESCROLL, payload: params }); }
}