import { mat4 } from 'gl-matrix';
import { getGLContext, getTextWidth } from '../commonFunc';
import { filtersList } from '../../tooltap/tooltap.config';
import { MoveableDiv } from '../Calculators/MoveableDiv';
import { CanvasTextRowSplitter } from '../TextHelpers/TextHelper';

import { getTransitionInfo, getSelectedType, hexToRgbA, hexToVec } from '../../commonFunction';
import { vsShader, vsGrShader } from './VsShader';
import { fsShader, fsFilterShader, fsGrShader } from './FsShader';
import bgImg from '../../../../assets/image/videoplaceholder.jpg';
// import webglFilter from '@longlost/webgl-filter/webgl-filter.js';

export const initShaderProgram = (gl) => {
  const vertexShader = LoadShader(gl, gl.VERTEX_SHADER, vsShader);
  const fragmentShader = LoadShader(gl, gl.FRAGMENT_SHADER, fsFilterShader);

  // Create the shader program
  const shaderProgram = gl.createProgram();
  gl.attachShader(shaderProgram, vertexShader);
  gl.attachShader(shaderProgram, fragmentShader);
  gl.linkProgram(shaderProgram);

  // If creating the shader program failed, alert
  if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
    alert('Unable to initialize the shader program: ' + gl.getProgramInfoLog(shaderProgram));
    return null;
  }

  return shaderProgram;
}



export const LoadShader = (gl, type, source) => {
  const shader = gl.createShader(type);

  // Send the source to the shader object
  gl.shaderSource(shader, source);

  // Compile the shader program
  gl.compileShader(shader);

  // See if it compiled successfully
  if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
    alert('An error occurred compiling the shaders: ' + gl.getShaderInfoLog(shader));
    gl.deleteShader(shader);
    return null;
  }

  return shader;
}

/*export const DrawTransition = (glTransitionData, progress, transitionFrom, transitionTo, width, height, prop)=>{
  glTransitionData.draw(progress, transitionFrom, transitionTo, width, height, prop)
}*/
export const setGLBackground = (gl, WebGLContext, backImg)=>{
  let programInfo = WebGLContext.programInfo;

  const texturebg = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texturebg);

  // Set the parameters so we can render any size image.
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.NEAREST);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.NEAREST);

  // Upload the image into the texture.
  gl.texImage2D(
    gl.TEXTURE_2D,
    0,
    gl.RGBA,
    gl.RGBA,
    gl.UNSIGNED_BYTE,
    backImg
  );


  gl.uniform1i(programInfo.uniformLocations.textGround, 1);
  gl.activeTexture(gl.TEXTURE1);
  gl.bindTexture(gl.TEXTURE_2D, texturebg);
  gl.clearColor(0, 0, 0, 0);
}

export const DrawScene = (gl, WebGLContext, ProjectJSON, groupData, currentTime) => {
  const glbuffer = gl.createBuffer();
  currentTime = currentTime.getTime();
  let programInfo = WebGLContext.programInfo;
  let tempJSON = JSON.parse(JSON.stringify(ProjectJSON))

  let bg_color = hexToRgbA('#' + tempJSON.bg_color);
  let gl_color = [bg_color.r / 255, bg_color.g / 255, bg_color.b / 255, bg_color.a];

  // Draw the scene.
  //gl.clearColor(...gl_color); // Clear to black, fully opaque
  if(window.isGreenVideo){
    gl.clearColor(0, 0, 0, 0);
  }else{
    gl.clearColor(...gl_color);
  }
  
  gl.clearDepth(1.0); // Clear everything
  gl.enable(gl.DEPTH_TEST); // Enable depth testing GEQUAL
  gl.depthFunc(gl.LEQUAL); // Near things obscure far things
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE);

  gl.enable(gl.BLEND);
  gl.enable(gl.CULL_FACE);
  gl.enable(gl.SAMPLE_COVERAGE);
  gl.enable(gl.SAMPLE_ALPHA_TO_COVERAGE); // Enable alpha
  gl.blendFunc(gl.SRC_ALPHA, gl.ONE_MINUS_SRC_ALPHA);

  // Clear the canvas before we start drawing on it.
  gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);

  // Create a perspective matrix, a special matrix that is
  // used to simulate the distortion of perspective in a camera.
  // Our field of view is 45 degrees, with a width/height
  // ratio that matches the display size of the canvas
  // and we only want to see objects between 0.1 units
  // and 100 units away from the camera.
  const fieldOfView = (45 * Math.PI) / 180; // in radians

  const aspect = gl.canvas.clientWidth / gl.canvas.clientHeight;
  const zNear = 0.1;
  const zFar = 100.0;
  const projectionMatrix = mat4.create();

  // note: glmatrix.js always has the first argument
  // as the destination to receive the result.
  mat4.perspective(projectionMatrix, fieldOfView, aspect, zNear, zFar);

  // Tell WebGL to use our program when drawing
  gl.useProgram(programInfo.program);

  if(window.isGreenVideo){
    gl.uniform1f(programInfo.uniformLocations.isGreen, 1.0);
  }else{
    gl.uniform1f(programInfo.uniformLocations.isGreen, 0.0);
  }

  // Set the shader uniforms
  gl.uniformMatrix4fv(programInfo.uniformLocations.projectionMatrix, false, projectionMatrix);

  let filterType = ProjectJSON?.filters?.preset;
  let checkType = filtersList.find((filter) => filter.type === filterType);
  if (!checkType) checkType = filtersList[0];
  let preset = new Float32Array(checkType.mat4)
  
  let filterColorVal = hexToVec(checkType.color);
  
  //console.log("colorData", preset);
  // set filter color
  //gl.uniform3fv(programInfo.uniformLocations.editValue, '#FFFFFF');
  
  gl.uniform1f(programInfo.uniformLocations.blackWhiteFilter, checkType.dark);
  gl.uniform3fv(programInfo.uniformLocations.editValue, filterColorVal);
  
  //gl.uniform4fv(programInfo.uniformLocations.filterPreset_v, [0, 0, 0, 0]);
  //gl.uniformMatrix4fv(programInfo.uniformLocations.filterPreset_m, false, preset);
  

  WebGLContext.activeModelViewMatrices.forEach(function (value, key) {
    let alpha = 1.0;
    let opacity = 1.0;
    let type = getSelectedType(key, tempJSON);

    if (!!type) {
      let ItemOb = Object.assign({}, tempJSON[type][key]);
      opacity = ItemOb?.alpha;

      let groupOptions = groupData[ItemOb.groups];
      let tsInfo = getTransitionInfo(groupOptions, key);
    
      let { maxEnd, minStart, clashID, clashID_ } = tsInfo;

      let transition = { s: null, e: null };
      let startTime = ItemOb.timeline_start;
      let endTime = ItemOb.timeline_end;

      let ts = ItemOb.transition;
      if (startTime === minStart && !!ts.start.preset) {
        
        transition.s = ts.start;
      }
      if (endTime === maxEnd && !!ts.end.preset) {
        
        transition.e = ts.end;
      }

      if (!!clashID) {
        let clashOb = groupOptions[clashID];
        let tsClash = clashOb.transition.start;
        if (ts.end.preset === tsClash.preset && !!ts.end.preset) transition.e = ts.end;
      }

      if (!!clashID_) {
        let clashOb = groupOptions[clashID_];
        let tsClash = clashOb.transition.end;
        if (ts.start.preset === tsClash.preset && !!ts.start.preset) transition.s = ts.start;
      }

     // let sDuration = transition.s?.duration;
     // let eDuration = transition.e?.duration;
      //if (!!sDuration && currentTime - startTime < sDuration){ alpha = (currentTime - startTime) / sDuration;}
     // else if (!!eDuration && endTime - currentTime < eDuration){alpha = (endTime - currentTime) / eDuration;} 
    }

    //gl.sampleCoverage(alpha * opacity, false);
    //console.log("transiparnce", alpha * opacity);
    gl.uniform1f(programInfo.uniformLocations.transparency, alpha * opacity);
    
    gl.uniformMatrix4fv(programInfo.uniformLocations.modelViewMatrix, false, value);
    // Tell WebGL how to pull out the positions from the position
    // buffer into the vertexPosition attribute
    {
      const numComponents = 3; // every coordinate composed of 2 values
      const type = gl.FLOAT; // the data in the buffer is 32-bit float
      const normalize = false; // don't normalize
      const stride = 0; // how many bytes to get from one set to the next
      const offset = 0; // how many bytes inside the buffer to start from

      if (!WebGLContext.activeBuffers.get(key)) return;
      //gl.bindBuffer(gl.ARRAY_BUFFER, glbuffer);
      gl.bindBuffer(gl.ARRAY_BUFFER, WebGLContext.activeBuffers.get(key).position);
      gl.vertexAttribPointer(programInfo.attribLocations.vertexPosition, numComponents, type, normalize, stride, offset);
      gl.enableVertexAttribArray(programInfo.attribLocations.vertexPosition);
    }

    // Tell WebGL how to pull out the texture coordinates from
    // the texture coordinate buffer into the textureCoord attribute.
    {
      const numComponents = 2;
      const type = gl.FLOAT;
      const normalize = false;
      const stride = 0;
      const offset = 0;
      //gl.bindBuffer(gl.ARRAY_BUFFER, glbuffer);
      gl.bindBuffer(gl.ARRAY_BUFFER, WebGLContext.activeBuffers.get(key).textureCoord);
      gl.vertexAttribPointer(programInfo.attribLocations.textureCoord, numComponents, type, normalize, stride, offset);
      gl.enableVertexAttribArray(programInfo.attribLocations.textureCoord);
    }

    // Tell WebGL which indices to use to index the vertices
    gl.bindBuffer(gl.ARRAY_BUFFER, glbuffer);
    //gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, WebGLContext.activeBuffers.get(key).indices);

    // Tell WebGL we want to affect texture unit 0
    gl.activeTexture(gl.TEXTURE0 + key);

    // Bind the texture to texture unit 0
    let currentTexture = WebGLContext.activeTextures.get(key)
    gl.bindTexture(gl.TEXTURE_2D, currentTexture);

    // Tell the shader we bound the texture to texture unit 0
    gl.uniform1i(programInfo.uniformLocations.uSampler, key);
    {
      const vertexCount = 6; //for one face
      const type = gl.UNSIGNED_SHORT;
      const offset = 0;
      gl.drawElements(gl.TRIANGLES, vertexCount, type, offset);
    }
  })
}

export const InitBuffers = (gl) => {
  const positionBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

  const positions = [-1, -1, 1, 1, -1, 1, 1, 1, 1, -1, 1, 1,];
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

  const textureCoordBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ARRAY_BUFFER, textureCoordBuffer);

  const textureCoordinates = [0.0, 0.0, 1.0, 0.0, 1.0, 1.0, 0.0, 1.0];
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(textureCoordinates), gl.STATIC_DRAW);

  const indexBuffer = gl.createBuffer();
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, indexBuffer);

  const indices = [0, 1, 2, 0, 2, 3];
  gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(indices), gl.STATIC_DRAW);

  return {
    position: positionBuffer,
    textureCoord: textureCoordBuffer,
    indices: indexBuffer,
  };
}

export const initVideoTexture =  (gl) => {
  //const gbIm = await loadImage(bgImg);
  const texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);
  gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);

  const srcFormat = gl.RGBA;
  const width = 1, height = 1;
  const level = 0, border = 0;
  const internalFormat = gl.RGBA;
  const srcType = gl.UNSIGNED_BYTE;
  const pixel = new Uint8Array([0, 100, 100, 255]);
  
  gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, width, height, border, srcFormat, srcType, pixel);

  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);

  return texture;
}

export const UpdateServerBuffers = (buffer, elementWidth, elementHeight, canvas) => {
  const canvasHeight = canvas.offsetHeight;
  const canvasWidth = canvas.offsetWidth;
  const gl = getGLContext(canvas);

  const positionBuffer = buffer.position;
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

  let elementAspectRatio = elementHeight / elementWidth;
  let canvasAspectRatio = canvasHeight / canvasWidth;

  let updater = 0;
  if (elementWidth / elementHeight > canvasWidth / canvasHeight) updater = -2.415 * (canvasAspectRatio / elementAspectRatio);
  else updater = -2.415;

  const positions = [-1 / elementAspectRatio, -1, updater, 1 / elementAspectRatio, -1, updater, 1 / elementAspectRatio, 1.0, updater, -1 / elementAspectRatio, 1.0, updater,];
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

  return {
    position: positionBuffer,
    textureCoord: buffer.textureCoord,
    indices: buffer.indices,
  };
}


export const SetupServerTextCanvas = (textInfo, textCanvas, projectJSON, canvas) => {
  let tempTextCanvas = textCanvas;
  if (textInfo.context) {
    var ctx = textCanvas.getContext('2d');
    let [width, height] = getTextWidthHeight(projectJSON, textInfo, canvas);
    tempTextCanvas = setUpTextTexture(ctx, width, height, textInfo, projectJSON, canvas)
  }

  return tempTextCanvas;
}

export const InitTextTexture = (gl, textCanvas) => {
  const texture = gl.createTexture();
  gl.bindTexture(gl.TEXTURE_2D, texture);

  const level = 0;
  const internalFormat = gl.RGBA;
  const srcFormat = gl.RGBA;
  const srcType = gl.UNSIGNED_BYTE;
  const src = textCanvas;
  gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, src);
  gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);

  if (IsPowerOf2(textCanvas.width) && IsPowerOf2(textCanvas.height)) gl.generateMipmap(gl.TEXTURE_2D);
  else {
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
  }

  return texture;
}

export const SetupServerText = (tempBuffer, tempMoveableDiv, textInfo, WebGLContext, newUUID, canvas, projectJSON) => {
  let moveableDiv = new MoveableDiv();
  const gl = getGLContext(canvas);

  const canvasH = canvas.offsetHeight;
  const canvasW = canvas.offsetWidth;
  let divHeight = textInfo.dimension.h;
  let divWidth = textInfo.dimension.w;

  const ratio = canvasW / projectJSON.width;
  const ratioWH = textInfo.ratioWH;
  const ratioW = projectJSON.width / ratioWH.w;
  const ratioH = projectJSON.height / ratioWH.h;

  let textElementHeight = divHeight * ratio * ratioH;
  let textElementWidth = divWidth * ratio * ratioW;
  tempBuffer = UpdateBuffers(tempBuffer, gl, textElementHeight, textElementWidth, canvasH, canvasW);
  WebGLContext.activeBuffers.set(newUUID, tempBuffer);

  tempMoveableDiv = moveableDiv.divDragResizeRotationUpdate(tempMoveableDiv, textInfo, canvas, projectJSON);
  tempMoveableDiv.style.border = '2px dashed purple';

  return [tempBuffer, tempMoveableDiv];
}

export const UpdateBuffers = (buffer, gl, elementHeight, elementWidth, canvasHeight, canvasWidth) => {
  // Create a buffer for the cube's vertex positions.
  const positionBuffer = buffer.position;

  // Select the positionBuffer as the one to apply buffer
  // operations to from here out.
  gl.bindBuffer(gl.ARRAY_BUFFER, positionBuffer);

  // Now create an array of positions for the textures.
  let elementAspectRatio = elementHeight / elementWidth;
  let canvasAspectRatio = canvasHeight / canvasWidth;

  let updater = 0;
  if (elementWidth / elementHeight > canvasWidth / canvasHeight)
    updater = -2.415 * (canvasAspectRatio / elementAspectRatio);
  else updater = -2.415;

  const positions = [
    // first
    -1 / elementAspectRatio, -1, updater,
    //second
    1 / elementAspectRatio, -1, updater,
    //third
    1 / elementAspectRatio, 1.0, updater,
    //forth
    -1 / elementAspectRatio, 1.0, updater,
  ];

  // Now pass the list of positions into WebGL to build the
  // shape. We do this by creating a Float32Array from the
  // JavaScript array, then use it to fill the current buffer.
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(positions), gl.STATIC_DRAW);

  return {
    position: positionBuffer,
    textureCoord: buffer.textureCoord,
    indices: buffer.indices,
  };
}
const loadImage = async (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve(img);
    };
    img.src = src;
  });
}

export const UpdateVideoTextures = (gl, texture, video) => {
 // if (video.readyState !== undefined && video.readyState === 0) return; 
  //const gbIm = await loadImage(bgImg);
  const level = 0;
  const srcFormat = gl.RGBA;
  const internalFormat = gl.RGBA;
  const srcType = gl.UNSIGNED_BYTE;
  
  gl.bindTexture(gl.TEXTURE_2D, texture);
  
  gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, video);
   
}

export const UpdateTextTextures = (gl, texture, textCanvas) => {
  gl.bindTexture(gl.TEXTURE_2D, texture);

  const level = 0;
  const src = textCanvas;
  const srcFormat = gl.RGBA;
  const internalFormat = gl.RGBA;
  const srcType = gl.UNSIGNED_BYTE;
  gl.texImage2D(gl.TEXTURE_2D, level, internalFormat, srcFormat, srcType, src);
  gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);

  return texture;
}

export const wrapText = function (ctx, text, maxWidth) {
  let line = '';
  let testLine = '';
  let lineArray = [];
  let words = text.split(' ');

  for (var n = 0; n < words.length; n++) {
    testLine += `${words[n]} `;
    let metrics = ctx.measureText(testLine);
    let testWidth = metrics.width;

    if (testWidth > maxWidth && n > 0) {
      lineArray.push(line);
      line = `${words[n]} `;
      testLine = `${words[n]} `;
    } else line += `${words[n]} `;

    if (n === words.length - 1) lineArray.push(line);
  }

  return lineArray;
}

export const setUpTextTexture = (ctx, width, height, textInfo, projectJSON, canvas, edit) => {
  let canvasWidth = canvas.offsetWidth;
  let projectWidth = projectJSON.width;
  let canvasRatio = canvasWidth / projectWidth;

  ctx.canvas.width = width;
  ctx.canvas.height = height;
  let textRows = CanvasTextRowSplitter(textInfo.context);

  let alignment;
  if (textInfo.properties.alignment === 'center') alignment = width / 2
  else if (textInfo.properties.alignment === 'right') alignment = width
  else alignment = 0;

  let bold = textInfo.properties.bold;
  let italic = textInfo.properties.italic;
  let font_size = textInfo.properties.font_size * 3;
  let font_family = textInfo.properties.font_family;
  let textLineHeight = (font_size * 1.3) * canvasRatio;
  font_size = font_size * canvasRatio;

  ctx.font = `${italic} ${bold} ${font_size}px ${font_family}`;
  ctx.clearRect(0, 0, width, height);
  ctx.fillStyle = textInfo.properties.background_color;
  ctx.fillRect(0, 0, width, height);
  ctx.fillStyle = textInfo.properties.text_color;
  ctx.textAlign = textInfo.properties.alignment;

  let maxHeight = textLineHeight * textRows.length;
  let diffHeight = (height - maxHeight) / textRows.length;
  textRows.forEach((text, i) => {
    let tempHeight = textLineHeight * (i + 0.75)
    if (maxHeight < height) tempHeight += diffHeight * (i + 0.5);

    let tempText = text;
    if (!!edit && textRows.length === i + 1) tempText += '|';
    ctx.fillText(tempText, alignment, tempHeight);
  })

  return ctx.canvas;
}

export const getTextWidthHeight = (projectJSON, textInfo, canvas) => {
  const canvasH = canvas.offsetHeight;
  const canvasW = canvas.offsetWidth;
  let divHeight = textInfo.dimension.h;
  let divWidth = textInfo.dimension.w;

  const ratio = canvasW / projectJSON.width;
  const ratioWH = textInfo.ratioWH;
  const ratioW = projectJSON.width / ratioWH.w;
  const ratioH = projectJSON.height / ratioWH.h;

  let textHeight = divHeight * ratio * ratioH;
  let textWidth = divWidth * ratio * ratioW;

  return [textWidth, textHeight];
}

export const getContentWidthHeight = (text, projectJSON, textInfo, canvas) => {
  let canvasWidth = canvas.offsetWidth;
  let projectWidth = projectJSON.width;
  let canvasRatio = canvasWidth / projectWidth;

  let bold = textInfo.properties.bold;
  let font_family = textInfo.properties.font_family;
  let font_size = textInfo.properties.font_size * 3;
  let options = { font: font_family, fontSize: font_size + 'px', fontWeight: bold };

  let maxWidth = 0;
  let textRows = CanvasTextRowSplitter(text);
  let maxHeight = (font_size * 1.3) * textRows.length;
  textRows.forEach((tempText) => {
    let size = getTextWidth(tempText, options)
    let tempWidth = size.width + 60;
    if (maxWidth < tempWidth) maxWidth = tempWidth;
  })

  return [maxWidth * canvasRatio, maxHeight * canvasRatio, canvasRatio];
}






export const IsPowerOf2 = (value) => {
  return (value & (value - 1)) === 0;
}