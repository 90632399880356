function formatTimecode(ms, fps) {
    const totalFrames = Math.round(ms / 1000 * fps);
    const frames = totalFrames % fps;
    const seconds = Math.floor(totalFrames / fps) % 60;
    const minutes = Math.floor(totalFrames / (fps * 60)) % 60;
    const hours = Math.floor(totalFrames / (fps * 3600));

    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}:${pad(frames)}`;
}

function pad(value) {
    return value.toString().padStart(2, '0');
}

function createEDLEntry(eventNumber, videoData, fps) {
    const timelineIn = formatTimecode(videoData.timeline_start, fps);
    const timelineOut = formatTimecode(videoData.timeline_end, fps);
    const segmentIn = formatTimecode(videoData.segment_start, fps);
    const segmentOut = formatTimecode(videoData.segment_end, fps);

    return `
${pad(eventNumber, 3)}  AX  V     C        ${segmentIn} ${segmentOut} ${timelineIn} ${timelineOut}
* FROM CLIP NAME: ${videoData.original_filename}
`;
}

export function createEDLFromJSON(projectJson) {
    const fps = projectJson.fps || 24;
    const title = projectJson.title || "Untitled Project";
    let videos = Array.isArray(projectJson.videos) ? projectJson.videos : Object.values(projectJson.videos || {});
    let assets = projectJson.asset ? projectJson.asset : {};

    let edl = `TITLE: ${title}\nFCM: NON-DROP FRAME\n\n`;

    let eventNumber = 1;
    for (const videoKey in videos) {
        if (videos.hasOwnProperty(videoKey)) {
            const videoData = videos[videoKey];

            // Initialize variable to hold original_filename
            let original_filename = null;

            // Loop through videos to find matching asset by uuid
            videos.forEach((video) => {
                // Compare the video.asset_uuid with the keys in assets (which are the UUIDs)
                Object.keys(assets).forEach((asset_uuid) => {
                    console.log("videos.forEach -  Object.keys(assets) : ", asset_uuid, " - ");
                    if (String(video.asset_uuid) === asset_uuid) {
                        console.log("videos.forEach -  Object.keys(assets) : String(video.asset_uuid) === asset_uuid : ", video.original);
                        original_filename = assets[asset_uuid].original;
                    }
                });
            });
            videoData.original_filename = original_filename;

            edl += createEDLEntry(eventNumber, videoData, fps);
            eventNumber++;
        }
    }

    return edl;
}
