import { v4 as uuidv4 } from 'uuid';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Divider } from '@mui/material';
import { MenuItem, Select, Button } from '@mui/material';

import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';

import LinearProgress from '@mui/material/LinearProgress';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import Swal from 'sweetalert2';
import { changeZeroSecond, convertStringtoWStringUTF16, getMaxZIndex } from '../commonFunction';
import { defaultPreviewStyle } from './tooltap.config';
import { SectionHeader } from './common/section.header';
import { getNewTextInformation } from '../webGL/commonFunc';
import { GetProjectUUID } from '../../../assets/script/commonFunction';
import { changedJSON_Store, ProjectJSON_Store, subCreating_Store, subtitlePostY_store } from '../../../store/actions/projectdata';
import { SERVER_STATUS, createSubtitle, getProjectJSON, subtitleWebHookPoll, getSubtitleInfo, updateSubtitleInfo } from '../../../axios/ApiProvider';
import PricingModal from '../../../pages/Pricing/pricingModal';
import { ColorPicker } from './common/colorPicker';
import { fontFamilyOptions } from './common/TextCommonFunc';
import { fontSizeOptions } from './common/TextCommonFunc';

import srtParser2 from "srt-parser-2";

let loadedSubKey = []
const languageList = [
  { label: 'Global English', key: 'en' },
  { label: 'Australian English', key: 'en_au' },
  { label: 'British English', key: 'en_uk' },
  { label: 'US English', key: 'en_us' },
  { label: 'Spanish', key: 'es' },
  { label: 'French', key: 'fr' },
  { label: 'German', key: 'de' },
  { label: 'Italian', key: 'it' },
  { label: 'Portuguese', key: 'pt' },
  { label: 'Dutch', key: 'nl' },
  { label: 'Hindi', key: 'hi' },
  { label: 'Japanese', key: 'ja' }
]
let subtitleUUIDKey = '';

const inputStyle = { position: 'absolute', top: 0, left: 0, opacity: 0 };

export const TooltapSubtitles = () => {
  const fileInputer = useRef();
  const dispatch = useDispatch();
  const projectUUID = GetProjectUUID();
  const uploadingFiles = useSelector((store) => store.projectdata.uploadingFiles);
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const loginState = useSelector((store) => store.maindata.loginState)
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON)
  const subcreating = useSelector((store) => store.projectdata.subcreating)
  

  
  const [language, setLanguage] = useState('en')
  const [firstLoad, setFirstLoad] = useState(true)
  const [textStyles, setTextStyles] = useState(defaultPreviewStyle);
  const subtitlePosYStore = useSelector((store) => store.projectdata.subtitlePosY);
  const [subtitlePosY, setSubtitlePosY] = useState(0);
  // const [subtitleUUIDKey, setSubtitleUUIDKey] = useState('');
 
  useEffect(() => {
    setSubtitlePosY(subtitlePosYStore);
  }, [subtitlePosYStore]);
  const onSetSubtitlePosY = (val) => {
    dispatch(subtitlePostY_store(val));
    // setSubtitlePosY(val);
  }
  
  const [showPricingPageModal, setShowPricingPageModal] = useState(false);
  const handleClosePricingModal = () => {
    setShowPricingPageModal(false)
  }

  const changeCreating = (flag) => {
    dispatch(subCreating_Store(flag))
  }

  const [openUploading, setOpenUploading] = useState(false);
  useEffect(() => {
    let loadCount = 0;
    let keys = Object.keys(uploadingFiles);
    if(keys.length > 0){
      keys.map(kItem => {
        let tmpFile = uploadingFiles[kItem];
        if(tmpFile.state==true){
          loadCount++;
        }
      })
      if(loadCount == keys.length){
        setOpenUploading(false);
      }else{
        setOpenUploading(true);
      }
      
    }else{
      setOpenUploading(false);
    }
  
  }, [uploadingFiles])

  
  const [subtitleUUID, setSubtitleUUID] = useState('');

  useEffect(() => {
    if (!firstLoad) return
    if (!ProjectJSON || !ProjectJSON?.subtitles) return;
    setFirstLoad(false);

    let assets = Object.assign({}, ProjectJSON.asset);
    let assetKeys = Object.keys(assets)
    if (!assetKeys.length) return;

    let subtitles = Object.assign({}, ProjectJSON.subtitles);
    let subKeys = Object.keys(subtitles);
    if(subKeys){
      setSubtitleUUID(subKeys[0]);
    }
    let checkKey = subKeys.find((key) => {
      let before = loadedSubKey.find((k) => key === k)
      return !before && subtitles[key].state !== 3
    });

    if (checkKey) {
      loadedSubKey.push(checkKey)
      getSubtitleWebHook(assetKeys[0], 'manual');
      changeCreating(true);
    }
  }, [ProjectJSON])

  // useEffect(() => {
  //   // getSubtitleInfo(subtitleUUID);
  // }, [subtitleUUID])

  const [userData, setUserData] = useState({})
  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')

    if (!!item && JSON.parse(item)?.state === 'loggedin') {
      tempData = JSON.parse(item)
    }

    setUserData(tempData)
  }, [loginState])
  const [progressTotal, setProgressTotal] = useState(0);
  const [progressVal, setProgressVal] = useState(0);
  
  useEffect(() => {
    if(progressTotal > 0){
      setTimeout(function() {
        let tmpProgressVal = Math.round(progressVal + ((1/progressTotal) * 100));
        if(tmpProgressVal > 100){
          tmpProgressVal = 100;
        }
        setProgressVal(tmpProgressVal);
      }, 1000)
    }
  }, [progressTotal, progressVal])
  
  const autoSubtitleClick = async () => {
    if (!loginState || !userData?.subtitle_state) {
      //window.toastr.error('You have spent your Subtitle request limits with your user, consider upgrading your subscription')
      setShowPricingPageModal(true);
      return
    }

    let flag = userData?.storage_usage_state
    if (!flag) {
      //window.toastr.error('You have run out of Cloud Storage Space with your current plan, you need to upgrade your account to upload files to a project')
      setShowPricingPageModal(true);
      return
    }

    if (subcreating) return;
    const tmpTotalVal = (ProjectJSON.duration / 1000) > 70? ((ProjectJSON.duration / 1000) / 3) + 30 : 60;
    setProgressTotal(tmpTotalVal);
    setProgressVal(0);
    let assets = Object.assign({}, ProjectJSON.asset)
    let videos = Object.assign({}, ProjectJSON.videos)

    let assetKeys = Object.keys(assets)
    let videoKeys = Object.keys(videos)
    if (!assetKeys.length || !videoKeys.length) return;
    if (!assetKeys[0]) return;

    changeCreating(true);
    let resData = await createSubtitle(projectUUID, assetKeys[0], language);

    if (resData.state !== 'success') {
      changeCreating(false);
      setProgressTotal(0);
      setProgressVal(0);
      return;
    }

    //if (resData.data.Code !== 'Subtitle Submit success.') {
    if(!resData.data.uuid){

      changeCreating(false);
      setProgressTotal(0);
      setProgressVal(0);
      return;
    }
    subtitleUUIDKey = resData.data.uuid;
    getSubtitleWebHook(assetKeys[0], 'auto');
  }

  const getSubtitleWebHook = async (asset_uuid, type) => {
    const JSONdata = await getProjectJSON(projectUUID);
    if (JSONdata.state !== 'success') {
      changeCreating(false);
      setProgressTotal(0);
      setProgressVal(0);
      return;
    }

    const projectData = JSONdata?.data[projectUUID];
    if (!projectData) {
      changeCreating(false);
      setProgressTotal(0);
      setProgressVal(0);
      return;
    }

    if (SERVER_STATUS === 'localhost' && type === 'auto') {
      projectData.subtitles = { "e904f0db-eafb-4c58-9aaf-63a4a6f037de": { "state": 2 } };
    }

    let newSubtitle = Object.assign({}, projectData.subtitles);
    let newSubKeys = Object.keys(newSubtitle);

    if (!newSubKeys.length) {
      changeCreating(false);
      setProgressTotal(0);
      setProgressVal(0);
      return;
    }

    let subKey = newSubKeys.find((key) => newSubtitle[key].state !== 3);
    if (!subKey) {
      changeCreating(false);
      setProgressTotal(0);
      setProgressVal(0);
      return;
    }
    
    let subState = newSubtitle[subKey].state;
    if (subState !== 2 && subState !== 3) {
      setTimeout(() => {
        getSubtitleWebHook(asset_uuid, type, 2);
      }, 3000);

      return;
    }

    let webhookRes = await subtitleWebHookPoll(projectUUID, asset_uuid, subKey, 1);
    let webhookData = webhookRes.data;

    changeCreating(false);
    setProgressTotal(0);
    setProgressVal(0);
    if (webhookRes.state !== 'success') return
    if (webhookData?.vendor !== "ASSEMBLYAI") return

    if (type === 'manual') {
      let newJSONdata = Object.assign({}, ProjectJSON);
      newJSONdata.subtitles = { ...newJSONdata.subtitles, [subKey]: { state: 3 } };
      
      updateSubtitleInfo(subKey, textStyles);

      dispatch(ProjectJSON_Store(newJSONdata));
    }

    let manifest = Object.assign({}, webhookData.manifest);
    let words = manifest?.words;
    
    await parseWordsToTexts(words, asset_uuid);
    await subtitleWebHookPoll(projectUUID, asset_uuid, subKey, 3);
  }

  const parseWordsToTexts = async (words, asset_uuid) => {
    const groupID = uuidv4();
    // const subtitleID = uuidv4();
    const subtitleID = subtitleUUIDKey;
    if (!words || !words?.length) return;
    let zIndex = getMaxZIndex(ProjectJSON) + 3;

    
    let sentences = [];
    words.sort((a, b) => { return a.end - b.start; });
    words.forEach((item) => {
      let tempItem = Object.assign({}, item);
      let lastIndex = sentences.length - 1;
      if (lastIndex < 0) {
        sentences.push([tempItem]);
      } else {
        let lastItem = sentences[lastIndex];
        let childItem = lastItem[lastItem.length - 1];
        const lastChr = childItem.text.substr(childItem.text.length - 1);
        if(['.', ',', '?', '!', ';', ':'].includes(lastChr)){
          sentences.push([tempItem]);
        }else{
          if (tempItem.start - childItem.end < 1000) lastItem.push(tempItem); else sentences.push([tempItem]);
        }
      }
      // 
      // else {
      //   let lastItem = tempWords[lastIndex];
      //   if (lastItem.length >= 5) tempWords.push([tempItem]);
      //   else {
      //     let childItem = lastItem[lastItem.length - 1];
      //     if (tempItem.start - childItem.end < 1000) lastItem.push(tempItem);
      //     else tempWords.push([tempItem]);
      //   }
      // }
    })

    let tempWords = [];
    sentences.forEach((sitems) => {
      if(sitems.length < 6){
        tempWords.push(sitems);
      }else{
        const divCount = Math.ceil(sitems.length / Math.round(sitems.length / 5))
        let startId = 0;
        while(sitems.length > 0){
          const slicedItem = sitems.splice(startId, divCount)
          tempWords.push(slicedItem);
          // startId += divCount+1;
        }
      }
    })
    let newWords = [];
    tempWords.forEach((items) => {
      let tempItem = items[0];
      items.forEach((item, index) => {
        tempItem.end = item.end;
        if (index > 0) tempItem.text += ' ' + item.text;
      })
      newWords.push(tempItem);
    })
    let loopNum = 0;
    let addTextInfo = {};
    let tempTextOb = null
    newWords.forEach(async (item) => {
      if (!!item.text && item.start >= 0 && item.end >= 0) {
        let checkDBItem = await checkDouBleItem(addTextInfo, item);
        if (checkDBItem) {
          const newUUID = uuidv4();
          let property = { context: item.text, properties: textStyles }
          let textInfo = await getNewTextInformation(property, ProjectJSON, newUUID, zIndex);
          textInfo.duration = item.end - item.start;
          textInfo.timeline_start = item.start;
          textInfo.timeline_end = item.end;
          textInfo.is_subtitle = true;
          textInfo.groups = groupID;
          textInfo.subtitle_uuid = subtitleID;

          if (item?.speaker) {
            textInfo.metadata = item.speaker;
            textInfo.metadatautf16 = convertStringtoWStringUTF16(item.speaker);
          }

          let projectHeight = ProjectJSON.height;
          let tempTextHeight = textInfo.dimension.h;
          // let textHeight = (projectHeight - tempTextHeight) / 7 * 6;
          
          let textHeight = (projectHeight - (projectHeight / 100) * subtitlePosY) - tempTextHeight;
          textInfo.coordinate = { ...textInfo.coordinate, y: textHeight };

          addTextInfo[newUUID] = textInfo;
          tempTextOb = textInfo
        }
      }

      loopNum++;
      if (loopNum === newWords.length) {
        let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
        changedData.text = addTextInfo;

        dispatch(changedJSON_Store(changedData));
        changeZeroSecond(tempTextOb, dispatch)
      }
    });
  }

  const checkDouBleItem = async (textInfo, item) => {
    if (!item || !item?.start && item.start !== 0 || !item?.end && item.end !== 0) return false;
    if (item.start > item.end) return false;
    let textKeys = Object.keys(textInfo);

    let returnState = true;
    textKeys.forEach((textItem) => {
      let itemS = textItem.timeline_start;
      let itemE = textItem.timeline_end;
      let B1 = itemS < item.start && item.start < itemE || itemS < item.end && item.end < itemE;
      let B2 = item.start < itemS && itemS < item.end || item.start < itemE && itemE < item.end;
      if (B1 || B2) returnState = false;
    })

    return returnState;
  }

  const onClickSubtitleCreation = () => {
    
      // const uploadingKeys = Object.keys(uploadingFiles);
       if(openUploading){
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "There is an ongoing upload in progress, are you sure that your want to continue with generating subtitles ?",
          showConfirmButton: true,
          confirmButtonText: "Create Subtitle",
          showCancelButton: true,
          cancelButtonText: "Cancel"
        }).then((result) => {
          if (result.isConfirmed) {
            autoSubtitleClick();
          }
        });
      }else{
        autoSubtitleClick();
      }
      
  }

  const onClickSubtitlefile = () => {
    fileInputer.current.click();
  }

  const chooseSubtitleFile = ({target}) => {
    let subtitleFile = target.files[0];
    const reader = new FileReader();
    reader.readAsText(subtitleFile, 'UTF-8');
    reader.onload = () => {
        const result = reader.result;
        if (result.includes('�')) {
            console.log('The file encoding is not utf-8! Trying CP1251...');
        } else {
          const strTesterr = subtitleFile.name.replace(/\.(srt|txt)$/, '').replace(/_+/g, '\ ').toUpperCase() + '\n' + result;
          const parser = new srtParser2();
          var srt_array = parser.fromSrt(result);
          createSubtitleByList(srt_array)
            // document.querySelector('#textarea1').value = file.name.replace(/\.(srt|txt)$/, '').replace(/_+/g, '\ ').toUpperCase() + '\n' + result;
        }
    }
  }

  const createSubtitleByList = (subtitleList) => {
    const groupID = uuidv4();
    let zIndex = getMaxZIndex(ProjectJSON) + 3;
    const subtitleID = uuidv4();
    let loopNum = 0;
    let addTextInfo = {};
    let tempTextOb = null
    subtitleList.forEach(async (item) => {
      if (!!item.text) {
        // let checkDBItem = await checkDouBleItem(addTextInfo, item);
        // console.log('checsssDB', checkDBItem);
        // if (checkDBItem) {
          // console.log('checDB', checkDBItem);
          const newUUID = uuidv4();
          let property = { context: item.text, properties: textStyles }
          let textInfo = await getNewTextInformation(property, ProjectJSON, newUUID, zIndex);
          textInfo.duration = (item.endSeconds - item.startSeconds) * 1000;
          textInfo.timeline_start = item.startSeconds * 1000;
          textInfo.timeline_end = item.endSeconds * 1000;
          textInfo.is_subtitle = true;
          textInfo.groups = groupID;
          textInfo.subtitle_uuid = subtitleID;

          if (item?.speaker) {
            textInfo.metadata = item.speaker;
            textInfo.metadatautf16 = convertStringtoWStringUTF16(item.speaker);
          }

          let projectHeight = ProjectJSON.height;
          let tempTextHeight = textInfo.dimension.h;
          // let textHeight = (projectHeight - tempTextHeight) / 7 * 6;
          
          let textHeight = (projectHeight - (projectHeight / 100) * subtitlePosY) - tempTextHeight;
          textInfo.coordinate = { ...textInfo.coordinate, y: textHeight };

          addTextInfo[newUUID] = textInfo;
          tempTextOb = textInfo
        }
      // }

      loopNum++;
      if (loopNum === subtitleList.length) {
        let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
        changedData.text = addTextInfo;

        dispatch(changedJSON_Store(changedData));
        changeZeroSecond(tempTextOb, dispatch)
      }
    });
  }

  return (
    <>
    
      <SectionHeader title={languageStrs.subtitles} className='pl-3' />

      <Typography md={12} className='v-block-footer pl-1'>
        {languageStrs.how_add_subtitles}
      </Typography>
      <Box className='px-10 pt-0'>
        <Grid container spacing={0}>
          <Grid item xs={12} paddingY={2}>
            <input type={`file`} style={inputStyle}
                ref={fileInputer}
                accept=".srt"
                onChange={chooseSubtitleFile}
              />
            <Button onClick={() => onClickSubtitlefile()} variant='outlined' color='primary' fullWidth={true}>Create Subtitle with File</Button>
          </Grid>
        </Grid>
      </Box>
      <hr></hr>
      <Box className='px-10 pt-0'>
        {!subcreating && (
          <Grid container spacing={0}>
            <Grid item xs={12}
              className={`${!subcreating && 'subtitle-hover'} v-subtitlecard mb-3 p-3`}
            >
              <Box md={12} onClick={() => {onClickSubtitleCreation()}}>
                <Box className='v-center'>
                  <Box className='v-Subtitle_ICON v-center'>
                    <Box className='v-Subtitle_iconAuto'>
                      {languageStrs.auto}<Box className='v-Subtitle_ICONfooter'></Box>
                    </Box>
                  </Box>
                </Box>

                <Typography md={12} className='v-Subtitlecardhead mt-3 mb-2'>{languageStrs.auto_subtitles}</Typography>
                <Typography md={12} className='v-Subtitlecardcontent'>{languageStrs.automatically_subtitles_video}</Typography>
              </Box>
            </Grid>
          </Grid>
        )}

        {!!subcreating && (
          <Box className='creating-subtitle'>
            {languageStrs.wait_moments_subtitles_being}
            <LinearProgress value={progressVal}/>
            <span>{progressVal} %</span>
          </Box>
        )}
      </Box >

      <Box className='p-2'>
        <Typography md={12} className='v-block-footer pl-1'>{languageStrs.select_language_detect}</Typography>

        <Box className='v-autocomplate pt-2'>
          <Select value={language}
            className='v-hoverborder v-radius v-commonboxheight'
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={({ target }) => {
              setLanguage(target.value)
            }}
          >
            {languageList.map((option, key) =>
              <MenuItem value={option.key} key={key}>
                <Typography component='span'
                >{option.label}</Typography>
              </MenuItem>
            )}
          </Select>
        </Box>
      </Box>

      <PreviewText textStyles={textStyles}
        setTextStyles={setTextStyles}
        subtitlePosY={subtitlePosY}
        setSubtitlePosY={onSetSubtitlePosY}
      />
      <PricingModal open={showPricingPageModal} onCloseModal={handleClosePricingModal} />
    </>
  )
}

export const PreviewText = (props) => {
  const { textStyles, setTextStyles, subtitlePosY, setSubtitlePosY } = props
  const languageStrs = useSelector((store) => store.editdata.languageStrs);
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON)
  const projectUUID = GetProjectUUID();
  const [thumbnailImg, setThumbnailImg] = useState('');
  
  const previewText = useRef(null);

  useEffect(() => {
    setThumbnailImg(ProjectJSON.thumbnail_url[projectUUID].signed_url);
  }, [ProjectJSON])

  const textTypeChange = (key, value) => {
    setTextStyles({
      ...textStyles,
      [key]: value
    })
  }

  const marks = [
    {
      value: 0,
      label: '0',
    },
    {
      value: 5.375,
      label: '1/16',
    },
    {
      value: 10.75,
      label: '1/8',
    },
    {
      value: 16.125,
      label: '3/16',
    },
    {
      value: 21.5,
      label: '1/4',
    },
    {
      value: 26.875,
      label: '5/16',
    },
    {
      value: 32.25,
      label: '3/8',
    },
    {
      value: 37.625,
      label: '7/16',
    },
    {
      value: 43,
      label: '1/2',
    },
    {
      value: 48.375,
      label: '9/16',
    },
    {
      value: 53.75,
      label: '5/8',
    },
    {
      value: 59.125,
      label: '11/16',
    },
    {
      value: 64.5,
      label: '3/4',
    },
    {
      value: 69.875,
      label: '13/16',
    },
    {
      value: 75.25,
      label: '7/8',
    },
    {
      value: 80.625,
      label: '15/16',
    },
    {
      value: 86,
      label: '1',
    },
  ];

  // const [subtitleLoc, setSubtitleLoc] = useState(0);

  const onChangeSubtitlePos = (e) => {
    
    setSubtitlePosY(e.target.value);
    // previewText.current.style.top = (100 - e.target.value) + '%';
  }
  return (
    <>
      <Box className='p-2'>
        <Box className='v-block-header pb-5 mt-10'>
          <Typography className='v-block-header-left'>{languageStrs.text_style}</Typography>
        </Box>

        <Box md={12} className='mb-2'>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <Box className='v-autocomplate'>
                <Select value={textStyles?.font_family}
                  className='v-hoverborder v-radius v-commonboxheight'
                  inputProps={{ 'aria-label': 'Without label' }}
                  onChange={(e) => { textTypeChange('font_family', e.target.value) }}
                >
                  {fontFamilyOptions.map((option, key) =>
                    <MenuItem value={option.label} key={key}>
                      <Typography component='span'
                        sx={{ fontFamily: option.label }}
                      >{option.label}</Typography>
                    </MenuItem>
                  )}
                </Select>
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box className="v-autocomplate">
                <Select
                  className='v-hoverborder v-radius v-commonboxheight'
                  inputProps={{ 'aria-label': 'Without label' }}
                  value={textStyles?.font_size}
                  onChange={(e) => { textTypeChange('font_size', parseInt(e.target.value)) }}
                >
                  {fontSizeOptions.map((option, key) =>
                    <MenuItem value={option.label} key={key}>
                      <Typography component='span'
                      >{option.label}</Typography>
                    </MenuItem>
                  )}
                </Select>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box md={12} className='mb-2'>
          <Grid container spacing={0}>
            <Grid item xs={4.8}>
              <Box className='v-commonboxheight v-radius v-spacebetween mr-1'>
                <Grid container className='v-spacebetween'>
                  <Grid item xs={6}>
                    <Button endIcon={<FormatBoldIcon />}
                      variant={textStyles?.bold === 'bold' ? 'contained' : 'outlined'}
                      onClick={() => { textTypeChange('bold', !textStyles?.bold ? 'bold' : '') }}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Button endIcon={<FormatItalicIcon />}
                      variant={textStyles?.italic === 'italic' ? 'contained' : 'outlined'}
                      onClick={() => { textTypeChange('italic', !textStyles?.italic ? 'italic' : '') }}
                    />
                  </Grid> 
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={7.2}>
              <Box className=' v-commonboxheight v-radius v-spacebetween mr-1 ml-1'>
                <Grid container >
                  <Grid item xs={4}   >
                    <Button endIcon={<FormatAlignLeftIcon />}
                      variant={textStyles?.alignment === 'left' ? 'contained' : 'outlined'}
                      onClick={() => { textTypeChange('alignment', 'left') }}
                    />
                  </Grid>

                  <Grid item xs={4} >
                    <Button endIcon={<FormatAlignCenterIcon />}
                      variant={textStyles?.alignment === 'center' ? 'contained' : 'outlined'}
                      onClick={() => { textTypeChange('alignment', 'center') }}
                    />
                  </Grid>

                  <Grid item xs={4} >
                    <Button endIcon={<FormatAlignRightIcon />}
                      variant={textStyles?.alignment === 'right' ? 'contained' : 'outlined'}
                      onClick={() => { textTypeChange('alignment', 'right') }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box md={12} className='mb-2'>
          <Box className='v-border v-radius'>
            <Box className='v-spacebetween p-10'>
              <span>{languageStrs.background_color}</span>
              <Box className='v-center mr-3' sx={{ position: 'relative' }}>
                <input type="text"
                  className='v-input-text mr-2 v-font-2-2'
                  value={textStyles.background_color}
                  onChange={() => { }} disabled />

                <ColorPicker color={textStyles.background_color}
                  Icon={<FormatColorFillIcon className='v-font-3-2 v-colorpicker-icon' />}
                  change={(color) => { textTypeChange('background_color', color) }}
                />
              </Box>
            </Box>

            <Divider />

            <Box className='v-spacebetween p-10'>
              <span>{languageStrs.text_color}</span>
              <Box className='v-center mr-3' sx={{ position: 'relative' }}>
                <input type="text"
                  className='v-input-text mr-2 v-font-2-2'
                  value={textStyles.text_color}
                  onChange={() => { }} disabled />

                <ColorPicker color={textStyles.text_color}
                  Icon={<FormatColorFillIcon className='v-font-3-2 v-colorpicker-icon' />}
                  change={(color) => { textTypeChange('text_color', color) }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <div className='my-2 p-10'
        style={{
          fontWeight: textStyles.bold,
          fontStyle: textStyles.italic,
          fontSize: textStyles.font_size,
          textAlign: textStyles.alignment,
          fontFamily: textStyles.font_family,
          backgroundColor: textStyles.background_color,
          color: textStyles.text_color
        }}
      >
        {languageStrs.preview_text}
      </div>
      <Box md={12} className='my-4'>
        <Grid container>
          <Grid className='relative' item xs={9} style={{backgroundColor: 'black'}}>
            <span className='p-2 absolute w-full' ref={previewText} style={{textAlign: textStyles.alignment, backgroundColor: textStyles.background_color, color: textStyles.text_color, fontStyle: textStyles.italic, fontWeight: textStyles.bold, fontSize: textStyles.font_size*0.5, fontFamily: textStyles.font_family, bottom: subtitlePosY+'%' }}>{languageStrs.preview_text}</span>
            {thumbnailImg && 
              <img src={thumbnailImg} className='h-full mx-auto' alt='footer-logo'/>
            }
          </Grid>
          <Grid item xs={3} className='py-2'>
            <Stack sx={{ height: 200 }} spacing={1} direction="row">
              <Slider
                size="small"
                orientation="vertical"
                value={subtitlePosY}
                step={5.375}
                onChange={(e) => { onChangeSubtitlePos(e) }}
                aria-labelledby="input-slider"
                max={86}
                marks={marks}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}