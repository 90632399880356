import React, {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase} from "@mui/material"
import {ArrowBack, ArrowForward} from '@mui/icons-material';
import { ClickAwayListener } from '@mui/base';
import VideocamIcon from '@mui/icons-material/Videocam';

import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';

import SearchIcon from '@mui/icons-material/Search';
import { SectionHeader } from '../common/section.header';
import { 
  getStockSearchKeyword, 
  getStockData, 
  createStock, 
  updateStock, 
  UploadAssets, 
  getPopularPexelsData, 
  getPexelsData, 
  getPopularPexelsImages,
  getPexelsImageSearch,
  getPexelsImageData,
  getPixaBayStockSearch, 
  getPopularPixaBayStockData, 
  getPopularPixabayImageStocks,
  getPixabayImageSearchStocks,
  getPixabayImageStock,
  getFreeSoundSearch,
  getFreeSoundData,
  getProjectJSON} from '../../../../axios/ApiProvider';
import { GetProjectUUID } from '../../../../assets/script/commonFunction';
import { getNewStockInformation, getThumbnailsInfo, getImageInformation, getNewAudioInformation, getNewAudioStockInformation } from '../../webGL/commonFunc';
import { VideoElements_Store, audioElements_Store, dataLoaded_Store, imageElements_Store } from '../../../../store/actions/webgldata';
import { changedJSON_Store, ProjectJSON_Store, thumbNails_Store } from '../../../../store/actions/projectdata';
import { loading_Store } from '../../../../store/actions/mainAction';
import { getMaxZIndex, changeZeroSecond } from '../../commonFunction';
import { localeForPexles, mimeTypes } from '../tooltap.config';
import { v4 as uuidv4 } from 'uuid';
import {webGlSizeOptions } from "../../../video-edit/videoEdit.config"
import pexelsVideoLogo from "../../../../assets/image/Logos/pexelVideo.png"
import pexelsImageLogo from "../../../../assets/image/Logos/pexelImage.png";
import pixabayVideoLogo from "../../../../assets/image/Logos/pixabayVideo.png";
import pixabayImageLogo from "../../../../assets/image/Logos/pixabayImage.png";
import freeSoundLogo from "../../../../assets/image/Logos/freeSound.png";
import stockLogo from "../../../../assets/image/Logos/stock_logo.png"
import { duration } from 'moment';

import {StockModal} from './stockModal';

export const FreeSound = () => {
    const [isLoadingStock, setIsLoadingStock] = useState(true);
    const [stockData, setStockData] = useState([]);
    const [searchKey, setSearchKey] = useState("");
    const projectUUID = GetProjectUUID();
    const audioElements = useSelector(store => store.webgldata.audioElements);
    const Time_Count = useSelector((store) => store.videodata.Time_Count);
    const projectJSON = useSelector(store => store.projectdata.projectJSON);
    const dispatch = useDispatch()
    const [openPopper, setOpenPopper] = useState(false)
    const anchorEl = useRef(null)
    const [keywords, setKeywords] = useState([]);

    const [selectedStock, setSelectedStock] = useState(null);
    const [openDetaiModal, setOpenDetailModal] = useState(false);

    const handleOpenPopover = (event) => {
      setOpenPopper(true);
    };
  
    const handleClosePopover = () => {
      setOpenPopper(false);
    };
  
    useEffect(() => {
        (async function() {
          if(searchKey) {
            // setPageNum(1);
            const keysRes = await getStockSearchKeyword(searchKey);
            let respStr = "";
            keysRes.map(st => respStr += st);
            const firstBracket = respStr.indexOf("(");
            respStr = respStr.slice(firstBracket + 1).slice(0, -1);
            const respJson = JSON.parse(respStr);
            console.log('gettingISSUE', respJson.hits);
            setKeywords(respJson.hits)
          }
        })()
      }, [searchKey])

    useEffect(() => {
      loadFreeSoundSearchData(searchKey)
    }, []);
  
    const loadFreeSoundSearchData = async (keyword) => {
      setIsLoadingStock(true)
      const loadedData = await getFreeSoundSearch(keyword);
      if(loadedData.state !== "success") return ;
      setStockData(loadedData.data.results)
      setIsLoadingStock(false)
    }
    
    const handleStockSound = async (soundItem) => {
      setSelectedStock(soundItem);
      setOpenDetailModal(true);

      // const soundRes = await getFreeSoundData(soundItem.id);
      // console.log(soundRes);
      // if(soundRes.state !== "success") return;
      // dispatch(loading_Store(true));
      // const newUUID = uuidv4();
      // let audioUrl = soundRes.data.previews['preview-hq-mp3'];
      // let audio_type = 'audio/mp3'
      // let duration = soundRes.data.duration;
      // getNewAudioStockInformation(audioUrl, duration, newUUID, projectUUID, (audioPlayer, audioInfo) => {
      //   let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} };
      //   let startTime = Time_Count.getTime();
  
      //   audioInfo.timeline_start = startTime;
      //   audioInfo.timeline_end = audioInfo.duration + startTime;
  
      //   changedJSON.audios = { [newUUID]: audioInfo }
      //   dispatch(audioElements_Store({...audioElements, [newUUID]: audioPlayer}));
      //   dispatch(changedJSON_Store(changedJSON));
      //   dispatch(loading_Store(false));
      // }, async (newAssets, assetUUID) => {
      //   let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} };
      //   const JSONdata = await getProjectJSON(projectUUID);
      //   const projectData = JSONdata?.data[projectUUID];
      //   if(JSONdata.state !== 'success'){
      //     changedData = { ...changedData, asset: { ...newAssets } };
      //   } else changedData = { ...changedData, asset: projectData.asset };
      //   dispatch(changedJSON_Store(changedData));
      // }, () => { dispatch(dataLoaded_Store(true)) })
  
    }

    const addStockData = async (stockItem) => {
      dispatch(loading_Store(true));
      const newUUID = uuidv4();
      let audioUrl = stockItem.previews['preview-hq-mp3'];
      let audio_type = 'audio/mp3'
      let duration = stockItem.duration;
      getNewAudioStockInformation(projectJSON, audioUrl, duration, newUUID, projectUUID, (audioPlayer, audioInfo) => {
        let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} };
        let startTime = Time_Count.getTime();
  
        const audioKeys = Object.keys(projectJSON.audios);
        audioKeys.map(aItemKey => {
          if(startTime < projectJSON.audios[aItemKey].timeline_end){
            startTime = projectJSON.audios[aItemKey].timeline_end;
          }
        })

        audioInfo.timeline_start = startTime;
        audioInfo.timeline_end = audioInfo.duration + startTime;
  
        changedJSON.audios = { [newUUID]: audioInfo }
        dispatch(audioElements_Store({...audioElements, [newUUID]: audioPlayer}));
        dispatch(changedJSON_Store(changedJSON));
        dispatch(loading_Store(false));
      }, async (newAssets, assetUUID) => {
        let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} };
        const JSONdata = await getProjectJSON(projectUUID);
        const projectData = JSONdata?.data[projectUUID];
        if(JSONdata.state !== 'success'){
          changedData = { ...changedData, asset: { ...newAssets } };
        } else changedData = { ...changedData, asset: projectData.asset };
        dispatch(changedJSON_Store(changedData));
      }, () => { dispatch(dataLoaded_Store(true)) })
    }
  
    const handleChangeInput = (e) => {
      setSearchKey(e.target.value);
      if(e.target.value === ""){
        handleClosePopover();
      } else {
        handleOpenPopover(e);
      }
    }
    const handleKeyUp = (e) => {
      if(e.keyCode === 13) {
        loadFreeSoundSearchData(searchKey);
        handleClosePopover();
      }
    }
    const handleClickKeyword =  (keyword) => {
        setSearchKey(keyword);
        loadFreeSoundSearchData(keyword);
        handleClosePopover();
      }
    const [previewItemId, setPreviewItemId] = useState(null);
    const previewAudioEl = useRef(null);

    useEffect(() => {
      // Everything around if statement
      if (previewAudioEl && previewAudioEl.current) {
        previewAudioEl.current.addEventListener('ended', previewEnded);
      
        return () => {
          if(previewAudioEl.current){
            previewAudioEl.current.removeEventListener('ended', previewEnded)
          }
        }
      }
    }, [previewAudioEl])
    const previewEnded = (e) => {
      setPreviewItemId(null);
    }
    const handlePreviewAudioPlay = (stockItem) => {
      setPreviewItemId(stockItem.id);
      previewAudioEl.current.src = stockItem.previews['preview-hq-mp3'];
      previewAudioEl.current.play();
    
    }
    const handlePreviewAudioPause = (stockItem) => {
      setPreviewItemId(null);
      previewAudioEl.current.pause();
    }
    return (
      <>
        <Grid container sx={{p: 1}}>
          <Grid item xs={12} paddingY={2}>
            <FormControl fullWidth ref={anchorEl}>
              <InputLabel htmlFor='outlined-freesound-search'>Search</InputLabel>
              <OutlinedInput 
                id='outlined-freesound-search'
                startAdornment={<InputAdornment postion='start'><SearchIcon /></InputAdornment>}
                label='Search'
                onChange={handleChangeInput}
                onKeyUp={handleKeyUp}
                value={searchKey}
              />
            </FormControl>
          </Grid>
          <audio className='freeSound-preview' ref={previewAudioEl} controls ></audio>
          <ClickAwayListener onClickAway={handleClosePopover}>
            <Popper
            open={openPopper}
            anchorEl={anchorEl.current}
            sx={{
                width: `${anchorEl?.current?.clientWidth}px`,
                border: "solid 1px #e5e7eb",
                zIndex: 1000,
                backgroundColor: "#FFF"
              }}
              placement="bottom-start"
            >
                {keywords.map(w => <MenuItem key={w.objectID}
                onClick={() =>{ handleClickKeyword(w.term)}}
              >{w.term}</MenuItem>)}
            </Popper>
          </ClickAwayListener>
        </Grid>
        <Grid container sx={{overflow: "auto", position: "relative"}}>
          <Grid item xs={12} sx={{position: "relative", minHeight: "300px"}}>
            <ImageList variant='masonry' cols={2} gap={8}>
              {stockData && stockData.map(sImgData => {
                return (
                  <ImageListItem key={sImgData.id} sx={{cursor: "pointer"}}
                  >
                    <img
                      src={sImgData.images['waveform_m']}
                      alt={sImgData.name}
                      loading='lazy'
                      style={{
                        minWidth: "184px",
                        minHeight: "103px",
                        flexGrow: "unset"
                      }}
                      onClick={() => {handleStockSound(sImgData)}}
                    />
                    <ImageListItemBar 
                      
                      subtitle={
                      <>
                      {previewItemId == sImgData.id && 
                        <PauseCircleOutlineIcon onClick={() => {handlePreviewAudioPause(sImgData)}}/>
                      }
                      {previewItemId != sImgData.id && 
                        <PlayCircleOutlineIcon onClick={() => {handlePreviewAudioPlay(sImgData)}}/>
                      }
                        {sImgData.name}
                      </>
                      }
                    />
                  </ImageListItem>
                )
              })}
            </ImageList>
          </Grid>
          {isLoadingStock && <Box sx={{position: "absolute", top: 0,  background: "rgba(0,0,0,0)", display: "flex", zIndex: 100000, flexDirection: "column", justifyContent: "center", height: "300px", width: "100%"}}>
              <Box sx={{ display: 'flex', position: "absolute", justifyContent: "center", width: "100%" }}>
                <CircularProgress />
              </Box>
            </Box>}
          <StockModal 
            open={openDetaiModal} 
            setOpen={setOpenDetailModal} 
            stockType='freesound'
            stockData={selectedStock}
            addStockData={addStockData}
            />
        </Grid>
      </>
    )
  }