import React, {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Modal from '@mui/material/Modal';
import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase, Stack} from "@mui/material"
import { getFreeSoundData } from '../../../../axios/ApiProvider';
import VideocamIcon from '@mui/icons-material/Videocam';

import { FreeSoundModal } from './ModalUis/freeSoundModal';
import { PixabayImageModal } from './ModalUis/pixabayImageModal';
import { PixabayVideoModal } from './ModalUis/pixabayVideoModal';
import { PexelsImageModal } from './ModalUis/pexelsImageModal';
import { PexelsVideoModal } from './ModalUis/pexelsVideoModal';
import { AdobeStockModal } from './ModalUis/adobeStockModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const StockModal = (props) => {
  const {open} = props;
  const {setOpen} = props;
  const {stockType} = props;
  const {stockData} = props;
  const {addStockData} = props;

  const [stockMedia, setStockMedia] = useState(null);

  const [stockTitle, setStockTitle] = useState('');
  const [stockDetail, setStockDetail] = useState('');
  
  const handleOpen = () =>{ 
    setOpen(true);

  };  
  useEffect(() => {
    if(open){
      console.log('page open', open, stockType);
      loadStockData();
    }
  }, [open])
  const loadStockData = async () => {
    console.log(stockData, stockType);
    let stockDataRes = null;
    switch(stockType){
      case 'freesound':
        stockDataRes = await getFreeSoundData(stockData.id);
        console.log('freesound Res', stockDataRes);
        setStockTitle('Freesound Stock Audio');
        setStockDetail(stockDataRes.data.description);
        setStockMedia(stockDataRes.data);
        break;
      case 'pexelsVideo':
        setStockTitle('Pexels Stock Video');
        setStockMedia(stockData);
        console.log('pexelsVideos', stockData);
        break;
      case 'pexelsImage':
        setStockTitle('Pexels Stock Image');
        setStockMedia(stockData);
        console.log('pexelsImagedata', stockData);
        break;
      case 'pixabayVideo':
        setStockTitle('Pixabay Stock Video');
        setStockMedia(stockData);
        break;
      case 'pixabayImage':
        setStockTitle('Pixabay Stock Image');
        setStockMedia(stockData);
        break;
      case 'stock':
        setStockTitle('Adobe Stock Video');
        setStockMedia(stockData);
        break;
    }
  }
  const addStock = () => {
    addStockData(stockMedia);
    handleClose();
  }
  const handleClose = () => { setOpen(false); }
  
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {stockType == 'freesound' && 
                <FreeSoundModal stockData={stockMedia}/>
              }
              {stockType == 'pixabayImage' && 
                <PixabayImageModal stockData={stockMedia} />
              }
              {stockType == 'pixabayVideo' && <PixabayVideoModal stockData={stockMedia} />}
              {stockType == 'pexelsImage' && <PexelsImageModal stockData={stockMedia} />}
              {stockType == 'pexelsVideo' && <PexelsVideoModal stockData={stockMedia} />}
              {stockType == 'stock' && <AdobeStockModal stockData={stockMedia} />}
            </Grid>
            <Grid item xs={12}>
              <Stack direction='row' justifyContent='end' alignItems='center' spacing={3}>
                <Button variant="contained" color='secondary' onClick={() => handleClose()}>Cancel</Button>
                <Button variant="contained" color='success' onClick={() => addStock()}>Add Stock</Button>
              </Stack>
              
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}