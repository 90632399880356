import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Box, Select, MenuItem, Button, FormControlLabel, Checkbox, Hidden } from "@mui/material";
import { SectionHeader } from "./common/section.header"
import { createSubtitle, subtitleWebHookPoll, createChatGPTSummary, getChatGPTSummary, submitChatGPTSummary, getTranscribe, getTranscribeUUid, getProjectJSON, duplicatePrjoectJSON, getSubtitleInfo } from '../../../axios/ApiProvider'; 
import { GetProjectUUID } from '../../../assets/script/commonFunction';
import { ProjectJSON_Store, addedTemplate_store, changedJSON_Store } from '../../../store/actions/projectdata';
import { changeZeroSecond } from '../commonFunction';
import { VideoElements_Store } from '../../../store/actions/webgldata';
import { isAnalyzing_Store, stageText_Store } from '../../../store/actions/mainAction';
import { getTotalProjectTime } from '../timeline/common/commonFunction';
import PricingModal from '../../../pages/Pricing/pricingModal';
import Swal from 'sweetalert2';
import { SummaryModal } from './common/SummaryModal';
import { Slider, TextField } from '@mui/material';

const languageToList1 = [
  { label: "English", key: "en" },
  // { label: "Bulgarian", key: "bg" },
  { label: "Arabic", key: "ar" },
  { label: "Catalan", key: "ca" },
  { label: "Mandarin", key: "cmn" },
  { label: "Czech", key: "cs" },
  { label: "Danish", key: "da" },
  { label: "German", key: "de" },
  { label: "Greek", key: "el" },
  { label: "Spanish", key: "es" },
  { label: "Estonian", key: "et" },
  { label: "Finnish", key: "fi" },
  { label: "French", key: "fr" },
  { label: "Galician", key: "gl" },
  { label: "Hindi", key: "hi" },
  { label: "Croatian", key: "hr" },
  { label: "Hungarian", key: "hu" },
  { label: "Indonesian", key: "id" },
  { label: "Italian", key: "it" },
  { label: "Japanese", key: "ja" },
  { label: "Korean", key: "ko" },
  { label: "Lithuanian", key: "lt" },
  { label: "Latvian", key: "lv" },
  { label: "Malay", key: "ms" },
  { label: "Dutch", key: "nl" },
  { label: "Norwegian", key: "no" },
  { label: "Polish", key: "pl" },
  { label: "Portuguese", key: "pt" },
  { label: "Romanian", key: "ro" },
  // { label: "Russian", key: "ru" },
  { label: "Slovakian", key: "sk" },
  { label: "Slovenian", key: "sl" },
  { label: "Swedish", key: "sv" },
  { label: "Turkish", key: "tr" },
  // { label: "Ukrainian", key: "uk" },
  { label: "Vietnamese", key: "vi" },
]

const formatTime = (seconds) => {
  const hrs = Math.floor(seconds / 3600).toString().padStart(2, '0');
  const mins = Math.floor((seconds % 3600) / 60).toString().padStart(2, '0');
  const secs = Math.floor(seconds % 60).toString().padStart(2, '0');
  const millis = ((seconds % 1) * 1000).toFixed(0).padStart(3, '0'); // Extract milliseconds

  return `${hrs}:${mins}:${secs}:${millis}`;
};

export const TooltapAiSummary = (props) => {
  const dispatch = useDispatch();
  const projectUUID = GetProjectUUID();
  const loginState = useSelector((store) => store.maindata.loginState)
  const [userData, setUserData] = useState({})
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const HeaderInfo = { title: "AI Summary", className: 'pl-3' };
  const [aiSilence, setAiSilence] = useState(false);
  const [aiReels, setAiReels] = useState(false);
  const [summaryUUID, setSummaryUUID] = useState(null);
  const [subTitlesData, setSubTitlesData] = useState(null);
  const [subtitleUUID, setSubtitleUUID] = useState(null);
  const [transcribeObj, setTranscribeObj] = useState(null);
  const [existSubtitleWords, setExistSubtitleWords] = useState([]);
  const [existSubtitleTxt, setExistSubtitleTxt] = useState('');
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const [language, setLanguage] = useState('en')
  const [sumaryTxt, setSumaryTxt] = useState('')
  const uploadingFiles = useSelector((store) => store.projectdata.uploadingFiles);
  const subcreating = useSelector((store) => store.projectdata.subcreating);
  const [uploading, setUploading] = useState([])

  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [showVideoPath, setShowVideoPath] = useState('');

  const [aiSummaryCount, setAiSummaryCount] = useState(0); // State for slider value
  const [aiSummaryDuration, setAiSummaryDuration] = useState(0); // State for slider value
  const [aiSummarySilence, setAiSummarySilence] = useState(1.0);
  const [projectMaxDuration, setProjectMaxDuration] = useState(0); // State for slider value

  useEffect(() => {
    let processing = [];
    let keys = Object.keys(uploadingFiles);
    keys.forEach((key) => {
      let file = uploadingFiles[key];
      if (!file.state) processing.push(file);
    })

    setUploading(processing);
  }, [uploadingFiles])

  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')

    if (!!item && JSON.parse(item)?.state === 'loggedin') {
      tempData = JSON.parse(item)
    }

    setUserData(tempData)
  }, [loginState])

  const languageChange = (e) => {
    let lan = e.target.value
    setLanguage(lan)
  }

  useEffect(() => {
    // Set default slider value to half of the maximum words
    if (existSubtitleWords && existSubtitleWords.length > 0) {
      setAiSummaryCount(Math.floor(existSubtitleWords.length / 2));
    }
    setAiSummarySilence(1 + Math.floor(existSubtitleWords.length / 1000));
  }, [existSubtitleWords]);

  useEffect(() => {
    initSummaryUUID()
    // createSubtitleStr();
    // loadSummaryUUID();
  }, [])
  const initSummaryUUID = async () => {
    dispatch(stageText_Store('Loading AI Summary Menu ...'))
    dispatch(isAnalyzing_Store(true));
    const exsitSubtitleID = Object.keys(ProjectJSON.subtitles);
    console.log('subTitleUUID', exsitSubtitleID);
    if(exsitSubtitleID.length > 0){
      setSubtitleUUID(exsitSubtitleID[0]);

      console.log("exsitSubtitleID : " + exsitSubtitleID[0]);

      const existSubtitleRes = await getSubtitleInfo(exsitSubtitleID[0]);

      console.log('existSubtitleRes : ', existSubtitleRes);

      if (existSubtitleRes.data.manifest.words) {

        setExistSubtitleWords(existSubtitleRes.data.manifest.words);
        setExistSubtitleTxt(existSubtitleRes.data.manifest.text);

        console.log("manifest : " + JSON.stringify(existSubtitleRes.data.manifest));
        console.log("words : " + existSubtitleRes.data.manifest.words);

        if (existSubtitleRes.data.manifest) {
          setAiSummaryCount(existSubtitleRes.data.manifest.words.length); // Set slider max value based on count
        }
      } else {
        setExistSubtitleTxt("Transcript is not available for the project");
      }
    }

    const projectDurations = Object.keys(ProjectJSON.videos).map(videoKey => ProjectJSON.videos[videoKey].timeline_end);
    const totalProjectDuration = Math.max(...projectDurations);

    setProjectMaxDuration(totalProjectDuration / 1000.0);
    setAiSummaryDuration(totalProjectDuration / 1000.0 / 2);

    setAiSummarySilence(1.0);

    dispatch(isAnalyzing_Store(false));
  }

  const handleInputChange = (event) => {
    const value = event.target.value.split('/')[0]; // Extracting the current count
    setAiSummaryCount(Number(value));
  };

  const handleDurationSliderChange = (event, newValue) => {
    setAiSummaryDuration(newValue);
    const newCountValue = (newValue / projectMaxDuration) * existSubtitleWords.length;
    setAiSummaryCount(newCountValue.toFixed(0));
  };

  const handleSilenceDetectionSliderChange = (event, newValue) => {
    setAiSummarySilence(newValue);
  };

  const [showPricingPageModal, setShowPricingPageModal] = useState(false);
  const handleClosePricingModal = () => {
    setShowPricingPageModal(false)
  }

  const createSubtitleStr =async (forceGenerateSubtitles) => {
    if(!userData || userData.subtitle_state == false){
      setShowPricingPageModal(true)
    }else{
      if(uploading.length > 0 || subcreating){
        Swal.fire({
          position: "center",
          icon: "warning",
          title: "There is an ongoing upload or an AI auto subtitles in progress, please wait for the completion.",
          showConfirmButton: true,
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.isConfirmed) {
            return;
          } else {
            return;
          }
        });
      }else{
        dispatch(isAnalyzing_Store(true));
        dispatch(stageText_Store('Analysing Transcript ...'))
        loadSummaryUUID(forceGenerateSubtitles);
      }
    }
  }

  const regenerateTranscript = async () => {
    if(uploading.length > 0 || subcreating){
      Swal.fire({
        position: "center",
        icon: "warning",
        title: "There is an ongoing upload or an AI auto subtitles in progress, please wait for the completion.",
        showConfirmButton: true,
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          return;
        } else {
          return;
        }
      });
    }else{
      createSubtitleStr(true);
    }
  }

  const loadSummaryUUID = async (forceGenerateSubtitles) => {
    let assets = Object.assign({}, ProjectJSON.asset)
    let assetKeys = Object.keys(assets)

    let category = "AI_STANDART"

    if (aiSilence == true) category = "AI_SILENCE_REMOVER"
    else if (aiReels == true) category = "AI_SUMMARY"
    else category = "AI_SUMMARY"

    const responseData = await createChatGPTSummary(category);
    if(responseData.state != 'success'){
      // window.toastr.error(responseData.data);
      Swal.fire({
        title: "AI Summary has failed",
        text: "AI Summary has failed for creating AI Summary",
        icon: "error"
      });
      dispatch(isAnalyzing_Store(false));
      return;
    }
    const aiSummaryUUID = responseData.data.uuid;
    setSummaryUUID(aiSummaryUUID)
    if(!subtitleUUID || forceGenerateSubtitles == true){
      setExistSubtitleTxt("");
      let resData = await createSubtitle(projectUUID, assetKeys[0], language);
      if(resData.state != 'success'){
        // window.toastr.error(resData.data);
        Swal.fire({
          title: "AI Summary has failed",
          text: "AI Summary has failed for creating Transcript",
          icon: "error"
        });
        dispatch(isAnalyzing_Store(false));
        return;
      }
      console.log('resData', resData);
      setSubtitleUUID(resData.data.uuid);
      setTimeout(function (){
        createAISummary(resData.data.uuid, aiSummaryUUID);
      }, 1000)
    } else {
      existAISummary(subtitleUUID, aiSummaryUUID);
    }
  }

  const existAISummary = async (subtitleID, aiSummaryUUID) => {
    
    // const responseData = await createChatGPTSummary();
    // const aiSummaryUUID = responseData.data.uuid;
    // setSummaryUUID(responseData.data.uuid)
    
    let assets = Object.assign({}, ProjectJSON.asset);
    let assetKeys = Object.keys(assets);
    const selectedAsset = assets[assetKeys[0]]
    const videos = ProjectJSON.videos
    
    const videoKeys = Object.keys(videos);
    let videoItem = videos[videoKeys[0]];
    let videoEleItem = videoElements[videoKeys[0]];
    console.log('VideoPSPAth', videoItem.signed_url, videoItem)
    setShowVideoPath(videoEleItem.src);
    // const existSubtitleRes = await getSubtitleInfo(subtitleID)
    // if(existSubtitleRes.state != 'success') return;
    // console.log('loading Subtitle Res', existSubtitleRes);
    let contextualData;
    
    if (aiSilence) {
      contextualData = parseSentences(existSubtitleWords)
      console.log("contextualData", contextualData);
      modalConfirmSumary(contextualData, videoItem, selectedAsset, videoEleItem);
      return;
    } else {
      contextualData = parseWords(existSubtitleWords);
    }
    dispatch(stageText_Store('Creating AI summary ...'))

    let gptSumaryData = await submitChatGPTSummary(aiSummaryUUID, sumaryTxt, contextualData, aiSummaryCount, aiSummarySilence);

    while(gptSumaryData.data.state_text != 'SUCCESS') {
      gptSumaryData = await getChatGPTSummary(aiSummaryUUID, sumaryTxt, contextualData);
      console.log('gptSumaryData state : ' + gptSumaryData.data.state_text);
      if (gptSumaryData.data.state_text === 'SUCCESS') {
        console.log('existAISummary -  gptSumaryData', gptSumaryData);
        break;
      } else if ((gptSumaryData.data.state_text === 'FAILED') || (gptSumaryData.data.state_text === undefined)) {
        Swal.fire({
          title: "AI Summary has failed, Please try again",
          text: `Reason code: ${aiSummaryUUID}`,
          icon: "error"
        });
        dispatch(isAnalyzing_Store(false));
        return;
      }
      console.log('gptSumaryData', gptSumaryData);
      await new Promise(r => setTimeout(r, 1000));
    }

    
    if(gptSumaryData.data.state_text != 'SUCCESS'){
      // window.toastr.error(gptSumaryData.data);
      Swal.fire({
        title: "AI Summary has failed",
        text: gptSumaryData.data.reason,
        icon: "error"
      });
      dispatch(isAnalyzing_Store(false));
      return;
    }
    const gptSumaryObj = JSON.parse(gptSumaryData.data.response);
    // const modalSummaryData = JSON.parse(gptSumaryData.data.metadata.summary)
    // console.log(modalSummaryData);
    // let modalArr = [];
    // const sumKeys = Object.keys(gptSumaryObj);

    // sumKeys.map(kItem => {
    //   gptSumaryObj[kItem].userDirect = kItem;
    //   modalArr.push(gptSumaryObj[kItem]);
    // })
    dispatch(isAnalyzing_Store(false));
    setModalData(gptSumaryObj);
    setSummaryData(gptSumaryData);
    setModalOpen(true);
    // Swal.fire({
    //   title: "Modal UI",
    //   html: modalHtml,
    //   showConfirmButton: true,
    //   showCancelButton: true
    // }).then((result) => {
    //   console.log(result);
    //   // modalConfirmSumary(gptSumaryObj, videoItem, selectedAsset, videoEleItem);
    // })
    
    // console.log('timelineStamp', gptSumaryObj);
    // let newTimestamps = {};
    // let newJSONData = Object.assign({}, ProjectJSON);
    // let initStartTime = 0
    // let updatedVideoElements = {};
    // gptSumaryObj.new_timestamps.map(stampItem => {
    //   const startTime = parseFloat(stampItem.split('-')[0]) * 1000;
    //   const endTime = parseFloat(stampItem.split('-')[1]) * 1000;
    //   const newItemId = uuidv4();
    //   const newVideoItem = Object.assign({}, videoItem);
    //   newVideoItem.duration = endTime - startTime;
    //   newVideoItem.segment_start = startTime - newVideoItem.timeline_start;
    //   newVideoItem.segment_end = endTime - newVideoItem.timeline_start;
    //   newVideoItem.timeline_end = initStartTime + (endTime - startTime);
    //   newVideoItem.timeline_start = initStartTime;
    //   newVideoItem.signed_url = selectedAsset.signed_url;
    //   initStartTime = newVideoItem.timeline_end;
    //   const newVideoEle = videoEleItem.cloneNode(true);
    //   newVideoEle.id = newItemId;
    //   if(aiReels){
        
    //     const ratioWH = newVideoItem.dimension.h / newVideoItem.dimension.w;
    //     const W = 1080;
    //     const H = newVideoItem.dimension.h *  ratioWH;
    //     newVideoItem.coordinate.x = 0;
    //     newVideoItem.coordinate.y = (1920 - H)/2;
    //     newVideoItem.dimension = {w: W, h: H};
    //     newVideoItem.ratioWH = {w: 1080, h: 1920};
    //   }
    //   updatedVideoElements = { ...updatedVideoElements, [newItemId]: newVideoEle };
      
    //   newTimestamps = {...newTimestamps, [newItemId]: newVideoItem};
    // })
    // newJSONData['videos'] = newTimestamps;
    // if(aiReels){
    //   newJSONData.width = 1080;
    //   newJSONData.height = 1920;
    // }
    // dispatch(stageText_Store('Opening new AI Summary Project ...'))
    // const totalTime = await getTotalProjectTime(newJSONData);
    // newJSONData.duration = totalTime;
    // const result = await duplicatePrjoectJSON(projectUUID, newJSONData);
    //   if (result.state !== 'success') {
    //     // return window.toastr.error(result.data.message)
    //     Swal.fire({
    //       title: "AI Summary has failed",
    //       text: "AI Summary has failed",
    //       icon: "error"
    //     });
    //     return;
    //   }
    //   const data = result.data
    //   const duplicateUUID = data['Duplicated Project uuid']
    //   console.log(result.data);
    //   // window.location.href = `/edit/${duplicateUUID}/`
  }

  const silenceBuffer = 500;

  const modalConfirmSumary = async (gptSumaryObj, videoItem, selectedAsset, videoEleItem) =>{
    console.log('timelineStamp', gptSumaryObj);
    let newTimestamps = {};
    let newJSONData = Object.assign({}, ProjectJSON);
    let initStartTime = 0
    let updatedVideoElements = {};

    gptSumaryObj.new_timestamps.map((stampItem, index, array) => {
      const startTime = parseFloat(stampItem.split('-')[0]) * 1000;
      const endTime = parseFloat(stampItem.split('-')[1]) * 1000;
      const newItemId = uuidv4();
      const newVideoItem = Object.assign({}, videoItem);
      newVideoItem.segment_start  = startTime - newVideoItem.timeline_start;
      newVideoItem.segment_end    = (index === array.length - 1) ? endTime - newVideoItem.timeline_start : endTime - newVideoItem.timeline_start + silenceBuffer;
      newVideoItem.duration       = newVideoItem.segment_end - newVideoItem.segment_start;
      newVideoItem.timeline_start = initStartTime;
      newVideoItem.timeline_end   = newVideoItem.timeline_start + newVideoItem.duration;
      newVideoItem.signed_url = selectedAsset.signed_url;
      initStartTime = newVideoItem.timeline_end;
      const newVideoEle = videoEleItem.cloneNode(true);
      newVideoEle.id = newItemId;
      if(aiReels){
        
        const ratioWH = newVideoItem.dimension.h / newVideoItem.dimension.w;
        const W = 1080;
        const H = newVideoItem.dimension.h *  ratioWH;
        newVideoItem.coordinate.x = 0;
        newVideoItem.coordinate.y = (1920 - H)/2;
        newVideoItem.dimension = {w: W, h: H};
        newVideoItem.ratioWH = {w: 1080, h: 1920};
      }
      updatedVideoElements = { ...updatedVideoElements, [newItemId]: newVideoEle };
      
      newTimestamps = {...newTimestamps, [newItemId]: newVideoItem};
    })
    newJSONData['videos'] = newTimestamps;


    console.log('summaryData', summaryData);

    let markers = [];
    let responseJson = JSON.parse(summaryData.data.response);
    let markerStartTime = 0;

    console.log("The RESPONSE JSON : " + JSON.stringify(responseJson, null, 2));

    let chapters = responseJson.chapters;

    for (let chapterKey in chapters) {
      if (chapters.hasOwnProperty(chapterKey)) {
        let chapter = chapters[chapterKey];
        console.log(`Chapter ${chapterKey}:`);
        console.log(`  Topic: ${chapter.topic}`);
        console.log(`  Comment: ${chapter.comment}`);
        console.log(`  Confidence: ${chapter.confidence}`);
        console.log(`  Importance: ${chapter.importance}`);
        console.log(`  Transcript: ${chapter.new_transcript}`);
        console.log(`  Timestamps: ${chapter.new_timestamps}`);

        const newMarkerId = uuidv4();
        const startTime = parseFloat(chapter.new_timestamps[0].split('-')[0]) * 1000;
        const endTime = parseFloat(chapter.new_timestamps[0].split('-')[1]) * 1000;

        // Check if the chapter's timestamp is included in gptSumaryObj
        const isChapterIncluded = gptSumaryObj.new_timestamps.some(stamp => {
          const [sumStartTime, sumEndTime] = stamp.split('-').map(time => parseFloat(time) * 1000);
          return startTime === sumStartTime && endTime === sumEndTime;
        });

        if (isChapterIncluded) {
          const newMarkerContext = {
            id: newMarkerId,
            timeline_start : markerStartTime,
            timeline_end   : markerStartTime + (endTime - startTime),
            original_start : startTime,
            original_end   : endTime,
            duration       : endTime - startTime,
            topic          : chapter.topic,
            comment        : chapter.comment,
            confidence     : chapter.confidence,
            importance     : chapter.importance,
            transcript     : chapter.new_transcript,
            color          : "#ff0000"
          }
          markerStartTime = newMarkerContext.timeline_end;
          markers = [...markers, newMarkerContext];
        }
      }
    }
    let sortedMarkers = markers.sort((a, b) => { return a.timeline_start - b.timeline_start; });
    newJSONData.markers = sortedMarkers;

    console.log("newJSONData" + newJSONData);

    if(aiReels){
      newJSONData.width = 1080;
      newJSONData.height = 1920;
    }
    dispatch(stageText_Store('Opening new AI Summary Project ...'))
    const totalTime = await getTotalProjectTime(newJSONData);
    newJSONData.duration = totalTime;

    newJSONData.title = 'AISummary-(' + Math.round(totalTime / 1000) + "secs)-" + newJSONData.title;

    const result = await duplicatePrjoectJSON(projectUUID, newJSONData);
      if (result.state !== 'success') {
        // return window.toastr.error(result.data.message)
        Swal.fire({
          title: "AI Summary has failed",
          text: "AI Summary has failed",
          icon: "error"
        });
        return;
      }
      const data = result.data
      const duplicateUUID = data['Duplicated Project uuid']
      console.log(result.data);
      //window.location.href = `/edit/${duplicateUUID}/`
      dispatch(isAnalyzing_Store(false));
      const popup = window.open(`/edit/${duplicateUUID}/`, '_blank');
      if (popup === null || typeof popup === 'undefined') {
        Swal.fire({
          title: "Please enable Popups",
          text: "Please allow popups for Videoo.io, so AI Summary project can be opened in a new tab.",
          icon: "warning"
        });
      }
  }

  const onModalCancel = () => {
    setModalOpen(false);
    setModalData([]);
    dispatch(isAnalyzing_Store(false));
  }

  const onModalConfirm = (selectedModal) => {
    console.log(selectedModal);
    //setModalOpen(false);
    setModalData([]);
    dispatch(isAnalyzing_Store(true));
    let assets = Object.assign({}, ProjectJSON.asset);
    let assetKeys = Object.keys(assets);
    const selectedAsset = assets[assetKeys[0]]
    const videos = ProjectJSON.videos
    const videoKeys = Object.keys(videos);
    let videoItem = videos[videoKeys[0]];
    let videoEleItem = videoElements[videoKeys[0]];
    modalConfirmSumary(selectedModal, videoItem, selectedAsset, videoEleItem)
  }

  const createAISummary = async (subtitleID, aiSummaryUUID) => {
    
    const JSONdata = await getProjectJSON(projectUUID);

    const projectData = JSONdata?.data[projectUUID];
    if (!projectData) {
      // changeCreating(false);
      
      return;
    }
    let assets = Object.assign({}, projectData.asset);
    let assetKeys = Object.keys(assets);
    const selectedAsset = assets[assetKeys[0]]
    const videos = projectData.videos
    
    const videoKeys = Object.keys(videos);
    let videoItem = videos[videoKeys[0]];
    let videoEleItem = videoElements[videoKeys[0]];
    videoKeys.map(vIndex => {
      if(videos[vIndex].asset_uuid == assetKeys[0]){
        videoItem = Object.assign({}, videos[vIndex]);
        videoEleItem = videoElements[vIndex];
      }
    })
    console.log('VideoPAth', videoItem.signed_url)
    setShowVideoPath(videoEleItem.src);
    let newSubtitle = Object.assign({}, projectData.subtitles);
    let newSubKeys = Object.keys(newSubtitle);
    let subKey = newSubKeys.find((key) => newSubtitle[key].state !== 3);
    let subState = newSubtitle[subKey].state;
    if (subState !== 2 && subState !== 3) {
      setTimeout(() => {
        createAISummary(subtitleID, aiSummaryUUID);
      }, 3000);

      return;
    }
    let webhookRes = await subtitleWebHookPoll(projectUUID, assetKeys[0], subtitleID, 2);
    let webhookData = webhookRes.data;

    if (webhookData.manifest.words == undefined) {
      setExistSubtitleTxt("Transcript is not available for the project");
      dispatch(isAnalyzing_Store(false));

      Swal.fire({
        title: "Transcription is not detected for this language selection.",
        text: `Reason code: ${aiSummaryUUID}`,
        icon: "warning"
      });

      return;
    }

    setSubTitlesData(webhookData.manifest);
    setExistSubtitleWords(webhookData.manifest.words);
    setExistSubtitleTxt(webhookData.manifest.text);

    console.log('loaded SubtitleData', webhookData)

    let contextualData;
    
    if (aiSilence) {
      contextualData = parseSentences(webhookData.manifest.words)
      modalConfirmSumary(contextualData, videoItem, selectedAsset, videoEleItem);
      return;
    } else {
      contextualData = parseWords(webhookData.manifest.words);
    }

    console.log("CREATTEEEEEEE" + webhookData.manifest.words);

    dispatch(stageText_Store('Creating AI summary ...'))

    let gptSumaryData = await submitChatGPTSummary(aiSummaryUUID, sumaryTxt, contextualData, aiSummaryCount, aiSummarySilence);

    while(gptSumaryData.data.state_text != 'SUCCESS') {
      gptSumaryData = await getChatGPTSummary(aiSummaryUUID, sumaryTxt, contextualData);
      console.log('gptSumaryData state : ' + gptSumaryData.data.state_text);
      if (gptSumaryData.data.state_text === 'SUCCESS') {
        console.log('existAISummary -  gptSumaryData', gptSumaryData);
        break;
      } else if ((gptSumaryData.data.state_text === 'FAILED') || (gptSumaryData.data.state_text === undefined)) {
        Swal.fire({
          title: "AI Summary has failed, Please try again",
          text: `Reason code: ${aiSummaryUUID}`,
          icon: "error"
        });
        dispatch(isAnalyzing_Store(false));
        return;
      }
      console.log('gptSumaryData', gptSumaryData);
      await new Promise(r => setTimeout(r, 1000));
    }

    if(gptSumaryData.data.state_text != 'SUCCESS'){
      // window.toastr.error(gptSumaryData.data);
      Swal.fire({
        title: "AI Summary has failed",
        text: gptSumaryData.data.reason,
        icon: "error"
      });
      dispatch(isAnalyzing_Store(false));
      return;
    }

    console.log('gptSumaryData', gptSumaryData.data.response);
    const gptSumaryObj = JSON.parse(gptSumaryData.data.response);
    dispatch(isAnalyzing_Store(false));
    setModalData(gptSumaryObj);
    setSummaryData(gptSumaryData);
    setModalOpen(true);
    // console.log('timelineStamp', gptSumaryObj);
    // let newTimestamps = {};
    // let newJSONData = Object.assign({}, projectData);
    // let initStartTime = 0
    // let updatedVideoElements = {};
    // gptSumaryObj.new_timestamps.map(stampItem => {
    //   const startTime = parseFloat(stampItem.split('-')[0]) * 1000;
    //   const endTime = parseFloat(stampItem.split('-')[1]) * 1000;
    //   const newItemId = uuidv4();
    //   const newVideoItem = Object.assign({}, videoItem);
    //   newVideoItem.duration = endTime - startTime;
    //   newVideoItem.segment_start = startTime - newVideoItem.timeline_start;
    //   newVideoItem.segment_end = endTime - newVideoItem.timeline_start;
    //   newVideoItem.timeline_end = initStartTime + (endTime - startTime);
    //   newVideoItem.timeline_start = initStartTime;
    //   newVideoItem.signed_url = selectedAsset.signed_url;
    //   initStartTime = newVideoItem.timeline_end;
    //   const newVideoEle = videoEleItem.cloneNode(true);
    //   newVideoEle.id = newItemId;
    //   updatedVideoElements = { ...updatedVideoElements, [newItemId]: newVideoEle };
    //   if(aiReels){
        
    //     const ratioWH = newVideoItem.dimension.h / newVideoItem.dimension.w;
    //     const W = 1080;
    //     const H = newVideoItem.dimension.h *  ratioWH;
    //     newVideoItem.coordinate.x = 0;
    //     newVideoItem.coordinate.y = (1920 - H)/2;
    //     newVideoItem.dimension = {w: W, h: H};
    //     newVideoItem.ratioWH = {w: 1080, h: 1920};
    //   }
    //   newTimestamps = {...newTimestamps, [newItemId]: newVideoItem};
    // })
    // newJSONData['videos'] = newTimestamps;
    // if(aiReels){
    //   newJSONData.width = 1080;
    //   newJSONData.height = 1920;
    // }
    // dispatch(stageText_Store('Opening new AI Summary Project ...'))
    // const totalTime = await getTotalProjectTime(newJSONData);
    // newJSONData.duration = totalTime;
    // const result = await duplicatePrjoectJSON(projectUUID, newJSONData);
    //   if (result.state !== 'success') {
    //     Swal.fire({
    //       title: "AI Summary has failed",
    //       text: "AI Summary has failed",
    //       icon: "error"
    //     });
    //     return; 
    //     // window.toastr.error(result.data.message)
    //   }
    //   const data = result.data
    //   const duplicateUUID = data['Duplicated Project uuid']
    //   console.log(result.data);
    //   // window.location.href = `/edit/${duplicateUUID}/`

   
  }
  const parseSentences = (words) => {
    // Sort words array by 'start' timestamp
    words.sort((a, b) => a.start - b.start);
  
    let transcript = '';
    let timeArr = [];
    let words_and_timestamps = '';
  
    let count = 0;
    let sentence_start = null;
    let previous_end = null;
  
    words.forEach((word) => {
      transcript += `${word.text} `;
      words_and_timestamps += `${word.text}(${count})-${word.start / 1000}:${word.end / 1000}\n`;
      count++;

      if (sentence_start === null) sentence_start = word.start;

      if (previous_end !== null && word.start - previous_end > 100) {
        timeArr.push(`${sentence_start / 1000}-${previous_end / 1000}`);
        sentence_start = word.start;
      }
  
      previous_end = word.end;
    });

    if ((sentence_start !== null) && (previous_end !== null)){
      timeArr.push(`${sentence_start / 1000}-${previous_end / 1000}`);
    }
  
    return {
      transcript: transcript.trim(),
      new_timestamps: timeArr,
      words_and_timestamps: words_and_timestamps.trim()
    };
  };

  const parseWords = (words) => {
    console.log('parseWords', words);
    words.sort((a,b) => { return a.end - b.start });
    let texts = '';
    let timeArr = [];
    let words_and_timestamps = '';

    let count = 0;
    words.forEach((wItem) => {
      texts = `${texts} ${wItem.text}`;
      timeArr.push(`${(wItem.start / 1000)}-${wItem.end / 1000}`)
      words_and_timestamps = `${words_and_timestamps} ${wItem.text}(${count})-${wItem.start / 1000}:${wItem.end / 1000}\n`;
      count++;
    });
    return {
      transcript: texts,
      timestamps: timeArr,
      words_and_timestamps: words_and_timestamps
    }
  }
  return (
    <>
    <SectionHeader {...HeaderInfo}/>
    <Box md={12} className='px-10 pt-0'>
    <FormControlLabel
        control={
          <Checkbox checked={aiReels} onChange={() => {setAiReels(!aiReels)}} />
        }
        label="AI Reels"
      />
      <FormControlLabel
        control={
          <Checkbox checked={aiSilence} onChange={() => {setAiSilence(!aiSilence)}} />
        }
        label="AI Silence Remover"
      />
      <Typography className="v-block-footer mb-2">How do you want to summarize your content</Typography>
      <Typography className="v-block-footer mb-2">{languageStrs.subtitles_allowance_remaining} : {userData?.subtitle_remaining}</Typography>

      <Box className="v-hoverborder v-radius">
        <Box className="v-inputbox px-10 py-5">
          <textarea 
            rows={3}
            value={sumaryTxt}
            className="default-input w-full"
            placeholder="For example : I want a detailed general summary ..."
            onChange={({target}) => {
              setSumaryTxt(target.value);
            }}
          />
        </Box>
      </Box>
      <Box className='p-2'>
        <Typography md={12} className='v-block-footer pl-1'>
          {languageStrs.select_language_detect}
        </Typography>

        <Box className='v-autocomplate pt-2'>
            <Select value={language}
              inputProps={{ 'aria-label': 'Without label' }}
              className='v-hoverborder v-radius v-commonboxheight'
              onChange={languageChange}
            >
              {languageToList1.map((option, key) =>
                <MenuItem value={option.key} key={key}>
                  <Typography component='span'
                  >{option.label}</Typography>
                </MenuItem>
              )}
            </Select>
        </Box>
      </Box>

      <Box 
        border={1} // Adds a border around the box
        borderRadius={4} // Optional: adds rounded corners to the border
        borderColor="grey.400" // Sets the color of the border
        padding={2} // Adds some padding inside the box
      >
        <Typography className="v-block-footer mb-2">Select the duration for your AI Summary (in secs)</Typography>
        <Box display="flex" alignItems="center" paddingY={2}>
          <Slider
            value={aiSummaryDuration.toFixed(2)}
            min={0}
            max={projectMaxDuration} // Assuming count is based on words array
            onChange={handleDurationSliderChange}
            variant="standard" // Use "standard" to minimize the border appearance
            valueLabelDisplay="auto"
            style={{ flex: 1 }}
          />
          <TextField
            value={`${aiSummaryDuration.toFixed(2)}/${projectMaxDuration.toFixed(2)}`}
            onChange={handleInputChange}
            variant="outlined"
            label="Duration(secs)"
            size="small"
            style={{ width: 100, marginLeft: 16 }}
            inputProps={{
              readOnly: true,
              style: {
                textAlign: 'center', // Center the text horizontally
                fontSize: '0.875rem', // Set the font size
                padding: 0, // Remove any padding from the input
                height: '1.0rem', // Ensure a consistent height for the input
                display: 'flex', // Use flexbox for alignment
                alignItems: 'center', // Vertically center the text
                justifyContent: 'center', // Horizontally center the text
                lineHeight: '1.0rem', // Ensure the line height matches the height
                border: 'none', // Remove the border
              },
            }}
            InputProps={{
              style: {
                height: '2.0rem', // Match the height to the input for consistency
                padding: 0, // Remove extra padding
                display: 'flex', // Flexbox for alignment
                alignItems: 'center', // Vertically center the content
                border: 'none', // Remove the border
              },
            }}
          />
        </Box>
        <Typography className="v-block-footer mb-2">Select the silence detection for your AI Summary (in secs)</Typography>
        <Box display="flex" alignItems="center" paddingY={2}>
          <Slider
            value={aiSummarySilence.toFixed(2)}
            min={0.5}
            max={10}
            step={0.1}
            onChange={handleSilenceDetectionSliderChange}
            variant="standard" // Use "standard" to minimize the border appearance
            valueLabelDisplay="auto"
            style={{ flex: 1 }}
          />
          <TextField
            value={`${aiSummarySilence.toFixed(2)}`}
            onChange={handleInputChange}
            variant="outlined"
            label="Silence(secs)"
            size="small"
            style={{ width: 100, marginLeft: 16 }}
            inputProps={{
              readOnly: true,
              style: {
                textAlign: 'center', // Center the text horizontally
                fontSize: '0.875rem', // Set the font size
                padding: 0, // Remove any padding from the input
                height: '1.0rem', // Ensure a consistent height for the input
                display: 'flex', // Use flexbox for alignment
                alignItems: 'center', // Vertically center the text
                justifyContent: 'center', // Horizontally center the text
                lineHeight: '1.0rem', // Ensure the line height matches the height
                border: 'none', // Remove the border
              },
            }}
            InputProps={{
              style: {
                height: '2.0rem', // Match the height to the input for consistency
                padding: 0, // Remove extra padding
                display: 'flex', // Flexbox for alignment
                alignItems: 'center', // Vertically center the content
                border: 'none', // Remove the border
              },
            }}
          />
        </Box>
      </Box>

      <Button className="mt-4" variant="outlined" fullWidth size='large' onClick={() => {createSubtitleStr(false)}}>Create AI Summary</Button>
      {existSubtitleTxt &&
        <Button  className="mt-4" variant="outlined" fullWidth size='small' onClick={regenerateTranscript}> Regenerate Transcript </Button>
      }
      {existSubtitleTxt &&

        <Box className='mt-4 border rounded p-6' >
          {existSubtitleTxt}
        </Box>
      }

    </Box>
    <SummaryModal 
    modalOpen={modalOpen} 
    onModalCancel={onModalCancel} 
    onModalConfirm={onModalConfirm}
    modalData={modalData}
    videolink={showVideoPath}
    intendedDuration={aiSummaryDuration}
    silenceSetting={aiSummarySilence}
    />
    <PricingModal open={showPricingPageModal} onCloseModal={handleClosePricingModal} />
    </>
  )
} 