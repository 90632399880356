import Moveable from 'react-moveable';
import { useDispatch, useSelector } from 'react-redux';
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Slider } from '@mui/material';

import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import { useWebGL } from '../videoEdit.provider';
import { SetupTextEdit } from './Calculators/MoveableDiv';
import { getContentWidthHeight } from './Setups/SetupClass';
import { WebGLMovementEnd, WebGLStetchEnd } from './Calculators/projectjsonHelper';
import { isElementDraging_Store } from '../../../store/actions/editAction';
import { ProjectJSON_Store, StretchScreenFlag_store } from '../../../store/actions/projectdata';
import { SelectedItemID_Store, switchVideo_Store } from '../../../store/actions/webgldata';
import { SaveVideoPlaying_Store, TimeCount_Store } from '../../../store/actions/videoPlayAction';
import { GetQuerySelector } from '../../../assets/script/commonFunction';
import { DrawScene, initShaderProgram, UpdateVideoTextures, InitBuffers, UpdateServerBuffers, setGLBackground } from './Setups/SetupClass';
import { DragResizeCalculator, RotationCalculator } from './Calculators/PositionCalculators';
import { getSelectedType, getMoveableIDWithArr, getGroupoptions, convertStringtoWStringUTF16, changeZeroSecond } from '../commonFunction';
import { copyObject } from '../commonFunction';
import { getGLContext, getTextWidth,GetVideoCover } from './commonFunc';

import createTransition from 'gl-transition';
import createTexture from "gl-texture2d";
import {transitionGLSLData} from './GLSLtransitiongs/transitions.data'


let tempProjectJSON = null;
export const WebGLStarter = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [webGLContext, setWebGLContext] = useWebGL();
  const [mainCanvas, setMainCanvas] = useState(null);
  const [canvasGL, setCanvasGL] = useState(null);

  const Time_Count = useSelector((store) => store.videodata.Time_Count);
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const webglDataing = useSelector(store => store.webgldata);
  const imageElements = useSelector(store => store.webgldata.imageElements);
  const moveableDivArray = useSelector(store => store.webgldata.moveableDivArray);
  const activeVideoUUID = useSelector(store => store.projectdata.activeVideoUUID);
  const activeImageUUID = useSelector(store => store.projectdata.activeImageUUID);
  const activeTextUUID = useSelector(store => store.projectdata.activeTextUUID);
  const selectedItemID = useSelector(store => store.webgldata.selectedItemID);
  const switchVideo = useSelector(store => store.webgldata.switchVideo);
  const fullScreenFlag = useSelector((store) => store.projectdata.fullScreenFlag);
  const StretchScreenFlag = useSelector((store) => store.projectdata.stretchScreenFlag);
  const VideoPlaying = useSelector((store) => store.videodata.videoPlaying)

  
  const [time, setTime] = useState(+new Date());
  // useEffect(() => {
  //   ref.current.style.display = VideoPlaying ? 'none' : '';
  // }, [VideoPlaying])

  const [groupData, setGroupData] = useState({});
  useEffect(() => {
    let groupOptions = getGroupoptions(ProjectJSON);
    tempProjectJSON = JSON.parse(JSON.stringify(ProjectJSON))
    removeNotExistsMoveable(true);
    setGroupData(groupOptions);
  }, [ProjectJSON])

  const [transitionCanvas, setTransitionCanvas] = useState();
  const [transitionCanvasGL, setTransitionCanvasGL] = useState();
  const [transitionFromID, setTransitionFromID] = useState();
  const [transitionToID, setTransitionToID] = useState()
  const [glTransitionData, setGLTransitionData] = useState();
  const [transitionFrom, setTransitionFrom] = useState();
  const [transitionTo, setTransitionTo] = useState();
  const [isTransition, setIsTransition] = useState(false);
  const [transitionTime, setTransitionTime] = useState(0);
  const [transitionDuration, setTransitionDuration] = useState();
  useEffect(() => {
    if(switchVideo.isSwitching && !isTransition){
      let type = getSelectedType(switchVideo.videoID, ProjectJSON);
      let transitionName = ProjectJSON[type][switchVideo.videoID].transition.end['preset'];
      if(transitionName !== '-'){
        
        let transitionCan = document.createElement('canvas');
        transitionCan.width = 1920;
        transitionCan.height = 1080;
        transitionCan.style.position = "absolute";
        transitionCan.style.width = webGLContext.webGLRef.style.width;
        transitionCan.style.height = webGLContext.webGLRef.style.height;
        mainCanvas.after(transitionCan);
        setTransitionCanvas(transitionCan);
        let transitionWebGL = getGLContext(transitionCan);
        transitionWebGL.pixelStorei(canvasGL.UNPACK_FLIP_Y_WEBGL, true);
        const buffer = transitionWebGL.createBuffer();
        transitionWebGL.bindBuffer(transitionWebGL.ARRAY_BUFFER, buffer);
        transitionWebGL.bufferData(
          transitionWebGL.ARRAY_BUFFER,
          new Float32Array([-1, -1, -1, 4, 4, -1]), // see a-big-triangle
          transitionWebGL.STATIC_DRAW
        );
        
        let fromObjID = switchVideo.videoID;
        let toObjID =  ProjectJSON[type][switchVideo.videoID].transition.end['associated_texture'];
        setTransitionToID(toObjID)
        setTransitionFromID(fromObjID)
        /*const fromTransitionImg = createTexture(transitionWebGL, videoElements[fromObjID]);
        const toTransitionImg = createTexture(transitionWebGL, videoElements[toObjID]);
        setTransitionFrom(fromTransitionImg);
        setTransitionTo(toTransitionImg);*/
        const glslData = transitionGLSLData.find(glData => glData.type == transitionName.replace('-', ''));
        let glTransitionTmp = createTransition(transitionWebGL, glslData);
        setTransitionCanvasGL(transitionWebGL);
        setGLTransitionData(glTransitionTmp);
        setIsTransition(true);
        setTransitionTime(new Date());
        setTransitionDuration(ProjectJSON[type][switchVideo.videoID].transition.end['duration']);
      }else{
        setIsTransition(false);
      }
    }
  }, [switchVideo])

  useEffect(() => {
    if (!webGLContext.webGLRef) return;
    const canvasEl = webGLContext.webGLRef;
    const canvasGl = getGLContext(canvasEl);
    const shaderProgram = initShaderProgram(canvasGl);
    
    const programData = {
      program: shaderProgram,
      attribLocations: {
        vertexPosition: canvasGl.getAttribLocation(shaderProgram, 'aVertexPosition'),
        textureCoord: canvasGl.getAttribLocation(shaderProgram, 'aTextureCoord'),
      },
      uniformLocations: {
        projectionMatrix: canvasGl.getUniformLocation(shaderProgram, 'uProjectionMatrix'),
        modelViewMatrix: canvasGl.getUniformLocation(shaderProgram, 'uModelViewMatrix'),
        //filterPreset_m: canvasGl.getUniformLocation(shaderProgram, 'aFilterPreset_m'),
        //filterPreset_v: canvasGl.getUniformLocation(shaderProgram, 'aFilterPreset_v'),
        isGreen: canvasGl.getUniformLocation(shaderProgram, 'isGreen'),
        transparency: canvasGl.getUniformLocation(shaderProgram, 'transparency'),
        editValue: canvasGl.getUniformLocation(shaderProgram, 'edit_values'),
        blackWhiteFilter: canvasGl.getUniformLocation(shaderProgram, 'blackwhite'),
        uSampler: canvasGl.getUniformLocation(shaderProgram, 'uSampler'),
      },
    }

    setWebGLContext({ ...webGLContext, programInfo: programData });
    setMainCanvas(canvasEl);
    setCanvasGL(canvasGl);
     
    
  }, [webGLContext.webGLRef])

  const [keepRatio, setKeepRatio] = useState(true);
  const [moveableRef, setMoveableRef] = useState(null);
  useEffect(() => {
    getMoveableTarget();

    let tempKeepRatio = true;
    if (!!selectedItemID) {
      let type = getSelectedType(selectedItemID, ProjectJSON);
      if (type === 'text') tempKeepRatio = false;
    }

    if (tempKeepRatio !== keepRatio) {
      setKeepRatio(tempKeepRatio);
    }
  }, [selectedItemID, fullScreenFlag])

  useEffect(() => {
    if (!selectedItemID) return;
    let types = ['text', 'videos', 'images'];
    let type = getSelectedType(selectedItemID, ProjectJSON);
    let checkType = types.find((t) => t === type);
    if (!checkType) return;

    let tempJSON = JSON.parse(JSON.stringify(ProjectJSON));
    let selectedItem = tempJSON[type][selectedItemID];
    if (!selectedItem) return;

    let indicatorT = Time_Count.getTime();
    let endTime = selectedItem?.timeline_end;
    let startTime = selectedItem?.timeline_start;
    if (endTime < 0 || !endTime && endTime !== 0) return;
    if (indicatorT < 0 || !indicatorT && indicatorT !== 0) return;
    if (startTime < 0 || !startTime && startTime !== 0) return;
    if (startTime <= indicatorT && indicatorT <= endTime) return;

    let nextTime = 0;
    if (indicatorT >= endTime) nextTime = endTime - 1;
    else nextTime = startTime;

    let newTime = new Date(nextTime);
    if (nextTime !== indicatorT) dispatch(TimeCount_Store(newTime))
  }, [selectedItemID])

  const getMoveableTarget = () => {
    let selectedItemTmp = selectedItemID;
    if(Array.isArray(selectedItemID)){
      selectedItemTmp = getMoveableIDWithArr(selectedItemID, ProjectJSON);
    }
    const targetRef = GetQuerySelector(`.thDiv${selectedItemTmp}`);
    if (!selectedItemID || !targetRef || fullScreenFlag) {
      setMoveableRef(null);
    } else {
      const targetRef = GetQuerySelector(`.thDiv${selectedItemTmp}`);
      setMoveableRef(targetRef);
    }
  }

  
  
  useEffect(() => {
    
    let handler = setTimeout(async () => {
      let is_canvas_changed = true;
      await setTime(+new Date());

      if (!mainCanvas || !webGLContext.activeTextures.size) return;
      //const bgImg = document.getElementById('greenBg');
      //setGLBackground(canvasGL, webGLContext, bgImg);
      if(switchVideo.isSwitching && isTransition){
        if(VideoPlaying){
          const fromTransitionImg = createTexture(transitionCanvasGL, videoElements[transitionFromID]);
          const toTransitionImg = createTexture(transitionCanvasGL, videoElements[transitionToID]);
          var progress = ((time - transitionTime)/transitionDuration);
          if(progress < 0.99){
            glTransitionData.draw(progress, fromTransitionImg, toTransitionImg, webGLContext.webGLRef.offsetWidth, webGLContext.webGLRef.offsetHeight, { resizeMode: "stretch"})
          }else{
            glTransitionData.draw(1, fromTransitionImg, toTransitionImg, webGLContext.webGLRef.offsetWidth, webGLContext.webGLRef.offsetHeight, { resizeMode: "stretch"})
            transitionCanvas.remove();
            setTransitionCanvas(null);
            setIsTransition(false);
            setTransitionTime(new Date());
            dispatch(switchVideo_Store({
              isSwitching: false,
              videoID: ''
            }));
          }
        }
      }
      if(activeVideoUUID.length > 0){
       activeVideoUUID.forEach(function (key) {
          if (!videoElements[key]) return;
          const currentTexture = webGLContext.activeTextures.get(key);
          if(videoElements[key].readyState == 4) {
            UpdateVideoTextures(canvasGL, currentTexture, videoElements[key]);
          } else {
            is_canvas_changed = false;
          }
        })  
      }
      activeImageUUID.forEach(function (key) {
        if (!imageElements[key]) return;
        const currentTexture = webGLContext.activeTextures.get(key);
        UpdateVideoTextures(canvasGL, currentTexture, imageElements[key]);
      })
      if (is_canvas_changed) {
        DrawScene(canvasGL, webGLContext, ProjectJSON, groupData, Time_Count);
        setCanvasGL(canvasGL);
      }
    }, 50);
    return () => handler && clearTimeout(handler);
  }, [time, switchVideo]);

  useEffect(() => {
    if (!!mainCanvas) {
      AddEventListerMoveableDivs()
    }
  }, [moveableDivArray, mainCanvas, fullScreenFlag])


  const serverTextRef = useRef();
  serverTextRef.current = [''];
  const AddEventListerMoveableDivs = async () => {
    await removeNotExistsMoveable(fullScreenFlag);

    if (!fullScreenFlag) {
      const textOb = ProjectJSON.text;
      const moveableParentEL = ref.current;
      const MoveableKeys = Object.keys(moveableDivArray);
      MoveableKeys.forEach(function (moveableKey) {
        moveableDivArray[moveableKey].addEventListener('click', () => {
          dispatch(SelectedItemID_Store(moveableKey));
        }, false)

        const MoveableRefs = Array.from(moveableParentEL.children);
        const MoveableItem = MoveableRefs.find((inputRef) => { return inputRef.getAttribute('id') === moveableKey; });
        if (!MoveableItem) moveableParentEL.appendChild(moveableDivArray[moveableKey]);
        // append the moveable divs into the parent div if not found

        const checkTextOb = activeTextUUID.find((textkey) => textkey === moveableKey);
        if (!checkTextOb) return;

        // const textItemOb = textOb[checkTextOb];
        // const moveableItem = moveableDivArray[moveableKey];
        // const SetTextParam = [textItemOb, moveableItem, checkTextOb, serverTextRef, webGLContext, ProjectJSON, mainCanvas];

        // SetupTextEdit(...SetTextParam, (changedText, textItemKey) => {
        //   let newJSONData = Object.assign({}, tempProjectJSON);
        //   const textitem = newJSONData.text[textItemKey];
        //   if (!textitem || textitem.context === changedText) return;

        //   let [maxWidth, maxHeight, acceptRatio] = getContentWidthHeight(changedText, ProjectJSON, textitem, mainCanvas);
        //   newJSONData.text[textItemKey].context = changedText;
        //   newJSONData.text[textItemKey].contextutf16 = convertStringtoWStringUTF16(changedText);
        //   textitem.dimension.h = maxHeight / acceptRatio;
        //   textitem.dimension.w = maxWidth / acceptRatio;
        //   dispatch(ProjectJSON_Store(newJSONData));
        //   changeZeroSecond(textitem, dispatch)
        // })
      })

      getMoveableTarget()
    }
  }

  const removeNotExistsMoveable = (state) => {
    const moveableParentEL = ref.current;
    const activeKeys = activeVideoUUID.concat(activeTextUUID);
    const moveableInputs = Array.from(moveableParentEL.children);
    moveableInputs.forEach((inputRef) => {
      const id = inputRef.getAttribute('id');
      if (!id) return;
      const checkActiveKey = activeKeys.find((activeKey) => activeKey === id);
      if (!checkActiveKey || state) inputRef.remove();
    })

    return;
  }

  // Moveable Event Function START
  const MoveableDragFunc = (props) => {
    const tmpProps = props;
    let { target, left, top, width, height } = tmpProps
    const centerX = mainCanvas.clientWidth / 2;
    const centerY = mainCanvas.clientHeight / 2;
    const ActiveMatrices = webGLContext.activeModelViewMatrices
    const type = getSelectedType(target.id, ProjectJSON)
    if (!type || type === '') return
    dispatch(isElementDraging_Store(true));
    const calulatedTop = (top + (height / 2));
    const calculatedLeft = (left + (width / 2));
    const nearedCenterVal = Math.abs(calulatedTop - centerY) * Math.abs(calculatedLeft - centerX);
    if( Math.abs(calulatedTop - centerY) < 20){
      target.style.top = `${centerY - (height / 2)}px`
      tmpProps.top = centerY - (height / 2);
    }else{
      target.style.top = `${top}px`
    }

    if(Math.abs(calculatedLeft - centerX) < 20){
      target.style.left = `${centerX - (width / 2)}px`
      tmpProps.left = centerX - (width / 2);
    }else{
      target.style.left = `${left}px`
    }
    
    let Matrix = ActiveMatrices.get(selectedItemID)
    const resizeData = [props, mainCanvas, Matrix]
    Matrix = DragResizeCalculator(...resizeData)
    ActiveMatrices.set(selectedItemID, Matrix)
  }

  const MoveableResizeFuc = (props) => {
    let tempJSON = {...ProjectJSON}
    const tmpProps = props;
    let { target, width, height, drag, delta } = tmpProps;
    const ActiveMatrices = webGLContext.activeModelViewMatrices
    const type = getSelectedType(target.id, tempJSON)
    if (!type) return

    let activeUUID = selectedItemID
    if (type === 'text') {
      let textInfo = tempJSON[type][activeUUID]

      let canvasW = webGLContext.webGLRef.offsetWidth
      let acceptRatio = canvasW / tempJSON.width
      let response = getNextFontSize(textInfo, height / acceptRatio)
      let [nextWidth, nextSize, rowCount] = response
      nextSize = parseFloat(nextSize.toFixed(1))

      let nextHeight = (nextSize * 3 * 1.3) * rowCount
      height = nextHeight * acceptRatio
      width = nextWidth * acceptRatio

      textInfo.dimension.w = nextWidth
      textInfo.dimension.h = nextHeight
      textInfo.properties.font_size = nextSize
    }

    target.style.width = `${width}px`
    target.style.height = `${height}px`
    target.style.left  = `${drag.left}px`;
    target.style.top  = `${drag.top}px`;
    // delta[0] && (target.style.width = `${width}px`)
    // delta[1] && (target.style.height = `${height}px`)

    tmpProps['top'] = drag.top
    tmpProps['left'] = drag.left
    let Matrix = ActiveMatrices.get(target.id)
    const resizeData = [tmpProps, mainCanvas, Matrix, type, webGLContext, activeUUID, canvasGL, tempJSON];
    Matrix = DragResizeCalculator(...resizeData);
    ActiveMatrices.set(target.id, Matrix);
  }

  const MoveableRotate = (props) => {
    const { target, transform } = props
    const ActiveMatrices = webGLContext.activeModelViewMatrices

    const elementID = target.id
    let type = getSelectedType(elementID, ProjectJSON)
    if (!type || type === '') return

    if (type === 'text') return
    target.style.transform = transform
    const CSSText = target.style.cssText
    let newMatrix = ActiveMatrices.get(elementID)

    newMatrix = RotationCalculator(CSSText, newMatrix)
    ActiveMatrices.set(elementID, newMatrix)
  }

  const JSONdataSaveFunc = async ({ target, isDrag }, flag) => {
    if (!isDrag) return
    let activeUUID = selectedItemID
    let type = getSelectedType(activeUUID, ProjectJSON);
    if (!type) return

    let tempJSON = copyObject(ProjectJSON)
    let itemInfo = tempJSON[type][activeUUID]
    const props = [target, mainCanvas, tempJSON, itemInfo]
    const positionData = await WebGLMovementEnd(...props)

    if (type === 'text' && flag === 'resize') {
      let height = positionData.dimension.h
      let response = getNextFontSize(itemInfo, height)
      let [nextWidth, nextSize, rowCount] = response
      nextSize = parseFloat(nextSize.toFixed(1))

      let nextHeight = (nextSize * 3 * 1.3) * rowCount
      itemInfo.dimension.h = nextHeight
      itemInfo.dimension.w = nextWidth
      // itemInfo.coordinate = positionData.coordinate
      itemInfo.properties.font_size = nextSize
    } else itemInfo.dimension = positionData.dimension

    if (type === 'text' && flag === 'rotate') return
    itemInfo.coordinate = positionData.coordinate
    itemInfo.rotation = positionData.rotation
    dispatch(ProjectJSON_Store(tempJSON));
    changeZeroSecond(itemInfo, dispatch)
    dispatch(isElementDraging_Store(false));
  }
  // Moveable Event Function END

  useEffect(() => {
    if(StretchScreenFlag){
      StretchScreen();
    }
  }, [StretchScreenFlag])
  const moveableDom = useRef();
  const StretchScreen = async () => {
    let activeUUID = selectedItemID
    let type = getSelectedType(activeUUID, ProjectJSON);
    
    if(type == 'videos' || type == 'images'){
      let currentTarget = moveableDom.current.props.target;
      if(currentTarget.id == activeUUID){
        currentTarget.style.width = `${webGLContext.webGLRef.offsetWidth}px`;
        currentTarget.style.height = `${webGLContext.webGLRef.offsetHeight}px`;
       // currentTarget.offsetWidth = webGLContext.webGLRef.offsetWidth;
        const ActiveMatrices = webGLContext.activeModelViewMatrices
        let Matrix = ActiveMatrices.get(selectedItemID)
        let tempJSON = copyObject(ProjectJSON)
        const resizeData = [
            {left: 0, top: 0, 
              width: webGLContext.webGLRef.offsetWidth, 
              height: webGLContext.webGLRef.offsetHeight,
               zIndex: currentTarget.style.zIndex}, 
               mainCanvas, Matrix,
               type, webGLContext, activeUUID, canvasGL, tempJSON
              ]
        Matrix = DragResizeCalculator(...resizeData)
        ActiveMatrices.set(selectedItemID, Matrix)
        const elementRatio = type == 'videos'? videoElements[activeUUID].videoHeight / videoElements[activeUUID].videoWidth : imageElements[activeUUID].height / imageElements[activeUUID].width;
        
        let itemInfo = tempJSON[type][activeUUID]
        const webGLprops = [
          {left: 0, top: 0, 
            width: webGLContext.webGLRef.offsetWidth, 
            height: webGLContext.webGLRef.offsetHeight,
            transform: webGLContext.webGLRef.style.transform 
          },
           mainCanvas, tempJSON, itemInfo,
           elementRatio
          ]
        const positionData = await WebGLStetchEnd(...webGLprops)
    
        itemInfo.dimension = positionData.dimension
        itemInfo.coordinate = positionData.coordinate
        itemInfo.rotation = positionData.rotation;
        itemInfo.ratioWH = positionData.ratioWH;
        dispatch(ProjectJSON_Store(tempJSON));
        changeZeroSecond(itemInfo, dispatch)
      }
    }

    setTimeout(() => { dispatch(StretchScreenFlag_store(false)) }, 100);
  }

  let fullScreenCtrlHeight = ref?.current?.offsetHeight / 6
  if (!fullScreenCtrlHeight || fullScreenCtrlHeight > 100) {
    fullScreenCtrlHeight = 100
  }

  let [maxCount, setMaxCount] = useState(0)
  let [duration, setDuration] = useState('')

  useEffect(() => {
    if (ProjectJSON.duration) {
      let tempTime = new Date(ProjectJSON.duration)
      let tempDuration = tempTime.MMSSM()

      if (duration !== tempDuration) {
        setDuration(tempDuration)
      }

      if (maxCount !== ProjectJSON.duration) {
        setMaxCount(ProjectJSON.duration)
      }
    } else {
      setDuration("")
    }
    
  }, [ProjectJSON])

  const VideoPlayFunc = (status) => {
    dispatch(SaveVideoPlaying_Store(status));
  }

  return (
    <div className='moveable-wrapper relative' ref={ref}>
      <Moveable className='moveable-container_'
        ref={moveableDom}
        target={moveableRef}
        container={null}
        origin={true}
        edge={false} // Resize event edges
        draggable={true} // draggable
        throttleDrag={0}
        onDragStart={(props) => { }} //{ target, clientX, clientY }
        onDrag={(props) => { MoveableDragFunc(props); }}
        onDragEnd={(props) => { JSONdataSaveFunc(props); }}

        keepRatio={keepRatio} // When resize or scale, keeps a ratio of the width, height.
        resizable={true} // resizable
        throttleResize={1}
        onResizeStart={(props) => { }} //{ target, clientX, clientY }
        onResize={(props) => { MoveableResizeFuc(props); }}
        onResizeEnd={(props) => { JSONdataSaveFunc(props, 'resize'); }}
        
        
        rotatable={true} // rotatable
        throttleRotate={0}
        onRotateStart={(props) => { }} //{ target, clientX, clientY }
        onRotate={(props) => { MoveableRotate(props); }}
        onRotateEnd={(props) => { JSONdataSaveFunc(props, 'rotate'); }}
      />

      {fullScreenFlag && (
        <div className='fullscreen-wrapper flex flex-col justify-center px-5 md:px-30 bg-fullScreen text-white'
          style={{ height: fullScreenCtrlHeight + 'px' }}
        >
          <div className='max-h-40 h-full flex flex-row gap-15 md:gap-30 items-center'>
            {VideoPlaying && (
              <div className='h-full cursor-pointer' onClick={() => { VideoPlayFunc(false) }}>
                <PauseIcon style={{ height: '100%', width: 'auto' }} />
              </div>
            )}

            {!VideoPlaying && (
              <div className='h-full cursor-pointer' onClick={() => { VideoPlayFunc(true) }}>
                <PlayArrowIcon style={{ height: '100%', width: 'auto' }} />
              </div>
            )}

            <div className='video-ctrl flex-1 pr-0'>
              <Slider track={false} onChange={() => { }}
                value={+ new Date(Time_Count)} min={0} max={maxCount}
              />
            </div>

            <div className='flex flex-row gap-5 items-center text-15 opacity-75'>
              {Time_Count.MMSSM()}

              {duration && (
                <span className='hidden md:flex'> / {duration}</span>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  )
})

const getNextFontSize = (textInfo, height) => {
  let bold = textInfo.properties.bold
  let font_family = textInfo.properties.font_family
  let font_size = textInfo.properties.font_size * 3
  let options = { font: font_family, fontSize: font_size + 'px', fontWeight: bold }

  let mw = 0
  let maxText = ''
  let textRows = textInfo.context.split('?entered!*-')
  textRows.forEach((text) => {
    let size = getTextWidth(text, options)
    if (mw < size.width) maxText = text
    if (mw < size.width) mw = size.width
  })

  let len = textRows.length
  let nextSize = (height / len) / 1.3
  let options1 = { font: font_family, fontSize: nextSize + 'px', fontWeight: bold }
  let size = getTextWidth(maxText, options1)
  let nextWidth = size.width + 60

  return [nextWidth, nextSize / 3, textRows.length]
}