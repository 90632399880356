import { calculateFrameCount, formatXml, sequence_timecode, sequence_label, createLoggingInfo } from './adobexmlformat_helper';
import { sequence_media_video_format, sequence_media_video_track } from './adobexmlformat_video';
import { sequence_media_audio } from './adobexmlformat_audio';

export function createPrettyXMLFromJSON(projectJson) {

    const xmlDoc = document.implementation.createDocument("", "", null);
    let videos = Array.isArray(projectJson.videos) ? projectJson.videos : Object.values(projectJson.videos || {});
    videos = videos.sort((a, b) => a.timeline_start - b.timeline_start);
    let projectFPS = videos.reduce((acc, video) => video.fps, 0);
    let projectTimebase, projectNtsc;
    projectJson.fps = projectFPS;

    if (projectFPS === undefined) {
        console.log("No FPS found in videos, forcing it to 29.979");
        projectFPS = 29.97;
    }
    console.log("sTandard FPS : ", projectFPS);

    if (Number.isInteger(projectFPS) || Math.abs(projectFPS - Math.round(projectFPS)) < Number.EPSILON) {
        // FPS is effectively an integer, use the timebase without ntsc
        console.log(`FPS is an integer: ${projectFPS}`);
        projectTimebase = Math.round(projectFPS);  // Just to ensure the integer value
        projectNtsc = "FALSE";
    } else {
        // FPS is not an integer, use timebase and set ntsc to TRUE for Adobe Premiere
        console.log(`FPS is not an integer: ${projectFPS}`);
        projectTimebase = Math.round(projectFPS);  // Round to nearest integer for timebase
        projectNtsc = "TRUE";  // Use ntsc true for non-integer fps
    }

    projectJson.fps = projectFPS;

    const totalDuration = projectJson.duration; // videos.reduce((acc, video) => acc + video.duration, 0);
    const durationFrames = calculateFrameCount(totalDuration, projectJson.fps, projectNtsc);

    // Root element
    const xmeml = xmlDoc.createElement("xmeml");
    xmeml.setAttribute("version", "4");
    xmlDoc.appendChild(xmeml);

    // Sequence element
    const sequence = xmlDoc.createElement("sequence");
    setSequenceAttributes(sequence, projectJson);
    xmeml.appendChild(sequence);

    // Add UUID, duration, rate, and name
    const uuid = xmlDoc.createElement("uuid");
    uuid.textContent = "977a82e4-2ea1-40a7-9a45-6606957bb7a1"; // Static UUID
    sequence.appendChild(uuid);

    const durationElement = xmlDoc.createElement("duration");
    durationElement.textContent = durationFrames;
    sequence.appendChild(durationElement);

    const rate = createRateElement(xmlDoc, projectJson, projectTimebase, projectNtsc);
    sequence.appendChild(rate);

    const name = xmlDoc.createElement("name");
    name.textContent = "Sequence 01" // projectJson.title;
    sequence.appendChild(name);

    // Media section
    let video_asset_map = {};

    let markers = Array.isArray(projectJson.markers) ? projectJson.markers : Object.values(projectJson.markers || {});
    markers.forEach((marker, index) => {
        console.log("Marker: ", marker);

        // Construct the context string with new lines using '&#xA;' for line breaks
        let context = `${marker.topic}    `+
                      `COMMENT : ${marker.comment}    `+
                      `TRANSCRIPT : ${marker.transcript}    `+
                      `TIMELINE_START : ${marker.original_start/1000.0}s    `+
                      `TIMELINE_END : ${marker.original_end/1000.0}s     `+
                      `CONFIDENCE : ${marker.confidence}    `+
                      `IMPORTANCE : ${marker.importance}    `;

        // Create the comment element and add a text node with the constructed context
        let commentElement = xmlDoc.createElement('comment');
        let textNode = xmlDoc.createTextNode(context); // Create a text node
        commentElement.appendChild(textNode); // Append the text node to the comment element

        // Instead of passing the element, pass the text content
        const markerElement = sequence_marker(
            xmlDoc,
            calculateFrameCount(marker.timeline_start, projectJson.fps, projectNtsc),
            calculateFrameCount(marker.timeline_end, projectJson.fps, projectNtsc),
            commentElement.textContent // Pass the textContent, not the element
        );

        sequence.appendChild(markerElement);
    });

    const media = xmlDoc.createElement("media");
    const media_video = sequence_media_video_format(xmlDoc, projectJson, projectTimebase, projectNtsc);
    media.appendChild(media_video);
    const media_video_track = sequence_media_video_track(xmlDoc, projectJson, videos, video_asset_map, projectTimebase, projectNtsc);
    media_video.appendChild(media_video_track);
    media.appendChild(sequence_media_audio(xmlDoc, projectJson, video_asset_map, projectTimebase, projectNtsc));
    sequence.appendChild(media);

    // Timecode section
    const timecode = sequence_timecode(xmlDoc, projectJson, projectTimebase, projectNtsc);
    sequence.appendChild(timecode);

    // Labels section
    const labels = sequence_label(xmlDoc);
    sequence.appendChild(labels);

    // Logging info
    const loggingInfo = createLoggingInfo(xmlDoc);
    sequence.appendChild(loggingInfo);

    // Pretty-print XML
    const serializer = new XMLSerializer();
    let xmlString = serializer.serializeToString(xmlDoc);

    // Add XML declaration and DOCTYPE
    const declaration = '<?xml version="1.0" encoding="UTF-8"?>\n';
    const doctype = '<!DOCTYPE xmeml>\n';
    xmlString = declaration + doctype + xmlString;

    xmlString = formatXml(xmlString);

    return xmlString;
}

function setSequenceAttributes(sequence, projectJson) {
    sequence.setAttribute("id", `sequence-1`);
    sequence.setAttribute("TL.SQAudioVisibleBase", "0");
    sequence.setAttribute("TL.SQVideoVisibleBase", "3");
    sequence.setAttribute("TL.SQVisibleBaseTime", "874535394673");
    sequence.setAttribute("TL.SQAVDividerPosition", "0.5");
    sequence.setAttribute("TL.SQHideShyTracks", "0");
    sequence.setAttribute("TL.SQHeaderWidth", "236");
    sequence.setAttribute("Monitor.ProgramZoomOut", "5653270022400");
    sequence.setAttribute("Monitor.ProgramZoomIn", "0");
    sequence.setAttribute("TL.SQTimePerPixel", "0.019126865907647492");
    sequence.setAttribute("MZ.EditLine", "923847724800");
    sequence.setAttribute("MZ.Sequence.PreviewFrameSizeHeight", projectJson.height);
    sequence.setAttribute("MZ.Sequence.PreviewFrameSizeWidth", projectJson.width);
    sequence.setAttribute("MZ.Sequence.AudioTimeDisplayFormat", "200");
    sequence.setAttribute("MZ.Sequence.PreviewRenderingClassID", "1061109567");
    sequence.setAttribute("MZ.Sequence.PreviewRenderingPresetCodec", "1634755443");
    sequence.setAttribute("MZ.Sequence.PreviewRenderingPresetPath", "EncoderPresets/SequencePreview/795454d9-d3c2-429d-9474-923ab13b7018/QuickTime.epr");
    sequence.setAttribute("MZ.Sequence.PreviewUseMaxRenderQuality", "false");
    sequence.setAttribute("MZ.Sequence.PreviewUseMaxBitDepth", "false");
    sequence.setAttribute("MZ.Sequence.EditingModeGUID", "795454d9-d3c2-429d-9474-923ab13b7018");
    sequence.setAttribute("MZ.Sequence.VideoTimeDisplayFormat", "103");
    sequence.setAttribute("MZ.WorkOutPoint", "15239249625600");
    sequence.setAttribute("MZ.WorkInPoint", "0");
    sequence.setAttribute("explodedTracks", "true");
}

function createRateElement(xmlDoc, projectJson, projectTimebase, projectNtsc) {
    const rate = xmlDoc.createElement("rate");
    const timebase = xmlDoc.createElement("timebase");
    timebase.textContent = projectTimebase;
    const ntsc = xmlDoc.createElement("ntsc");
    ntsc.textContent = projectNtsc;
    rate.appendChild(timebase);
    rate.appendChild(ntsc);
    return rate;
}

function sequence_marker(xmlDoc, start, end, context) {
    const marker = xmlDoc.createElement("marker");

    // Create the 'in' element (starting frame of the marker)
    const startFrame = xmlDoc.createElement("in");
    startFrame.textContent = start;
    marker.appendChild(startFrame);

    const endFrame = xmlDoc.createElement("out");
    endFrame.textContent = end;
    marker.appendChild(endFrame);

    // Create the 'duration' element (optional, depending on FCPXML version)
    const durationElement = xmlDoc.createElement("duration");
    durationElement.textContent = end - start;
    marker.appendChild(durationElement);

    // Create the 'comment' element (marker description)
    const comment = xmlDoc.createElement("comment");
    comment.textContent = context;
    marker.appendChild(comment);

    // Create the 'color' element (color of the marker)
    const color = xmlDoc.createElement("color");
    color.textContent = "Red"; // Color for the marker
    marker.appendChild(color);

    // Append the marker to the sequence
    return marker;
}
