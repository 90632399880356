import React, { useEffect, useState } from 'react'
import { CirclePicker, AlphaPicker } from 'react-color'
import { Box, Grid, Typography, Menu } from '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled'

import './colorPicker.scss'
import { rgbaToHex, hexToRgbA } from '../../commonFunction'
import { ColorTab, ColorTabPanel, ColorTabsList } from './section.tabbar'


const colors1 = [
  '#000000',
  '#fff',
  '#a766fa',
  '#5269da',
  '#147de8',
  '#22cfce',
  '#eaf1f9',
  '#1dc321',
  '#fecb2f',
  '#fd8f27',
  '#ff006a',
  '#d8d8d8',
  '#243e43',
  '#ea7a76',
  '#359ba5',
  '#f65d94',
  '#ff1717',
  '#61bcf9',
  '#fce371',
  '#7ee7c2',
  '#f088e6',
  '#ff4149',
  '#0101f8',
  '#fefd54'
];

const colors2 = [
  '#ffcdd2',
  '#ef9a9a',
  '#f4848f',
  '#ff5e6e',
  '#e45063',
  '#ff9800',
  '#fb8c00',
  '#f57c00',
  '#ef6c00',
  '#e65100',
  '#ffe45c',
  '#ffe145',
  '#ffdd2e',
  '#ffd917',
  '#ffd600',
  '#8bc34a',
  '#7cb342',
  '#689f38',
  '#558b2f',
  '#33691e',
  '#b2ebf2',
  '#4dd0e1',
  '#00bcd4',
  '#0097a7',
  '#006064',
  '#e1bee7',
  '#ce93d8',
  '#ba68c8',
  '#ab47bc',
  '#9c27b0',
  '#638cff',
  '#4f7eff',
  '#3c70ff',
  '#2962ff',
  '#265ae8'
];

export const ColorPicker = (props) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const openMenu = (e) => { setAnchorEl(e.currentTarget) }
  const closeMenu = () => { setAnchorEl(null) }

  const rgba = { r: 0, g: 0, b: 0, a: 0 }
  const [alphaColor, setAlphaColor] = useState(rgba)

  useEffect(() => {
    let color = props.color
    let rgbColor = hexToRgbA(color)
    setAlphaColor(rgbColor)
  }, [props.color])

  const colorChanged = (color) => {
    let rgb = color.rgb
    let hex = rgbaToHex(rgb)
    setAlphaColor(rgb)
    props.change(hex)
  }

  const colorChanging = (color) => {
    let rgb = color.rgb
    setAlphaColor(rgb)
  }

  let hexColor = rgbaToHex(alphaColor)
  let style = {
    backgroundColor: hexColor,
    borderRadius: '50%',
    position: 'relative'
  }

  return (
    <>
      <Box sx={style} onClick={openMenu} className='p-1'>{props.Icon}</Box>

      <Menu anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        className='colorPicker-wrapper'
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        sx={{ mt: 1.5 }}
      >
        <Box className='flex flex-col gap-20 p-15'>
          <TabsUnstyled defaultValue={0}>
            <ColorTabsList>
              <ColorTab>Color Picker</ColorTab>
              <ColorTab>Palettes</ColorTab>
            </ColorTabsList>

            <ColorTabPanel value={0} className='v-scroll' sx={{ overflowX: 'hidden' }} >
              <Typography className='v-left ml-2 v-font-2-3'>All Colors</Typography>
              <CirclePicker
                className='p-2'
                circleSize={32}
                color={hexColor}
                colors={colors1}
                onChangeComplete={colorChanged} />
            </ColorTabPanel>

            <ColorTabPanel value={1} className='v-scroll' sx={{ overflowX: 'hidden' }}>
              <CirclePicker
                className='p-2'
                circleSize={32}
                color={hexColor}
                colors={colors2}
                onChangeComplete={colorChanged} />
            </ColorTabPanel>
          </TabsUnstyled>

          <AlphaPicker
            width='100%'
            className='mt-3'
            color={alphaColor}
            onChange={colorChanging}
            onChangeComplete={colorChanged}
          />

          <Box className='v-border v-radius v-left mt-2'>
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <Box className='m-2'
                  sx={{
                    width: '20px', height: '20px', borderRadius: '50%',
                    backgroundColor: hexColor
                  }}>
                </Box>
              </Grid>

              <Grid item xs={8} className='v-cneter' sx={{ display: 'flex' }}>
                <input type="text" className='v-input-colortext v-font-2-2 v-center'
                  value={hexColor} onChange={() => { }} />
              </Grid>

              <Grid item xs={2} sx={{ display: 'flex' }}>
                <Box className='v-center v-font-2-3' sx={{ color: 'rgb(164, 160, 160)' }}>hex</Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Menu>
    </>
  )
}