import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Menu, Divider, MenuItem, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import ReplayIcon from '@mui/icons-material/Replay';
import PanoramaFishEyeOutlinedIcon from '@mui/icons-material/PanoramaFishEyeOutlined';
import RadioButtonCheckedOutlinedIcon from '@mui/icons-material/RadioButtonCheckedOutlined';
import CreateIcon from '@mui/icons-material/Create';

import Swal from 'sweetalert2';

import './header.scss';
import logoimg from '../../../assets/image/Logo.png';
import workSpaceImg from '../../../assets/image/workspace.png'
import workSpaceMenuImg from '../../../assets/image/workspace-menu.png'

import { tips } from '../../../util'
import { ExportPage } from './export';
import { Compress } from "./compress"
import { Convert } from "./convert"
import { UserAvatar } from '../../home-header/header';
import { ChangeProjectWorkspace, ShareProject, createWorkspace, getProjectHistory, getWorkspacesList, renameWorkSpace } from '../../../axios/ApiProvider';
import { EmailValidate } from '../../../provider/validate/FormValidate';
import { ProjectJSON_Store, sequenceStatus_Store, sequenceNum_Store } from '../../../store/actions/projectdata';
import {languageCode_Store} from '../../../store/actions/mainAction';
import { useGlobalContext } from '../../../provider';
import { languageStrs_Store } from '../../../store/actions/editAction';
import Badge from '@mui/material/Badge';
import { editorLangEnStrs } from '../../../lang/editor_string_en';
import { editorLangGmStrs } from '../../../lang/editor_string_gm';
import LanguageIcon from '@mui/icons-material/Language';
import PricingModal from '../../../pages/Pricing/pricingModal';
let apiController = null;
const HOME_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_HOME_URL
export const HeaderSection = () => {
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const dispatch = useDispatch();
  const sequenceNumber = useSelector((store) => store.projectdata.sequenceNumber);
  const projectUUID = useSelector((store) => store.projectdata.projectUUID);
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const loginState = useSelector((store) => store.maindata.loginState);

  const [projectName, setProjectName] = useState('');
  const [searchParams] = useSearchParams();
  const from = searchParams.get("from");
  const theme = useTheme();
  const downSM = useMediaQuery(theme.breakpoints.down("sm"))

  const [showPricingPageModal, setShowPricingPageModal] = useState(false)

  const handleClosePricingModal = () => {
    setShowPricingPageModal(false)
  }

  const onBlue = () => {
    let tempJSON = JSON.parse(JSON.stringify(ProjectJSON));
    if (!tempJSON?.title && tempJSON?.title !== '') return;
    if (tempJSON?.title === projectName) return;
    dispatch(ProjectJSON_Store({ ...tempJSON, title: projectName }));
  }

  const onChange = (e) => {
    let value = e.target.value
    setProjectName(value)
  }

  useEffect(() => {
    let name = '';
    if (!!ProjectJSON?.title) name = ProjectJSON.title;
    setProjectName(name);
  }, [ProjectJSON]);

  const isKeyboardPlaying = (e) => {
    if(e.target.nodeName == "BODY"){
      if((e.key == 'z'||e.keyCode == 90) && e.metaKey == true && e.shiftKey == false){
        settingBackFunc(-1);
      }
      if((e.key == 'z'||e.keyCode == 90) && e.metaKey == true && e.shiftKey == true){
        settingBackFunc(1);
      }
    }
  }
  useEffect(() => {
    window.addEventListener('keydown', isKeyboardPlaying, false);
    return () => {
      window.removeEventListener("keydown", isKeyboardPlaying)
    }
  }, [sequenceNumber])
  
  const settingBackFunc = async (flag) => {
    if (sequenceNumber.c === undefined) return;

    let type = 'undo';
    let sequence = sequenceNumber.c + flag;
    
    if (sequence < 0 || sequenceNumber.m < sequence) return;
    if(apiController != null){
      apiController.abort();
    }
    dispatch(sequenceNum_Store({ ...sequenceNumber, c: sequence }));
    dispatch(sequenceStatus_Store(false));
    // apiController.signal;
    apiController = new AbortController();
    let response = await getProjectHistory(projectUUID, type, sequence, apiController)
    let history = response.data?.history;
    if (response.state !== "success" || !history) return;
    let sequenceNum = response.data.sequence;
    let newJSON = { ...history, sequence: sequenceNum, asset: ProjectJSON.asset };
    // dispatch(sequenceNum_Store({ ...sequenceNumber, c: sequenceNum }));
    dispatch(sequenceStatus_Store(false));
    apiController = null;
    dispatch(ProjectJSON_Store(newJSON));
   
  }

  const [userData, setUserData] = useState({})
  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') tempData = JSON.parse(item)
    setUserData(tempData)
  }, [loginState])

  return (
    <Box className='v-homeheader px-10'>
      <Box className='v-homeheaderleft'>
        <a href={HOME_FRONTEND_URL}>
          <img src={logoimg} alt='' />
        </a>

        <div className='w-full border-2 border-black rounded-xl px-5 mobile:border-none'>
          <input
            onBlur={onBlue}
            value={projectName}
            onChange={onChange}
            className='w-full text-12 mobile:text-15'
          />
        </div>
      </Box>

      <Box className='v-homeheaderright v-right gap-2 md:gap-10'>
        <ExportPage projectName={projectName} />
        {from === "feature-compress-video" && <Compress />}
        {from === "feature-convert-video" && <Convert />}

        {!loginState ? (
          <Box className='v-center pl-2 mr-2'>
            <Typography component='h2'>{languageStrs.save_your_project_later} —</Typography>
            <a href={`${HOME_FRONTEND_URL}/signin/?project_uuid=${projectUUID}`}>{languageStrs.sign_in}</a>
            {/* <Link component='a' to={`${HOME_FRONTEND_URL}/signin/?project_uuid=${projectUUID}`}>sign in</Link> */}

            <h2>or</h2><h5>·</h5>
            <a href={`${HOME_FRONTEND_URL}/signup/?project_uuid=${projectUUID}`}>{languageStrs.sign_up}</a>
            {/* <Link component='a' to={`/signup/?project_uuid=${projectUUID}`}>sign up</Link> */}
          </Box>
        ) : (
          <UserAvatar userData={userData} />
        )}
        <Box className='hidden sm:inline-flex gap-10 '>
        
          <Box className='v-center items-end gap-2 pointer redo_editBtn'>
            <Badge badgeContent={sequenceNumber.c?sequenceNumber.c:'0'} color="secondary">
              <ReplayIcon
                onClick={() => { settingBackFunc(-1); }}
                className='-rotate-45'
              />
            </Badge>
            <span className='hidden mobile:flex text-12'>
              {languageStrs.redo}
            </span>
          </Box>

          <Box className='v-center items-end gap-2 pointer undo_editBtn'>
            <Badge badgeContent={(sequenceNumber.m-sequenceNumber.c)?(sequenceNumber.m-sequenceNumber.c):'0'} color="secondary">
              <ReplayIcon
                onClick={() => { settingBackFunc(1); }}
                style={{ WebkitTransform: 'rotatey(180deg) rotate(-45deg)' }}
              />
            </Badge>
           
            <span className='hidden mobile:flex text-12'>
              {languageStrs.undo}
            </span>
          </Box>
        </Box>

        {downSM && !loginState 
          ? <></> 
          : <>
          <ProjectShare showPricingPageModal={showPricingPageModal} setShowPricingPageModal={setShowPricingPageModal} />
          <WorkspaceChange showPricingPageModal={showPricingPageModal} setShowPricingPageModal={setShowPricingPageModal} />
          </> }
        {/* <LangChangeSelector /> */}
      </Box>
   
      <PricingModal open={showPricingPageModal} onCloseModal={handleClosePricingModal} />
    </Box>
  )
}

const LangChangeSelector = () => {
  const dispatch = useDispatch();
  const [isLanglist, setIsLanglist] = useState(null);
  const languageCode = useSelector((store) => store.maindata.languageCode);
  const handleLangSelected = (langStr) => {
    dispatch(languageCode_Store(langStr));
    setIsLanglist(null);
  }

  useEffect(() => {
    switch(languageCode){
      case 'EN':
        dispatch(languageStrs_Store(editorLangEnStrs));
        break;
      case 'GM':
        dispatch(languageStrs_Store(editorLangGmStrs));
        break;
      default:
        dispatch(languageStrs_Store(editorLangEnStrs));
        break;
    }
  }, [languageCode])

  return (
    <>
      <Button
        id="demo-positioned-button"
        onClick={(e) => { setIsLanglist(e.currentTarget) }}
      >
        <LanguageIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={isLanglist}
        open={Boolean(isLanglist)}
        onClose={() => { setIsLanglist(null) }}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => handleLangSelected('EN')}>English</MenuItem>
        <MenuItem onClick={() => handleLangSelected('GM')}>German</MenuItem>
      </Menu>
    </>
  )
}

const ProjectShare = (props) => {
  const {showPricingPageModal, setShowPricingPageModal} = props;
  const languageStrs = useSelector((store) => store.editdata.languageStrs);
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const projectUUID = useSelector((store) => store.projectdata.projectUUID);
  const loginState = useSelector((store) => store.maindata.loginState);
  const [planMenuEl, setPlanMenuEl] = useState(null)
  const [shareMail, setShareMail] = useState('')

  const [userData, setUserData] = useState({})
  const [userPlan, setUserPlan] = useState(0);
  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') tempData = JSON.parse(item)
    setUserData(tempData)
    setUserPlan(tempData.subscription)
  }, [loginState])

  const changeMail = (e) => {
    const value = e.target.value
    setShareMail(value)
  }

  
  const shareProject = async () => {
    try {
      if (!ProjectJSON?.workspace_uuid) {
        throw new Error('Please login')
      }

      if (!shareMail) {
        throw new Error('Please enter email address')
      }

      const checkMail = EmailValidate(shareMail)
      const tempMail = checkMail?.email

      if (!checkMail.status) {
        throw new Error('Please enter validate email address')
      }

      const workspace_uuid = ProjectJSON.workspace_uuid
      const result = await ShareProject(workspace_uuid, tempMail, projectUUID)

      if (result.state === 'success') {
        tips('success', 'Project share successed to ' + tempMail)
      }
    } catch (err) {
      tips('warning', err.message)
    }
  }

  const onOpenColllaborateList = (e) => {
    if(loginState){
      if ((userPlan == 3 || userPlan == 6)) {
        setPlanMenuEl(e.currentTarget);
      } else {
        setShowPricingPageModal(true);
      }
    } else{
      setShowPricingPageModal(true);
    }
    
  }

  return (
    <>
      <div onClick={(e) => { onOpenColllaborateList(e) }}
        className='flex flex-row gap-3 items-center justify-center cursor-pointer select-none collabrate_hintCls'
      >
        <span className='hidden md:inline text-13'>{languageStrs.collaborate} </span>
        <img alt=''
          src={workSpaceImg}
          className='w-30 h-30'
        />
      </div>

      <Menu
        anchorEl={planMenuEl}
        open={Boolean(planMenuEl)}
        onClose={() => { setPlanMenuEl(null) }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
        PaperProps={{ sx: { mt: 1 } }}
        className="default-menu"
      >
        <div className="max-w-150 py-5 border-1 border-[#140505] bg-white rounded-lg outline-none">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-5 cursor-pointer text-center">
              <div className="px-10 text-15 font-semibold leading-none">
                {languageStrs.enter_email_share_project}
              </div>

              <Divider className="w-full" />

              <div className="text-12 font-semibold leading-none">
                <input type='text'
                  value={shareMail}
                  placeholder='email@email.com'
                  className='default-input w-full px-10 text-15'
                  onKeyDown={(e) => { e.stopPropagation() }}
                  onChange={changeMail}
                />
              </div>
            </div>

            <Divider className="w-full" />

            <div className='flex flex-row items-center justify-center px-5 select-none'>
              <span onClick={shareProject}
                className='cursor-pointer hover:opacity-80'
              >
                {languageStrs.share_project}
              </span>
            </div>
          </div>
        </div>
      </Menu>
    </>
  )
}

const WorkspaceChange = (props) => {
  const {showPricingPageModal, setShowPricingPageModal} = props;
  const languageStrs = useSelector((store) => store.editdata.languageStrs);
  const [menuEl, setMenuEl] = useState(null)
  const [globalState, { onLoading }] = useGlobalContext()
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const projectUUID = useSelector((store) => store.projectdata.projectUUID);

  const [firstLoad, setFirstLoad] = useState(false)
  const [workspaceType, setWorkspaceType] = useState(null)
  const [workSpacesList, setWorkSpacesList] = useState([])
  const [activeWorkspace, setActiveWorkspace] = useState(null)
  const loginState = useSelector((store) => store.maindata.loginState);

  useEffect(() => {
    getWorkSpaces()
  }, [])

  useEffect(() => {
    if (!firstLoad && ProjectJSON?.workspace_uuid) {
      let tempuuid = ProjectJSON?.workspace_uuid
      if (typeof tempuuid === 'string') {
        setWorkspaceType(tempuuid)
        setFirstLoad(true)
      }
    }
  }, [ProjectJSON])

  useEffect(() => {
    let checkworkspace = workSpacesList.find((workspace) => (
      workspace.uuid === workspaceType
    ))

    if (checkworkspace) {
      setActiveWorkspace(checkworkspace)
    } else if (activeWorkspace !== null) {
      setActiveWorkspace(null)
    }
  }, [workspaceType, workSpacesList])

  const getWorkSpaces = async () => {
    try {
      const result = await getWorkspacesList()

      if (result.state === 'success') {
        if (typeof result.data === 'object') {
          setWorkSpacesList(result.data)
        }
      }

      return true
    } catch (err) {
      console.log(err.message)
      return false
    }
  }

  const workspaceUUIDchange = async (uuid, flag) => {
    try {
      const checkUuid = workSpacesList.find((workspace) => (
        workspace.uuid === uuid
      ))

      if (checkUuid && uuid !== workspaceType) {
        onLoading(true)
        setMenuEl(false)

        let result = await ChangeProjectWorkspace(projectUUID, workspaceType, uuid)
        if (result.state === 'success') {
          onLoading(false)
          setWorkspaceType(uuid)

          if (!flag) {
            tips("success", 'workspace change successed')
          }
        } else {
          throw new Error('workspace change failure!')
        }
      }
    } catch (err) {
      onLoading(false)

      if (!flag) {
        tips("warning", err.message)
      }
    }
  }

  const createNewWorkspace = async () => {
    try {
      onLoading(true)
      setMenuEl(false)

      let result = await createWorkspace()
      if (result.state === 'success') {
        await getWorkSpaces()
        tips("success", result.data)
      } else {
        throw new Error('Create new workspace failure!')
      }

      onLoading(false)
    } catch (err) {
      tips("warning", err.message)
      onLoading(false)
    }
  }

  const onWorkSpaceList = (e) => {
    
    if(loginState){
      setMenuEl(e.currentTarget)
    }else{
      setShowPricingPageModal(true);
    }
  }

  const changeWorkSpaceName = (workspace) => {
    setMenuEl(null) 
    Swal.fire({
      title: "Change your workspace title",
      input: "text",
      showCancelButton: true,
      confirmButtonText: "Rename",
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        if (!value) {
          return "You need to write something!";
        }
      },
      preConfirm: async (renamestr) => {
        // try {
           const response = await renameWorkSpace(workspace.uuid, renamestr);
          // if (!response.ok) {
          //   return Swal.showValidationMessage(`
          //     ${JSON.stringify(await response.json())}
          //   `);
          // }
          // return response.json();
        // } catch (error) {
        //   Swal.showValidationMessage(`
        //     Request failed: ${error}
        //   `);
        // }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then((result) => {
      if (result.isConfirmed) {
        getWorkSpaces();
        Swal.fire({
          title: `Workspace title changed`,
          imageUrl: result.value.avatar_url
        });
      }
    });
    // alert('Change WorkSpace');
  }

  return (
    <div className=''>
      <div onClick={(e) => { onWorkSpaceList(e) }}
        className='flex flex-row items-center justify-center cursor-pointer select-none changeWorkspace_hintCls'
      >
        <span className='overflow-ellipsis max-w-150 hidden md:inline text-13'>
          {activeWorkspace?.title || ''}
        </span>
        <img alt='' src={workSpaceMenuImg} className='w-30 h-30' />
        
      </div>

      <Menu anchorEl={menuEl}
        open={Boolean(menuEl)}
        onClose={() => { setMenuEl(null) }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
        transformOrigin={{ vertical: 'top', horizontal: 'right', }}
        PaperProps={{ sx: { mt: 1 } }}
        className="default-menu"
      >
        <div className='flex flex-col bg-white py-5'>
          <MenuItem onClick={createNewWorkspace} sx={{ backgroundColor: '#b3b3b3 !important' }}
            className="toolbar-SortBy-select-item border border-solid border-black px-5"
          >
            <span>+ {languageStrs.create_new_workspace}</span>
          </MenuItem>

          {workSpacesList.map((workspace, key) => (
            <MenuItem key={key}
               className={`toolbar-SortBy-select-item ${workspace.uuid === workspaceType && 'Mui-selected'}`}
            >
              <div className='d-flex'
               onClick={() => { workspaceUUIDchange(workspace.uuid) }}
              >
                <PanoramaFishEyeOutlinedIcon className="radio-outline" />
                <RadioButtonCheckedOutlinedIcon className="radio-checked" />
                <span>{`${workspace?.title} (${workspace?.uuid})`}</span>
              </div>
              <CreateIcon onClick={() => {changeWorkSpaceName(workspace)}}/>
            </MenuItem>
          ))}
        </div>
      </Menu>
    </div>
  )
}