import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Grid, Stack, Button, FormControl, FormHelperText, Divider, TextField, Modal, IconButton } from '@mui/material';

export const SpeakerModal = ({ open, onClose, speakers, setSpeakers, updateSubtitlesSpeakers }) => {
  const [editedSpeakers, setEditedSpeakers] = useState(speakers);

  useEffect(() => {
    if(speakers.length > 0){
      let tmpEditSpeaker = [];
      speakers.map(spItem => {
        tmpEditSpeaker.push({
          oldOne: spItem,
          value: spItem
        })
      })
      setEditedSpeakers(tmpEditSpeaker);
    }
    
  }, [speakers]);

  const handleEditSpeaker = (index, value) => {
    const updatedSpeakers = [...editedSpeakers];
    updatedSpeakers[index].value = value;
    setEditedSpeakers(updatedSpeakers);
  };

  const handleSave = () => {
    setSpeakers(editedSpeakers);
    updateSubtitlesSpeakers(editedSpeakers); // Call function to update subtitles
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box className='modal-box' sx={{ padding: 4, bgcolor: 'white', maxWidth: 400, margin: 'auto', mt: '20vh' }}>
        <Typography variant='h6' mb={2}>Edit Speakers</Typography>
        {editedSpeakers.map((speaker, index) => (
          <Box key={index} mb={2} display='flex' alignItems='center'>
            <TextField
              value={speaker.value}
              onChange={(e) => handleEditSpeaker(index, e.target.value)}
              fullWidth
              className='speaker-input'
              variant='outlined'
              size='small'
            />
          </Box>
        ))}
        <Button variant='contained' color='primary' onClick={handleSave}>Save</Button>
      </Box>
    </Modal>
  );
};
