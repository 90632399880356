import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase, Stack} from "@mui/material"
import VideocamIcon from '@mui/icons-material/Videocam';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';

export const AdobeStockModal = (props) => {
    const {stockData} = props;
    const renderKeywords = (keywords) => {
      let strKeywords = [];
      
      keywords?.map((keyItem) => {
        strKeywords.push(keyItem.name);
      });
      return strKeywords.toString();
    }
    const renderDuration = (duration) => {
      let subtitle = "";
      const minute = Math.round(duration / 60000);
      const second = Math.round((duration - minute * 60000) / 1000);
      subtitle += `   ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}  `;
      return subtitle;
    }
    return (
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography id="spring-modal-title" variant="h6" component="h2">
              Adobe Stock Video
            </Typography>
            <Typography variant="overline" component={'p'}>
              title: <b>{stockData?.title}</b>
            </Typography>
            <Typography variant="overline" component={'p'}>
              keywords: <b>{renderKeywords(stockData?.keywords)}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            
            <Typography variant="overline" component={'p'}>
              Width & Height: <b>{stockData?.width} X {stockData?.height}</b>
            </Typography>
            <Typography variant="overline" component={'p'}>
              Duration: <b>{stockData?.duration}</b>
            </Typography>
            <Typography variant="overline" component={'p'}>
              Framerate: <b>{stockData?.framerate}</b>
            </Typography>
            <Typography variant="overline" component={'p'}>
              Description: <b>{stockData?.description}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} md={8}>
            <div style={{position: 'relative'}}>
              <video muted src={stockData?.video_preview_url}>
              </video>
              <div className='stockMediaBar'>
                <Stack direction='row' justifyContent='start' alignItems='center'>
                  <VideocamIcon sx={{ color: 'white' }}/>
                  <Typography color={'white'} variant='span'>{renderDuration(stockData?.duration)}</Typography>
                </Stack>
              </div>
            </div>
          </Grid>
        </Grid>
      </Box>
    )
}