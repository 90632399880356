import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Box, Menu, Typography, LinearProgress, Grid, FormControl, InputLabel, Select, MenuItem  } from '@mui/material';
import ReactLoading from 'react-loading'

import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined"

import { convertVideo, getWebHookData } from "../../../axios/ApiProvider"

export const Convert = () => {
  const compressBtnRef = useRef(null)
  const [optionEl, setOptionEL] = useState(null);
  const filedata = useSelector((store) => store.projectdata.uploadingFiles[Object.keys(store.projectdata.uploadingFiles)[0]])
  const projectUUID = useSelector((store) => store.projectdata.projectUUID);
	const [openOptionModal, setOpenOptionModal] = useState(false)
	const [openDownloadModal, setOpenDownloadModal] = useState(false)
	const [progressing, setProgressing] = useState(false)
	const [isFinishProgressing, setIsFinishProgressing] = useState(false)
  const [webhookInfo, setWebhookInfo] = useState(null);
	const [convertOptions, setConvertOptions] = useState({
		"Video Converter Format" : "No Change",
		"Select Aspect Ratio" : "No Change",
		"Resolution" : "No Change",
		"Codec" : "No Change"
	})
  const history = useNavigate();

  const closeOptionMenu = () => { setOptionEL(null); };
  const openOptionMenu = (e) => { 
    setOpenOptionModal(true)
    setOptionEL(e.currentTarget);
  };

	useEffect(() => {
		if(filedata && !openOptionModal) {
      if(compressBtnRef.current) {
        compressBtnRef.current.click();
      }
		}
	}, [filedata])

	const getOriginalFilename = (url) => {
    const filelink = url.split("?")[0];
    const filename = filelink.split("/").reverse()[0];

    return filename
  }

	const handleOptionChange = e => {
		setConvertOptions({
			...convertOptions,
			[e.target.name]: e.target.value
		})
	}

	const handleConvert = async () => {
		setProgressing(true)
		const response = await convertVideo(projectUUID, convertOptions);
    if (response.state !== 'success') {
			setProgressing(false)
			return;
		}

    const exportData = response.data;
    const ifState = exportData.uuid !== projectUUID
    if (ifState || !exportData.asset_uuid || !exportData.webhook_url) return history('/');
		setOpenDownloadModal(true);
		const webhookUrl = exportData.webhook_url
		if (!isFinishProgressing && !!webhookUrl) {
			const webhookInterval = () => {
				setTimeout(async () => {
					const response = await getWebHookData(webhookUrl);
					if(response.state !== 'success') return
						const webHookData = response.data;
						setWebhookInfo(webHookData);
					if (!!webHookData && !!webHookData.signed_url){
						setProgressing(false)
						setIsFinishProgressing(true)
					} else {
						webhookInterval();
					}
				}, 5000)
			}
			webhookInterval();
		}
	}

	const downloadFunc = () => {
    let videoUrl = webhookInfo?.signed_url;
    const filename = getOriginalFilename(webhookInfo?.signed_url)
		
    if (!isFinishProgressing || !videoUrl) return;
    fetch(videoUrl).then(res => res.blob()).then(file => {
      let tempUrl = URL.createObjectURL(file);
      const link = document.createElement("a");

      link.href = tempUrl;
      link.download = filename || 'video.mp4';

      document.body.appendChild(link);
      link.click();
      URL.revokeObjectURL(tempUrl);
      link.remove();
    }).catch(() => { alert("Failed to download file!"); });
  }

  return (
    <>
      <Box ref={compressBtnRef} className='v-headerExport v-spacebetween v-font-2-4 v-font-bold pl-4' onClick={openOptionMenu}>
        <span className="v-headerExportText">Convert</span> <ChangeCircleIcon className='v-headerExportIcon v-font-4 md:mr-[20px] md:ml-[15px]' />
      </Box>

      <Menu
        anchorEl={optionEl}
        open={Boolean(optionEl)}
        onClose={closeOptionMenu}
        MenuListProps={{ 'aria-labelledby': 'basic-button' }}
        className='export-menu-wrapper'
				anchorOrigin={{
          vertical: 'bottom',
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: 'right',
        }}
      >
        {openOptionModal && !openDownloadModal && 
        <Box className='export-menu-container gap-10'>
        <CloseOutlinedIcon
          onClick={closeOptionMenu}
          className='menu-close-btn '
        />
        <Typography variant="h5" textAlign="center" 
						sx={{
							marginBottom: "20px"
						}}
					>Convert my video to a...</Typography>
					<Grid container spacing={1}>
						<Grid item xs={12}>
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
							<InputLabel id="demo-select-small-label">Video Converter Format</InputLabel>
							<Select
								name="Video Converter Format"
								value={convertOptions['Video Converter Format']}
								label="Age"
								onChange={handleOptionChange}
								color="primary"
							>
								<MenuItem value="No Change">
								No Change
								</MenuItem>
								<MenuItem value="AVI">AVI</MenuItem>
								<MenuItem value="FLV">FLV</MenuItem>
								<MenuItem value="GIF">GIF</MenuItem>
								<MenuItem value="MKV">MKV</MenuItem>
								<MenuItem value="MOV">MOV</MenuItem>
								<MenuItem value="MP4">MP4</MenuItem>
								<MenuItem value="MPEG">MPEG</MenuItem>
								<MenuItem value="SWF">SWF</MenuItem>
								<MenuItem value="TS">TS</MenuItem>
								<MenuItem value="VOB">VOB</MenuItem>
								<MenuItem value="WEBM">WEBM</MenuItem>
								<MenuItem value="WMV">WMV</MenuItem>
							</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
								<InputLabel id="demo-select-small-label">Select Aspect Ratio</InputLabel>
								<Select
									name="Select Aspect Ratio"
									value={convertOptions['Select Aspect Ratio']}
									label="Age"
									onChange={handleOptionChange}
								>
									<MenuItem value="No Change">
										No Change
									</MenuItem>
									<MenuItem value="Portable">Portable</MenuItem>
									<MenuItem value="Landscape (16:9)">Landscape (16:9)</MenuItem>
									<MenuItem value="WideScreen(17:9)">WideScreen(17:9)</MenuItem>
									<MenuItem value="Square">Square</MenuItem>
									<MenuItem value="Reel (4:5)">Reel (4:5)</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
								<InputLabel id="demo-select-small-label">Resolution</InputLabel>
								<Select
									name="Resolution"
									value={convertOptions['Resolution']}
									label="Age"
									onChange={handleOptionChange}
								>
									<MenuItem value="No Change">
										No Change
									</MenuItem>
									<MenuItem value="360p">360p</MenuItem>
									<MenuItem value="720p">720p</MenuItem>
									<MenuItem value="1080p">1080p</MenuItem>
									<MenuItem value="4K">4K</MenuItem>
									<MenuItem value="8K">8K</MenuItem>
								</Select>
							</FormControl>
						</Grid>
						<Grid item xs={12}>
							<FormControl sx={{ m: 1, minWidth: 120 }} size="small" fullWidth>
								<InputLabel id="demo-select-small-label">Codec</InputLabel>
								<Select
									name="Codec"
									value={convertOptions['Codec']}
									label="Age"
									onChange={handleOptionChange}
								>
									<MenuItem value="No Change">
										No Change
									</MenuItem>
									<MenuItem value="H264">H264</MenuItem>
									<MenuItem value="HEVC">HEVC</MenuItem>
									<MenuItem value="H256">H256</MenuItem>
									<MenuItem value="WebM">WebM</MenuItem>
								</Select>
							</FormControl>
						</Grid>
					</Grid>
        <Box display="flex" justifyContent="center">
          <button 
						disabled={!filedata || !filedata?.state || progressing}
						className={`convert-btn ${filedata && filedata.state ? "" : "btn-disabled"}`} 
						onClick={handleConvert}
					>
						{filedata && filedata.state ? "Convert" : "Preparing..."}
						{progressing && <ReactLoading type={'spinningBubbles'} color='#FFFFFF' height={14} width={14} />}
					</button>
        </Box>
        </Box>
        }
        {
          openOptionModal && openDownloadModal && 
          <Box className='export-menu-container gap-10'>
          <CloseOutlinedIcon
            onClick={closeOptionMenu}
            className='menu-close-btn'
          />
					<Box className="video-section mt-6">
						<Box className="video-container">
							{!!isFinishProgressing ? (
									<video controls >
										<source src={!!webhookInfo && !!webhookInfo.signed_url ? webhookInfo.signed_url : ''} type="video/mp4" />
									</video>
								) : (
									<Box className="upload-progressing">
										<Box className="progress-title">
											<Typography component='h2'>{!!webhookInfo ? webhookInfo.progress : 0}%</Typography>
										</Box>

										<Box className="progress-bar">
											<LinearProgress variant="determinate" value={!!webhookInfo ? webhookInfo.progress : 0} />
										</Box>
									</Box>
								)}
							</Box>
						</Box>
						<Box component='button' className='download-button' marginTop="10px" onClick={downloadFunc}>
              <FileDownloadOutlinedIcon />
              <Typography component='h2'>Download</Typography>
            </Box>
          </Box>
        }
      </Menu>
    </>
  )
}