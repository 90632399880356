import { DISPLAYSIZECHANGE, LOGINSTATE, LOADING, LANGUAGECODE, ISANALYZING, STAGETEXT } from "../constants/mainConst";

export const mainStore = (state = {}, action) => {
	switch (action.type) {
		case DISPLAYSIZECHANGE: {
			return {
				...state,
				DisplaySizeChange: state.DisplaySizeChange + action.payload
			};
		}

		case LOGINSTATE: {
			return {
				...state,
				loginState: action.payload
			};
		}

		case LOADING: {
			return {
				...state,
				loading: action.payload
			}
		}
		
		case LANGUAGECODE: {
			return {
				...state,
				languageCode: action.payload
			}
		}

		case ISANALYZING: {
			return {
				...state,
				isAnalyzing: action.payload
			}
		}

		case STAGETEXT: {
			return {
				...state,
				stageText: action.payload
			}
		}

		default: {
			return { ...state };
		}
	}
};
