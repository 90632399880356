export const fontFamilyOptions = [
  { label: 'Aboreto' },
  { label: 'AkayaKanadaka' },
  { label: 'AlfaSlabOne' },
  { label: 'ArchitectsDaughter' },
  { label: 'Audiowide' },
  { label: 'BhuTukaExpandedOne' },
  { label: 'BioRhyme' },
  { label: 'BrunoAceSC' },
  { label: 'BungeeInline' },
  { label: 'BungeeShade' },
  { label: 'ChakraPetch' },
  { label: 'Changa' },
  { label: 'CherryBombOne' },
  { label: 'Chokokutai' },
  { label: 'Diplomata' },
  { label: 'Eater' },
  { label: 'EmblemaOne' },
  { label: 'Estonia' },
  { label: 'FascinateInline' },
  { label: 'FasterOne' },
  { label: 'FreckleFace' },
  { label: 'GideonRoman' },
  { label: 'Gidugu' },
  { label: 'Goldman' },
  { label: 'GrenzeGotisch' },
  { label: 'Hubballi' },
  { label: 'Hurricane' },
  { label: 'Joan' },
  { label: 'KronaOne' },
  { label: 'Limelight' },
  { label: 'Mansalva' },
  { label: 'MarcellusSC' },
  { label: 'Marhey' },
  { label: 'Megrim' },
  { label: 'Mirza' },
  { label: 'Monofett' },
  { label: 'Monoton' },
  { label: 'NewRocker' },
  { label: 'OoohBaby' },
  { label: 'Pacifico' },
  { label: 'Padyakke' },
  { label: 'Peralta' },
  { label: 'Plaster' },
  { label: 'PoiretOne' },
  { label: 'RammettoOne' },
  { label: 'RhodiumLibre' },
  { label: 'RibeyeMarrow' },
  { label: 'Righteous' },
  { label: 'RubikRegular' },
  { label: 'RubikGemstones' },
  { label: 'RubikGlitch' },
  { label: 'RubikMaze' },
  { label: 'RubikMoonrocks' },
  { label: 'RubikVinyl' },
  { label: 'RubikWetPaint' },
  { label: 'RussoOne' },
  { label: 'SairaStencilOne' },
  { label: 'SeymourOne' },
  { label: 'Shojumaru' },
  { label: 'SortsMillGoudy' },
  { label: 'SuezOne' },
  { label: 'Thasadith' },
  { label: 'TurretRoad' },
  { label: 'Underdog' },
  { label: 'WaitingfortheSunrise' },
];


export const fontSizeOptions = [
  { label: 10 },
  { label: 15 },
  { label: 20 },
  { label: 25 },
  { label: 30 },
  { label: 35 },
  { label: 40 },
  { label: 45 },
  { label: 50 },
  { label: 55 },
  { label: 60 },
  { label: 65 },
  { label: 70 },
  { label: 75 },
  { label: 80 },
  { label: 85 },
  { label: 90 },
  { label: 95 },
  { label: 100 },
];