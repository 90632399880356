export const vsShader = `
attribute vec4 aVertexPosition;
attribute vec2 aTextureCoord;

uniform mat4 uModelViewMatrix;
uniform mat4 uProjectionMatrix;
// uniform mat4 aFilterPreset_m;
// uniform vec4 aFilterPreset_v;

varying highp vec2 vTextureCoord;
//varying highp mat4 vFilterPreset_m;
//varying highp vec4 vFilterPreset_v;

void main(void) {
  gl_Position = uProjectionMatrix * uModelViewMatrix * aVertexPosition;
  
  vTextureCoord = aTextureCoord;
//  vFilterPreset_m = aFilterPreset_m;
 // vFilterPreset_v = aFilterPreset_v;
}
`;

export const vsGrShader = `attribute vec2 a_position;
attribute vec2 a_texCoord;
uniform vec2 u_resolution; 
varying vec2 v_texCoord;
  
void main() {  
  gl_Position =  vec4(( (a_position / u_resolution * 2.0) - 1.0)  * vec2(1, -1), 0, 1);  
  v_texCoord = a_texCoord;  
}`;