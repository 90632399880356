import React, {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase} from "@mui/material"
import {ArrowBack, ArrowForward} from '@mui/icons-material';
import { ClickAwayListener } from '@mui/base';
import VideocamIcon from '@mui/icons-material/Videocam';

import SearchIcon from '@mui/icons-material/Search';
import { SectionHeader } from '../common/section.header';
import { 
  getStockSearchKeyword, 
  getStockData, 
  createStock, 
  updateStock, 
  UploadAssets, 
  getPopularPexelsData, 
  getPexelsData, 
  getPopularPexelsImages,
  getPexelsImageSearch,
  getPexelsImageData,
  getPixaBayStockSearch, 
  getPopularPixaBayStockData, 
  getPopularPixabayImageStocks,
  getPixabayImageSearchStocks,
  getPixabayImageStock,
  getFreeSoundSearch,
  getFreeSoundData,
  getProjectJSON} from '../../../../axios/ApiProvider';
import { GetProjectUUID } from '../../../../assets/script/commonFunction';
import { getNewStockInformation, getThumbnailsInfo, getImageInformation, getNewAudioInformation, getNewAudioStockInformation } from '../../webGL/commonFunc';
import { VideoElements_Store, audioElements_Store, dataLoaded_Store, imageElements_Store } from '../../../../store/actions/webgldata';
import { changedJSON_Store, ProjectJSON_Store, thumbNails_Store } from '../../../../store/actions/projectdata';
import { loading_Store } from '../../../../store/actions/mainAction';
import { getMaxZIndex, changeZeroSecond } from '../../commonFunction';
import { localeForPexles, mimeTypes } from '../tooltap.config';
import { v4 as uuidv4 } from 'uuid';
import {webGlSizeOptions } from "../../../video-edit/videoEdit.config"
import pexelsVideoLogo from "../../../../assets/image/Logos/pexelVideo.png"
import pexelsImageLogo from "../../../../assets/image/Logos/pexelImage.png";
import pixabayVideoLogo from "../../../../assets/image/Logos/pixabayVideo.png";
import pixabayImageLogo from "../../../../assets/image/Logos/pixabayImage.png";
import freeSoundLogo from "../../../../assets/image/Logos/freeSound.png";
import stockLogo from "../../../../assets/image/Logos/stock_logo.png"
import { duration } from 'moment';

import { StockModal } from './stockModal';

export const AdobeStock = () => {
    const languageStrs = useSelector((store) => store.editdata.languageStrs)
    const [searchKeyword, setSearchKeyword] = useState("");
    const [openPopper, setOpenPopper] = useState(false)
    const anchorEl = useRef(null)
    const [keywords, setKeywords] = useState([]);
    const [stockData, setStockData] = useState([]);
    const [isLoadingStock, setIsLoadingStock] = useState(false)
    const projectUUID = GetProjectUUID();
    const videoElements = useSelector(store => store.webgldata.videoElements);
    const Time_Count = useSelector((store) => store.videodata.Time_Count);
    const projectJSON = useSelector(store => store.projectdata.projectJSON);
    const [isHoverId, setIsHoverId] = useState(null);

    const [selectedStock, setSelectedStock] = useState(null);
    const [openDetaiModal, setOpenDetailModal] = useState(false);

    const dispatch = useDispatch()
    const handleOpenPopover = (event) => {
      setOpenPopper(true);
    };
  
    const handleClosePopover = () => {
      setOpenPopper(false);
    };
  
    useEffect(() => {
      (async function () {
        setIsLoadingStock(true)
        const stock = await getStockData("");
        let stockStr = "";
        stock.data.map(s => stockStr += s);
        const stockJson = JSON.parse(stockStr);
        setStockData(stockJson)
        setIsLoadingStock(false)
      })()
      
    }, [])
    
  
    useEffect(() => {
      (async function() {
        if(searchKeyword) {
          const stockData = await getStockSearchKeyword(searchKeyword);
          let respStr = "";
          stockData.map(st => respStr += st);
          const firstBracket = respStr.indexOf("(");
          respStr = respStr.slice(firstBracket + 1).slice(0, -1);
          const respJson = JSON.parse(respStr);
          setKeywords(respJson.hits)
        }
      })()
    }, [searchKeyword])
    
  
    const handleChangeInput=  e => {
      setSearchKeyword(e.target.value);
      if(e.target.value === "") {
        handleClosePopover();
      } else {
        handleOpenPopover(e)
      }
    }
  
    const handleGetStockData = async (stockName) => {
      setIsLoadingStock(true)
      const stock = await getStockData(stockName);
      let stockStr = "";
      stock.data.map(s => stockStr += s);
      const stockJson = JSON.parse(stockStr);
      setStockData(stockJson)
      setIsLoadingStock(false)
    }
  
    const handleKeyUp = e => {
      if(e.keyCode === 13) {
        handleGetStockData(searchKeyword);
        handleClosePopover();
      }
    }
  
    const handleClickKeyword =  (keyword) => {
      setSearchKeyword(keyword);
      handleGetStockData(keyword)
      handleClosePopover();
    }
  
    const handleClickStock = (stockItem) => {
      setSelectedStock(stockItem);
      setOpenDetailModal(true);
    }
  
    const addStockData = async (stockdata) => {
      const resp = await createStock();
      const stockUUID = resp.data.stock_uuid;
      if(stockUUID) {
        dispatch(loading_Store(true));
        const updatedStockResp = await updateStock(stockUUID, stockdata);
        
        await UploadAssets(projectUUID, updatedStockResp.data.asset_uuid);
        
        let startTime = Time_Count.getTime()
  
        let maxZIndex = getMaxZIndex(projectJSON);
        let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
  
        const newProjectJSON = {...projectJSON};
        const vKeys = projectJSON.videos? Object.keys(projectJSON.videos) : [];
        let addingGroupId = stockUUID;
        if(vKeys.length > 0){
          addingGroupId = projectJSON.videos[vKeys[0]].groups;
          vKeys.map(vKeyItem => {
            if(startTime < projectJSON.videos[vKeyItem].timeline_end){
              console.log('timelineEnd', projectJSON.videos[vKeyItem].timeline_end);
              startTime = projectJSON.videos[vKeyItem].timeline_end;
            }
            console.log('looping time');
          })
        }
        
        await getNewStockInformation(projectUUID, newProjectJSON, updatedStockResp.data.asset_uuid, stockUUID, updatedStockResp.data.stock_manifest.video_preview_url, stockdata, Object.keys(videoElements).length, maxZIndex,
        (videoPlayer, videoInfo, newAssets) => {
          dispatch(VideoElements_Store({ ...videoElements, [stockUUID]: videoPlayer }));
          changedJSON.flag = true;
          changedJSON.videos = {
              [stockUUID]: {
                ...videoInfo,
                groups: addingGroupId,
                timeline_start: startTime,
                timeline_end: videoInfo.duration + startTime,
                asset_uuid: updatedStockResp.data.asset_uuid,
                asset_filename:  updatedStockResp.data.asset_filename,
                asset_extension : updatedStockResp.data.asset_filename.split(".").reverse()[0]
              }
          }
  
          dispatch(changedJSON_Store(changedJSON))
          dispatch(ProjectJSON_Store({
            ...newProjectJSON,
            asset: {
              ...newProjectJSON.asset,
              ...newAssets
            }
          }))
          
          changeZeroSecond(videoInfo, dispatch)
          dispatch(loading_Store(false));
        }); 
      }
    }
  
    return (
      <>
        <Grid container sx={{p: 1}}>
          <Grid item xs={12}>
            <FormControl fullWidth ref={anchorEl} >
              <InputLabel htmlFor="outlined-adornment-amount">{languageStrs.search}</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                label="Search"
                onChange={handleChangeInput}
                onKeyUp={handleKeyUp}
                value={searchKeyword}
              />
            </FormControl>
          </Grid>
          <ClickAwayListener onClickAway={handleClosePopover}>
            <Popper
              open={openPopper}
              anchorEl={anchorEl.current}
              sx={{
                width: `${anchorEl?.current?.clientWidth}px`,
                border: "solid 1px #e5e7eb",
                zIndex: 1000,
                backgroundColor: "#FFF"
              }}
              placement="bottom-start"
            >
              {keywords.map(w => <MenuItem key={w.objectID} onClick={() => {
                handleClickKeyword(w.term)
              }}>{w.term}</MenuItem>)}
            </Popper>
          </ClickAwayListener>
        </Grid>
        <Grid container sx={{overflow: "auto"}}>
          <Grid item xs={12} sx={{position: "relative", minHeight: "300px"}}>
            <ImageList variant="masonry" cols={2} gap={8} >
              {stockData?.files?.map(file => {
                let subtitle = "";
                const minute = Math.round(file.duration / 60000);
                const second = Math.round((file.duration - (minute * 60000)) / 1000);
                subtitle += `   ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}  `;
                if(file.width > 3840) {
                  subtitle += "4K "
                }
  
                if(file.width > 1920) {
                  subtitle += "HD"
                }
  
                return (
                <ImageListItem key={file.id} sx={{cursor: "pointer"}} 
                onClick={() => {
                  handleClickStock(file)
                }}
                onMouseOver={() => {if(isHoverId != file.id){ setIsHoverId(file.id); } console.log('onMuseOver', file)}}
                onMouseLeave={() => {setIsHoverId(null); console.log('onMuseLeave')}}
                >
                  <img
                    src={`${file.thumbnail_url}`}
                    alt={file.title}
                    loading="lazy"
                    style={{
                      minWidth: "184px",
                      minHeight: "103px"
                    }}
                  />
                  {isHoverId == file.id && 
                    <video className='stockPreview-video' src={file.video_preview_url} loop autoPlay style={{
                        top: '0px',
                        position: "absolute",
                        minWidth: "184px",
                        minHeight: "103px",
                        flexGrow: "unset"
                      }}></video>
                    }
                  <ImageListItemBar
                    subtitle={<><VideocamIcon/>{subtitle}</>}
                  />
                </ImageListItem>)
                }
              )}
            </ImageList>
            {isLoadingStock && <Box sx={{position: "absolute", top: 0,  background: "rgba(0,0,0,0)", display: "flex", zIndex: 100000, flexDirection: "column", justifyContent: "center", height: "300px", width: "100%"}}>
              <Box sx={{ display: 'flex', position: "absolute", justifyContent: "center", width: "100%" }}>
                <CircularProgress />
              </Box>
            </Box>}
          </Grid>
        </Grid>
        <StockModal 
            open={openDetaiModal} 
            setOpen={setOpenDetailModal} 
            stockType='stock'
            stockData={selectedStock}
            addStockData={addStockData}
            />
      </>
    )
  }