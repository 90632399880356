import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PersonIcon from '@mui/icons-material/Person';
import './collaborations.scss';

export const Collaborations = () => {
    const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
    const [collaboratList, setCollaboratList] = useState([]);

    const CalcuDifferentDay = (day) => {
        let day1 = new Date();
        let day2 = new Date(day);
        let dif_day = day1.getTime() - day2.getTime();
        let TotalYears = Math.floor(dif_day / (1000 * 3600 * 24 * 30 * 12));
        let TotalMonths = Math.floor(dif_day / (1000 * 3600 * 24 * 30));
        let TotalDays = Math.floor(dif_day / (1000 * 3600 * 24));
        let Totalhours = Math.floor(dif_day / (1000 * 3600));
        let Totalmins = Math.floor(dif_day / (1000 * 60));
        let Totalsecs = Math.floor(dif_day / (1000));
        return TotalYears > 0? TotalYears + ' years ago':TotalMonths > 0? TotalMonths + ' months ago' : TotalDays > 0 ? TotalDays+' days ago' : Totalhours > 0 ? Totalhours+' hours ago' : Totalmins > 0 ? Totalmins + ' mins ago' : Totalsecs + ' sec ago' ;
        // return TotalDays ? TotalDays : '0';
      }

    useEffect(() => {
        let collaboratArr = [];
        if(ProjectJSON.collaborations){
            const collaboratKeys = Object.keys(ProjectJSON.collaborations);
            if(collaboratKeys){
                collaboratKeys.map(keyItem => {
                    ProjectJSON.collaborations[keyItem].coordinate_x = window.outerWidth * ProjectJSON.collaborations[keyItem].coordinate_x;
                    ProjectJSON.collaborations[keyItem].coordinate_y = window.outerHeight * ProjectJSON.collaborations[keyItem].coordinate_y;
                    collaboratArr.push(ProjectJSON.collaborations[keyItem]);
                })
            }
        }
       
       setCollaboratList(collaboratArr);
    }, [ProjectJSON])

    const handleCollaboratorClick = (index) => {
        const updatedList = collaboratList.filter((_, i) => i !== index);
        setCollaboratList(updatedList);
    }

    return (
        <>
            {collaboratList.map( (clItem, key) =>
                <div key={key} className="collaborativeItem" style={{left: clItem.coordinate_x+'px', top: clItem.coordinate_y+'px'}}
                 onClick={() => handleCollaboratorClick(key)}>
                    <div className="collaborativeName">
                        <div className="collaborativeIcon">
                            <PersonIcon />
                        </div>
                        <div className="collaborativeSpan"><span>{clItem.user}</span></div>
                        
                    </div>
                    <div className="collaborativedate">
                        <span>{CalcuDifferentDay(clItem.changed_at)}</span>
                    </div>
                   
                </div>
            )}
        </>
    )
}