export function calculateFrameCount(durationMs, fps) {
    const durationSeconds = durationMs / 1000; // Convert milliseconds to seconds
    const frames = durationSeconds * fps; // Calculate frames
    return Math.ceil(frames); // Round to the nearest whole frame
}

export function calculatePproTicks(timeInFrames, fps) {
    if (!fps || fps <= 0) {
        throw new Error('Invalid frame rate');
    }
    const ticksPerSecond = 254016000000; // Premiere's tick value for 1 second
    const ticksPerFrame = ticksPerSecond / fps; // Ensure fps is valid
    const ticks = timeInFrames * ticksPerFrame;
    return Math.ceil(ticks);
}

export function calculatePproTicksFromMs(timestampMs, fps) {
    if (!fps || fps <= 0) {
        throw new Error('Invalid frame rate');
    }
    const ticksPerSecond = 254016000000; // Premiere's tick value for 1 second

    // Convert the timestamp from milliseconds to ticks
    const seconds = timestampMs / 1000.0;
    const ticks = seconds * ticksPerSecond;

    return Math.ceil(ticks); // Round to the nearest whole tick
}

export function calculateTimecodeString(frame, fps) {
    const totalSeconds = frame / fps; // Convert frames to seconds
    const hours = Math.floor(totalSeconds / 3600); // Calculate hours
    const minutes = Math.floor((totalSeconds % 3600) / 60); // Calculate minutes
    const seconds = Math.floor(totalSeconds % 60); // Calculate seconds
    const frames = Math.floor((totalSeconds - Math.floor(totalSeconds)) * fps); // Calculate frames

    // Format timecode as HH:MM:SS:FF (frames)
    const formattedTimecode = 
        String(hours).padStart(2, '0') + ':' + 
        String(minutes).padStart(2, '0') + ':' + 
        String(seconds).padStart(2, '0') + ':' + 
        String(frames).padStart(2, '0');

    return formattedTimecode;
}

// Function to format XML string with indents and newlines
export function formatXml(xml) {
    const PADDING = '  '; // Define the indent
    const reg = /(>)(<)(\/*)/g;
    let pad = 0;
    xml = xml.replace(reg, '$1\r\n$2$3');
    return xml
        .split('\r\n')
        .map((line) => {
            let indent = 0;
            if (line.match(/.+<\/\w[^>]*>$/)) {
                indent = 0;
            } else if (line.match(/^<\/\w/)) {
                if (pad !== 0) {
                    pad -= 1;
                }
            } else if (line.match(/^<\w[^>]*[^\/]>.*$/)) {
                indent = 1;
            } else {
                indent = 0;
            }

            const padding = PADDING.repeat(pad);
            pad += indent;
            return padding + line;
        })
        .join('\r\n');
}

export function sequence_timecode(xmlDoc, projectJSON, projectTimebase, projectNtsc) {
    const timecode = xmlDoc.createElement("timecode");
    const rateTimecode = xmlDoc.createElement("rate");
    const timebaseTimecode = xmlDoc.createElement("timebase");
    timebaseTimecode.textContent = projectTimebase;
    const ntscTimecode = xmlDoc.createElement("ntsc");
    ntscTimecode.textContent = projectNtsc;
    rateTimecode.appendChild(timebaseTimecode);
    rateTimecode.appendChild(ntscTimecode);
    timecode.appendChild(rateTimecode);

    const timecodeString = xmlDoc.createElement("string");
    timecodeString.textContent = "00:00:00:00"; // Adjust if necessary
    const frame = xmlDoc.createElement("frame");
    frame.textContent = 0; //calculateFrameCount(); // Adjust if necessary
    const displayFormat = xmlDoc.createElement("displayformat");
    displayFormat.textContent = projectNtsc === "TRUE" ? "DF" : "NDF";

    timecode.appendChild(timecodeString);
    timecode.appendChild(frame);
    timecode.appendChild(displayFormat);

    return timecode;
}

export function sequence_label(xmlDoc) {
    const labels = xmlDoc.createElement("labels");
    const label = xmlDoc.createElement("label2");
    label.textContent = "Forest"; // Adjust if necessary
    labels.appendChild(label);
    return labels;
}

export function createLoggingInfo(xmlDoc) {
    const loggingInfo = xmlDoc.createElement("logginginfo");
    const description = xmlDoc.createElement("description");
    const scene = xmlDoc.createElement("scene");
    const shotTake = xmlDoc.createElement("shottake");
    const logNote = xmlDoc.createElement("lognote");
    const good = xmlDoc.createElement("good");
    const originalVideoFileName = xmlDoc.createElement("originalvideofilename");
    const originalAudioFileName = xmlDoc.createElement("originalaudiofilename");

    loggingInfo.appendChild(description);
    loggingInfo.appendChild(scene);
    loggingInfo.appendChild(shotTake);
    loggingInfo.appendChild(logNote);
    loggingInfo.appendChild(good);
    loggingInfo.appendChild(originalVideoFileName);
    loggingInfo.appendChild(originalAudioFileName);
    
    return loggingInfo;
}