import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Box, Typography, Dialog } from "@mui/material";

import "./pricing.scss";
import { SaveUpTo, MobileSaveUpTo } from "../../Icons";
import Swal from 'sweetalert2';
import { Header } from './../../component/home-header/header';
import { Footer } from './../../component/home-footer/footer';
import { GridListsItem, FreequentlyQuestion } from "./../../component/pricing/compare.grid";
import { CreditModal } from "./../../component/pricing/credit.modal";
import { getCustomerPlan } from "../../axios/ApiProvider";
import CloseIcon from '@mui/icons-material/Close';

import { plandata_config, usedLogs, comparePlan_lists, GetSelectedPlan, SetSelectedPlan } from "./pricing.config.js";
import facebookLogo from "../../assets/image/Logos/facebook_logo.png";
import instagramLogo from "../../assets/image/Logos/instagram_logo.png";
import tiktokLogo from "../../assets/image/Logos/tiktok_logo.png";
import twitterLogo from "../../assets/image/Logos/twitter_logo.png";
import youtubeLogo from "../../assets/image/Logos/youtube_logo.png";
import whatsappLogo from "../../assets/image/Logos/whatsapp_logo.png";
import { GetProjectUUID } from '../../assets/script/commonFunction.js';
const BASE_FRONTEND_URL = process.env.REACT_APP_BASE_FRONTEND_HOME_URL;
const PricingModal = ({open, onCloseModal}) => {
	const languageStrs = useSelector((store) => store.editdata.languageStrs)
	const projectUUID = GetProjectUUID();
  	const [planMonthly, setplanMonthly] = useState(true);
	const [comparesitems, setcomparesitems] = useState([]);
	const loginState = useSelector((store) => store.maindata.loginState);

	useEffect(() => {
		let temp = [];
		comparePlan_lists.forEach((list) => {
			temp.push(...list);
		});

		setcomparesitems(temp);
	}, []);

	const [userPlan, setUserPlan] = useState({})
	useEffect(() => {
		if (!!loginState) {
			(async () => {
				let result = await getCustomerPlan()
				if (result.state === 'success') {
					setUserPlan(result.data)
				}
			})()
		}
	}, [loginState])

	const [activePlan, setActivePlan] = useState(-1)
	useEffect(() => {
		let tempPlan = -1
		if (!!userPlan['product.plan']) {
			tempPlan = Number(userPlan['product.plan']) || 0
			if (tempPlan > 3) {
				tempPlan = Number(tempPlan) - 3
				setplanMonthly(false)
			} else setplanMonthly(true)
		}

		setActivePlan(tempPlan)
	}, [userPlan])

	const [planModalStatus, setplanModalStatus] = useState(false);
	const [ModalPlanData, SetModalPlanData] = useState(plandata_config[0][1]);

	const planModalOpen = (data) => {
		if(!loginState){
			Swal.fire({
				position: "center",
				icon: "success",
  				text: "You need to Sign up first",
				showConfirmButton: true,
				confirmButtonText: "Okay",
			}).then((result) => {
				if (result.isConfirmed) {
					window.location = `${BASE_FRONTEND_URL}/signup/?project_uuid=${projectUUID}`;		
				}
			});
			return;
		}
		if (data?.name === 'Free') return
		SetModalPlanData(data)
		setplanModalStatus(true)
		SetSelectedPlan(data.plan)
	}
  return (
    <Dialog open={open}
      className='upload-media-popup-wrapper'
      
      onClose={onCloseModal}
    >
      <Box className="um-popup-contain">
        <Box className="popup-close-btn" onClick={onCloseModal}>
          <CloseIcon />
        </Box>
      		<Box className="pricing_containner">

				<Box className="content-containner">
					<section className="plan-interval-section">
						<Box className="plan-interval-contain">
							<Box
								className={planMonthly ? "plan-iterval-toggle-active" : "plan-iterval-toggle"}
								onClick={() => { setplanMonthly(true); }}
							>
								<Typography className="plan-iterval-text">{languageStrs.monthly}</Typography>
							</Box>

							<Box
								className={!planMonthly ? "plan-iterval-toggle-active" : "plan-iterval-toggle"}
								onClick={() => { setplanMonthly(false); }}
							>
								<Typography className="plan-iterval-text">{languageStrs.annually}</Typography>
							</Box>

							<Box className="annually-SaveUpTo-contain">
								<SaveUpTo className="saveupto-img" />
								<MobileSaveUpTo className="mobile-saveupto-img" />
								<Typography className="saveupto-text">Save Up To 27%</Typography>
							</Box>
						</Box>
					</section>

					<section className="plan-hightlight-section">
						<Box className="plan-hightlight-contain">
							{plandata_config.map((planitem, index) => (
								<Box className="plan-hightlight-items" key={index}>
									{planitem.map((item, index) => (
										<Box key={index}
											className={`plan-hightlight-item ${activePlan !== item.plan ? item.class : 'custom-plan'} ${item.plan == 0? 'freePlan-brder':''}`}
										>
											{(activePlan !== item.plan && item.class) && (
												<Typography className="most-popular-txt">
													{languageStrs.most_popular}
												</Typography>
											)}

											{activePlan === item.plan && (
												<Typography className="custom-text">
													Your Plan {!userPlan?.active ? ' / Inactive' : (
														userPlan['product.plan'] > 3 ? '/ Annually' : '/ Monthly'
													)}
												</Typography>
											)}

											<Box className="plan-hightlight-header">
												<img alt='icon-path'
													src={item.icon_path}
													className="plan-hightlight-headerimg"
												/>

												<Typography className="plan-hightlight-headertxt">
													{item.name}
												</Typography>
											</Box>

											<Typography className="plan-hightlight-description">
												{item.description}
											</Typography>

											<Box className="plan-hightlight-price">
												<Typography className="plan-hightlight-pricetxt">
													${planMonthly ? item.monthlyprice : item.Annuallyprice}
												</Typography>
											</Box>

											<Typography className="plan-hightlight-price-description">
												{item.monthlyprice && (
													planMonthly ? item.price_description : `${item.price_description} ($${item.Annuallyprice * 12})`
												)}
											</Typography>

											{(item.name === "Free" && !loginState) ? (
												<Link to={"./../signup"}
													className="plan-hightlight-gobtn"
												>
													{item.btnTxt}
												</Link>
											) : (
												<Box onClick={() => { planModalOpen(item) }}
													className={`plan-hightlight-gobtn ${item.name === 'Free' && ('disaled')}`}
												>
													{item.btnTxt}
												</Box>
											)}

											<Box className="plan-hightlight-featurelist">
												{item.featureList.items.map((list, index) => (
													<Box className="plan-hightlight-featurelist-item" key={index}>
														{item.featureList.icon}
														<Typography className="plan-hightlight-featurelist-txt">
															{list}
														</Typography>
													</Box>
												))}
											</Box>

											<Link to={"#"} className="plan-hightlight-footer">
												{item.footerTxt}
											</Link>
										</Box>
									))}
								</Box>
							))}
						</Box>
					</section>

					<section className="contactEnterprise-section">
						<Box className="contactEnterprise-contain">
							<Typography className="contactEnterprise-txt">
								Need additional features? Ask us about our Enterprise plan
							</Typography>

							<a href="mailto:info@videoo.io" style={{ textDecoration: 'none' }}>
								<Box className="contactEnterprise-btn">Contact Sales</Box>
							</a>
						</Box>
					</section>
       			</Box>
			</Box>
      </Box>
      {ModalPlanData && (
				<CreditModal
					userPlan={userPlan}
					ModalPlanData={ModalPlanData}
					ModalStatus={planModalStatus}
					SetModalStatus={setplanModalStatus}
				/>
			)}
    </Dialog>
  )
}

export default PricingModal