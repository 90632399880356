import React, {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase} from "@mui/material"
import {ArrowBack, ArrowForward} from '@mui/icons-material';
import { ClickAwayListener } from '@mui/base';
import VideocamIcon from '@mui/icons-material/Videocam';

import SearchIcon from '@mui/icons-material/Search';
import { SectionHeader } from '../common/section.header';
import { 
  getStockSearchKeyword, 
  getStockData, 
  createStock, 
  updateStock, 
  UploadAssets, 
  getPopularPexelsData, 
  getPexelsData, 
  getPopularPexelsImages,
  getPexelsImageSearch,
  getPexelsImageData,
  getPixaBayStockSearch, 
  getPopularPixaBayStockData, 
  getPopularPixabayImageStocks,
  getPixabayImageSearchStocks,
  getPixabayImageStock,
  getFreeSoundSearch,
  getFreeSoundData,
  getProjectJSON} from '../../../../axios/ApiProvider';
import { GetProjectUUID } from '../../../../assets/script/commonFunction';
import { getNewStockInformation, getNewPexelsStockInformation, getThumbnailsInfo, getImageInformation, getNewAudioInformation, getNewAudioStockInformation } from '../../webGL/commonFunc';
import { VideoElements_Store, audioElements_Store, dataLoaded_Store, imageElements_Store } from '../../../../store/actions/webgldata';
import { changedJSON_Store, ProjectJSON_Store, thumbNails_Store } from '../../../../store/actions/projectdata';
import { loading_Store } from '../../../../store/actions/mainAction';
import { getMaxZIndex, changeZeroSecond } from '../../commonFunction';
import { localeForPexles, mimeTypes } from '../tooltap.config';
import { v4 as uuidv4 } from 'uuid';
import {webGlSizeOptions } from "../../../video-edit/videoEdit.config"
import pexelsVideoLogo from "../../../../assets/image/Logos/pexelVideo.png"
import pexelsImageLogo from "../../../../assets/image/Logos/pexelImage.png";
import pixabayVideoLogo from "../../../../assets/image/Logos/pixabayVideo.png";
import pixabayImageLogo from "../../../../assets/image/Logos/pixabayImage.png";
import freeSoundLogo from "../../../../assets/image/Logos/freeSound.png";
import stockLogo from "../../../../assets/image/Logos/stock_logo.png"
import { duration } from 'moment';

import { StockModal } from './stockModal';

export const PexelsVideo = () => {
    const languageStrs = useSelector((store) => store.editdata.languageStrs)
    const [searchKeyword, setSearchKeyword] = useState("");
    const [openPopper, setOpenPopper] = useState(false)
    const anchorEl = useRef(null)
    const [keywords, setKeywords] = useState([]);
    const [stockData, setStockData] = useState([]);
    const [isLoadingStock, setIsLoadingStock] = useState(false)
    const [orientation, setOrientation] = useState("landscape");
    const [page, setPage] = useState(1); // useState(Math.floor(Math.random() * 20000)); // Use this to get a random Pexel popular page
    const [pageNum, setPageNum] = useState(1)
    const [locale, setLocale] = useState("en-US")
    const projectUUID = GetProjectUUID();
    const videoElements = useSelector(store => store.webgldata.videoElements);
    const Time_Count = useSelector((store) => store.videodata.Time_Count);
    const projectJSON = useSelector(store => store.projectdata.projectJSON);
    const [isHoverId, setIsHoverId] = useState(null);

    const [selectedStock, setSelectedStock] = useState(null);
    const [openDetaiModal, setOpenDetailModal] = useState(false);

    const dispatch = useDispatch()
    const handleOpenPopover = (event) => {
      setOpenPopper(true);
    };
  
    const handleClosePopover = () => {
      setOpenPopper(false);
    };
  
    const getPublicPexelsStock = async () => {
      try {
        setIsLoadingStock(true)
        const pexelsStockData = await getPopularPexelsData(page);
        if(pexelsStockData.state === "success") {
          setStockData(pexelsStockData.data)
          setPageNum(Math.round(pexelsStockData.data.total_results / pexelsStockData.data.per_page + 0.49))
        }
        setIsLoadingStock(false)
      } catch (error) {
        setIsLoadingStock(false)
      }
     
    }
  
    useEffect(() => {
     // getPublicPexelsStock()
    }, [])
    
  
    useEffect(() => {
      (async function() {
        if(searchKeyword) {
          setPage(1)
          const stockData = await getStockSearchKeyword(searchKeyword);
          let respStr = "";
          stockData.map(st => respStr += st);
          const firstBracket = respStr.indexOf("(");
          respStr = respStr.slice(firstBracket + 1).slice(0, -1);
          const respJson = JSON.parse(respStr);
          setKeywords(respJson.hits)
        }
      })()
    }, [searchKeyword])
    
  
    const handleChangeInput=  e => {
      setSearchKeyword(e.target.value);
      if(e.target.value === "") {
        handleClosePopover();
      } else {
        handleOpenPopover(e)
      }
    }
  
    const handleGetStockData = async (stockName) => {
      if(stockName) {
        try {
          setIsLoadingStock(true)
          const pexelsStockData = await getPexelsData(stockName, page, locale, orientation)
          if(pexelsStockData.state === "success") {
            setStockData(pexelsStockData.data)
            console.log('aditDa', pexelsStockData.data);
            setPageNum(Math.round(pexelsStockData.data.total_results / pexelsStockData.data.per_page + 0.49))
          }
          setIsLoadingStock(false)
        } catch (error) {
          setIsLoadingStock(false)
        }
        
      }
    }
  
    const handleKeyUp = e => {
      if(e.keyCode === 13) {
        handleGetStockData(searchKeyword);
        handleClosePopover();
      }
    }
  
    const handleClickKeyword =  (keyword) => {
      setSearchKeyword(keyword);
      handleGetStockData(keyword)
      handleClosePopover();
    }
  
    const handleClickStock = (stockItem) => {
      setSelectedStock(stockItem);
      setOpenDetailModal(true);
    }
    
    const addStockData = async (stockData) => {
      const hVideo = stockData?.video_files.sort((a, b) => b.width - a.width)[0];
      const video_pictures = stockData?.video_pictures;
      const duration = stockData.duration * 1000
      // projectUUID, projectJSON, signed_url, originalFileName, mimeType, duration, thumbNails, videoLength, zIndex, callBack
      const stockUUID = uuidv4()
      // const thumbnails = {}
      const thumbnails = []
      dispatch(loading_Store(true));
      video_pictures.map((p, index) => {
        let timestamp = (duration/1000) / (video_pictures.length + 1) * p.nr;
        const extension = p.picture.split("?")[0].split(".").reverse()[0];
        thumbnails.push({
          url: p.picture,
          timestamp,
          content_type: mimeTypes[extension]
        })
      })
        
        let startTime = Time_Count.getTime()
  
        let maxZIndex = getMaxZIndex(projectJSON);
        let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
  
        const newProjectJSON = {...projectJSON};
        let addingGroupId = stockUUID;
        const vKeys = projectJSON.videos? Object.keys(projectJSON.videos) : [];
        if(vKeys.length > 0){
          addingGroupId = projectJSON.videos[vKeys[0]].groups;
          vKeys.map(vKeyItem => {
            if(startTime < projectJSON.videos[vKeyItem].timeline_end){
              console.log('timelineEnd', projectJSON.videos[vKeyItem].timeline_end);
              startTime = projectJSON.videos[vKeyItem].timeline_end;
            }
            console.log('looping time');
          })
        }
        
        await getNewPexelsStockInformation(projectUUID, stockUUID, newProjectJSON, hVideo.link, hVideo.link, hVideo.file_type, duration, thumbnails, Object.keys(videoElements).length, maxZIndex,
        (videoPlayer, videoInfo, newAssets, assetUUID, thumbnail_info_json) => {
  
          const tempThumbnails = {}
          Object.values(thumbnail_info_json).map(th => {
            tempThumbnails[th.uuid] = {
              signed_url: th.external_url,
              timestamp: th.timestamp
            }
          })
  
          dispatch(VideoElements_Store({ ...videoElements, [stockUUID]: videoPlayer }));
          changedJSON.flag = true;
          changedJSON.videos = {
              [stockUUID]: {
                ...videoInfo,
                groups: addingGroupId,
                timeline_start: startTime,
                timeline_end: videoInfo.duration + startTime,
                asset_uuid: assetUUID,
                asset_filename:  hVideo.link,
                asset_extension: hVideo.file_type.split("/").reverse()[0]
              }
          }
  
          const tempAssets = {
            ...newAssets,
            [assetUUID]: {
              ...newAssets[assetUUID],
              thumbnail: tempThumbnails
            }
          }
  
          dispatch(changedJSON_Store(changedJSON))
          dispatch(ProjectJSON_Store({
            ...newProjectJSON,
            asset: {
              ...newProjectJSON.asset,
              ...tempAssets
            }
          }))
          changeZeroSecond(videoInfo, dispatch)
          dispatch(loading_Store(false));
        }); 
      }
  
    const handleChangeOrientation = (e) => {
      setOrientation(e.target.value)
    }
  
    const handleChangeLocale = (e) => {
      setLocale(e.target.value)
    }
  
    const handleChangePage = (e) => {
      if(e.target.value < 1 || e.target.value > pageNum) return;
      setPage(e.target.value)
    }
  
    useEffect(() => {
      if(searchKeyword) {
        handleGetStockData(searchKeyword)
      } else {
        getPublicPexelsStock()
      }
    }, [orientation, locale, page])
    
  
    return (
      <>
        <Grid container sx={{p: 1}}>
          <Grid item xs={6} padding={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{languageStrs.orientation}</InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={orientation}
              label="Orientation"
              onChange={handleChangeOrientation}
              >
                <MenuItem value="landscape">{languageStrs.landscape}</MenuItem>
                <MenuItem value="portrait">{languageStrs.portrait}</MenuItem>
                <MenuItem value="square">{languageStrs.square}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} padding={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">{languageStrs.locale}</InputLabel>
              <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={locale}
              label="Locale"
              onChange={handleChangeLocale}
              >
                {localeForPexles.map(lo => <MenuItem value={lo}>{lo}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Button 
                variant="contained" 
                startIcon={<ArrowBack />} 
                sx={{marginRight: "10px"}} 
                onClick={() => {
                  if(page === 1) return;
                  setPage(page - 1);
                }}
              >
                Prev
              </Button>
              <input
                type="number"
                max={pageNum}
                min={1}
                style={{
                  width: "50px",
                }}
                className='stock-page-input'
                value={page}
                onChange={handleChangePage}
              />
              <span style={{fontSize: "20px"}}>/{pageNum}</span>
              <Button 
                variant="contained" 
                endIcon={<ArrowForward />} 
                sx={{marginLeft: "10px"}}
                onClick={() => {
                  if(page === pageNum) return;
                  setPage(Number(page) + 1);
                }}
              >
                {languageStrs.next}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} paddingY={2}>
            <FormControl fullWidth ref={anchorEl} >
              <InputLabel htmlFor="outlined-adornment-amount">{languageStrs.search}</InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                label="Search"
                onChange={handleChangeInput}
                onKeyUp={handleKeyUp}
                value={searchKeyword}
              />
            </FormControl>
          </Grid>
          
          <ClickAwayListener onClickAway={handleClosePopover}>
            <Popper
              open={openPopper}
              anchorEl={anchorEl.current}
              sx={{
                width: `${anchorEl?.current?.clientWidth}px`,
                border: "solid 1px #e5e7eb",
                zIndex: 1000,
                backgroundColor: "#FFF"
              }}
              placement="bottom-start"
            >
              {keywords.map(w => <MenuItem key={w.objectID} onClick={() => {
                handleClickKeyword(w.term)
              }}>{w.term}</MenuItem>)}
            </Popper>
          </ClickAwayListener>
          
        </Grid>
        <Grid container sx={{overflow: "auto", position: "relative"}}>
          <Grid item xs={12} sx={{position: "relative", minHeight: "300px"}} >
            <ImageList variant="masonry" cols={2} gap={8} >
              {stockData?.videos?.map((video, key) => {
                let subtitle = "";
                const minute = Math.floor(video.duration / 60);
                const second = Math.round((video.duration - (minute * 60)));
                subtitle += `   ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}  `;
                const hVideo = video?.video_files.sort((a, b) => b.width - a.width)[0];
                if(hVideo) {
                  subtitle += hVideo?.quality.toUpperCase();
                }
  
                return (
                <ImageListItem key={key} sx={{cursor: "pointer"}} 
                onClick={() => {
                  // handleClickStock(hVideo, video.video_pictures, video.duration * 1000)
                  handleClickStock(video)
                }}
                onMouseOver={() => {if(isHoverId != video.id){ setIsHoverId(video.id); }console.log('onMuseOver', video)}}
                onMouseLeave={() => {setIsHoverId(null); console.log('onMuseLeave')}}
                >
                  <img
                    src={`${video.image}`}
                    alt={video.id}  
                    loading="lazy"
                    style={{
                      minWidth: "184px",
                      minHeight: "103px",
                      flexGrow: "unset"
                    }}
                  />
                  {isHoverId == video.id && 
                    <video className='stockPreview-video' src={video.video_files[0].link} loop autoPlay style={{
                        top: '0px',
                        position: "absolute",
                        minWidth: "184px",
                        minHeight: "103px",
                        flexGrow: "unset"
                      }}></video>
                    }
                  <ImageListItemBar
                    subtitle={<><VideocamIcon/>{subtitle}</>}
                  />
                </ImageListItem>)
                }
              )}
            </ImageList>
            {isLoadingStock && <Box sx={{position: "absolute", top: 0,  background: "rgba(0,0,0,0)", display: "flex", zIndex: 100000, flexDirection: "column", justifyContent: "center", height: "300px", width: "100%"}}>
              <Box sx={{ display: 'flex', position: "absolute", justifyContent: "center", width: "100%" }}>
                <CircularProgress />
              </Box>
            </Box>}
          </Grid>
        </Grid>
        <StockModal 
            open={openDetaiModal} 
            setOpen={setOpenDetailModal} 
            stockType='pexelsVideo'
            stockData={selectedStock}
            addStockData={addStockData}
            />
      </>
    )
  }
  