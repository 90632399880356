import { calculateFrameCount, calculatePproTicks, calculatePproTicksFromMs } from "./adobexmlformat_helper";

export function sequence_media_audio(xmlDoc, projectJson, video_asset_map, projectTimebase, projectNtsc) {
    const audio = xmlDoc.createElement("audio");
    const numOutputChannels = xmlDoc.createElement("numOutputChannels");
    numOutputChannels.textContent = "2"; // Stereo
    audio.appendChild(numOutputChannels);

    const formatAudio = xmlDoc.createElement("format");
    const sampleCharacteristicsAudio = xmlDoc.createElement("samplecharacteristics");
    const depth = xmlDoc.createElement("depth");
    depth.textContent = "16"; // 16-bit depth
    const sampleRate = xmlDoc.createElement("samplerate");
    sampleRate.textContent = "44100"; // 44.1kHz sample rate
    sampleCharacteristicsAudio.appendChild(depth);
    sampleCharacteristicsAudio.appendChild(sampleRate);
    formatAudio.appendChild(sampleCharacteristicsAudio);
    audio.appendChild(formatAudio);

    // Create the outputs structure
    const outputs = createAudioOutputs(xmlDoc, numOutputChannels.textContent);
    audio.appendChild(outputs);

    // Create two audio tracks as per the example structure
    let numberOfVideos = Array.isArray(projectJson.videos) ? projectJson.videos.length : Object.keys(projectJson.videos).length;

    const trackAudio1 = sequence_media_audio_track(xmlDoc, projectJson, video_asset_map, 1, projectTimebase, projectNtsc);
    audio.appendChild(trackAudio1);

    const trackAudio2 = sequence_media_audio_track(xmlDoc, projectJson, video_asset_map, 2, projectTimebase, projectNtsc);
    audio.appendChild(trackAudio2);

    // Append tracks to the audio element
    appendAudioTracks(xmlDoc, audio);

    return audio;
}

export function appendAudioTracks(xmlDoc, audioElement) {
    const tracksData = [
        { currentExplodedTrackIndex: "0", totalExplodedTrackCount: "2", outputChannelIndex: "1" },
        { currentExplodedTrackIndex: "1", totalExplodedTrackCount: "2", outputChannelIndex: "2" },
        { currentExplodedTrackIndex: "0", totalExplodedTrackCount: "2", outputChannelIndex: "1" },
        { currentExplodedTrackIndex: "1", totalExplodedTrackCount: "2", outputChannelIndex: "2" }
    ];

    tracksData.forEach((trackData) => {
        const track = xmlDoc.createElement("track");
        track.setAttribute("TL.SQTrackAudioKeyframeStyle", "0");
        track.setAttribute("TL.SQTrackShy", "0");
        track.setAttribute("TL.SQTrackExpandedHeight", "41");
        track.setAttribute("TL.SQTrackExpanded", "0");
        track.setAttribute("MZ.TrackTargeted", "1");
        track.setAttribute("PannerCurrentValue", "0.5");
        track.setAttribute("PannerIsInverted", "true");
        track.setAttribute("PannerStartKeyframe", "-91445760000000000,0.5,0,0,0,0,0,0");
        track.setAttribute("PannerName", "Balance");
        track.setAttribute("currentExplodedTrackIndex", trackData.currentExplodedTrackIndex);
        track.setAttribute("totalExplodedTrackCount", trackData.totalExplodedTrackCount);
        track.setAttribute("premiereTrackType", "Stereo");

        const enabled = xmlDoc.createElement("enabled");
        enabled.textContent = "TRUE";
        track.appendChild(enabled);

        const locked = xmlDoc.createElement("locked");
        locked.textContent = "FALSE";
        track.appendChild(locked);

        const outputChannelIndex = xmlDoc.createElement("outputchannelindex");
        outputChannelIndex.textContent = trackData.outputChannelIndex;
        track.appendChild(outputChannelIndex);

        audioElement.appendChild(track);
    });
}

function createAudioOutputs(xmlDoc, numOutputChannels) {
    const outputs = xmlDoc.createElement("outputs");
    for (let i = 1; i <= numOutputChannels; i++) {
        const group = xmlDoc.createElement("group");
        const indexGroup = xmlDoc.createElement("index");
        indexGroup.textContent = i;
        const numChannels = xmlDoc.createElement("numchannels");
        numChannels.textContent = "1"; // Mono channels in stereo
        const downmix = xmlDoc.createElement("downmix");
        downmix.textContent = "0";
        const channel = xmlDoc.createElement("channel");
        const channelIndex = xmlDoc.createElement("index");
        channelIndex.textContent = i;
        group.appendChild(indexGroup);
        group.appendChild(numChannels);
        group.appendChild(downmix);
        channel.appendChild(channelIndex);
        group.appendChild(channel);
        outputs.appendChild(group);
    }
    return outputs;
}

function sequence_media_audio_track(xmlDoc, projectJson, video_asset_map, trackIndex, projectTimebase, projectNtsc) {
    const trackAudio = xmlDoc.createElement("track");
    trackAudio.setAttribute("TL.SQTrackAudioKeyframeStyle", "0");
    trackAudio.setAttribute("TL.SQTrackShy", "0");
    trackAudio.setAttribute("TL.SQTrackExpandedHeight", "41");
    trackAudio.setAttribute("TL.SQTrackExpanded", "0");
    trackAudio.setAttribute("MZ.TrackTargeted", "1");
    trackAudio.setAttribute("PannerCurrentValue", "0.5");
    trackAudio.setAttribute("PannerIsInverted", "true");
    trackAudio.setAttribute("PannerStartKeyframe", "-91445760000000000,0.5,0,0,0,0,0,0");
    trackAudio.setAttribute("PannerName", "Balance");
    trackAudio.setAttribute("currentExplodedTrackIndex", `${trackIndex - 1}`);
    trackAudio.setAttribute("totalExplodedTrackCount", "2");
    trackAudio.setAttribute("premiereTrackType", "Stereo");

    // Add multiple clipitems to the audio track
    let numberOfVideos = Array.isArray(projectJson.videos) ? projectJson.videos.length : Object.keys(projectJson.videos).length;
    let videos = Array.isArray(projectJson.videos) ? projectJson.videos : Object.values(projectJson.videos || {});
    videos = videos.sort((a, b) => a.timeline_start - b.timeline_start);
    videos.forEach((videoData, index) => {
        let is_asset_used_before = false;
        if (video_asset_map[videoData.asset_uuid]) {
            is_asset_used_before = true;
        }
        const clipitemAudio = sequence_media_audio_track_clipitem(xmlDoc, projectJson, videoData, index, trackIndex, is_asset_used_before, projectTimebase, projectNtsc);
        trackAudio.appendChild(clipitemAudio);
    });

    const enabledAudio = xmlDoc.createElement("enabled");
    enabledAudio.textContent = "TRUE";
    trackAudio.appendChild(enabledAudio);

    const lockedAudio = xmlDoc.createElement("locked");
    lockedAudio.textContent = "FALSE";
    trackAudio.appendChild(lockedAudio);

    // Set the output channel index
    const outputChannelIndex = xmlDoc.createElement("outputchannelindex");
    outputChannelIndex.textContent = trackIndex;
    trackAudio.appendChild(outputChannelIndex);

    return trackAudio;
}

function sequence_media_audio_track_clipitem(xmlDoc, projectJson, videoData, index, trackIndex, is_asset_used_before, projectTimebase, projectNtsc) {
    const clipitemAudio = xmlDoc.createElement("clipitem");
    clipitemAudio.setAttribute("id", `clipitem-${index + 1}-${trackIndex}`);
    clipitemAudio.setAttribute("premiereChannelType", "stereo");

    const masterclipidAudio = xmlDoc.createElement("masterclipid");
    masterclipidAudio.textContent = `masterclip-1`;
    clipitemAudio.appendChild(masterclipidAudio);

    const nameElementAudio = xmlDoc.createElement("name");
    nameElementAudio.textContent = videoData.asset_filename;
    clipitemAudio.appendChild(nameElementAudio);

    const enabledAudio = xmlDoc.createElement("enabled");
    enabledAudio.textContent = "TRUE";
    clipitemAudio.appendChild(enabledAudio);

    const durationAudio = xmlDoc.createElement("duration");
    durationAudio.textContent = calculateFrameCount(videoData.duration, projectJson.fps, projectNtsc);
    clipitemAudio.appendChild(durationAudio);

    const rateAudio = xmlDoc.createElement("rate");
    const timebaseAudio = xmlDoc.createElement("timebase");
    timebaseAudio.textContent = projectTimebase;
    const ntscAudio = xmlDoc.createElement("ntsc");
    ntscAudio.textContent = projectNtsc;
    rateAudio.appendChild(timebaseAudio);
    rateAudio.appendChild(ntscAudio);
    clipitemAudio.appendChild(rateAudio);

    const startAudio = xmlDoc.createElement("start");
    startAudio.textContent = calculateFrameCount(videoData.timeline_start, projectJson.fps, projectNtsc);
    clipitemAudio.appendChild(startAudio);

    const endAudio = xmlDoc.createElement("end");
    endAudio.textContent = calculateFrameCount(videoData.timeline_end, projectJson.fps, projectNtsc);
    clipitemAudio.appendChild(endAudio);

    const inAudio = xmlDoc.createElement("in");
    inAudio.textContent = calculateFrameCount(videoData.segment_start, projectJson.fps, projectNtsc);
    clipitemAudio.appendChild(inAudio);

    const outAudio = xmlDoc.createElement("out");
    outAudio.textContent = calculateFrameCount(videoData.segment_end, projectJson.fps, projectNtsc);
    clipitemAudio.appendChild(outAudio);

    // const pproTicksIn = xmlDoc.createElement("pproTicksIn");
    // pproTicksIn.textContent = calculatePproTicksFromMs(videoData.segment_start, projectJson.fps, projectNtsc);  // (calculateFrameCount(videoData.timeline_start, projectJson.fps), projectJson.fps);
    // clipitemAudio.appendChild(pproTicksIn);

    // const pproTicksOut = xmlDoc.createElement("pproTicksOut");
    // pproTicksOut.textContent = calculatePproTicksFromMs(videoData.segment_end, projectJson.fps, projectNtsc); // calculatePproTicks(calculateFrameCount(videoData.timeline_end, projectJson.fps), projectJson.fps);
    // clipitemAudio.appendChild(pproTicksOut);

    const fileAudio = xmlDoc.createElement("file");
    fileAudio.setAttribute("id", `file-${1}`);
    clipitemAudio.appendChild(fileAudio);

    const sourcetrack = xmlDoc.createElement("sourcetrack");
    const mediaType = xmlDoc.createElement("mediatype");
    mediaType.textContent = "audio";
    sourcetrack.appendChild(mediaType);

    const trackIndexElem = xmlDoc.createElement("trackindex");
    trackIndexElem.textContent = trackIndex;
    sourcetrack.appendChild(trackIndexElem);
    clipitemAudio.appendChild(sourcetrack);

    // Create the video and audio link elements
    const videoLink = sequence_media_audio_track_clipitem_video_link(xmlDoc, index);
    clipitemAudio.appendChild(videoLink);

    const audioLink_track1 = sequence_media_audio_track_clipitem_audio_link(xmlDoc, index, 1);
    clipitemAudio.appendChild(audioLink_track1);

    const audioLink_track2 = sequence_media_audio_track_clipitem_audio_link(xmlDoc, index, 2);
    clipitemAudio.appendChild(audioLink_track2);

    const loggingInfo = sequence_media_audio_track_clipitem_logginginfo(xmlDoc);
    clipitemAudio.appendChild(loggingInfo);

    const colorInfo = appendColorInfo(xmlDoc, clipitemAudio);
    clipitemAudio.appendChild(colorInfo);
    
    // Labels section
    const labels = sequence_media_audio_track_clipitem_labels(xmlDoc);
    clipitemAudio.appendChild(labels);

    return clipitemAudio;
}

function sequence_media_audio_track_clipitem_video_link(xmlDoc, index) {
    const linkVideo = xmlDoc.createElement("link");
    const linkClipRefVideo = xmlDoc.createElement("linkclipref");
    linkClipRefVideo.textContent = `clipitem-${index + 1}`;
    linkVideo.appendChild(linkClipRefVideo);

    const mediaTypeVideo = xmlDoc.createElement("mediatype");
    mediaTypeVideo.textContent = "video";
    linkVideo.appendChild(mediaTypeVideo);

    const trackIndexVideo = xmlDoc.createElement("trackindex");
    trackIndexVideo.textContent = "1"; // Assuming video track is always track 1
    linkVideo.appendChild(trackIndexVideo);

    const clipIndexVideo = xmlDoc.createElement("clipindex");
    clipIndexVideo.textContent = index + 1;
    linkVideo.appendChild(clipIndexVideo);

    return linkVideo;
}

function sequence_media_audio_track_clipitem_audio_link(xmlDoc, index, trackIndex) {
    const linkAudio = xmlDoc.createElement("link");
    const linkClipRefAudio = xmlDoc.createElement("linkclipref");
    linkClipRefAudio.textContent = `clipitem-${index + 1}-${trackIndex}`;
    linkAudio.appendChild(linkClipRefAudio);

    const mediaTypeAudio = xmlDoc.createElement("mediatype");
    mediaTypeAudio.textContent = "audio";
    linkAudio.appendChild(mediaTypeAudio);

    const trackIndexAudio = xmlDoc.createElement("trackindex");
    trackIndexAudio.textContent = trackIndex;
    linkAudio.appendChild(trackIndexAudio);

    const clipIndexAudio = xmlDoc.createElement("clipindex");
    clipIndexAudio.textContent = index + 1;
    linkAudio.appendChild(clipIndexAudio);

    const groupIndexAudio = xmlDoc.createElement("groupindex");
    groupIndexAudio.textContent = "1";
    linkAudio.appendChild(groupIndexAudio);

    return linkAudio;
}

export function sequence_media_audio_track_clipitem_logginginfo(xmlDoc) {
    const loggingInfo = xmlDoc.createElement("logginginfo");

    // Append elements with empty text nodes to ensure non-self-closing tags
    const description = xmlDoc.createElement("description");
    description.appendChild(xmlDoc.createTextNode("")); // This forces <description></description>
    
    const scene = xmlDoc.createElement("scene");
    scene.appendChild(xmlDoc.createTextNode("")); // This forces <scene></scene>
    
    const shottake = xmlDoc.createElement("shottake");
    shottake.appendChild(xmlDoc.createTextNode("")); // This forces <shottake></shottake>
    
    const lognote = xmlDoc.createElement("lognote");
    lognote.appendChild(xmlDoc.createTextNode("")); // This forces <lognote></lognote>
    
    const good = xmlDoc.createElement("good");
    good.appendChild(xmlDoc.createTextNode("")); // This forces <good></good>
    
    const originalVideoFileName = xmlDoc.createElement("originalvideofilename");
    originalVideoFileName.appendChild(xmlDoc.createTextNode("")); // This forces <originalvideofilename></originalvideofilename>
    
    const originalAudioFileName = xmlDoc.createElement("originalaudiofilename");
    originalAudioFileName.appendChild(xmlDoc.createTextNode("")); // This forces <originalaudiofilename></originalaudiofilename>

    // Append elements to loggingInfo
    loggingInfo.appendChild(description);
    loggingInfo.appendChild(scene);
    loggingInfo.appendChild(shottake);
    loggingInfo.appendChild(lognote);
    loggingInfo.appendChild(good);
    loggingInfo.appendChild(originalVideoFileName);
    loggingInfo.appendChild(originalAudioFileName);

    return loggingInfo;
}

export function appendColorInfo(xmlDoc, clipitemAudio) {
    const colorInfo = xmlDoc.createElement("colorinfo");

    // Append elements with empty text nodes to ensure non-self-closing tags
    const lut = xmlDoc.createElement("lut");
    lut.appendChild(xmlDoc.createTextNode("")); // This forces <lut></lut>
    colorInfo.appendChild(lut);

    const lut1 = xmlDoc.createElement("lut1");
    lut1.appendChild(xmlDoc.createTextNode("")); // This forces <lut1></lut1>
    colorInfo.appendChild(lut1);

    const ascSop = xmlDoc.createElement("asc_sop");
    ascSop.appendChild(xmlDoc.createTextNode("")); // This forces <asc_sop></asc_sop>
    colorInfo.appendChild(ascSop);

    const ascSat = xmlDoc.createElement("asc_sat");
    ascSat.appendChild(xmlDoc.createTextNode("")); // This forces <asc_sat></asc_sat>
    colorInfo.appendChild(ascSat);

    const lut2 = xmlDoc.createElement("lut2");
    lut2.appendChild(xmlDoc.createTextNode("")); // This forces <lut2></lut2>
    colorInfo.appendChild(lut2);

    // Finally, append the colorInfo section to the clipitemAudio element
    clipitemAudio.appendChild(colorInfo);

    return colorInfo;
}


export function sequence_media_audio_track_clipitem_labels(xmlDoc) {
    const labels = xmlDoc.createElement("labels");
    const label = xmlDoc.createElement("label2");
    label.textContent = "Iris"; // Adjust if necessary
    labels.appendChild(label);
    return labels;
}