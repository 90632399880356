import { mat4 } from "gl-matrix";

import { MoveableDiv } from './Calculators/MoveableDiv';
import { InitBuffers, initVideoTexture, UpdateServerBuffers, SetupServerTextCanvas, InitTextTexture, SetupServerText } from './Setups/SetupClass';
import { DragResizeInitialCalculator, RotationInitialCalculator } from './Calculators/PositionCalculators';
import { DefaultTextData, DefaultVideoData, DefaultAssetData, DefaultImageData, defaultAudioData } from "../videoEdit.config";
import { CreateAsset, UpdateAssets, UploadAssets, createThumbnail, uploadThumbnail, UploadVideo, measureFileSize } from "../../../axios/ApiProvider";
import { convertStringtoWStringUTF16, copyObject } from '../commonFunction';
import { v4 as uuidv4 } from 'uuid';
import Resizer from "react-image-file-resizer";
import Swal from "sweetalert2";

import MediaInfo from 'mediainfo.js';
import { getInfo } from 'react-mediainfo'

export const THUMBNAIL_WIDTH = process.env.REACT_APP_THUMBNAIL_WIDTH

const checkVideoData = (tempElements, assets) => {
  let tempObjects = {};
  let tempKeys = Object.keys(tempElements);
  let assetKeys = Object.keys(assets);

  tempKeys.forEach((key) => {
    const tempData = Object.assign({}, tempElements[key]);
    const assetUUID = tempData.asset_uuid;

    const assetkey = assetKeys.find((key) => assetUUID === key ? true : false);
    if (!!assetkey && assetkey === assetUUID) tempObjects[key] = { ...tempData };
  });

  return tempObjects;
}

export const getVideoLoadedData = async (signedURL, videoID, videoObItem, asset_uuid, callBack) => {
  let result = { status: '', message: '' };
  let current_video_player;
  let all_video_elements = document.getElementsByClassName("myElement_videos");
  let all_video_elements_array = Array.from(all_video_elements);
  let current_asset_uuid = asset_uuid ? asset_uuid : videoObItem.asset_uuid;

  all_video_elements_array.forEach((element, index) => {
    if (element.getAttribute('data-asset-uuid') === current_asset_uuid) {
      current_video_player = element;
    }
  });

  if (current_video_player) {     
    return current_video_player;
  }

  let existVideoElements = []
  let existVideoTags = document.getElementsByClassName("myElement_videos");
  existVideoElements = Array.from(existVideoTags)
  const existVideoEle = existVideoElements.find(vEle => vEle.id == videoID);
  if(existVideoEle){
    existVideoEle.remove();
  }

  const video = document.createElement('video');
  video.crossOrigin = "anonymous";
  video.classList.add("myElement_videos");
  video.id = videoID;
  video.src = `${signedURL}`;
  video.setAttribute('data-asset-uuid', current_asset_uuid);
  video.muted = false;
  video.preload = "metadata";
  video.playsInline = true;
  video.loop = false;
  if(videoObItem.speed){
    video.playbackRate = videoObItem.speed;
  }else{
    video.playbackRate = 1.0;
  }
  video.style.display="none";
  document.body.appendChild(video);

  const videoSource = document.createElement('source');
  videoSource.src = `${signedURL}`;
  videoSource.type="video/mp4";
  video.appendChild(videoSource);

  //let currentTime = videoObItem.segment_start + 1000
  video.addEventListener("loadedmetadata", async (props) => {
    if (!video && !video.src) {
      callBack({
        status: 'failure',
        message: 'video load failure'
      });
      window.toastr.error('Video cannot be loaded');
      return
    }
    video.muted = false;
    //video.currentTime = currentTime
    callBack({
      status: 'success',
      message: 'video metadata loaded'
    }, video)
  })
  videoSource.addEventListener('error', (err)=>{
    console.log('source Errior', err);
    window.toastr.error('One of the video sources failed to load');
    result = { status: 'failure', message: 'One of the video source failed to load' };
    callBack(result, video);
  })
  video.addEventListener('error', (error) => {
    console.log('One of the videos failed to load', error);
    window.toastr.error('One of the videos failed to load');
    result = { status: 'failure', message: 'One of the videos failed to load' };
    callBack(result, video);
  })

  return video;
}

export const getAudioLoadedData = async (signedURL, audioID, audioObItem, callBack) => {
  let result = { status: '', message: '' };
  let video_url = signedURL

  const audio = document.createElement('audio');
  audio.classList.add("myElement_audios");
  audio.crossOrigin = "anonymous";
  audio.muted = true;
  audio.id = audioID;
  audio.preload = "auto";
  audio.playsInline = true;
  audio.loop = true;

  let currentTime = audioObItem? audioObItem.segment_start? audioObItem.segment_start : 0 : 0;
  audio.addEventListener("loadedmetadata", async (props) => {
    if (!audio && !audio.src) {
      callBack({ status: 'failure', message: 'Audio load failure' })
      return
    }
    
    audio.muted = false;
    //audio.currentTime = 0.0;
    audio.currentTime = currentTime;
    callBack({ status: 'success', message: 'Audio metadata loaded' }, audio)
  })

  audio.addEventListener('error', (error) => {
    console.log(error)
    window.toastr.error('Audio could not be played');
    result = { status: 'failure', message: 'Audio could not be played' };
    callBack(result, audio);
  });

  audio.src = video_url;

  document.body.appendChild(audio);

  return audio;
}

export const getDuration = async (file) => {
  return new Promise((resolve, reject) => {
    let videoUrl = URL.createObjectURL(file)
    const videoPlayer = document.createElement('video');
    videoPlayer.crossOrigin = "anonymous";
    videoPlayer.muted = true;

    videoPlayer.addEventListener('error', (ex) => { reject("error when loading video file", ex); });
    videoPlayer.addEventListener('loadedmetadata', async () => {
      videoPlayer.currentTime = 1000;
      await new Promise(r => setTimeout(r, 1000));
      let duration = videoPlayer.duration;
      resolve(duration);
    })

    videoPlayer.src = videoUrl;
  })
}

export const getVideoAssetFps = async (fileInput) => {
  console.log("A");
  console.log("B");
  let fps = 0;

  const file = fileInput;
  console.log("C");
  if (file) {
    console.log("D");
    const metaResult = await getInfo(file);
    console.log(metaResult);

    fps = metaResult.media.track.find(track => track["@type"] === "Video").FrameRate;

    console.log("THISS IS THE FPS", fps);
    // document.getElementById('fpsOutput').textContent = `FPS: ${fps}`;

    // Show FPS in alert using SweetAlert
    // Swal.fire({
    //   position: "center",
    //   icon: "warning",
    //   title: `FPS: ${fps}`,
    //   showConfirmButton: true,
    //   confirmButtonText: "Ok",
    // });
  }
  return fps;
};

export const getVideoElements = async (projectJSON, callBack) => {
  const tempAssets = projectJSON.asset
  const tempElements = { ...projectJSON.videos, ...projectJSON.audios }
  const videoObjects = await checkVideoData(tempElements, tempAssets);

  const assets = projectJSON.asset;
  const videoKeys = Object.keys(videoObjects);

  let loadedNumber = 0;
  let loadedELItems = {};
  let loadedELItems1 = {};

  videoKeys.forEach(async (videoKey, index) => {
    const videoObjectItem = videoObjects[videoKey];
    const assetKey = videoObjectItem.asset_uuid;

    if (!!assets[assetKey]) {
      const videoSignURL = assets[assetKey].cdn_url;
      // const videoSignURL = videoObjectItem.signed_url
      if (typeof videoObjectItem?.rotation === 'number') {
        let tempVideoItem = await getVideoLoadedData(videoSignURL, videoKey, videoObjectItem, assetKey, (res) => {
          let all_video_elements = document.getElementsByClassName("myElement_videos");
          let all_audio_elements = document.getElementsByClassName("myElement_audios");

          loadedNumber += 1;
          if (res.status === 'failure' || res.status === 'error') loadedELItems[videoKey] = tempVideoItem;
          if (res.status === 'success') loadedELItems[videoKey] = tempVideoItem;

          videoKeys.forEach(innerVideoKey => {
            const innerVideoObjectItem = videoObjects[innerVideoKey];
            if (innerVideoObjectItem.asset_uuid === assetKey) {
              loadedELItems[innerVideoKey] = tempVideoItem;
            }
          });

          if (loadedNumber  === all_video_elements.length + all_audio_elements.length) {
            callBack(loadedELItems, loadedELItems1, 'loaded');
          }
        })
      } else {
        let tempAudioItem = await getAudioLoadedData(videoSignURL, videoKey, videoObjectItem, (res) => {
          let all_video_elements = document.getElementsByClassName("myElement_videos");
          let all_audio_elements = document.getElementsByClassName("myElement_audios");

          loadedNumber += 1;
          if (res.status === 'failure' || res.status === 'error') loadedELItems1[videoKey] = tempAudioItem;
          if (res.status === 'success') loadedELItems1[videoKey] = tempAudioItem;
          if (loadedNumber === all_video_elements.length + all_audio_elements.length) {
            callBack(loadedELItems, loadedELItems1, 'loaded');
          }
        })
      }

    } else loadedNumber += 1;
  });
  let all_video_elements = document.getElementsByClassName("myElement_videos");
  let all_audio_elements = document.getElementsByClassName("myElement_audios");

  // To open empty project, there is no video or audio elements
  if (all_video_elements.length + all_audio_elements.length === 0) {
    callBack(loadedELItems, loadedELItems1, 'loaded');
  }
}

export const getImageElements = async (projectJSON, callBack) => {
  const images = Object.assign({}, projectJSON.images);
  const imgKeys = Object.keys(images);
  const assets = projectJSON.asset;

  let loadedNumber = 0;
  let loadedELItems = {};

  imgKeys.forEach(async (imgKey, index) => {
    const imageObjectItem = images[imgKey];
    const assetsKey = imageObjectItem.asset_uuid;
    if (!!assets[assetsKey]) {
      const imgSignURL = assets[assetsKey].cdn_url;
      await getImageLoaded(imgSignURL, imgKey, (img) => {
        loadedNumber += 1;
        if (!!img) loadedELItems[imgKey] = img;
        if (loadedNumber === imgKeys.length) callBack(loadedELItems, 'loaded');
      });
    } else loadedNumber += 1;

    if (loadedNumber === imgKeys.length) callBack(loadedELItems, 'loaded');
  });

  if (!imgKeys.length) callBack(loadedELItems, 'loaded');

}

export const getVideoWebGLElements = async (VideoUUID, projectJSON, videoElements, webGLContext) => {
  const videos = projectJSON.videos;
  const canvas = webGLContext.webGLRef;
  const gl = getGLContext(canvas);
  const moveableDiv = new MoveableDiv();

  let tempMoveableDivs = {};
  VideoUUID.forEach((videoKey) => {
    let tempBuffer = InitBuffers(gl);
    let tempTexture = initVideoTexture(gl);
    let TempMoveableItem = moveableDiv.divCreate(videoKey);
    const VideoUUIDItem = videos[videoKey];
    const TempVideoItem = videoElements[videoKey];

    const width = TempVideoItem?.videoWidth;
    const height = TempVideoItem?.videoHeight;
    if (!width || !height) return;

    tempBuffer = UpdateServerBuffers(tempBuffer, width, height, canvas);
    TempMoveableItem = moveableDiv.divDragResizeRotationUpdate(TempMoveableItem, VideoUUIDItem, canvas, projectJSON);
    TempMoveableItem.style.border = '2px dashed green';

    tempMoveableDivs[videoKey] = TempMoveableItem;
    let currentMatrix = mat4.create(); currentMatrix[11] = -5;
    currentMatrix = DragResizeInitialCalculator(VideoUUIDItem, canvas, currentMatrix, projectJSON);
    currentMatrix = RotationInitialCalculator(VideoUUIDItem.rotation, currentMatrix);

    webGLContext.activeModelViewMatrices.set(videoKey, currentMatrix)
    webGLContext.activeTextures.set(videoKey, tempTexture)
    webGLContext.activeBuffers.set(videoKey, tempBuffer)
  })

  return [tempMoveableDivs];
}

export const getTextWebGLElements = async (TextUUID, projectJSON, webGLContext) => {
  const texts = projectJSON.text;
  const canvas = webGLContext.webGLRef;
  const gl = getGLContext(canvas);
  const moveableDiv = new MoveableDiv();

  let tempMoveableDivs = {};
  TextUUID.forEach(function (textKey) {
    const TextUUIDItem = { ...DefaultTextData, ...texts[textKey] };
    let TempMoveableItem = moveableDiv.inputFieldCreate(textKey);

    let tempTextCanvas = document.createElement('canvas');
    let tempBuffer = InitBuffers(gl);
    tempTextCanvas = SetupServerTextCanvas(TextUUIDItem, tempTextCanvas, projectJSON, canvas);
    const tempTexture = InitTextTexture(gl, tempTextCanvas);

    const SetupParam = [tempBuffer, TempMoveableItem, TextUUIDItem, webGLContext, textKey, canvas, projectJSON];
    [tempBuffer, TempMoveableItem] = SetupServerText(...SetupParam);
    tempMoveableDivs[textKey] = TempMoveableItem;

    let ActiveMatrice = mat4.create(); ActiveMatrice[11] = -5;
    ActiveMatrice = DragResizeInitialCalculator(TextUUIDItem, canvas, ActiveMatrice, projectJSON);
    ActiveMatrice = RotationInitialCalculator(TextUUIDItem.rotation, ActiveMatrice);

    webGLContext.activeModelViewMatrices.set(textKey, ActiveMatrice);
    webGLContext.activeTextCanvases.set(textKey, tempTextCanvas);
    webGLContext.activeBuffers.set(textKey, tempBuffer);
    webGLContext.activeTextures.set(textKey, tempTexture);
  });

  return [tempMoveableDivs];
}

export const getImageWebGLElements = async (imgKeys, projectJSON, imageElements, webGLContext) => {
  const images = Object.assign({}, projectJSON.images);
  const canvas = webGLContext.webGLRef;
  const gl = getGLContext(canvas);
  const moveableDiv = new MoveableDiv();
  
  let tempMoveableDivs = {};
  imgKeys.forEach((imgKey) => {
    let tempBuffer = InitBuffers(gl);
    let tempTexture = initVideoTexture(gl);
    let TempMoveableItem = moveableDiv.divCreate(imgKey);
    const ImageUUIDItem = images[imgKey];
    const TempImageItem = imageElements[imgKey];

    const width = TempImageItem?.naturalWidth;
    const height = TempImageItem?.naturalHeight;
    if (!width || !height) return;

    tempBuffer = UpdateServerBuffers(tempBuffer, width, height, canvas);
    TempMoveableItem = moveableDiv.divDragResizeRotationUpdate(TempMoveableItem, ImageUUIDItem, canvas, projectJSON);
    TempMoveableItem.style.border = '2px dashed green';

    tempMoveableDivs[imgKey] = TempMoveableItem;
    let currentMatrix = mat4.create(); currentMatrix[11] = -5;
    currentMatrix = DragResizeInitialCalculator(ImageUUIDItem, canvas, currentMatrix, projectJSON);
    currentMatrix = RotationInitialCalculator(ImageUUIDItem.rotation, currentMatrix);

    webGLContext.activeModelViewMatrices.set(imgKey, currentMatrix)
    webGLContext.activeTextures.set(imgKey, tempTexture)
    webGLContext.activeBuffers.set(imgKey, tempBuffer)
  })

  return [tempMoveableDivs];
}

export const ResetServerInformation = async (videoKeys, textKeys, imageKeys, projectJSON, canvas) => {
  const canvasW = canvas.offsetWidth;
  const ratio = canvasW / projectJSON.width;

  const videosOb = Object.assign({}, projectJSON.videos);
  const imagesOb = Object.assign({}, projectJSON.images);
  const textOb = Object.assign({}, projectJSON.text);

  videoKeys.forEach(function (videoKey) {
    let videoItem = videosOb[videoKey];
    const ratioWH = videoItem.ratioWH;
    const ratioW = projectJSON.width / ratioWH.w;
    const ratioH = projectJSON.height / ratioWH.h;

    const W = videoItem.dimension.w * ratio * ratioW;
    const H = videoItem.dimension.h * ratio * ratioH;
    const X = videoItem.coordinate.x * ratio * ratioW;
    const Y = videoItem.coordinate.y * ratio * ratioH;

    // const InputEL = document.getElementById(`${videoKey}`);
    // if (!!InputEL) {
    //   InputEL.style.width = `${W}px`;
    //   InputEL.style.height = `${H}px`;
    //   InputEL.style.left = `${X}px`;
    //   InputEL.style.top = `${Y}px`;
    // }
  })

  imageKeys.forEach(function (imagekey) {
    let imageItem = imagesOb[imagekey];
    const ratioWH = imageItem.ratioWH;
    const ratioW = projectJSON.width / ratioWH.w;
    const ratioH = projectJSON.height / ratioWH.h;

    const W = imageItem.dimension.w * ratio * ratioW;
    const H = imageItem.dimension.h * ratio * ratioH;
    const X = imageItem.coordinate.x * ratio * ratioW;
    const Y = imageItem.coordinate.y * ratio * ratioH;

    const InputEL = document.getElementById(`${imagekey}`);
    if (!!InputEL) {
      InputEL.style.width = `${W}px`;
      InputEL.style.height = `${H}px`;
      InputEL.style.left = `${X}px`;
      InputEL.style.top = `${Y}px`;
    }
  })

  textKeys.forEach((textkey) => {
    let textItem = { ...DefaultTextData, ...textOb[textkey] };
    const ratioWH = textItem.ratioWH;
    const ratioW = projectJSON.width / ratioWH.w;
    const ratioH = projectJSON.height / ratioWH.h;

    const W = textItem.dimension.w * ratio * ratioW;
    const H = textItem.dimension.h * ratio * ratioH;
    const X = textItem.coordinate.x * ratio * ratioW;
    const Y = textItem.coordinate.y * ratio * ratioH;

    const InputEL = document.getElementById(`${textkey}`);
    if (!!InputEL) {
      window.a = InputEL;
      InputEL.style.width = `${W}px`;
      InputEL.style.height = `${H}px`;
      InputEL.style.left = `${X}px`;
      InputEL.style.top = `${Y}px`;
    }
  });
};

export const getNewTextInformation = async (property, projectJSON, groupID, zIndex) => {
  const textInfo = Object.assign({}, DefaultTextData);
  const [textContext, textElementW, textElementH] = getFitContext(property, projectJSON)
  const [divStyleTop, divStyleLeft] = getCenterStyle(textElementH, textElementW, projectJSON);

  let textELDuration = 3000;
  let canvasW = projectJSON.width;
  let canvasH = projectJSON.height;

  return {
    ...textInfo,
    context: textContext,
    contextutf16: convertStringtoWStringUTF16(textContext),
    duration: parseInt(textELDuration),
    timeline_end: parseInt(textELDuration),
    coordinate: {
      ...textInfo.coordinate,
      y: divStyleTop,
      x: divStyleLeft,
      zIndex: zIndex,
    },
    dimension: {
      ...textInfo.dimension,
      h: textElementH,
      w: textElementW,
    },
    properties: {
      ...textInfo.properties,
      ...property.properties,
    },
    ratioWH: {
      w: canvasW,
      h: canvasH,
    },
    groups: groupID,
  }
};

export const getTextWidth = (text, options) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  context.font = `${options.fontWeight} ${options.fontSize} ${options.font}`;
  const metrics = context.measureText(text);

  return metrics;
}

export const getFitContext = (property, projectJSON) => {
  let bold = property.properties.bold;
  let font_family = property.properties.font_family;
  let font_size = property.properties.font_size * 3;
  let options = { font: font_family, fontSize: font_size + 'px', fontWeight: bold };
  let size = getTextWidth(property.context, options)

  let textContext = '';
  let canvasW = projectJSON.width;
  let textElementH = font_size * 1.3;
  let textElementW = size.width + 60;

  let length = 0, textWidth = 0, tempText = '';
  let texts = property.context.replaceAll('?entered!*-', ' ').split(' ');

  texts.forEach((text, key) => {
    let rowText = tempText;
    if (!!tempText) rowText += ' '; rowText += text;

    let textSize = getTextWidth(rowText, options)
    let rowWidth = textSize.width + 60;

    if (rowWidth >= canvasW || texts.length === key + 1) {
      length += 1;
      let newRowText = ''
      if (!!textContext) textContext += '?entered!*-'
      if (rowText.split(' ').length === 1) { newRowText = rowText; tempText = ''; }
      else { newRowText = tempText; tempText = text; }
      textContext += newRowText;

      let rowSize = getTextWidth(newRowText, options)
      let tempWidth = rowSize.width + 60;
      if (textWidth < tempWidth) textWidth = tempWidth;

      if (rowText.split(' ').length !== 1 && texts.length === key + 1) {
        tempText = '';
        let tempRtext = newRowText + ' ' + text;
        let tempRsize = getTextWidth(tempRtext, options);
        let tempRwidth = tempRsize.width + 60;

        if (tempRwidth <= canvasW) {
          textContext += ' ' + text;
          if (textWidth < tempRwidth) {
            textWidth = tempRwidth;
          }
        } else { textContext += '?entered!*-' + text; length += 1; }
      }
    } else tempText = rowText;
  })

  textElementW = textWidth;
  textElementH *= length;

  return [textContext, textElementW, textElementH]
}

export const getCenterStyle = (textElementH, textElementW, projectJSON) => {
  const params = [textElementH, textElementW, projectJSON.width, projectJSON.height]

  const moveableDiv = new MoveableDiv()
  return moveableDiv.divPositionTopLeftCalculator(...params)
}

export const getNewVideoInformation = async (projectJSON, videoFile, videoType, duration, fps, newUUID, projectUUID, videoLength, zIndex, callBack, localThumbnailCallback, uploadCallback, loadCancel, uploading) => {
  let videoInfo = copyObject(DefaultVideoData)
  const newVideoURL = URL.createObjectURL(videoFile);
  const originalFileName = videoFile.name;
  const mimeType = videoFile.type;

  const CreateAsset_CAresult = await CreateAsset();
  if (!CreateAsset_CAresult || CreateAsset_CAresult.state === 'error') window.toastr.error(CreateAsset_CAresult.data.message);
  if (CreateAsset_CAresult.state !== 'success' || !CreateAsset_CAresult.data.uuid) return;
  const assetUUID = CreateAsset_CAresult.data.uuid;
  videoInfo.asset_uuid = assetUUID;

  getVideoLoadedData(newVideoURL, newUUID, videoInfo, assetUUID, async (res, videoPlayer) => {
    loadCancel();
    if (res.status === 'failure' || res.status === 'error') console.log(res.message);
    if (res.status !== 'success') return;

    //const LengthRatio = !videoLength ? 1 : 5;
    const LengthRatio = 1;
    const canvasW = projectJSON.width / LengthRatio;
    const canvasH = projectJSON.height / LengthRatio;
    const videoPlayerW = videoPlayer.videoWidth;
    const videoPlayerH = videoPlayer.videoHeight;

    if (videoPlayerW === 0 || videoPlayerH === 0) window.toastr.error('Video Type Error');
    if (videoPlayerW === 0 || videoPlayerH === 0) return;

    const moveableDiv = new MoveableDiv();
    const [divHeight, divWidth] = moveableDiv.divHeightWidthCalculator(videoPlayerH, videoPlayerW, canvasW, canvasH);
    const [divStyleTop, divStyleLeft] = moveableDiv.divPositionTopLeftCalculator(divHeight, divWidth, canvasW, canvasH);

    videoInfo.duration = parseInt(duration * 1000) // convert to milliseconds
    videoInfo.fps      = fps;
    
    videoInfo.coordinate = { ...videoInfo.coordinate, y: divStyleTop, x: divStyleLeft, zIndex: zIndex }
    videoInfo.dimension = { ...videoInfo.dimension, h: divHeight, w: divWidth }
    videoInfo.ratioWH = { w: projectJSON.width, h: projectJSON.height };
    videoInfo.signed_url = newVideoURL;
    console.log('updating videos', projectJSON);
    const vKeys = projectJSON.videos? Object.keys(projectJSON.videos) : [];
    //videoInfo.groups = newUUID;
    let isNewGroup = false;
    if(videoType == "record"){
      //videoInfo.timeline_start = Time_Count.getTime()
      videoInfo.timeline_end = parseInt(duration * 1000)
      videoInfo.segment_end = parseInt(duration * 1000)
      videoInfo.groups = newUUID;
      isNewGroup = true;
    }else if(vKeys.length > 0){
      videoInfo.timeline_start = parseInt(projectJSON.duration)
      videoInfo.timeline_end = parseInt(projectJSON.duration) + parseInt(duration * 1000)
      videoInfo.segment_end = parseInt(duration * 1000)
      videoInfo.groups = projectJSON.videos[vKeys[0]].groups;
    }else{
      //videoInfo.timeline_start = Time_Count.getTime()
      videoInfo.timeline_end = parseInt(duration * 1000)
      videoInfo.segment_end = parseInt(duration * 1000)
      videoInfo.groups = newUUID;
      isNewGroup = true;
    }

    const thumbNails = await getThumbnailsInfo(duration);
    const thumbnailInfo = thumbNails.map(th => ({
      timestamp: th.time,
      content_type: th.content_type,
      url: null
    }))

    const CAresult = await UpdateAssets(assetUUID, projectUUID, originalFileName, mimeType, 1, JSON.stringify(thumbnailInfo));
    if (!CAresult || CAresult.state === 'error') window.toastr.error(CAresult.data.message);
    if (CAresult.state !== 'success' || !CAresult.data.uuid) return;
    
    if (CAresult.data.filename) {
      videoInfo.asset_filename = CAresult.data.filename;
      videoInfo.asset_extension = CAresult.data.filename.split(".").reverse()[0]
    }
    videoInfo.speed = 1.0;
    callBack(videoPlayer, videoInfo, isNewGroup);

    const thumbNailFiles = await getThumbnails(videoFile);
    const newLocalThumbs = {}

    if (thumbNailFiles.length) {
      thumbNailFiles.map((thumbItem, index) => {
        const thumbnails = Object.values(CAresult.data.thumbnail_info_json);
        const uuid = thumbnails[index].uuid;
        if(uuid) {
          newLocalThumbs[uuid] = {
            url: URL.createObjectURL(thumbItem.file),
            timestamp: thumbItem.time.toFixed(1)
          }
        }
      })
    }

    localThumbnailCallback({
      [assetUUID]: {
          ...DefaultAssetData,
          cdn_url: CAresult.data.signedurl,
          signed_url: CAresult.data.signedurl,
          type: CAresult.data.category,
          localThumbnail: {...newLocalThumbs}
        }
    })
    
    
    const thumbs = Object.values(CAresult.data.thumbnail_info_json);
    const uploadThumbnailResult = [];
    for(let i = 0; i < thumbs.length; i ++) {
      const uploadStatus = await uploadThumbnail(thumbs[i].signedurl, thumbNailFiles[i].file);
      if (uploadStatus.state === 'success') uploadThumbnailResult[i] = thumbs[i];
    }

    const newthumbs = {}
    uploadThumbnailResult.sort((a,b) => a.timestamp - b.timestamp).forEach((thumbItem) => {
      newthumbs[thumbItem.uuid] = {
        signed_url: thumbItem.signedurl,
        timestamp: thumbItem.timestamp
      }
    })
    
    const newAssets = {
      [assetUUID]:  {
        ...DefaultAssetData,
        cdn_url: CAresult.data.signedurl,
        signed_url: CAresult.data.signedurl,
        type: CAresult.data.category,
        thumbnail: { ...newthumbs }
      }
    } 

    await uploadCallback({ [assetUUID]: newAssets[assetUUID] }, assetUUID);
    await blob_Upload(newVideoURL, mimeType, projectUUID, assetUUID, uploading, false, CAresult);
    await measureFileSize(assetUUID);
  });
}

export const getNewAudioInformation = (projectJson, audiofile, duration, newUUID, projectUUID, callBack, uploadCallback, loadCancel, uploading) => {
  let audioInfo = copyObject(defaultAudioData)
  const newAudioURL = URL.createObjectURL(audiofile);
  const originalFileName = audiofile.name;
  const mimeType = audiofile.type;

  getAudioLoadedData(newAudioURL, newUUID, audioInfo, async (res, audioPlayer) => {
    loadCancel();

    if (res.status !== 'success') {
      console.log(res.message);
      return;
    }

    audioInfo.duration = parseInt(duration * 1000) // convert to milliseconds
    audioInfo.timeline_end = parseInt(duration * 1000)
    audioInfo.segment_end = parseInt(duration * 1000)
    audioInfo.signed_url = newAudioURL;
    const audioKeys = Object.keys(projectJson.audios);
    if(audioKeys.length > 0){
      audioInfo.groups = projectJson.audios[audioKeys[0]].groups;
    }else{
      audioInfo.groups = newUUID;
    }
    // audioInfo.groups = newUUID;

    const CreateAsset_CAresult = await CreateAsset();
    if (!CreateAsset_CAresult || CreateAsset_CAresult.state === 'error') window.toastr.error(CreateAsset_CAresult.data.message);
    if (CreateAsset_CAresult.state !== 'success' || !CreateAsset_CAresult.data.uuid) return;
    const assetUUID = CreateAsset_CAresult.data.uuid;
    audioInfo.asset_uuid = assetUUID;
    
    const CAresult = await UpdateAssets(assetUUID, projectUUID, originalFileName, mimeType, 2);
    if (!CAresult || CAresult.state === 'error') window.toastr.error(CAresult.data.message);
    if (CAresult.state !== 'success' || !CAresult.data.uuid) return;

    if (CAresult.data.filename) {
      audioInfo.asset_filename = CAresult.data.filename;
      audioInfo.asset_extension = CAresult.data.filename.split(".").reverse()[0]
    }

    callBack(audioPlayer, audioInfo);

    await blob_Upload(newAudioURL, mimeType, projectUUID, assetUUID, uploading, true, CAresult);
    const newAssets = {
      [assetUUID]:  {
        ...DefaultAssetData,
        cdn_url: CAresult.data.signedurl,
        signed_url: CAresult.data.signedurl,
        type: CAresult.data.category,
      }
    } 
    uploadCallback({ [assetUUID]: newAssets[assetUUID] }, assetUUID);
    await measureFileSize(assetUUID);
  })
}

export const getNewAudioStockInformation = (projectJson, audioLink, duration, newUUID, projectUUID, callBack, uploadCallback, loadCancel) => {
  let audioInfo = copyObject(defaultAudioData)
  // const newAudioURL = URL.createObjectURL(audiofile);
  const originalFileName = audioLink;
  const mimeType = 'audio/mp3';

  getAudioLoadedData(audioLink, newUUID, audioInfo, async (res, audioPlayer) => {
    loadCancel();

    if (res.status !== 'success') {
      console.log(res.message);
      return;
    }

    audioInfo.duration = parseInt(duration * 1000) // convert to milliseconds
    audioInfo.timeline_end = parseInt(duration * 1000)
    audioInfo.segment_end = parseInt(duration * 1000)
    audioInfo.signed_url = audioLink;
    const audioKeys = Object.keys(projectJson.audios);
    if(audioKeys.length > 0){
      audioInfo.groups = projectJson.audios[audioKeys[0]].groups;
    }else{
      audioInfo.groups = newUUID;
    }

    const CreateAsset_CAresult = await CreateAsset();
    if (!CreateAsset_CAresult || CreateAsset_CAresult.state === 'error') window.toastr.error(CreateAsset_CAresult.data.message);
    if (CreateAsset_CAresult.state !== 'success' || !CreateAsset_CAresult.data.uuid) return;
    const assetUUID = CreateAsset_CAresult.data.uuid; 
    audioInfo.asset_uuid = assetUUID; 
    
    const CAresult = await UpdateAssets(assetUUID, projectUUID, originalFileName, mimeType, 9);
    if (!CAresult || CAresult.state === 'error') window.toastr.error(CAresult.data.message);
    if (CAresult.state !== 'success' || !CAresult.data.uuid) return;
  
    if (CAresult.data.filename) {
      audioInfo.asset_filename = CAresult.data.filename;
      audioInfo.asset_extension = CAresult.data.filename.split(".").reverse()[0]
    }

    callBack(audioPlayer, audioInfo);

    // await blob_Upload(audioLink, mimeType, projectUUID, assetUUID, uploading, true, CAresult);
    const newAssets = {
      [assetUUID]:  {
        ...DefaultAssetData,
        cdn_url: CAresult.data.signedurl,
        signed_url: CAresult.data.signedurl,
        type: CAresult.data.category,
      }
    } 
    uploadCallback({ [assetUUID]: newAssets[assetUUID] }, assetUUID);
    await measureFileSize(assetUUID);
  })
}

export const getImageInformation = async (projectJSON, originalFileName, mimeType, imagefile, newUUID, projectUUID, type, zIndex, callBack, uploadCallback, loadCancel, uploading) => {

  let imageIfo = Object.assign({}, DefaultImageData);
  const typeRatio = type === 'emoji' ? 2 : 1;
  const canvasW = projectJSON.width;
  const canvasH = projectJSON.height;
  const imageDuration = 3000;

  await getImageLoaded(imagefile, newUUID, async (img) => {
    setTimeout(() => { loadCancel(); }, 100);
    if (!img) return;

    const imageElementW = img.naturalWidth;
    const imageElementH = img.naturalHeight;
    let imgGroupId = newUUID;
    const moveableDiv = new MoveableDiv();
    const [divHeight, divWidth] = moveableDiv.divHeightWidthCalculator(imageElementH, imageElementW, canvasW, canvasH);
    const [divStyleTop, divStyleLeft] = moveableDiv.divPositionTopLeftCalculator(divHeight, divWidth, canvasW, canvasH);
    const imgKeys = Object.keys(projectJSON.images);
    if(imgKeys.length > 0){
      imgGroupId = imgKeys[0];
    }
    
    imageIfo.duration = imageDuration;
    imageIfo.timeline_end = imageDuration;
    imageIfo.dimension = { ...imageIfo.dimension, h: divHeight / typeRatio, w: divWidth / typeRatio };
    imageIfo.coordinate = { ...imageIfo.coordinate, y: divStyleTop, x: divStyleLeft, zIndex: zIndex };
    imageIfo.ratioWH = { w: canvasW, h: canvasH };
    imageIfo.groups = imgGroupId;

    const CreateAsset_CAresult = await CreateAsset();
    if (!CreateAsset_CAresult || CreateAsset_CAresult.state === 'error') window.toastr.error(CreateAsset_CAresult.data.message);
    if (CreateAsset_CAresult.state !== 'success' || !CreateAsset_CAresult.data.uuid) return;
    const assetUUID = CreateAsset_CAresult.data.uuid;
    imageIfo.asset_uuid = assetUUID;

    const CAresult = await UpdateAssets(assetUUID, projectUUID, originalFileName, mimeType, 3);
    if (!CAresult || CAresult.state === 'error') window.toastr.error(CAresult.data.message);
    if (CAresult.state !== 'success' || !CAresult.data.uuid) return;

    if (CAresult.data.filename) {
      imageIfo.asset_filename = CAresult.data.filename;
      imageIfo.asset_extension = CAresult.data.filename.split(".").reverse()[0];
    }

    callBack(img, imageIfo);

    const [status, newAssets, asset_uuid] = await image_Upload(img.src, originalFileName, mimeType, projectUUID, assetUUID, uploading, CAresult);
    if (status === 'failure' || status !== 'success' || !newAssets[assetUUID]) return;
    uploadCallback({ [assetUUID]: newAssets[assetUUID] }, assetUUID);
    await measureFileSize(assetUUID);
  });
}

const image_Upload = async (blobURL, originalFileName, mimeType, projectUUID, assetUUID, uploading, assetResult) => {
  try {
    let blob = await fetch(blobURL).then(r => r.blob());
    let file = new File([blob], originalFileName, { type: mimeType, lastModified: new Date().getTime() });

    if (!assetResult || assetResult.state === 'error') window.toastr.error(assetResult.data.message);
    if (assetResult.state !== 'success') return 'failure';

    const assetsData = assetResult.data;
    if (assetsData.code) window.toastr.error(assetsData.code);
    else if (assetsData.uuid === assetUUID) {
      const signedurl = assetsData.signedurl;
      const uploadStatus = await UploadVideo(file, signedurl, uploading);
      if (uploadStatus.state !== 'success') return ['failure'];

      let newAssets = {};
      newAssets[assetUUID] = {
        ...DefaultAssetData,
        cdn_url: signedurl,
        signed_url: signedurl,
        type: assetsData.category
      }

      return ['success', newAssets, assetUUID];
    } else { window.toastr.error('access denied'); };
    if (assetsData.uuid !== assetUUID) return ['failure'];

    return ['', '', '']
  } catch (err) {
    console.log(err.message)
    return ['failure'];
  }
}

const getImageLoaded = async (url, id, callBack) => {
  let state = false;
  let img = new Image()
  img.setAttribute('crossorigin', 'anonymous');

  img.onload = async function () {
    if (!state) img.src = await getImageUrl(img);
    else callBack(img)
    if (!state) state = true
  }

  img.onerror = function () {
    alert('not error');
    window.toastr.error('Image could not be loaded')
    callBack(null)
  }
  // img.crossOrigin = "anonymous";
  img.id = id;
  img.src = url;
}

const getImageUrl = async (img) => {
  let canvas = document.createElement("canvas")
  let w = img.naturalWidth
  let h = img.naturalHeight

  canvas.width = w; canvas.height = h
  let ctx = canvas.getContext("2d")

  ctx.fillStyle = "#00000000"
  ctx.fillRect(0, 0, w, h)

  ctx.drawImage(img, 0, 0)
  let dataURL = canvas.toDataURL("image/jpg")

  return dataURL;
}

const blob_Upload = async (blobURL, mimeType, projectUUID, assetUUID, uploading, flag, assetResult) => {
  try {
    let blob = await fetch(blobURL).then(r => r.blob());
    let file = new File([blob], "thisVideo.mp4", { type: mimeType, lastModified: new Date().getTime() });

    if (!assetResult || assetResult.state === 'error' || assetResult.state !== 'success') return window.toastr.error(assetResult.data.message);

    const assetsData = assetResult.data;
    if (assetsData.code) {
      window.toastr.error(assetsData.code);
    } else if (assetsData.uuid === assetUUID) {
      const signedurl = assetsData.signedurl;
      await UploadVideo(file, signedurl, uploading);
    }
  } catch (error) {
    console.log(error)
  }
}
    
const uploadThumbnailAction = async (file, assetUUID) => {
  let uploadThumbnailResult = [];
  let thumbNails = await getThumbnails(file);
  const timestamps = thumbNails.map(th => th.time.toFixed(1));
  const thumbnailPackResp = await createThumbnail(assetUUID, timestamps, thumbNails.length,);
  const pack = thumbnailPackResp.data;
  const thumbs = Object.values(pack);
  for(let i = 0; i < thumbs.length; i ++) {
    thumbNails[i].uuid = thumbs[i].uuid
    const uploadStatus = await uploadThumbnail(thumbs[i].signedurl, thumbNails[i].file);
    if (uploadStatus.state === 'success') uploadThumbnailResult[i] = thumbs[i];
  }

  return {
    thumbNails: uploadThumbnailResult,
    localThumbnails: thumbNails
  };
}

export const getThumbnailsInfo = (duration) => {
  let tempThumbnails = [];
  let length = 1;
  switch (true) {
    case duration > 100: length = 10; break;
    case duration > 3: length = 5; break;
    default: length = 1; break;
  }

  for (let i = 0; i < length; i++) {
    const timestamp = duration / length * i + 0.1;

    tempThumbnails.push({ time: timestamp, content_type: "blob" });
  }
  return tempThumbnails
}

export const getThumbnails = async (file, flag) => {
  let tempThumbnails = [];
  return new Promise((resolve, reject) => {
    let videoUrl = URL.createObjectURL(file)
    const videoSource = document.createElement('source');
    videoSource.src = videoUrl;
    const videoPlayer = document.createElement('video');
    videoPlayer.crossOrigin = "anonymous";
    videoPlayer.muted = true;
    videoPlayer.preload="metadata"
    videoPlayer.appendChild(videoSource);
    videoPlayer.addEventListener('error', (ex) => { reject("error when loading video file", ex); });
    videoPlayer.addEventListener('loadedmetadata', async () => {
      videoPlayer.currentTime = 1000;
      await new Promise(r => setTimeout(r, 1000));

      let length = 1;
      let duration = videoPlayer.duration;
      if (!flag) {
        switch (true) {
          case duration > 100: length = 10; break;
          case duration > 3: length = 5; break;
          default: length = 1; break;
        }
      } else length = flag;

      for (let i = 0; i < length; i++) {
        const timestamp = duration / length * i + 0.1;
        videoPlayer.currentTime = timestamp;
        await new Promise(r => setTimeout(r, 500));

        const blobImage = await GetVideoCover(videoPlayer, timestamp);
        const ratio = videoPlayer.videoWidth / videoPlayer.videoHeight;
        const newImageFile = new File([blobImage], "thisVideo.png", { type: "image/png", lastModified: new Date().getTime() });
        let convertedFile = newImageFile;
        if(videoPlayer.videoWidth > videoPlayer.videoHeight && videoPlayer.videoWidth > THUMBNAIL_WIDTH) {
          convertedFile = await resizeImage(newImageFile, THUMBNAIL_WIDTH, THUMBNAIL_WIDTH / ratio);
        }

        if(videoPlayer.videoHeight > videoPlayer.videoWidth && videoPlayer.videoHeight > THUMBNAIL_WIDTH) {
          convertedFile = await resizeImage(newImageFile, THUMBNAIL_WIDTH * ratio, THUMBNAIL_WIDTH);
        }

        tempThumbnails.push({ file: convertedFile, time: timestamp, content_type: "blob" });
        if (tempThumbnails.length === length) resolve(tempThumbnails);
      }
    })

  //  videoPlayer.src = videoUrl;
    
  })
}

export const GetVideoCover = (videoPlayer, seekTo = 0.0) => {
  return new Promise((resolve, reject) => {
    let changedTime = false;
    if (videoPlayer.duration < seekTo) seekTo = videoPlayer.duration;
    videoPlayer.addEventListener('timeupdate', () => {
      if (changedTime) return; changedTime = true;

      const canvas = document.createElement("canvas");
      canvas.width = videoPlayer.videoWidth;
      canvas.height = videoPlayer.videoHeight;
       // draw the video frame to canvas
      const ctx = canvas.getContext("2d");
      ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
      ctx.canvas.toBlob(blob => { resolve(blob); videoPlayer.remove() }, "image/jpeg", 0.75 /* quality */);
    });

    videoPlayer.currentTime = seekTo;
  });
}


export const getGLContext = (canvas) => {
  const options = { premultipliedAlpha: false }
  return canvas.getContext("webgl", options)
}

export const toDataURL = url => fetch(url)
  .then(response => response.blob())
  .then(blob => new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onloadend = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  }))

export const dataURLtoFile = (dataurl, filename) => {
  let arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
  bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
  u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type:mime});
 }

 export const toFileObjFromURL = async (url, ext) => {
  const dataUrl = await toDataURL(url);
  return dataURLtoFile(dataUrl, `file.${ext}`);
  // toDataURL(url)
  // .then(dataUrl => {
  //    const fileData = dataURLtoFile(dataUrl, `file.${ext}`);

  //  })
 }

 export const getNewStockInformation = async (projectUUID, projectJSON, asset_uuid, stock_uuid, signed_url, stockData, videoLength, zIndex, callBack) => {
  let videoInfo = copyObject(DefaultVideoData)
  let mimeType = "video/mp4";
  const CreateAsset_CAresult = await CreateAsset(projectUUID, signed_url, mimeType, 9);
  if (!CreateAsset_CAresult || CreateAsset_CAresult.state === 'error') window.toastr.error(CreateAsset_CAresult.data.message);
  if (CreateAsset_CAresult.state !== 'success' || !CreateAsset_CAresult.data.uuid) return;
  const assetUUID = CreateAsset_CAresult.data.uuid;
  videoInfo.asset_uuid = assetUUID;
  console.log("getNewStockInformation videoInfo.asset_uuid", videoInfo.asset_uuid);

  getVideoLoadedData(signed_url, stock_uuid, videoInfo, assetUUID, async (res, videoPlayer) => {
    const LengthRatio = !videoLength ? 1 : 5;
    const canvasW = projectJSON.width / LengthRatio;
    const canvasH = projectJSON.height / LengthRatio;
    const videoPlayerW = videoPlayer.videoWidth;
    const videoPlayerH = videoPlayer.videoHeight;
    if (videoPlayerW === 0 || videoPlayerH === 0) window.toastr.error('Video Type Error');
    if (videoPlayerW === 0 || videoPlayerH === 0) return;

    const moveableDiv = new MoveableDiv();
    const [divHeight, divWidth] = moveableDiv.divHeightWidthCalculator(videoPlayerH, videoPlayerW, canvasW, canvasH);
    const [divStyleTop, divStyleLeft] = moveableDiv.divPositionTopLeftCalculator(divHeight, divWidth, canvasW, canvasH);

    videoInfo.duration = parseInt(stockData.duration) 
    videoInfo.timeline_end = parseInt(stockData.duration)
    videoInfo.segment_end = parseInt(stockData.duration)
    videoInfo.coordinate = { ...videoInfo.coordinate, y: divStyleTop, x: divStyleLeft, zIndex: zIndex }
    videoInfo.dimension = { ...videoInfo.dimension, h: divHeight, w: divWidth }
    videoInfo.ratioWH = { w: projectJSON.width, h: projectJSON.height };
    videoInfo.signed_url = signed_url;
    // const vKeys = projectJSON.videos? Object.keys(projectJSON.videos) : [];
    // if(vKeys.length > 0){
    //   videoInfo.groups = projectJSON.videos[vKeys[0]].groups;
    //   // let startStockTime = 0;
    //   // vKeys.map(vKeyItem => {
    //   //   if(startStockTime < projectJSON.videos[vKeyItem].timeline_end){
    //   //     startStockTime = projectJSON.videos[vKeyItem].timeline_end;
    //   //   }
    //   // })
    //   // videoInfo.timeline_end = parseInt(stockData.duration) + startStockTime;
    //   // videoInfo.timeline_start = startStockTime;
    // }else{
    //   videoInfo.groups = stock_uuid;
    // }

    const stockFile = await toFileObjFromURL(signed_url, signed_url.split("?")[0].split(".").reverse()[0]);
    const {thumbNails, localThumbnails} = await uploadThumbnailAction(stockFile, asset_uuid);
    const newthumbs = {}
    const newLocalThumbs = {};

    if (thumbNails.length) {
      thumbNails.forEach((thumbItem) => {
        newthumbs[thumbItem.uuid] = {
          signed_url: thumbItem.signedurl,
          timestamp: thumbItem.timestamp
        }
      })
    }

    if (localThumbnails.length) {
      localThumbnails.forEach((thumbItem) => {
        newLocalThumbs[thumbItem.uuid] = {
          url: URL.createObjectURL(thumbItem.file),
          timestamp: thumbItem.time.toFixed(1)
        }
      })
    }

    const CAresult = await UpdateAssets(assetUUID, projectUUID, signed_url, mimeType, 9, JSON.stringify(thumbNails) );
    
    const newAssets = {};
    newAssets[asset_uuid] = {
      ...DefaultAssetData,
      cdn_url: signed_url,
      signed_url: signed_url,
      thumbnail: { ...newthumbs },
      localThumbnail: {...newLocalThumbs}
    }

    callBack(videoPlayer, videoInfo, newAssets)
  })
}

export const getNewPexelsStockInformation = async (projectUUID, stockUUID, projectJSON, signed_url, originalFileName, mimeType, duration, thumbNails, videoLength, zIndex, callBack) => {
  let videoInfo = copyObject(DefaultVideoData)
  videoInfo.asset_uuid = stockUUID;

  const CreateAsset_CAresult = await CreateAsset();
  if (!CreateAsset_CAresult || CreateAsset_CAresult.state === 'error') window.toastr.error(CreateAsset_CAresult.data.message);
  if (CreateAsset_CAresult.state !== 'success' || !CreateAsset_CAresult.data.uuid) return;
  const assetUUID = CreateAsset_CAresult.data.uuid;
  videoInfo.asset_uuid = assetUUID;

  console.log("getNewPexelsStockInformation videoInfo.asset_uuid", stockUUID);
  getVideoLoadedData(signed_url, stockUUID, videoInfo, assetUUID, async (res, videoPlayer) => {
    const LengthRatio = !videoLength ? 1 : 5;
    const canvasW = projectJSON.width / LengthRatio;
    const canvasH = projectJSON.height / LengthRatio;
    const videoPlayerW = videoPlayer.videoWidth;
    const videoPlayerH = videoPlayer.videoHeight;
    if (videoPlayerW === 0 || videoPlayerH === 0) window.toastr.error('Video Type Error');
    if (videoPlayerW === 0 || videoPlayerH === 0) return;

    const moveableDiv = new MoveableDiv();
    const [divHeight, divWidth] = moveableDiv.divHeightWidthCalculator(videoPlayerH, videoPlayerW, canvasW, canvasH);
    const [divStyleTop, divStyleLeft] = moveableDiv.divPositionTopLeftCalculator(divHeight, divWidth, canvasW, canvasH);

    videoInfo.duration = parseInt(duration) 
    videoInfo.timeline_end = parseInt(duration)
    videoInfo.segment_end = parseInt(duration)
    videoInfo.coordinate = { ...videoInfo.coordinate, y: divStyleTop, x: divStyleLeft, zIndex: zIndex }
    videoInfo.dimension = { ...videoInfo.dimension, h: divHeight, w: divWidth }
    videoInfo.ratioWH = { w: projectJSON.width, h: projectJSON.height };
    videoInfo.signed_url = signed_url;
    // videoInfo.groups = stockUUID;
    // const vKeys = projectJSON.videos? Object.keys(projectJSON.videos) : [];
    // if(vKeys.length > 0){
    //   videoInfo.groups = projectJSON.videos[vKeys[0]].groups;
    //   // let startStockTime = 0;
    //   // vKeys.map(vKeyItem => {
    //   //   if(startStockTime < projectJSON.videos[vKeyItem].timeline_end){
    //   //     console.log('timelineEnd', projectJSON.videos[vKeyItem].timeline_end);
    //   //     startStockTime = projectJSON.videos[vKeyItem].timeline_end;
    //   //   }
    //   //   console.log('looping time');
    //   // })
    //   // videoInfo.timeline_end = parseInt(duration) + startStockTime;
    //   // videoInfo.timeline_start = startStockTime;
    // }else{
    //   videoInfo.groups = stockUUID;
    // }

    const CAresult = await UpdateAssets(assetUUID, projectUUID, originalFileName, mimeType, 9, JSON.stringify(thumbNails) );

    console.log("getNewPexelsStockInformation CAresult : " + originalFileName);

    const newAssets = {};
    newAssets[assetUUID] = {
      ...DefaultAssetData,
      cdn_url: signed_url,
      signed_url: signed_url,
    }

    callBack(videoPlayer, videoInfo, newAssets, assetUUID, CAresult.data.thumbnail_info_json)
  })
}

export const resizeImage = (file, width, height) => new Promise(resolve => {
  Resizer.imageFileResizer(file, width, height, 'PNG', 100, 0,
  uri => {
    resolve(uri);
  }, 'file' );
});