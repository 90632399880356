export const DISPLAYSIZECHANGE = 'DISPLAYSIZECHANGE';
export const LOGINSTATE = 'LOGINSTATE';
export const LOADING = 'LOADING';
export const LANGUAGECODE = 'EN';
export const ISANALYZING = 'ISANALYZING';
export const STAGETEXT = 'STAGETEXT';

export const mainConst = {
	DisplaySizeChange: 0,
	loginState: false,
	loading: false,
	languageCode: 'EN',
	isAnalyzing: false,
	stageText: 'Analyzing Transcript...'
};

