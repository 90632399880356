import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid } from '@mui/material';
import TabsUnstyled from '@mui/base/TabsUnstyled';

import { copyObject } from '../commonFunction';
import { filtersList } from './tooltap.config';
import { SectionHeader } from './common/section.header';
import { Tab, TabPanel, TabsList } from './common/section.tabbar';
import { ProjectJSON_Store } from '../../../store/actions/projectdata';
import { FBokeh, FFilmBurn, FSuper8, FVideooPortra, FGlitchParty, FGlitch, FFishEye, FLensFlare } from './common/ImageList';
import { FLightLeaks, FTelevision, FVHSOverlay, FCRT, FVHS, FPolaroid, FOld, FFilmic, FDustyFilm } from './common/ImageList';
import { FStrobe, FNightVision, FThermalVision, FDreamVision, FInvert, FPrisma, FViewfinder } from './common/ImageList';
import { FRGBSplitDream, FRGBSplit, FDisplacementNightmare, FGhostDream, FGhosting, FSnowy, FScanlinesParty } from './common/ImageList';
import { FVignetteSepia, FPixelate, FPixelateandPosterise, FPixelateandBlur, FPosterise, FBokehBlur, FBoxBlur } from './common/ImageList';

const HeaderInfo = { title: 'Filters', className: 'pl-3' }
export const TooltapFilters = () => {
  const dispatch = useDispatch();
  const languageStrs = useSelector((store) => store.editdata.languageStrs)

  const projectJSON = useSelector(store => store.projectdata.projectJSON);
  
  const [activeColor, setActiveColor] = useState('');
  useEffect(() => {
    if (!projectJSON?.filters) return;
    setActiveColor(projectJSON.filters.preset);
  }, [projectJSON])

  const filterFunc = ({ type, color }) => {
    let filters = projectJSON.filters;
    if (filters.preset === type) return;

    let newJSONData = copyObject(projectJSON);
    newJSONData.filters = {
      value: color,
      preset: type
    };
    dispatch(ProjectJSON_Store(newJSONData));
  }

  const [testColor, setTestColor] = useState();
  const testColorChanged = (color) =>{
    setTestColor(color);
    filterFunc({type: 'test', color: color});
  }
 const print_title = (titleKey) => {
  return languageStrs['flt_'+titleKey];
 }
  return (
    <>
      <SectionHeader {...HeaderInfo} />
      <Box md={12} className='px-10 pt-0'>
        <TabsUnstyled defaultValue={0} className='v-FilterTap'>
          <TabsList>
            <Tab>{languageStrs.color} {languageStrs.filters}</Tab>
            {/* <Tab>Effects</Tab> */}
          </TabsList>

          <TabPanel value={0} md={12} className='v-filterTapbody'>
            <Grid container spacing={0}>
              {filtersList.map((filter, key) =>
                <Grid item xs={6} md={4} lg={6} key={key}
                  className={`${activeColor === filter.type && 'v-filter-active'} pr-1 mb-2`}
                >
                  <Box className='v-filtertapBox v-radius v-pointer' onClick={() => { filterFunc(filter) }}>
                    {filter.type !== 'None' && (
                      <img src={filter.img} className='v-filterbox-img' alt='color-grading' />
                    )}
                  </Box>
                  <Typography className='v-filtertitle v-font-2-2 pl-1'>{print_title(filter.title)}</Typography>
                </Grid>
              )}
            </Grid>
          </TabPanel>

          {/* <TabPanel value={1} md={12} className='v-filterTapbody'>
            <Grid container spacing={0}>
              <Grid item xs={6} md={4} lg={6} className='v-filter-active pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>None</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FBokeh} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Bokeh</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FFilmBurn} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Film Burn</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FSuper8} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Super 8</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FVideooPortra} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>VIDEOO Portra</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FGlitchParty} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Glitch Party</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FGlitch} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Glitch</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FFishEye} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Fish Eye</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FLensFlare} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Lens Flare</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FLightLeaks} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Light Leaks</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FTelevision} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Television</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FVHSOverlay} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>VHS Overlay</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FCRT} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>CRT</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FVHS} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>VHS</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FPolaroid} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Polaroid</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FOld} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Old</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FFilmic} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Filmic</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FDustyFilm} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Dusty Film</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FStrobe} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Strobe</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FNightVision} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Night Vision</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FThermalVision} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Thermal Vision</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FDreamVision} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Dream Vision</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FInvert} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Invert</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FPrisma} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Prisma</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FViewfinder} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Viewfinder</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FRGBSplitDream} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>RGB Split Dream</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FRGBSplit} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>RGB Split</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FDisplacementNightmare} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Displacement Nightmare</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FGhostDream} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Ghost Dream</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FGhosting} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Ghosting</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FSnowy} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Snowy</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FScanlinesParty} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Scanlines Party</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FVignetteSepia} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Vignette Sepia</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FPixelate} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Pixelate</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FPixelateandPosterise} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Pixelate and Posterise</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FPixelateandBlur} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Pixelate and Blur</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FPosterise} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Posterise</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FBokehBlur} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Bokeh Blur</Typography>
              </Grid>

              <Grid item xs={6} md={4} lg={6} className='pr-1 mb-2'>
                <Box className='v-filtertapBox v-radius v-pointer'>
                  <img src={FBoxBlur} className='v-filterbox-img' alt='filter-img' />
                </Box>

                <Typography className='v-filtertitle v-font-2-2 pl-1'>Box Blur</Typography>
              </Grid>
            </Grid>
          </TabPanel> */}
        </TabsUnstyled>
      </Box>
    </>
  )
}