import { v4 as uuidv4 } from 'uuid';
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fabric } from "fabric";
import { FabricJSCanvas, useFabricJSEditor } from "fabricjs-react";

import { SectionHeader } from "./common/section.header";
import { Box, Typography, Grid, IconButton, Button, Slider, FormControlLabel, Radio, FormLabel } from "@mui/material";
import { ColorPicker } from './common/colorPicker';

import BrushIcon from '@mui/icons-material/Brush';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import PinchIcon from '@mui/icons-material/Pinch';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import RectangleIcon from '@mui/icons-material/Rectangle';
import Crop54Icon from '@mui/icons-material/Crop54';

import CircleIcon from '@mui/icons-material/Circle';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import DeleteIcon from '@mui/icons-material/Delete';

import { changedJSON_Store, thumbNails_Store, uploadingFiles_Store } from "../../../store/actions/projectdata"; 
import { dataLoaded_Store, imageElements_Store } from "../../../store/actions/webgldata";
import { changeZeroSecond, getMaxZIndex } from "../commonFunction";
import { getImageInformation } from "../webGL/commonFunc";
import { SERVER_STATUS, getProjectJSON } from "../../../axios/ApiProvider";
import { GetProjectUUID } from '../../../assets/script/commonFunction';
import { EmojiCommonUrl, EmojiNames, showingCount } from './tooltap.config';


let tempProjectJSON = null;
let tempLoadingData = null;

export const TooltapfabricDraw = (props) => {
    const languageStrs = useSelector((store) => store.editdata.languageStrs)
    const { editor, onReady } = useFabricJSEditor();
    const history = [];

    const dispatch = useDispatch();
    const projectUUID = GetProjectUUID();
    const HeaderInfo = { title: languageStrs.magic_draw, className: 'pl-3' };
    const uploadingFiles = useSelector((store) => store.projectdata.uploadingFiles);
    const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
    const thumbNails = useSelector(store => store.projectdata.thumbNails);
    const imageElements = useSelector(store => store.webgldata.imageElements);
    const Time_Count = useSelector((store) => store.videodata.Time_Count);
        
    const drawCanvas = useRef();
    //let isErase = false;
    
   // const [drawContext, setDrawContext] = useState(null);
    const [isDrawDown, setIsDrawDown] = useState(true);
    const [isDynamicBrush, setIsDynamicBrush] = useState(false);
    const [brushName, setBrushName] = useState('brush');
    const [drawColor, setDrawColor] = useState('#000000ff')
    const [drawWidth, setDrawWidth] = useState(20);
   

    useEffect(() => {
        if (!editor || !fabric) {
          return;
        }
        console.log("clientWidth", drawCanvas.current.clientWidth);
        editor.canvas.setHeight(drawCanvas.current.clientWidth * (1080/1920));
        editor.canvas.setWidth(drawCanvas.current.clientWidth);
        editor.canvas.renderAll();
        editor.canvas.isDrawingMode = true;
        editor.canvas.freeDrawingBrush.width = drawWidth;
        editor.canvas.freeDrawingBrush.color = drawColor;
        editor.setStrokeColor(drawColor);
    }, [editor?.canvas]);

    useEffect(() => {
      if (!editor || !fabric) {
        return;
      }
      //if(brushName == 'brush'){
        editor.canvas.freeDrawingBrush.color = drawColor;
        editor.setStrokeColor(drawColor);
      //}
      
    }, [drawColor]);

    useEffect(() => {
      if (!editor || !fabric) {
        return;
      }
      editor.canvas.freeDrawingBrush.width = drawWidth
    }, [drawWidth]);

    useEffect(() => {
      if (!editor || !fabric) {
        return;
      }
      if(isDrawDown){
        editor.canvas.isDrawingMode = true;
      }else{
        console.log("DrawSSS");
        editor.canvas.isDrawingMode = false;
      }
      
    }, [isDrawDown])

   useEffect(() => { 
      tempProjectJSON = { ...ProjectJSON };
   
    }, [ProjectJSON]);
  
    useEffect(() => { tempLoadingData = { ...uploadingFiles } }, [uploadingFiles]);
  
    /*useEffect(() => {
      const ctx = drawCanvas.current.getContext("2d");
      setDrawContext(ctx);
  
    }, [])*/
  
    const onDrawFreeArrow = () => {
      const freeArrowSVG = `<svg id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
        <g id="color">
          <path fill="${drawColor}" d="M31.6687,28.2991c-8.1155,0-13.92,5.3219-13.92,15.52V62.69h-6.14V43.4894c0-13.5,7.7484-20.793,20.2484-20.793H51.8389l-10.4-9.6,3.8-4.1,17.8,16.5-17.8,16.5-3.8-4.1,10.4-9.6Z"/>
        </g>
        <g id="line">
          <path fill="none" stroke="${drawColor}" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M31.6687,28.2991c-8.1155,0-13.92,5.3219-13.92,15.52V62.69h-6.14V43.4894c0-13.5,7.7484-20.793,20.2484-20.793H51.8389l-10.4-9.6,3.8-4.1,17.8,16.5-17.8,16.5-3.8-4.1,10.4-9.6Z"/>
        </g>
      </svg>
      `;
      var group = [];
      fabric.loadSVGFromString(freeArrowSVG, function(objects,options) {

          var loadedObjects = new fabric.Group(group);

          loadedObjects.set({
                  left: 100,
                  top: 100,
                  width:175,
                  height:175
          });

          editor?.canvas.add(loadedObjects);
          editor?.canvas.renderAll();

      },function(item, object) {
              object.set('id',item.getAttribute('id'));
              group.push(object);
      })
    }

    const onDrawArrow = () => {
      const arrowSVG = `
      <svg id="emoji" viewBox="0 0 72 72" xmlns="http://www.w3.org/2000/svg">
        <g id="color">
          <polygon fill="${drawColor}" stroke="none" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" points="49.2124,51.5343 67,35.0363 49.2124,18.5382 45.4234,22.6138 55.8191,32.2554 5,32.2554 5,37.8171 55.8191,37.8171 45.4234,47.4587"/>
        </g>
        <g id="hair"/>
        <g id="skin"/>
        <g id="skin-shadow"/>
        <g id="line">
          <polygon fill="none" stroke="${drawColor}" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" points="49.2124,51.5343 67,35.0363 49.2124,18.5382 45.4234,22.6138 55.8191,32.2554 5,32.2554 5,37.8171 55.8191,37.8171 45.4234,47.4587"/>
        </g>
      </svg>
      `;
      var group = [];
      fabric.loadSVGFromString(arrowSVG, function(objects,options) {

          var loadedObjects = new fabric.Group(group);

          loadedObjects.set({
                  left: 100,
                  top: 100,
                  width:175,
                  height:175
          });

          editor?.canvas.add(loadedObjects);
          editor?.canvas.renderAll();

      },function(item, object) {
              object.set('id',item.getAttribute('id'));
              group.push(object);
      })
   
    }

    const onDrawSquare = () => {
      var newCircle = new fabric.Circle({
        left:50,
        top:50,                
        radius:30,
        stroke: drawColor,
        strokeWidth:3,
        fill: drawColor
      })
      editor?.canvas.add(newCircle);
    }
    const onDrawEmptyRectangle = () => {
      var newRect = new fabric.Rect({
        top: 50,
        left: 50,
  
        width: 100,
        height: 100,
        border: 10,
        borderColor: 'black',
        strokeWidth: 5, 
        stroke: 'drawColor',
        fill: 'transparent',
      });
      editor?.canvas.add(newRect);
    }
    const onDrawRectangle = () => {
      var newRect = new fabric.Rect({
        top: 50,
        left: 50,
  
        width: 100,
        height: 100,
        border: 3,
        borderColor: 'black',
        fill: drawColor,
      });
      editor?.canvas.add(newRect);
    }

    const onDrawCircle = () => {
      var newCircle = new fabric.Circle({
        left:50,
        top:50,                
        radius:30,
        stroke: drawColor,
        strokeWidth:3,
        fill: ''
      })
      editor?.canvas.add(newCircle);
    }
 
    const onHandlePointer = () => {
      console.log('Se;ected');
      setIsDrawDown(false);
    }

    const onHandleBrush = () => {
      setIsDrawDown(true);
      ///setBrushName('brush')
    }
  
    const onHandleMagicBrush = () => {
      setBrushName('magic')
    }
  
    const onHandleDynamicBrush = () => {
      setIsDynamicBrush(!isDynamicBrush)
    }
  
    const onHandleErase = () => {
      editor.canvas.remove(editor.canvas.getActiveObject());
      //isErase = true;
      //setIsDynamicBrush(false)
      //setIsErase(true)
    }
  
    const onHandleDrawWidthChange = (e) => {
      setDrawWidth(e.target.value);
    }
  
    const onHandleDrawColor = (color) => {
      setDrawColor(color)
    }
  
    const onHandleClear = () => {
      //editor.canvas._objects.splice(0, editor.canvas._objects.length);
      editor.canvas.clear()
      /*if(drawContext)
      {
        //drawContext.clearRect(0, 0, drawCanvas.current.width, drawCanvas.current.height);
      }*/
    }
  
    const onHandleSave = async () => {
      const svg = editor?.canvas.toSVG();
      const blob = new Blob([svg], {type: 'image/svg+xml'});
      console.info(svg);
      //editor?.canvas.toBlob(async (blob) => {
        const drawImagUrl = URL.createObjectURL(blob);
      
        const newUUID = uuidv4();
        const originalFileName = 'MagicCanvasDraw.png';
        const mimeType = 'image/png';
  
        let fileData = { pc: 0, type: 'image', name: originalFileName, state: false }
        dispatch(uploadingFiles_Store({ ...uploadingFiles, [newUUID]: fileData }));
        dispatch(dataLoaded_Store(false));
  
        let maxZIndex = getMaxZIndex(ProjectJSON);
        let props = [ProjectJSON, originalFileName, mimeType, drawImagUrl, newUUID, projectUUID, 'image', maxZIndex];
  
        await getImageInformation(...props, async(img, imageInfo) => {
            let imgThumbNail = [{uuid: uuidv4(), content_type: 'blob', signed_url: drawImagUrl }];
            dispatch(thumbNails_Store({ ...thumbNails, [imageInfo.asset_uuid]: imgThumbNail }));
            onHandleClear();
            let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
            let startTime = Time_Count.getTime()
            imageInfo = {
              ...imageInfo,
              timeline_start: startTime,
              timeline_end: imageInfo.duration + startTime
            }
            changedJSON.images = {
              [newUUID]: imageInfo
            }
  
            dispatch(imageElements_Store({ ...imageElements, [newUUID]: img }));
            dispatch(changedJSON_Store(changedJSON));
            changeZeroSecond(imageInfo, dispatch);
          }, async (newAssets, assetUUID) => {
            editor.canvas._objects.splice(0, editor.canvas._objects.length);
            let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
            if (!tempProjectJSON.images[newUUID]) return;
  
            const JSONdata = await getProjectJSON(projectUUID);
            const projectdata = JSONdata?.data[projectUUID];
  
            if(JSONdata.state !== 'success' || SERVER_STATUS === 'localhost')
              changedData = { ...changedData, asset: { ...newAssets } };
            else changedData = { ...changedData, asset: projectdata.asset };
            let fileItem = { ...tempLoadingData[newUUID], state: true };
            dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }));
            dispatch(changedJSON_Store(changedData));
            
          }, () => {dispatch(dataLoaded_Store(true));}, (e) => {
            let pc = parseFloat(e.loaded / e.total * 100).toFixed(2);
            let fileItem = { ...tempLoadingData[newUUID], pc: Number(pc) };
            dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
          })
      //});
    }
  
    return (
      <>
        <SectionHeader {...HeaderInfo}/>
        <Grid container spacing={2} padding={2}>
          <Grid item xs={6}>
            <Box className="v-block-header pb-3">
              <Typography className="v-block-header-left">{languageStrs.brush_stroke}</Typography>
            </Box>
            <Box className="p-3 border-1" style={{minHeight: '160px', padding: '20px !important', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <div className="brushDot" style={{width: `${drawWidth}px`, height: `${drawWidth}px`, border: `${Math.ceil(drawWidth/2)}px solid black`}}></div>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box className="v-block-header pb-3">
              <Typography className="v-block-header-left">{languageStrs.tools}</Typography>
            </Box>
            <Box>
              <Grid container spacing={2}>
              
                <Grid item xs={6}>
                  <Button variant="contained" color="success" onClick={() => {onHandlePointer()}} className={!isDrawDown? 'activeBrushBtn':''}>
                    <PinchIcon />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button variant="contained" color="success" onClick={() => {onHandleBrush()}} className={isDrawDown? 'activeBrushBtn':''}>
                    <BrushIcon />
                  </Button>
                </Grid>
                <Grid item xs={6}> 
                  <Button variant="contained" color="success" onClick={() => {onHandleErase()}} >
                    <DeleteIcon />
                  </Button>
                </Grid>
                <Grid item xs={6}> 
                  
                </Grid>
                
                {!isDynamicBrush && <Grid item xs={12}>
                  <Typography className="v-block-header-left">{languageStrs.brush_size}</Typography>
                  <Box >
                    <Slider aria-label="Size" step={10}
                      value={drawWidth}
                      min={10}
                      max={120}
                      onChange={onHandleDrawWidthChange}
                    ></Slider>
                  </Box>
                </Grid>}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} padding={2}>
              <Grid item xs={2}>
                <Button variant="contained" color="success" onClick={() => {onDrawEmptyRectangle()}} style={{minWidth: '50px'}}>
                  <Crop54Icon />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" color="success" onClick={() => {onDrawCircle()}} style={{minWidth: '50px'}}>
                  <PanoramaFishEyeIcon />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" color="success" onClick={() => {onDrawRectangle()}} style={{minWidth: '50px'}} >
                  <RectangleIcon />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" color="success" onClick={() => {onDrawSquare()}} style={{minWidth: '50px'}} >
                  <CircleIcon />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" color="success" onClick={() => {onDrawArrow()}} style={{minWidth: '50px'}} >
                  <TrendingFlatIcon />
                </Button>
              </Grid>
              <Grid item xs={2}>
                <Button variant="contained" color="success" onClick={() => {onDrawFreeArrow()}} style={{minWidth: '50px'}} >
                  <ShortcutIcon />
                </Button>
              </Grid>
            </Grid>
          </Grid>
          {brushName != 'magic' && <Grid item xs={12}>
            <Box className='v-spacebetween'>
              <FormLabel>{languageStrs.draw_color}</FormLabel>
              <Box className='v-center mr-3'>
                <input type="text"
                  className='v-input-text mr-2 v-font-2-2'
                  value={drawColor}
                  onChange={() => { }} disabled />
  
                <ColorPicker
                  Icon={<FormatColorFillIcon className='v-font-3-2 v-colorpicker-icon' />}
                  color={drawColor}
                  change={onHandleDrawColor} />
              </Box>
            </Box>
          </Grid>}
          <Grid item xs={12} ref={drawCanvas}>
            <FabricJSCanvas className="fabricDraw-canvas" onReady={onReady} />
          </Grid>
          {/* <Grid item xs={12}>
            <canvas ref={drawCanvas} width='1920' height='1080' style={{width: '100%', border: '1px solid black'}}></canvas>
          </Grid> */}
          <Grid item xs={12}>
            <Box className="v-spacebetween">
              <Button variant="contained" onClick={() => { onHandleSave() }}>{languageStrs.save}</Button>
              <Button variant="outlined" onClick={() => { onHandleClear() }} >{languageStrs.clear}</Button>
            </Box>
          </Grid>
        </Grid>
      </>
    )
}