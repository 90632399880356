const slideGl =  {
    type: 'slide',
    glsl: `uniform int type; // = 0
    uniform bool In; // = false
    // type: slide to/from which edge, which corner, or center
    // In: if true slide new image in, otherwise slide old image out
    
    #define rad2 rad / 2.0
    
    vec4 transition(vec2 uv)
    {
    vec2 uv0 = uv;
    float rad = In ? progress : 1.0 - progress;
    float xc1, yc1;
    // I used if/else instead of switch in case it's an old GPU
    if (type == 0) { xc1 = .5 - rad2; yc1 = 0.0; }
    else if (type == 1) { xc1 = 1.0 - rad; yc1 = .5 - rad2; }
    else if (type == 2) { xc1 = .5 - rad2; yc1 = 1.0 - rad; }
    else if (type == 3) { xc1 = 0.0; yc1 = .5 - rad2; }
    else if (type == 4) { xc1 = 1.0 - rad; yc1 = 0.0; }
    else if (type == 5) { xc1 = 1.0 - rad; yc1 = 1.0 - rad; }
    else if (type == 6) { xc1 = 0.0; yc1 = 1.0 - rad; }
    else if (type == 7) { xc1 = 0.0; yc1 = 0.0; }
    else if (type == 8) { xc1 = .5 - rad2; yc1 = .5 - rad2; }
    uv.y = 1.0 - uv.y;
    vec2 uv2;
    if ((uv.x >= xc1) && (uv.x <= xc1 + rad) && (uv.y >= yc1) && (uv.y <= yc1 + rad))
        {
        uv2 = vec2((uv.x - xc1) / rad, 1.0 - (uv.y - yc1) / rad);
        return(In ? getToColor(uv2) : getFromColor(uv2));
        }
    return(In ? getFromColor(uv0) : getToColor(uv0));
    }`,
    defaultParams: {type: 0, In: false },
    paramsTypes: {type: 'int', In: 'bool', }
  }

  export default slideGl