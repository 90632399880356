import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button from "@mui/material/Button";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from "@mui/material/Box";
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { v4 as uuidv4 } from 'uuid';

import { CaptureDialog_Store } from "../../../store/actions/editAction"
import { GetProjectUUID } from '../../../assets/script/commonFunction';
import { getProjectJSON, SERVER_STATUS } from '../../../axios/ApiProvider';
import { getMaxZIndex, changeZeroSecond } from '../../../component/video-edit/commonFunction';
import { changedJSON_Store, thumbNails_Store, uploadingFiles_Store, ProjectJSON_Store } from '../../../store/actions/projectdata';
import { getNewVideoInformation, getThumbnails, getDuration, getVideoAssetFps, getVideoLoadedData } from '../../../component/video-edit/webGL/commonFunc';
import { dataLoaded_Store, VideoElements_Store } from '../../../store/actions/webgldata';
import {webGlSizeOptions} from "../../../component/video-edit/videoEdit.config"

import videopost from "../../../assets/image/videoplaceholder.jpg"

let tempVideoEls = null;
let tempProjectJSON = null;
let tempLoadingData = null;

const webcamResolutions = [
  {
    text: "240p",
    value: "320:240",
    width: 320,
    height: 240,
  },
  {
    text: "480p",
    value: "640:480",
    width: 640,
    height: 480
  },
  {
    text: "720p",
    value: "1280:720",
    width: 1280,
    height: 720
  },
  {
    text: "1080p",
    value: "1920:1080",
    width: 1920,
    height: 1080
  },
  {
    text: "4K",
    value: "3840:2160",
    width: 3840,
    height: 2160
  },

]

const WebcamCapture = () => {
  const dispatch = useDispatch();
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const captureDialog = useSelector(store => store.editdata.captureDialog);
  const videoRef = useRef(null)
  const [webcamDeviceList, setWebcamDeviceList] = useState([])
  const [webcamDevice, setWebcamDevice] = useState('')
  const [audioDeviceList, setAudioDeviceList] = useState([])
  const [audioDevice, setAudioDevice] = useState('')
  const [constraints, setConstraints] = useState({
    video: {
      height: {
        exact: 240
      },
      width: {
        exact: 320
      },
      frameRate: 30
    },
    audio: true
  })
  const [stream, setStream] = useState(null)
  const [recording, setRecording] = useState(false);
  const [recorder, setRecorder] = useState();
  const [recordable, setRecordable] = useState(false);
  //const [recordedBlob, setRecordedBlob] = useState(null);
  const [isUploadable, setIsUploadable] = useState(false);
  const [resolutionList, setResolutionList] = useState([webcamResolutions]);
  const [resolution, setResolution] = useState("320:240");
  const [maxWidth, setMaxWidth] = useState(3840);
  const [recordingTime, setRecordingTime] = useState(0);
  const [trackingInterval, setTrackingInterval] = useState(null)
  const projectUUID = GetProjectUUID();
  const loginState = useSelector((store) => store.maindata.loginState);
  const thumbNails = useSelector(store => store.projectdata.thumbNails);
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const uploadingFiles = useSelector((store) => store.projectdata.uploadingFiles);
  const Time_Count = useSelector((store) => store.videodata.Time_Count);

  useEffect(() => { tempVideoEls = { ...videoElements } }, [videoElements]);
  useEffect(() => { tempProjectJSON = { ...ProjectJSON } }, [ProjectJSON]);
  useEffect(() => { tempLoadingData = { ...uploadingFiles } }, [uploadingFiles]);

  const [userData, setUserData] = useState({})
  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') tempData = JSON.parse(item)
    setUserData(tempData)
  }, [loginState])

  useEffect(() => {
   /* navigator.mediaDevices.enumerateDevices().then(function (devices) {
      let videoInputDevices = []
      let audioInputDevices = []
      for (let i = 0; i < devices.length; i++) {
        let device = devices[i];
        if (device.kind === 'videoinput') {
          videoInputDevices.push({
            label: device.label || 'camera ' + (i + 1),
            id: device.deviceId
          })
        }
        if (device.kind === "audioinput") {
          audioInputDevices.push({
            label: device.label || 'microphone ' + (i + 1),
            id: device.deviceId
          })
        }
      };
      setWebcamDevice(videoInputDevices[0].id)
      setWebcamDeviceList(videoInputDevices)
      setAudioDevice(audioInputDevices[0].id)
      setAudioDeviceList(audioInputDevices)
    })*/
  }, [])

  useEffect(() => {
    if (captureDialog.webcam) {
      setRecordable(false);
      setRecordingTime(0);
      if(!webcamDevice){
        navigator.mediaDevices.enumerateDevices().then(function (devices) {
          let videoInputDevices = []
          let audioInputDevices = []
          for (let i = 0; i < devices.length; i++) {
            let device = devices[i];
            if (device.kind === 'videoinput') {
              videoInputDevices.push({
                label: device.label || 'camera ' + (i + 1),
                id: device.deviceId
              })
            }
            if (device.kind === "audioinput") {
              audioInputDevices.push({
                label: device.label || 'microphone ' + (i + 1),
                id: device.deviceId
              })
            }
          };
          setWebcamDevice(videoInputDevices[0].id)
          setWebcamDeviceList(videoInputDevices)
          setAudioDevice(audioInputDevices[0].id)
          setAudioDeviceList(audioInputDevices)
        })
      }
      
      if (stream) {
        stream.getTracks().forEach(track => {
          track.stop();
        });
      }
      navigator.getUserMedia = (navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.mediaDevices.getUserMedia);
      navigator.getUserMedia(constraints, (stream) => {
        window.stream = stream;
        setStream(stream)
        if (videoRef.current) {
          setRecordable(true);
          videoRef.current.srcObject = stream;
        }
      }, (err) => {
        console.log(err)
      })
    }
  }, [captureDialog, constraints])

  useEffect(() => {
    if (webcamDevice) {
      setConstraints({
        ...constraints,
        video: {
          ...constraints.video,
          deviceId: webcamDevice,
          bitrate: 10000,
          frameRate: {
            ideal: 60,
            max: 60,
          },
        }
      })
    let capaStream;
    navigator.getUserMedia = ( navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.mediaDevices.getUserMedia);
      navigator.getUserMedia(constraints, async (s) => {
        capaStream = s;
        const videoTrack = s.getVideoTracks()[0];
        let stream_settings = videoTrack.getSettings();
        let stream_capability = videoTrack.getCapabilities();
        await videoTrack.applyConstraints({
          frameRate: 120,
        });  
        console.log( stream_settings, videoTrack.getCapabilities());
        const maxiumWidth = stream_capability.width.max
        const maxiumHeight = stream_capability.height.max
        setMaxWidth(maxiumWidth);
        let tmpResolArr = [];
        webcamResolutions.map(resolItem => {
          if(!(resolItem.width > maxiumWidth) && !(resolItem.height > maxiumHeight)){
            tmpResolArr.push(resolItem);
          }
        });
        if(tmpResolArr.length > 0){
          setResolutionList(tmpResolArr);
          setResolution(maxiumWidth+":"+maxiumHeight);
        }else{
          setResolutionList(webcamResolutions);
          setResolution("3840:2160");
        }
        
        capaStream.getTracks().forEach(track => {
          track.stop();
        });
      }, (err) => {
        console.log(err)
      })
    }
  }, [webcamDevice])

  useEffect(() => {
    if (audioDevice) {
      setConstraints({
        ...constraints,
        audio: {
          deviceId: audioDevice
        }
      })
    }
  }, [audioDevice])
  const [filehandler, setFileHandler] = useState(null);
  const [recFileWriter, setRecFileWriter] = useState(null)
  
  useEffect(() => {
    if (recorder) {
      recorder.ondataavailable = async (e) => {
        console.log('dataabailable', e);
        // setRecordedBlob(e.data)
        await recFileWriter.write(e.data);
        recFileWriter.close();
        setIsUploadable(true);
        //let frameStream = trackProcessor.readable;
        /*const worker = new Worker(new URL("../../../component/video-edit/webcamWriter", import.meta.url));
        //setEncodeWorker(worker)
        worker.postMessage({
          type: 'start',
          fileHandle: filehandler,
          blobData: recordedBlob
        }, [recordedBlob]);*/
      }
    }
  }, [recorder])
  useEffect(() => {
    if(isUploadable){
      handleUpload()
    }
  }, [isUploadable])
  /*useEffect(() => {
    if (recordedBlob) {
      handleUpload()
    }
  }, [recordedBlob])*/

  useEffect(() => {
    if(recording) {
      const interval = setInterval(() => {
        setRecordingTime((time) => time + 100);
      }, 100);
      setTrackingInterval(interval)
    } else {
      clearInterval(trackingInterval)
    }
  }, [recording])

  const handleClose = () => {
    if (stream) {
      stream.getTracks().forEach(track => {
        track.stop();
      });
    //  setRecordedBlob(null)
      setRecFileWriter(null);
      //await writer.write( new Blob( [ "some data" ] ) );
      setFileHandler(null);
    }
    dispatch(CaptureDialog_Store({
      webcam: false
    }))
  }

  const handleChangeDevice = (e) => {
    setWebcamDevice(e.target.value)
  }

  const handleChangeAudioDevice = (e) => {
    setAudioDevice(e.target.value)
  }
  const [newFileUUID, setNewFileUUID] = useState();
  const handleRecord = async () => {
    if (recording) {
      setRecording(false)
      recorder.stop()
    } else {
      setIsUploadable(false);
      const newUUID = uuidv4();
      setNewFileUUID(newUUID);
      const root = await navigator.storage.getDirectory('/download');
      console.log(root);
      const directoryHandle = await root.getDirectoryHandle("testing", {
        create: true,
      });
      const draftHandle = await directoryHandle.getFileHandle(`${newUUID}.webm`, { create: true });
      console.log('fileData', draftHandle);
      // Get sync access handle
      const writer = await draftHandle.createWritable();
      // const fileHandle = await window.showSaveFilePicker({
      //   startIn: 'downloads',
      //   suggestedName: 'myVideo.mp4',
      //   types: [{
      //     description: 'Video File',
      //     accept: {'video/mp4' :['.mp4']}
      //     }],
      // });
      //const writer = await fileHandle.createWritable(); // request writable stream
      setRecFileWriter(writer);
      //await writer.write( new Blob( [ "some data" ] ) );
      setFileHandler(draftHandle);
      setRecording(true);
      const recOptions = {
        audioBitsPerSecond: 128000,
        videoBitsPerSecond: 2500000,
        audioConstantBitRate: true
      };
      const mediaRecorder = new MediaRecorder(stream, recOptions);
      mediaRecorder.start()
      setRecorder(mediaRecorder)
    }
  }

  const handleUpload = async () => {
   // const newUUID = uuidv4();
    /*let videofile = new File([recordedBlob], "webcamrecord.mp4", {
      type: "video/mp4"
    })*/
    let videofile = await filehandler.getFile();
    const videoLength = Object.keys(tempVideoEls).length;

    let duration = await getDuration(videofile)
    if (duration === Infinity) {
      window.toastr.info('video type error', 'can`t load this video')
      return
    }

    let fps = await getVideoAssetFps(videofile)
    console.log("THIS IS FPS : ", fps);

    if (!loginState) {
      if (videofile.size > 250 * 1024 * 1024 || duration > 5 * 60) {
        let title = 'Please sign in or sign up to upload videos'
        let content = 'larger than 250 Mbytes or longer than 5 minutes'
        window.toastr.info(title, content)
        return
      }
    } else {
      let flag = userData?.storage_usage_state
      if (!flag) {
        window.toastr.error('You have run out of Cloud Storage Space with your current plan, you need to upgrade your account to upload files to a project')
        return
      }
    }

    handleClose()

    let newProjectJSON = {...ProjectJSON}
    if(Object.keys(videoElements).length === 0) {
      const objURL = URL.createObjectURL(videofile);
      console.log('obj', objURL);
      getVideoLoadedData(objURL, uuidv4(), {segment_start: 10}, null, (res, videoPlayer) => {
        const videoPlayerW = videoPlayer.videoWidth;
        const videoPlayerH = videoPlayer.videoHeight;
        if(videoPlayerH / videoPlayerW > 1) {
          newProjectJSON.width = webGlSizeOptions[0].wSize.width;
          newProjectJSON.height = webGlSizeOptions[0].wSize.height;
          dispatch(ProjectJSON_Store(newProjectJSON));
        }
      })
    }

    let fileData = { pc: 0, type: 'video', name: videofile.name, state: false }
    dispatch(uploadingFiles_Store({ ...uploadingFiles, [newFileUUID]: fileData }));

    let tempThumbNails = [];
    let _thumbNails = await getThumbnails(videofile, 1);

    _thumbNails.forEach((thumbNail) => {
      let tempData = {
        uuid: uuidv4(),
        content_type: 'blob',
        timestamp: thumbNail.time,
        signed_url: URL.createObjectURL(thumbNail.file)
      }

      tempThumbNails.push(tempData);
    });

    let maxZIndex = getMaxZIndex(ProjectJSON);
    let props = [newProjectJSON, videofile, "record", duration, fps, newFileUUID, projectUUID, videoLength, maxZIndex]
    getNewVideoInformation(...props, 
      async (videoPlayer, videoInfo) => {
      dispatch(thumbNails_Store({ ...thumbNails, [newFileUUID]: tempThumbNails }));
      let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      //let startTime = ProjectJSON.duration? parseInt(ProjectJSON.duration) : Time_Count.getTime()
      let startTime = Time_Count.getTime()

      videoInfo = {
        ...videoInfo,
        timeline_start: startTime,
        timeline_end: videoInfo.duration + startTime
      }

      changedJSON.flag = true
      changedJSON.videos = { [newFileUUID]: videoInfo }
      dispatch(VideoElements_Store({ ...tempVideoEls, [newFileUUID]: videoPlayer }));
      dispatch(changedJSON_Store(changedJSON));
      changeZeroSecond(videoInfo, dispatch)
    },
    (tempAssets) => {
      let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      changedData = { ...changedData, asset: { ...tempAssets } };
      dispatch(changedJSON_Store(changedData));
    },
    async (newAssets, assetUUID) => {
      let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      if (!tempProjectJSON.videos[newFileUUID]) return;
      changedData.videos[newFileUUID] = tempProjectJSON.videos[newFileUUID];
      // changedData.videos[newUUID].asset_uuid = assetUUID;

      const JSONdata = await getProjectJSON(projectUUID);
      const projectData = JSONdata?.data[projectUUID];

      if (JSONdata.state !== 'success' || SERVER_STATUS === 'localhost')
        changedData = { ...changedData, asset: { ...newAssets } };
      else changedData = { ...changedData, asset: projectData.asset };

      // let fileItem = { ...tempLoadingData[newUUID], state: true };
      // dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
      dispatch(changedJSON_Store(changedData));
    },
    () => { dispatch(dataLoaded_Store(true)); }, 
    (e) => {
      let pc = parseFloat(e.loaded / e.total * 100).toFixed(2);
      let fileItem = { ...tempLoadingData[newFileUUID], pc: Number(pc) };
      dispatch(uploadingFiles_Store({ ...tempLoadingData, [newFileUUID]: fileItem }))
      if(Number(pc) === 100) {
        setTimeout(() => {
          let fileItem = { ...tempLoadingData[newFileUUID], pc: Number(pc), state: true };
          dispatch(uploadingFiles_Store({ ...tempLoadingData, [newFileUUID]: fileItem }))
        }, 1000)
      }
    })
  }

  
  const handleChangeResolution = e => {
    setResolution(e.target.value)
  }

  useEffect(() => {
    setConstraints({
      ...constraints,
      video: {
        ...constraints.video,
        width: {
          exact: Number(resolution.split(":")[0])
        },
        height: {
          exact: Number(resolution.split(":")[1])
        }
      }
    })
  }, [resolution])
  const timeCounRender = (timeCountNum) => {
    const secNum = parseInt(timeCountNum / 1000);
    const minNum = parseInt(secNum / 60);
    //const mileSec = parseInt((timeCountNum % 1000));
    return ''+(minNum.toString().padStart(2, '0')) + ':' +((secNum % 60).toString().padStart(2, '0'));
  }
  return (
    <Dialog
      open={captureDialog.webcam}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {languageStrs.record_webcam_and_upload}
        {(recording || recordingTime !== 0) && 
         <>
          <div
            style={{
              marginLeft: "20px",
              display: "inline-block",
              backgroundColor: "red",
              width: "15px",
              height: "15px",
              borderRadius: "50%"
            }}
          />
          <span style={{
            marginLeft: "10px"
          }}>{timeCounRender(recordingTime)}</span>
        </>
        }
      </DialogTitle>
      <DialogContent>
        <video poster={videopost} ref={videoRef} autoPlay muted />
        <Box marginY={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{languageStrs.video_captureDevice}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={webcamDevice}
              label="Video Capture Device"
              onChange={handleChangeDevice}
            >
              {webcamDeviceList.map(device => {
                return <MenuItem value={device.id} key={device.id}>{device.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
        <Box marginY={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{languageStrs.resolution}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={resolution}
              label="Resolutions"
              onChange={handleChangeResolution}
            >
              {resolutionList.filter(re => re.width <= maxWidth).map((resolution, index) => <MenuItem value={resolution.value} key={index}>{resolution.text}</MenuItem>)}
            </Select>
          </FormControl>
        </Box>
        <Box marginY={3}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">{languageStrs.audio_captureDevice}</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={audioDevice}
              label="Audio Capture Device"
              onChange={handleChangeAudioDevice}
            >
              {audioDeviceList.map(device => {
                return <MenuItem value={device.id} key={device.id}>{device.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRecord} autoFocus variant='contained' color='error' disabled={!recordable}>{recording ? languageStrs.stop : languageStrs.record}</Button>
        {/* <Button variant='contained' color="success" disabled={!recordedBlob || recording} onClick={handleUpload}>Upload</Button> */}
        <Button onClick={handleClose}>
          {languageStrs.cancel}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WebcamCapture