import { PROJECT_UUID, PROJECTJSON, ACTIVEVIDEOUUID, ACTIVETEXTUUID, CHANGEDJSON, ACTIVEIMAGEUUID, SAVESTATE, SEQUENCESTATUS, SEQUENCENUMBER, THUMBNAILS, UPLOADINGFILES, TIMELINEFIT, SUBCREATING, THUMBNAILSTATE, EDITSUBTITLE, PROJECTVOLUME, FULLSCREENFLAG, STRETCHSCREENFLAG, ACTIVEAUDIOUUID, CAPTURETHUMBNAIL, ADDEDTEMPLATE, SUBTITLEPOSY} from '../constants/projectinfo';

export const ProjectUUID_Store = (params) => {
  return (dispatch) => dispatch({ type: PROJECT_UUID, payload: params, })
}

export const ProjectJSON_Store = (params) => {
  return (dispatch) => dispatch({ type: PROJECTJSON, payload: params })
}

export const activeVideoUUID_Store = (params) => {
  return (dispatch) => dispatch({ type: ACTIVEVIDEOUUID, payload: params })
}

export const activeTextUUID_Store = (params) => {
  return (dispatch) => dispatch({ type: ACTIVETEXTUUID, payload: params })
}

export const activeImageUUID_Store = (params) => {
  return (dispatch) => dispatch({ type: ACTIVEIMAGEUUID, payload: params })
}

export const activeAudioUUID_Store = (params) => {
  return (dispatch) => dispatch({ type: ACTIVEAUDIOUUID, payload: params })
}

export const changedJSON_Store = (params) => {
  return (dispatch) => dispatch({ type: CHANGEDJSON, payload: params })
}

export const saveState_Store = (params) => {
  return (dispatch) => dispatch({ type: SAVESTATE, payload: params })
}

export const sequenceStatus_Store = (params) => {
  return (dispatch) => dispatch({ type: SEQUENCESTATUS, payload: params })
}

export const sequenceNum_Store = (params) => {
  return (dispatch) => dispatch({ type: SEQUENCENUMBER, payload: params })
}

export const thumbNails_Store = (params) => {
  return (dispatch) => dispatch({ type: THUMBNAILS, payload: params })
}

export const uploadingFiles_Store = (params) => {
  return (dispatch) => dispatch({ type: UPLOADINGFILES, payload: params })
}

export const timelineFit_Store = (params) => {
  return (dispatch) => dispatch({ type: TIMELINEFIT, payload: params })
}

export const subCreating_Store = (params) => {
  return (dispatch) => dispatch({ type: SUBCREATING, payload: params })
}

export const thumbnailState_Store = (params) => {
  return (dispatch) => dispatch({ type: THUMBNAILSTATE, payload: params })
}

export const editSubtitle_Store = (params) => {
  return (dispatch) => dispatch({ type: EDITSUBTITLE, payload: params })
}

export const projectvolume_store = (params) => {
  return (dispatch) => dispatch({ type: PROJECTVOLUME, payload: params })
}

export const fullScreenFlag_store = (params) => {
  return (dispatch) => dispatch({ type: FULLSCREENFLAG, payload: params })
}

export const StretchScreenFlag_store = (params) => {
  return (dispatch) => dispatch({ type: STRETCHSCREENFLAG, payload: params })
}

export const captureThumbnailFlag_store = (params) => {
  return (dispatch) => dispatch({ type: CAPTURETHUMBNAIL, payload: params })
}

export const addedTemplate_store = (params) =>{
  return (dispatch) => dispatch({type: ADDEDTEMPLATE, payload: params});
}

export const subtitlePostY_store = (params) => {
  return (dispatch) => dispatch({type: SUBTITLEPOSY, payload: params});
}