import { v4 as uuidv4 } from 'uuid';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Typography, Grid, Stack, Slider } from '@mui/material';

import TabsUnstyled from '@mui/base/TabsUnstyled';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';

import { BrandKitico } from '../../../Icons';
import { SectionHeader } from './common/section.header';
import { Tab, TabPanel, TabsList } from './common/section.tabbar';

import { opacityMarks } from './tooltap.config';
import { ProjectJSON_Store } from '../../../store/actions/projectdata';
import { GetProjectUUID } from '../../../assets/script/commonFunction';
import { getProjectJSON, SERVER_STATUS } from '../../../axios/ApiProvider';
import { changeZeroSecond, copyObject, getMaxZIndex, getSelectedType } from '../commonFunction';
import { changedJSON_Store, thumbNails_Store, uploadingFiles_Store } from '../../../store/actions/projectdata';
import { getNewVideoInformation, getImageInformation, getThumbnails, getDuration, getVideoAssetFps, getNewAudioInformation } from '../webGL/commonFunc';
import { dataLoaded_Store, VideoElements_Store, imageElements_Store, audioElements_Store } from '../../../store/actions/webgldata';
import webcamCaptureIcon from "../../../assets/image/icons/webcam-capture.png"
import screenCaptureIcon from "../../../assets/image/icons/screen-capture.png"
import { CaptureDialog_Store } from "../../../store/actions/editAction"
import { webGlSizeOptions } from '../../video-edit/videoEdit.config';
import PricingModal from '../../../pages/Pricing/pricingModal';  ;

let tempVideoEls = null;
let tempAudioEls = null;
let tempProjectJSON = null;
let tempLoadingData = null;
let tempAssets

const inputStyle = { position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', opacity: 0 };
export const TooltapUpload = () => {
  const dispatch = useDispatch();
  const projectUUID = GetProjectUUID();
  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const HeaderInfo = { title: languageStrs.media_upload, className: 'pl-3' };
  const loginState = useSelector((store) => store.maindata.loginState);
  const thumbNails = useSelector(store => store.projectdata.thumbNails);
  const ProjectJSON = useSelector(store => store.projectdata.projectJSON);
  const videoElements = useSelector(store => store.webgldata.videoElements);
  const audioElements = useSelector(store => store.webgldata.audioElements);
  const imageElements = useSelector(store => store.webgldata.imageElements);
  const uploadingFiles = useSelector((store) => store.projectdata.uploadingFiles);
  const Time_Count = useSelector((store) => store.videodata.Time_Count);

  
  const [assets, setAssets] = useState([]);
  useEffect(() => { tempVideoEls = { ...videoElements } }, [videoElements]);
  useEffect(() => { tempAudioEls = { ...audioElements } }, [audioElements]);
  useEffect(() => { 
    tempProjectJSON = { ...ProjectJSON };
    if(ProjectJSON.asset){
      buildProjectAsset(ProjectJSON.asset) 
    }
  }, [ProjectJSON]);
  useEffect(() => { tempLoadingData = { ...uploadingFiles } }, [uploadingFiles]);

  const [userData, setUserData] = useState({})
  const [userPlan, setUserPlan] = useState(0);
  useEffect(() => {
    let tempData = {}
    let item = localStorage.getItem('user_data')
    if (!!item && JSON.parse(item)?.state === 'loggedin') tempData = JSON.parse(item)
    setUserData(tempData)
    setUserPlan(tempData.subscription)
  }, [loginState])

  const [showPricingPageModal, setShowPricingPageModal] = useState(false)
  const handleClosePricingModal = () => {
    setShowPricingPageModal(false)
  }

  const buildProjectAsset = (assetData) => {
    const tmpAsset = assetData;
    const assetKeys = Object.keys(tmpAsset);
    let assetList = [];
    assetKeys.map(astKey => {
      const astItem = tmpAsset[astKey];
      const astThumKeys = Object.keys(astItem.thumbnail);
      let astThumbs = [];
      astThumKeys.map(astThKey => {
        astThumbs.push(astItem.thumbnail[astThKey]);
      })
      astThumbs.sort((a, b) => { return a.timestamp - b.timestamp; });
      assetList.push({
        name: astItem.filename,
        thumbNail: astThumbs.length>0?astThumbs[0].signed_url : ''
      })
    })
    setAssets(assetList);
  }


  const chooseVideoFunc = async ({ target }) => {
    if (!target || !target.files[0]) {
      window.toastr.warning('file select error')
      return
    }

    const newUUID = uuidv4();
    let videofile = target.files[0];
    const videoLength = Object.keys(tempVideoEls).length;

    let video_type = videofile.type;
    let allow_type = ['video/mp4', 'video/webm', 'video/quicktime'];
    let accep_type = allow_type.find((type) => type === video_type);

    if (!accep_type) {
      window.toastr.error("Can't load this video file")
      return
    }

    let duration = await getDuration(videofile)
    if (duration === Infinity) {
      window.toastr.info('video type error', 'can`t load this video')
      return
    }

    let fps = await getVideoAssetFps(videofile)
    console.log("THIS IS FPS : ", fps);
    // if (fps === 0) {
    //   window.toastr.info('video type error, cant get fps', 'can`t load this video')
    //   return
    // }

    console.log("VideooFile Size : " + videofile.size + " duration : " + duration + " user plan " + userPlan)
    let msg = "";
    if (loginState) {
      if (userPlan == 0) {
        if (videofile.size > 1024 * 1024 * 1024) {
          msg = 'You cannot upload files larger than 1 Gbyte, please ugprade your subscription to upload larger files';
        }
        if (duration > 10 * 60) {
          msg = 'You cannot upload files longer than 10 minutes, please upgrade your subscription to upload longer videos';
        }
      } else if ((userPlan == 1) || (userPlan == 4)) {
        if (videofile.size > 10 * 1024 * 1024 * 1024) {
          msg = 'You cannot upload files larger than 10 Gbyte, please ugprade your subscription to upload larger files';
        }
        if (duration > 25 * 60){
          msg = 'You cannot upload files longer than 25 minutes, please upgrade your subscription to upload longer videos';
        }
      }
      else if ((userPlan == 2) || (userPlan == 3) || (userPlan == 5) || (userPlan == 6))  {
        if (videofile.size > 50 * 1024 * 1024 * 1024) {
          msg = 'You cannot upload files larger than 50 Gbyte, contact info@videoo.io';
        }
        if( duration > 720 * 60){
          msg = 'In order to upload files longer than 2 hours, contact info@videoo.io';
        }
      }
      let storage_flag = userData?.storage_usage_state
      if (!storage_flag) {
        msg = 'You have run out of Cloud Storage Space with your current plan, please upgrade your subscription for more cloud space.';
      }
    } else {
      if (videofile.size > 250 * 1024 * 1024) {
        msg = 'You cannot upload files larger than 250 Mbytes, please signup and ugprade your subscription to upload larger files';
      }
      if (duration > 5 * 60) {
        msg = 'You cannot upload files longer than 5 minutes, please signup and upgrade your subscription to upload longer videos';
      }
    }
    if (msg !== "") {
      window.toastr.info(msg)
      setShowPricingPageModal(true);
      return
    }

    let newProjectJSON = {...ProjectJSON}

    let fileData = { pc: 0, type: 'video', name: videofile.name, state: false }
    dispatch(uploadingFiles_Store({ ...uploadingFiles, [newUUID]: fileData }));

    let tempThumbNails = [];
    let _thumbNails = await getThumbnails(videofile, 1);

    _thumbNails.forEach((thumbNail) => {
      let tempData = {
        uuid: uuidv4(),
        content_type: 'blob',
        timestamp: thumbNail.time,
        signed_url: URL.createObjectURL(thumbNail.file)
      }

      tempThumbNails.push(tempData);
    });

    let maxZIndex = getMaxZIndex(ProjectJSON);
    let props = [newProjectJSON, videofile, '', duration, fps, newUUID, projectUUID, videoLength, maxZIndex]

    getNewVideoInformation(...props, 
      async (videoPlayer, videoInfo, isNewGroup) => {
      dispatch(thumbNails_Store({ ...thumbNails, [newUUID]: tempThumbNails }));
      let changedJSON = {fps: fps, timelinerowlabel: {}, videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      let startTime = Time_Count.getTime()

      console.log("getNewVideoInformation videoInfo : ", videoInfo);

      /*videoInfo = {
        ...videoInfo,
        timeline_start: startTime,
        timeline_end: videoInfo.duration + startTime
      }*/

      videoInfo = {
        ...videoInfo,
        fps: fps,
      }

      changedJSON.flag = true
      if(isNewGroup){
        changedJSON.timelinerowlabel = {"video": [videoInfo.groups]}
      }

      changedJSON.fps    = fps;
      changedJSON.videos = { [newUUID]: videoInfo }
      dispatch(VideoElements_Store({ ...tempVideoEls, [newUUID]: videoPlayer }));
      dispatch(changedJSON_Store(changedJSON));
      changeZeroSecond(videoInfo, dispatch)
    },
    (tempAssets) => {
      let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      changedData = { ...changedData, asset: { ...tempAssets } };
      dispatch(changedJSON_Store(changedData));
    },
    async (newAssets, assetUUID) => {
      let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      if (!tempProjectJSON.videos[newUUID]) return;
      changedData.videos[newUUID] = tempProjectJSON.videos[newUUID];
      // changedData.videos[newUUID].asset_uuid = assetUUID;

      const JSONdata = await getProjectJSON(projectUUID);
      const projectData = JSONdata?.data[projectUUID];

      if (JSONdata.state !== 'success' || SERVER_STATUS === 'localhost')
        changedData = { ...changedData, asset: { ...newAssets } };
      else changedData = { ...changedData, asset: projectData.asset };

      // let fileItem = { ...tempLoadingData[newUUID], state: true };
      // dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
      dispatch(changedJSON_Store(changedData));
    },
    () => { dispatch(dataLoaded_Store(true)); }, 
    (e) => {
      let pc = parseFloat(e.loaded / e.total * 100).toFixed(2);
      let fileItem = { ...tempLoadingData[newUUID], pc: Number(pc) };
      dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
      if(Number(pc) === 100) {
        setTimeout(() => {
          let fileItem = { ...tempLoadingData[newUUID], pc: Number(pc), state: true };
          dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
        }, 1000)
      }
    })
  }

  const chooseAudioFunc = async ({ target }) => {
    if (!target || !target.files[0]) {
      window.toastr.warning('file select error')
      return
    }

    const newUUID = uuidv4();
    let audiofile = target.files[0];

    let audio_type = audiofile.type;
    let allow_type = ['audio/mp3', 'audio/mpeg', 'audio/wav'];
    let accep_type = allow_type.find((type) => type === audio_type);

    if (!accep_type) {
      window.toastr.error("Can't load this video file")
      return
    }

    let duration = await getDuration(audiofile)
    if (duration === Infinity) {
      window.toastr.info('audio type error', 'can`t load this audio')
      return
    }

    if (!loginState) {
      if (audiofile.size > 250 * 1024 * 1024 || duration > 5 * 60) {
        let title = 'Please sign in or sign up to upload audios'
        let content = 'larger than 250 Mbytes or longer than 5 minutes'
        window.toastr.info(title, content)
        return
      }
    } else {
      let flag = userData?.storage_usage_state
      if (!flag) {
        window.toastr.error('You have run out of Cloud Storage Space with your current plan, you need to upgrade your account to upload files to a project')
        return
      }
    }

    let fileData = { pc: 0, type: 'audio', name: audiofile.name, state: false }
    dispatch(uploadingFiles_Store({ ...uploadingFiles, [newUUID]: fileData }));

    getNewAudioInformation(ProjectJSON, audiofile, duration, newUUID, projectUUID, (audioPlayer, audioInfo) => {
      let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      let startTime = Time_Count.getTime()

      const audioKeys = Object.keys(ProjectJSON.audios);
      audioKeys.map(aItemKey => {
        if(startTime < ProjectJSON.audios[aItemKey].timeline_end){
          startTime = ProjectJSON.audios[aItemKey].timeline_end;
        }
      })

      audioInfo.timeline_start = startTime
      audioInfo.timeline_end = audioInfo.duration + startTime
      changedJSON.audios = { [newUUID]: audioInfo }
      dispatch(audioElements_Store({ ...tempVideoEls, [newUUID]: audioPlayer }));
      dispatch(changedJSON_Store(changedJSON));
    }, async (newAssets, assetUUID) => {
      let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      if (!tempProjectJSON.audios[newUUID]) return;
      changedData.audios[newUUID] = tempProjectJSON.audios[newUUID];

      const JSONdata = await getProjectJSON(projectUUID);
      const projectData = JSONdata?.data[projectUUID];

      if (JSONdata.state !== 'success' || SERVER_STATUS === 'localhost') {
        changedData = { ...changedData, asset: { ...newAssets } };
      } else changedData = { ...changedData, asset: projectData.asset };

      let fileItem = { ...tempLoadingData[newUUID], state: true };
      dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
      dispatch(changedJSON_Store(changedData));
    }, () => { dispatch(dataLoaded_Store(true)) }, (e) => {
      let pc = parseFloat(e.loaded / e.total * 100).toFixed(2);
      let fileItem = { ...tempLoadingData[newUUID], pc: Number(pc) };
      dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
    })
  }

  const chooseImageFunc = async (e) => {
    if (!e || !e.target || !e.target.files[0]) window.toastr.warning('file select error');
    if (!e || !e.target || !e.target.files[0]) return;

    if (loginState) {
      let flag = userData?.storage_usage_state
      if (!flag) {
        window.toastr.error('You have run out of Cloud Storage Space with your current plan, you need to upgrade your account to upload files to a project')
        return
      }
    }

    const newUUID = uuidv4();
    let imgFile = e.target.files[0];
    const newImgUrl = URL.createObjectURL(imgFile);
    const originalFileName = imgFile.name;
    const mimeType = imgFile.type;

    let fileData = { pc: 0, type: 'image', name: imgFile.name, state: false }
    dispatch(uploadingFiles_Store({ ...uploadingFiles, [newUUID]: fileData }));
    dispatch(dataLoaded_Store(false));


    let maxZIndex = getMaxZIndex(ProjectJSON);
    
    let props = [ProjectJSON, originalFileName, mimeType, newImgUrl, newUUID, projectUUID, 'image', maxZIndex]
    await getImageInformation(...props, async (img, imageInfo) => {
      let imgThumbNail = [{ uuid: uuidv4(), content_type: 'blob', signed_url: newImgUrl }];
      dispatch(thumbNails_Store({ ...thumbNails, [imageInfo.asset_uuid]: imgThumbNail }));
      let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      let startTime = Time_Count.getTime()
      const imgKeys = Object.keys(ProjectJSON.images);
      if(imgKeys.length > 0){
        // imgGroupId = imgKeys[0];
        imgKeys.map(keyItem => {
          if(startTime < ProjectJSON.images[keyItem].timeline_end){
            startTime = ProjectJSON.images[keyItem].timeline_end;
          }
        })
      }
      imageInfo = {
        ...imageInfo,
        timeline_start: startTime,
        timeline_end: imageInfo.duration + startTime
      }

      changedJSON.images = {
        [newUUID]: imageInfo
      }

      dispatch(imageElements_Store({ ...imageElements, [newUUID]: img }));
      dispatch(changedJSON_Store(changedJSON));
      changeZeroSecond(imageInfo, dispatch)
    }, async (newAssets, assetUUID) => {
      let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      if (!tempProjectJSON.images[newUUID]) return;
      //changedData.images[newUUID] = tempProjectJSON.images[newUUID]

      const JSONdata = await getProjectJSON(projectUUID);
      const projectData = JSONdata?.data[projectUUID];

      if (JSONdata.state !== 'success' || SERVER_STATUS === 'localhost')
        changedData = { ...changedData, asset: { ...newAssets } };
      else changedData = { ...changedData, asset: projectData.asset };

      let fileItem = { ...tempLoadingData[newUUID], state: true };
      dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
      dispatch(changedJSON_Store(changedData));
    }, () => { dispatch(dataLoaded_Store(true)); }, (e) => {
      let pc = parseFloat(e.loaded / e.total * 100).toFixed(2);
      let fileItem = { ...tempLoadingData[newUUID], pc: Number(pc) };
      dispatch(uploadingFiles_Store({ ...tempLoadingData, [newUUID]: fileItem }))
    });
  }

  const handleOpenWebcamCaptureDialog = () => {
    dispatch(CaptureDialog_Store({
      webcam: true
    }))
  }

  const handleOpenScreenCaptureDialog = () => {
    dispatch(CaptureDialog_Store({
      screen: true
    }))
  }

  const handleOpenWSCaptureDialog = () => {
    dispatch(CaptureDialog_Store({
      webcamScreen: true
    }))
  }

  return (
    <>
      <SectionHeader {...HeaderInfo} />
      <Box md={12} className='px-10 pt-0'>
        <TabsUnstyled defaultValue={0}>
          <TabsList>
            <Tab>{languageStrs.uploader}</Tab>
            {/* <Tab>Brand Kit</Tab> */}
          </TabsList>

          <TabPanel value={0} md={12}>
            <Box className='v-input-container v-font-2-2 pr-3 pl-3 mt-4' >
              <Box className='v-pointer input-text' >{languageStrs.upload_video}</Box>
              <input type={`file`} style={inputStyle}
                accept=".mp4, .webm, .mov"
                onChange={chooseVideoFunc}
              />
              <BackupOutlinedIcon className='v-font-3-3' />
            </Box>

            <Box className='v-input-container v-font-2-2 pr-3 pl-3 mt-4' >
              <Box className='v-pointer input-text' >{languageStrs.upload_audio}</Box>
              <input type={`file`} style={inputStyle}
                accept=".mp3, .mpeg, .wav" onChange={chooseAudioFunc}
              />
              <BackupOutlinedIcon className='v-font-3-3' />
            </Box>

            <Box className='v-input-container v-font-2-2 pr-3 pl-3 mt-4' >
              <Box className='v-pointer input-text' >{languageStrs.upload_image}</Box>
              <input type={`file`} style={inputStyle}
                accept=".jpg, .jpeg, .png, .svg, .gif"
                onChange={chooseImageFunc}
              />
              <BackupOutlinedIcon className='v-font-3-3' />
            </Box>

            <Box className='v-input-container v-font-2-2 pr-3 pl-3 mt-4' onClick={handleOpenWebcamCaptureDialog} >
              <Box className='v-pointer input-text' >{languageStrs.create_webcam_recording}</Box>
              <img src={webcamCaptureIcon} width={30} height={30} alt="webcam capture icon" />
            </Box>
            <Box className='v-input-container v-font-2-2 pr-3 pl-3 mt-4' onClick={handleOpenScreenCaptureDialog} >
              <Box className='v-pointer input-text' >{languageStrs.create_screen_capture_recording}</Box>
              <img src={screenCaptureIcon} width={30} height={30} alt="screen capture icon" />
            </Box>
            <Box className='v-input-container v-font-2-2 pr-3 pl-3 mt-4' onClick={handleOpenWSCaptureDialog} >
              <Box className='v-pointer input-text' >{languageStrs.create_webcam_screen_recording}</Box>
              <Box display="flex" >
                <img src={webcamCaptureIcon} width={30} height={30} alt="webcam capture icon" />
                <img src={screenCaptureIcon} width={30} height={30} alt="screen capture icon" />
              </Box>
            </Box>

            {/* <Box className='v-input-container v-font-2-2 pr-3 pl-3 mt-4' >
              <Box className='v-pointer input-text' >Upload Audio</Box>
              <input type={`file`} style={inputStyle}
                accept=".MP3, .MP4, .M4A"
              />
              <BackupOutlinedIcon className='v-font-3-3' />
            </Box> */}
          </TabPanel>
          {assets.length > 0 && 
            <TabsList className='mt-8'>
              <Tab>{languageStrs.project_asset}</Tab>
            </TabsList>
          }
          
          <TabPanel value={0}>
            <Grid container spacing={3} >
            {assets.map((assetData, key) =>
              <Grid item md={6} key={key}>
                <img src={assetData.thumbNail} ></img>
              </Grid>
            )}
            </Grid>
          </TabPanel>
          {/* <TabPanel value={1}>
            <Stack md={12} className='v-hoverborder v-radius p-3 mt-4'>
              <Grid container spacing={0} className='v-BrandKit'>
                <Grid item xs={10}>
                  <Typography className='v-BrandKit-text'>Brand Kit</Typography>
                  <Typography className='v-BrandKitbottom-text'>Upgrade to PRO to unlock</Typography>
                </Grid>

                <Grid item xs={2}>
                  <Box className='v-BrandKit-icon'>
                    <BrandKitico />
                  </Box>
                </Grid>
              </Grid>
            </Stack>

            <Typography className='v-block-footer mt-2'>Remove background noise and enhance audio quality</Typography>
          </TabPanel> */}
        </TabsUnstyled>
      </Box>
      <PricingModal open={showPricingPageModal} onCloseModal={handleClosePricingModal} />
    </>
  )
}