import { VIDEOPLAYING, ZOOMRATE, TIMECOUNT, VIDEOBUFFERING, VIDEOPLAYSTARTED } from './../constants/videoPlayConst';

export const SaveVideoPlaying_Store = (params) => {
  return (dispatch) => dispatch({ type: VIDEOPLAYING, payload: params, });
};

export const SaveZoomRate_Store = (params) => {
  return (dispatch) => dispatch({ type: ZOOMRATE, payload: params, });
};

export const TimeCount_Store = (params) => {
  return (dispatch) => dispatch({ type: TIMECOUNT, payload: params, });
};

export const SaveVideoBuffering_Store = (params) => {
  return (dispatch) => dispatch({ type: VIDEOBUFFERING, payload: params, });
};

export const SaveVideoPlayStarted_Store = (params) => {
  return (dispatch) => dispatch({ type: VIDEOPLAYSTARTED, payload: params, });
}