export const volumeMarks = [
  { value: 0.2, label: '' },
  { value: 0.4, label: '' },
  { value: 0.6, label: '' },
  { value: 0.8, label: '' },
];

export const speedMarks = [
  { value: 0.25, label: 'x0.25' },
  { value: 0.5, label: 'x0.5' },
  { value: 0.75, label: 'x0.75' },
  { value: 1.0, label: 'x1.0' },
  { value: 1.25, label: 'x1.25' },
  { value: 1.5, label: 'x1.5' },
  { value: 1.75, label: 'x1.75' },
  { value: 2.0, label: 'x2.0' },
  { value: 2.25, label: 'x2.25' },
  { value: 2.5, label: 'x2.5' },
  { value: 2.75, label: 'x2.75' },
  { value: 3.0, label: 'x3.0' },
  { value: 3.25, label: 'x3.25' },
  { value: 3.5, label: 'x3.5' },
  { value: 3.75, label: 'x3.75' },
  { value: 4.0, label: 'x4.0' },
]

export const zoomMarks = [
  { value: -6, label: '' },
  { value: -2, label: '' },
  { value: 0, label: '' },
  { value: 2, label: '' },
  { value: 6, label: '' },
];