import { useDispatch, useSelector } from 'react-redux';
import { Box, Stack, Typography } from '@mui/material';
import { linkactive, getSelectedType } from './../commonFunction';
import { SelectedItemID_Store } from '../../../store/actions/webgldata';

const Iconcm = (props) => {
  const dispatch = useDispatch()
  const { index, Icon, title } = props
  const projectData = useSelector(store => store.projectdata.projectJSON)
  const selectedID = useSelector(store => store.webgldata.selectedItemID)

  const onSettedClick = () => { linkactive(index, dispatch, false); }

  const onDoubleClick = () => {
    if (index !== 4) return
    let activeType = getSelectedType(selectedID, projectData)
    if (activeType === 'text') dispatch(SelectedItemID_Store(null))
  }

  return (
    <Box onClick={() => { onSettedClick() }} onDoubleClick={onDoubleClick} className={`my-step-num${index}`}>
      <Stack className={`v-menu-item v-pointer pb-3`} direction="column">
        {Icon}
        <Typography className='pt-2'>{title}</Typography>
      </Stack>
    </Box>
  )
}
export default Iconcm;