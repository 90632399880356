import { useState, useEffect } from 'react';

const usePinchZoom = (ref, zoomInCb, zoomOutCb) => {
  const [lastTouchDistance, setLastTouchDistance] = useState(0);
  const [pinching, setPinching] = useState(false)
  const [delta, setDelta] = useState(0)
  const [touchDistance, setTouchDistance] = useState(0)

  const getDistance = (touches) => {
    const x1 = touches[0].clientX;
    const y1 = touches[0].clientY;
    const x2 = touches[1].clientX;
    const y2 = touches[1].clientY;
    return Math.sqrt((x2-x1)**2 + (y2-y1)**2);
  }

  useEffect(() => {
    if(Math.abs(delta) > 31) {
      setLastTouchDistance(touchDistance)
    }
  }, [touchDistance, delta])
  

  useEffect(() => {
    const element = ref.current;

    const onTouchStart = (event) => {
      if (event.touches.length == 2) {
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];
        const touchDistance = Math.sqrt(
            Math.pow(touch1.pageX - touch2.pageX, 2) +
            Math.pow(touch1.pageY - touch2.pageY, 2)
        );
        setLastTouchDistance(touchDistance);
        setPinching(true);
    }
    };
    
    const onTouchMove = (event) => {
      if (event.touches.length == 2) {
        const touch1 = event.touches[0];
        const touch2 = event.touches[1];
        const touchDistance = Math.sqrt(
            Math.pow(touch1.pageX - touch2.pageX, 2) +
            Math.pow(touch1.pageY - touch2.pageY, 2)
        );
        setTouchDistance(touchDistance)
        const delta = touchDistance - lastTouchDistance;
        setDelta(delta)
      }
    }

    const onTouchEnd = (event) => {
      // if (pinching && event.touches.length < 2) {
        setPinching(false);
      // }
    };

    if (element) {
      element.addEventListener('touchstart', onTouchStart);
      element.addEventListener('touchmove', onTouchMove)
      element.addEventListener('touchend', onTouchEnd);
    }

    return () => {
      element.removeEventListener('touchstart', onTouchStart);
      element.removeEventListener('touchend', onTouchEnd);
    };
  }, [ref, lastTouchDistance]);

  return {
    lastTouchDistance,
    pinching,
    touchDistance,
    delta
  };
};

export default usePinchZoom;