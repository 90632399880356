import React, {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase} from "@mui/material"
import {ArrowBack, ArrowForward} from '@mui/icons-material';
import { ClickAwayListener } from '@mui/base';
import VideocamIcon from '@mui/icons-material/Videocam';

import SearchIcon from '@mui/icons-material/Search';
import { SectionHeader } from '../common/section.header';
import { 
  getStockSearchKeyword, 
  getPopularPixabayImageStocks,
  getPixabayImageSearchStocks,
  getPixabayImageStock,
  getFreeSoundSearch,
  getFreeSoundData,
  getProjectJSON} from '../../../../axios/ApiProvider';
import { GetProjectUUID } from '../../../../assets/script/commonFunction';
import { getNewStockInformation, getThumbnailsInfo, getImageInformation, getNewAudioInformation, getNewAudioStockInformation } from '../../webGL/commonFunc';
import { VideoElements_Store, audioElements_Store, dataLoaded_Store, imageElements_Store } from '../../../../store/actions/webgldata';
import { changedJSON_Store, ProjectJSON_Store, thumbNails_Store } from '../../../../store/actions/projectdata';
import { loading_Store } from '../../../../store/actions/mainAction';
import { getMaxZIndex, changeZeroSecond } from '../../commonFunction';
import { localeForPexles, mimeTypes } from '../tooltap.config';
import { v4 as uuidv4 } from 'uuid';
import {webGlSizeOptions } from "../../../video-edit/videoEdit.config"
import pexelsVideoLogo from "../../../../assets/image/Logos/pexelVideo.png"
import pexelsImageLogo from "../../../../assets/image/Logos/pexelImage.png";
import pixabayVideoLogo from "../../../../assets/image/Logos/pixabayVideo.png";
import pixabayImageLogo from "../../../../assets/image/Logos/pixabayImage.png";
import freeSoundLogo from "../../../../assets/image/Logos/freeSound.png";
import stockLogo from "../../../../assets/image/Logos/stock_logo.png"
import { duration } from 'moment';
import { StockModal } from './stockModal';

export const PixabayImage = () => {
    const [isLoadingStock, setIsLoadingStock] = useState(true);
    const [pageNum, setPageNum] = useState(1);
    const [pageTotal, setPageTotal] = useState(1);
    const [stockData, setStockData] = useState([]);
    const [orientation, setOrientation] = useState("landscape");
    const [locale, setLocale] = useState("en-US");
    const [searchKey, setSearchKey] = useState("");
    const projectUUID = GetProjectUUID();
    const thumbNails = useSelector(store => store.projectdata.thumbNails);
    const imageElements = useSelector(store => store.webgldata.imageElements);
    const Time_Count = useSelector((store) => store.videodata.Time_Count);
    const projectJSON = useSelector(store => store.projectdata.projectJSON);
    const dispatch = useDispatch()
    const [openPopper, setOpenPopper] = useState(false)
    const anchorEl = useRef(null)
    const [keywords, setKeywords] = useState([]);
    
    const [selectedStock, setSelectedStock] = useState(null);
    const [openDetaiModal, setOpenDetailModal] = useState(false);

    const handleOpenPopover = (event) => {
      setOpenPopper(true);
    };
  
    const handleClosePopover = () => {
      setOpenPopper(false);
    };
  
    useEffect(() => {
        (async function() {
          if(searchKey) {
            // setPageNum(1);
            const keysRes = await getStockSearchKeyword(searchKey);
            let respStr = "";
            keysRes.map(st => respStr += st);
            const firstBracket = respStr.indexOf("(");
            respStr = respStr.slice(firstBracket + 1).slice(0, -1);
            const respJson = JSON.parse(respStr);
            console.log('gettingISSUE', respJson.hits);
            setKeywords(respJson.hits)
          }
        })()
      }, [searchKey])
  
    useEffect(() => {
      getPopularPixabayImageData();
    }, [pageNum])
  
    const getPopularPixabayImageData = async () => {
      setIsLoadingStock(true);
      const imgsRes = await getPopularPixabayImageStocks(pageNum);
      console.log('loadeedPixabayImgs', imgsRes);
      if(imgsRes.state !== "success") return;
      setPageTotal(Math.round(imgsRes.data.total / 20));
      setStockData(imgsRes.data.hits);
      setIsLoadingStock(false)
      // setPageTotal
    }
  
    const handleChangePageNum = (e) => {
      if(e.target.value < 1 || e.target.value > pageTotal) return;
      setPageNum(e.target.value)
    }
  
    const handleChangeInput = (e) => {
      setSearchKey(e.target.value);
      if(e.target.value === ""){
        handleClosePopover();
      } else {
        handleOpenPopover(e);
      }
    }
  
    const getPixabayImageSearchData = async (keyword) => {
      setIsLoadingStock(true)
      const searchedData = await getPixabayImageSearchStocks(keyword, pageNum, locale, orientation);
      console.log(searchedData);
      if(searchedData.state !== "success") return;
      setPageTotal(Math.round(searchedData.data.total / 20));
      setStockData(searchedData.data.hits);
      setIsLoadingStock(false)
    }
  
    useEffect(() => {
      if(searchKey === ""){
        getPopularPixabayImageData();
      }else{
        if(pageNum > 1){
          setPageNum(1);
        }
        getPixabayImageSearchData(searchKey);
      }
    }, [orientation, locale])
  
    const handleStockImage = (stockItem) => {
      setSelectedStock(stockItem);
      setOpenDetailModal(true);
    }

    const addStockData = async (stockData) => {
      console.log(stockData);
      const newUUID = uuidv4();
      const originalFilePath = stockData.largeImageURL;
      const originalFileName = stockData.previewURL.split("/").reverse()[0];
      const mimeType = `image/${stockData.previewURL.split(".").reverse()[0]}`
      dispatch(loading_Store(true));
      let maxZIndex = getMaxZIndex(projectJSON);
      let props = [projectJSON, originalFileName, mimeType, originalFilePath, newUUID, projectUUID, 'pixabyImage']
      await getImageInformation(...props, maxZIndex, async (img, imageInfo) => {
        let imgThumbNail = [{uuid: uuidv4(), content_type: 'blob', signed_url: originalFilePath}];
        dispatch(thumbNails_Store({...thumbNails, [imageInfo.asset_uuid]: imgThumbNail}));
        let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
        let startTime = Time_Count.getTime();
        const imgKeys = Object.keys(projectJSON.images);
        if(imgKeys.length > 0){
          // imgGroupId = imgKeys[0];
          imgKeys.map(keyItem => {
            if(startTime < projectJSON.images[keyItem].timeline_end){
              startTime = projectJSON.images[keyItem].timeline_end;
            }
          })
        }
        imageInfo = {
          ...imageInfo,
          timeline_start: startTime,
          timeline_end: imageInfo.duration + startTime,
        };
  
        changedJSON.images = {
          [newUUID]: imageInfo
        }
  
        dispatch(imageElements_Store({...imageElements, [newUUID]: img}));
        dispatch(changedJSON_Store(changedJSON));
        changeZeroSecond(imageInfo, dispatch);
        dispatch(loading_Store(false));
      }, async (assetUUID, newAssets) => {
        let changedData = { videos: {}, audios: {}, images: {}, asset: {}, text: {} };
        const JSONdata = await getProjectJSON(projectUUID);
        const projectData = JSONdata?.data[projectUUID];
  
        if(JSONdata.state !== 'success'){
          changedData = { ...changedData, asset: {...newAssets} };
        }else{
          changedData = {...changedData, asset: projectData.asset};
        }
        
        // dispatch(changedJSON_Store(changedData));

       
      }, () => {dispatch(dataLoaded_Store(true))})
    }
    const handleKeyUp = (e) => {
      if(e.keyCode === 13) {
        getPixabayImageSearchData(searchKey);
        handleClosePopover();
      }
    }
    const handleClickKeyword =  (keyword) => {
        setSearchKey(keyword);
        getPixabayImageSearchData(keyword);
        handleClosePopover();
      }

    return (
      <>
        <Grid container sx={{p: 1}}>
          <Grid item xs={6} padding={2}>
            <FormControl fullWidth>
              <InputLabel id="pixaBayImage-Orientation">Orientation</InputLabel>
              <Select 
                labelId='pixaBayImage-Orientation'
                id="pixaBayImage-Orientation-select"
                value={orientation}
                label="Orientation"
                onChange={(e) => { setOrientation(e.target.value) }}
              >
                <MenuItem value="landscape">Landscape</MenuItem>
                <MenuItem value="portrait">Portrait</MenuItem>
                <MenuItem value="square">Square</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} padding={2}>
            <FormControl fullWidth>
              <InputLabel id='pixabayImage-locale'>Locale</InputLabel>
              <Select 
                labelId='pixabayImage-locale'
                id='pixabayImage-locale-select'
                value={locale}
                label={'Locale'}
                onChange={(e) => {setLocale(e.target.value)}}
              >
                {localeForPexles.map(lo => <MenuItem value={lo}>{lo}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box display={'flex'} justifyContent={'center'}>
              <Button
                variant='contained'
                startIcon={<ArrowBack />}
                sx={{marginRight: "10px"}}
                onClick={() => {
                  if(pageNum === 1) return;
                  setPageNum(pageNum - 1);
                }}
              >Prev</Button>
              <input 
                type='number'
                max={pageTotal}
                style={{width: "50px"}}
                className='stock-page-input'
                value={pageNum}
                onChange={handleChangePageNum}
              />
              <span style={{fontSize: "20px"}}>/{pageTotal}</span>
              <Button
                variant='contained'
                endIcon={<ArrowForward />}
                sx={{marginLeft: '10px'}}
                onClick={() => {
                  if(pageNum === pageTotal) return; 
                  setPageNum(Number(pageNum) + 1);
                }}
              >Next</Button>
            </Box>
          </Grid>
          <Grid item xs={12} paddingY={2}>
            <FormControl fullWidth ref={anchorEl}>
              <InputLabel htmlFor='outlined-pixabayImg-search'>Search</InputLabel>
              <OutlinedInput 
                id='outlined-pixabayImg-search'
                startAdornment={<InputAdornment position='start'><SearchIcon /></InputAdornment>}
                label="Search"
                onChange={handleChangeInput}
                onKeyUp={handleKeyUp}
                value={searchKey}
              />
            </FormControl>
          </Grid>
          <Popper 
            open={openPopper}
            anchorEl={anchorEl.current}
            sx={{
                width: `${anchorEl?.current?.clientWidth}px`,
                border: "solid 1px #e5e7eb",
                zIndex: 1000,
                backgroundColor: "#FFF"
              }}
              placement="bottom-start"
          >
            {keywords.map(w => <MenuItem key={w.objectID}
                onClick={() =>{ handleClickKeyword(w.term)}}
              >{w.term}</MenuItem>)}
          </Popper>
        </Grid>
        <Grid container sx={{overflow: 'auto',  position: 'relative'}}>
          <Grid item xs={12} sx={{position: "relative", minHeight: "300px"}}>
            <ImageList variant='masonry' cols={2} gap={8}>
              {stockData.map(stItem => {
                let subtitle = ""
                subtitle = `${stItem.imageWidth} X ${stItem.imageHeight}`;
                return (
                  <ImageListItem key={stItem.id} sx={{cursor: "pointer"}} 
                    onClick = {() => {handleStockImage(stItem)}}
                  >
                    <img 
                      src={`${stItem.webformatURL}`}
                      alt={stItem.tags}  
                      loading="lazy"
                      style={{
                        minWidth: "184px",
                        minHeight: "103px",
                        flexGrow: "unset"
                      }}
                    />
                    <ImageListItemBar 
                      subtitle={<><VideocamIcon />{subtitle}</>}
                    />
                  </ImageListItem>
                )
              })}
            </ImageList>
          </Grid>
          {isLoadingStock && <Box sx={{position: "absolute", top: 0,  background: "rgba(0,0,0,0)", display: "flex", zIndex: 100000, flexDirection: "column", justifyContent: "center", height: "300px", width: "100%"}}>
              <Box sx={{ display: 'flex', position: "absolute", justifyContent: "center", width: "100%" }}>
                <CircularProgress />
              </Box>
            </Box>}
        </Grid>
        <StockModal 
            open={openDetaiModal} 
            setOpen={setOpenDetailModal} 
            stockType='pixabayImage'
            stockData={selectedStock}
            addStockData={addStockData}
            />
      </>
    )
  }