import { SelectedEditTool_Store } from '../../store/actions/editAction';
import { editSubtitle_Store, thumbnailState_Store } from '../../store/actions/projectdata';
import { contextDefault } from './timeline/timeline.config';
import { webGlSizeOptions } from './videoEdit.config';

export const copyObject = (obJSON) => {
  let tempJSON = JSON.parse(JSON.stringify(obJSON));
  return tempJSON;
}

export const linkactive = (index, dispatch, isEditSubtitle) => {
  const Selected = document.getElementsByClassName("v-menu-active");
  for (let i = 0; i < Selected.length; i++) Selected[i].classList.remove('v-menu-active');
  const menu = document.getElementsByClassName("v-menu-body");
  menu[0].children[index].classList.add('v-menu-active');
  dispatch(SelectedEditTool_Store(index))
  if(isEditSubtitle){
    dispatch(editSubtitle_Store(true))
  }else{
    dispatch(editSubtitle_Store(false))
  }
  
  let width = window.innerWidth;
  if (width <= 992) {
    let menutoolbar = document.getElementsByClassName('v-editor')[0].getElementsByClassName('v-editor-container')[0].getElementsByClassName('v-body')[0].getElementsByClassName('v-menu-toolbar')[0];
    let mobiledrawer = document.getElementsByClassName('v-mobile-drawer')[0];
    mobiledrawer.style.display = 'block';

    menutoolbar.style.height = '80vh';
  }
}

export const MobileToolbarClose = () => {
  let width = window.innerWidth;
  if (width <= 992) {
    let menutoolbar = document.getElementsByClassName('v-editor')[0].getElementsByClassName('v-editor-container')[0].getElementsByClassName('v-body')[0].getElementsByClassName('v-menu-toolbar')[0];
    let mobiledrawer = document.getElementsByClassName('v-mobile-drawer')[0];
    menutoolbar.style.height = '0px';
    mobiledrawer.style.display = 'none';
  }
}

export const toolbardraghandle = () => {
  let touchmouseposition = { X: 0, Y: 0 };
  let menutoolbar = document.getElementsByClassName('v-editor')[0].getElementsByClassName('v-editor-container')[0].getElementsByClassName('v-body')[0].getElementsByClassName('v-menu-toolbar')[0];
  let mobiledrawer = document.getElementsByClassName('v-mobile-drawer')[0];
  let dragtag = document.getElementsByClassName('v-toolbarhandleheader')[0];

  let tempHeight = '80vh'
  dragtag.addEventListener('mousedown', () => {
    if (!/Android|webOS|iPhone|iPad/i.test(navigator.userAgent)) {

      menutoolbar.style.height = '0px';
      mobiledrawer.style.display = 'none';
    }
  })

  dragtag.addEventListener('touchstart', () => {
    tempHeight = menutoolbar.style.height
  })

  dragtag.addEventListener('touchmove', (e) => {
    let wheight = window.innerHeight;
    var touch = e.changedTouches.item(0);
    let beforeY = touchmouseposition.Y;
    let Y = touch.screenY;
    let NextY = wheight - Y + 190;

    if (beforeY !== Y && NextY < wheight * 0.9 && NextY > 10) menutoolbar.style.height = NextY + 'px';
    touchmouseposition.X = touch.screenX;
    touchmouseposition.Y = Y;
  });

  dragtag.addEventListener('touchend', (e) => {
    let wheight = window.innerHeight;
    var touch = e.changedTouches.item(0);
    let Y = touch.screenY;
    let NextY = wheight - Y + 190;

    if (NextY <= wheight * 0.25) {
      menutoolbar.style.height = '0px';
      mobiledrawer.style.display = 'none';
    } else menutoolbar.style.height = tempHeight;
  })
}

export const MouseMove_MMSSM_TimeFormat = (event, ChangeState, Duration) => {
  let increate = 100;
  let val = Duration;
  let min = val.slice(0, 2);
  let sec = val.slice(3, 5);
  let milli = val.slice(6, 9);

  let x = event.clientX;
  let setmilli = Number(milli), setsec = Number(sec), setmin = Number(min);
  if (x > ChangeState.BeforePosition) {
    setmilli += increate;
    if (setmilli >= 1000) {
      setsec += 1;
      setmilli -= 1000;

      if (setsec >= 60) {
        setmin += 1;
        setsec -= 60;
      }
    }

    if (setmilli >= 1000) setmilli = 999;
    if (setsec >= 60) setsec = 59;
    if (setmilli < 0) setmilli = 0;
    if (setsec < 0) setsec = 0;
    if (setmin < 0) setmin = 0;
  } else if (ChangeState.BeforePosition > x) {
    if (setmilli < increate) {
      setmilli = 999 - increate;
      if (setsec - 1 < 0) {
        setsec = 59;
        if (setmin - 1 < 0) { setmilli = 0; setsec = 0; setmilli = 0; }
        else setmin -= 1;
      } else setsec -= 1;
    } else setmilli -= increate;
    if (setmilli >= 1000) setmilli = 999;
    if (setsec >= 60) setsec = 59;
    if (setmilli < 0) setmilli = 0;
    if (setsec < 0) setsec = 0;
    if (setmin < 0) setmin = 0;
  }

  let value = setmin < 10 ? ('0' + setmin) : (setmin + '');
  value += setsec < 10 ? (':0' + setsec) : (':' + setsec);
  value += setmilli < 10 ? ('.0' + setmilli) : ('.' + setmilli);
  return value;
}

export const InputKeyChangeTimeFormat = (e) => {
  let value = e.target.value;
  let currentval = value.replace(/\\:/g,).replace(/\\./g, '');
  let length = currentval.length;
  let min = '', sec = '', milli = '';

  if (length > 0) {
    min = currentval.slice(0, 2);
    if (length > 2) {
      sec = currentval.slice(2, 4);
      if (length > 4) milli = currentval.slice(4, 7);
      else milli = '0';
    } else sec = '00';
  } else min = '00';

  if (Number(min) && Number(min) > 60) min = '60';
  if (Number(sec) && Number(sec) >= 60) sec = '59';
  let val = min + ':' + sec + '.' + milli;
  return val;
}

export const SetInputSelectionStart = (e, Start) => {
  if (Start === 3 || Start === 6) Start++;
  if (Start > 9) Start = 9;
  e.target.selectionStart = Start;
  e.target.selectionEnd = Start;
}


// webGL display size level check Start
export const getWebGlSizeLevel = (ProjectJSON) => {
  if (!ProjectJSON || !ProjectJSON?.width || !ProjectJSON?.height) return false;
  const tempType = { width: ProjectJSON?.width, height: ProjectJSON?.height };

  const activeType = webGlSizeOptions.find((option) => {
    const tempSize = option.wSize;
    return tempSize.width === tempType.width && tempSize.height === tempType.height
  });

  if (!activeType) return false;
  else return activeType;
}
// webGL display size level check End


export const getSelectedType = (elementID, ProjectJSON) => {
  let type = '';
  if (!ProjectJSON.videos) return type;
  const newJSONData = Object.assign({}, ProjectJSON);
  const checktextKey = newJSONData.text[elementID];
  const checkvideoKey = newJSONData.videos[elementID];
  const checkimageKey = newJSONData.images[elementID];
  const checkaudioKey = newJSONData.audios[elementID];

  if (!!checktextKey) type = 'text';
  else if (!!checkvideoKey) type = 'videos';
  else if (!!checkimageKey) type = 'images';
  else if (!!checkaudioKey) type = 'audios';

  return type;
}

export const getMoveableIDWithArr = (selectedIDArr, ProjectJSON) => {
  const newJSONData = Object.assign({}, ProjectJSON);
  let settedID = '';
  selectedIDArr.map(itemId => {
    const checkaudioKey = newJSONData.audios[itemId];
    if(!checkaudioKey){
      settedID = itemId;
    }
  })
  return settedID;
}

export const getGroupoptions = (ProjectJSON) => {
  let groupOptions = {};
  let texts = Object.assign({}, ProjectJSON.text);
  let videos = Object.assign({}, ProjectJSON.videos);
  let images = Object.assign({}, ProjectJSON.images);

  let textKeys = Object.keys(texts);
  let videoKeys = Object.keys(videos);
  let imageKeys = Object.keys(images);

  textKeys.forEach((key) => {
    let ItemOb = Object.assign({}, texts[key]);
    if (!groupOptions[ItemOb.groups]) groupOptions[ItemOb.groups] = {};
    groupOptions[ItemOb.groups][key] = ItemOb;
  })

  videoKeys.forEach((key) => {
    let ItemOb = Object.assign({}, videos[key]);
    if (!groupOptions[ItemOb.groups]) groupOptions[ItemOb.groups] = {};
    groupOptions[ItemOb.groups][key] = ItemOb;
  })

  imageKeys.forEach((key) => {
    let ItemOb = Object.assign({}, images[key]);
    if (!groupOptions[ItemOb.groups]) groupOptions[ItemOb.groups] = {};
    groupOptions[ItemOb.groups][key] = ItemOb;
  })

  return groupOptions;
}

export const getTransitionInfo = (groupData, itemKey) => {
  
  let ItemOb = groupData? Object.assign({}, groupData[itemKey]) : {};
  let tempGroup = Object.assign({}, groupData);
  let startTime = groupData? ItemOb.timeline_start : 0;
  let endTime = groupData? ItemOb.timeline_end : 0;
  let tempKeys = Object.keys(tempGroup);

  let maxEnd = 0;
  let clashID = '';
  let clashID_ = '';
  let clashItem = false;
  let minStart = contextDefault.maxTime * 1000;
  tempKeys.forEach((key) => {
    let item = tempGroup[key];
    let endT = item.timeline_end;
    let startT = item.timeline_start;

    if (endT > maxEnd) maxEnd = endT;
    if (startT < minStart) minStart = startT;
    if (itemKey !== key && endT === startTime) clashID_ = key;
    if (itemKey !== key && startT === endTime) { clashItem = true; clashID = key; }
  });

  return { maxEnd: maxEnd, minStart: minStart, clashID: clashID, clashItem: clashItem, clashID_: clashID_ };
}

export const rgbaToHex = ({ r, g, b, a }) => {
  let outParts = [
    r.toString(16), g.toString(16),
    b.toString(16), Math.round(a * 255).toString(16).substring(0, 2)
  ];

  // Pad single-digit output values
  outParts.forEach((part, i) => {
    if (part.length === 1) outParts[i] = '0' + part;
  })

  return ('#' + outParts.join(''));
}

export const hexToVec = (hex) => {
  let c = hex.substring(1).slice(0, 6).split('');
  if (c.length == 3) c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  c = '0x' + c.join('');
  let alpha = parseInt(hex.substring(1).slice(6, 8), 16);
  let rgb_val = { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255};
  return [rgb_val.r / 255, rgb_val.g / 255, rgb_val.b / 255];
}

export const hexToRgbA = (hex) => {
  let c = hex.substring(1).slice(0, 6).split('');
  if (c.length == 3) c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  c = '0x' + c.join('');

  let alpha = parseInt(hex.substring(1).slice(6, 8), 16);
  return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255, a: !!alpha ? alpha / 255 : 1 };
}

// Z-Index calculation functions
export const getMaxZIndex = (ProjectJSON) => {
  if (!ProjectJSON?.videos) return 0;
  let elements = { ...ProjectJSON.videos, ...ProjectJSON.images, ...ProjectJSON.text }

  let maxIndex = -1;
  let keys = Object.keys(elements);
  keys.forEach((key) => {
    let item = elements[key];
    let zIndex = item.coordinate.zIndex;
    if (zIndex > maxIndex) maxIndex = zIndex;
  });

  if (maxIndex <= 9 && maxIndex >= -9) maxIndex += 1;
  if (maxIndex > 9) maxIndex = 9;
  if (maxIndex < -9) maxIndex = -9;

  return maxIndex;
}

export const getNextZIndex = (ProjectJSON, uuid, type, flag) => {
  let elementOb = ProjectJSON[type][uuid];
  let zIndexAdjuster = elementOb.coordinate.zIndex;
  if (typeof zIndexAdjuster !== 'number') zIndexAdjuster = 0;
  let elements = { ...ProjectJSON.videos, ...ProjectJSON.images, ...ProjectJSON.text }

  let nextIndex = flag === 1 ? 10 : -10;
  let keys = Object.keys(elements);
  keys.forEach((key) => {
    if (key === uuid) return;
    let item = elements[key];
    let zIndex = item.coordinate.zIndex;
    if (flag === 1 && zIndexAdjuster <= zIndex && nextIndex > zIndex) nextIndex = zIndex;
    if (flag === -1 && zIndexAdjuster >= zIndex && nextIndex < zIndex) nextIndex = zIndex;
  })

  if (nextIndex <= 9 && nextIndex >= -9) nextIndex += flag;
  if (nextIndex === 10 || nextIndex === -10) nextIndex = zIndexAdjuster;

  if (nextIndex > 9) nextIndex = 9;
  if (nextIndex < -9) nextIndex = -9;
  return nextIndex;
}

export const getWebglResizeJson = (projectjson, option, videoElements, imageElements) => {
  const newProjectJSON = Object.assign({}, projectjson);
  let videoitems = newProjectJSON.videos;
  let imageitems = newProjectJSON.images;
  let textItems = newProjectJSON.text;
  const videoKeys = Object.keys(videoitems);
  const imageKeys = Object.keys(imageitems);
  const textKeys = Object.keys(textItems);

  videoKeys.forEach((videoKey) => {
    let videoItem = videoitems[videoKey];
    const bRatioW = projectjson.width / videoItem.ratioWH.w;
    const bRatioH = projectjson.height / videoItem.ratioWH.h;

    const ratioW = option.wSize.width / videoItem.ratioWH.w;
    const ratioH = option.wSize.height / videoItem.ratioWH.h;
    const videoEl = videoElements[videoKey];
    const videoRatio = videoEl.videoHeight / videoEl.videoWidth;
    const projectRatio = option.wSize.height / option.wSize.width;

    if(projectRatio > videoRatio){
      console.log('projectRatio', videoRatio);
      videoItem.dimension.w = option.wSize.width;
      videoItem.dimension.h = option.wSize.width * videoRatio;
      videoItem.ratioWH = { w: option.wSize.width , h: option.wSize.height };
      videoItem.coordinate.x = 0;
      videoItem.coordinate.y = Math.round((option.wSize.height - videoItem.dimension.h) / 2) 
    }else{
      console.log('videoRatio', videoRatio);
      videoItem.dimension.w = Math.round(option.wSize.height / videoRatio);
      videoItem.dimension.h = option.wSize.height;
      // videoItem.ratioWH = { w: Math.round(option.wSize.height *  videoRatio), h: option.wSize.height };
      videoItem.ratioWH = { w: option.wSize.width, h: option.wSize.height };
      videoItem.coordinate.x = (option.wSize.width - videoItem.dimension.w) / 2;
      // videoItem.coordinate.x = 0;
      videoItem.coordinate.y = 0;
    }
    // if (videoRatio > projectRatio) {
    //   const newHeight = videoItem.dimension.w * bRatioW / bRatioH * ratioH / ratioW;
    //   videoItem.coordinate.x += (videoItem.dimension.w - newHeight) / 2;
    //   videoItem.dimension.w = newHeight;
    // } else {
    //   const newWidth = videoItem.dimension.h * bRatioH / bRatioW * ratioW / ratioH;
    //   videoItem.coordinate.y += (videoItem.dimension.h - newWidth) / 2;
    //   videoItem.dimension.h = newWidth;
    // }
    
  })

  imageKeys.forEach((imagekey) => {
    
    let imageItem = imageitems[imagekey];
    const bRatioW = projectjson.width / imageItem.ratioWH.w;
    const bRatioH = projectjson.height / imageItem.ratioWH.h;

    const ratioW = option.wSize.width / imageItem.ratioWH.w;
    const ratioH = option.wSize.height / imageItem.ratioWH.h;
    const imageEL = imageElements[imagekey];
    const imageRatio = imageEL.naturalHeight / imageEL.naturalWidth;
    const projectRatio = projectjson.height / projectjson.width;

    imageItem.dimension.w = imageItem.dimension.w * (ratioW);
    imageItem.dimension.h = imageItem.dimension.w * imageRatio;
    imageItem.coordinate.x = imageItem.coordinate.x * (ratioW);
    imageItem.coordinate.y = imageItem.coordinate.y * (ratioH);
    imageItem.ratioWH = { w: option.wSize.width , h: option.wSize.height };
    // if (imageRatio > projectRatio) {
    //   const newHeight = imageItem.dimension.w * bRatioW / bRatioH * ratioH / ratioW;
    //   imageItem.coordinate.x += (imageItem.dimension.w - newHeight) / 2;
    //   imageItem.dimension.w = newHeight;
    // } else {
    //   const newWidth = imageItem.dimension.h * bRatioH / bRatioW * ratioW / ratioH;
    //   imageItem.coordinate.y += (imageItem.dimension.h - newWidth) / 2;
    //   imageItem.dimension.h = newWidth;
    // }
  })

  textKeys.forEach(textKey => {
    let textItem = textItems[textKey];
    const projectRatio = option.wSize.height / option.wSize.width;
    const originRatio = textItem.ratioWH.h / textItem.ratioWH.w;
    const widthRatio = option.wSize.width / textItem.ratioWH.w;
    const heightRatio = option.wSize.height / textItem.ratioWH.h;

    console.log('checkingRatio', projectRatio, originRatio, widthRatio, heightRatio)

    // if(textItem.is_subtitle){
      if(projectRatio > 1){
        textItem.dimension.w = textItem.dimension.w * widthRatio;
        textItem.dimension.h = textItem.dimension.h * heightRatio;
        textItem.coordinate.x = textItem.coordinate.x * widthRatio;
        // if(textItem.is_subtitle){
        textItem.coordinate.y = textItem.coordinate.y * heightRatio;
        // }
        if(textItem.coordinate.y > (option.wSize.height - textItem.dimension.h)){
          textItem.coordinate.y = (option.wSize.height - textItem.dimension.h);
        }
        textItem.properties.font_size = textItem.properties.font_size * widthRatio;

      }else{
        textItem.dimension.w = textItem.dimension.w * widthRatio;
        textItem.dimension.h = textItem.dimension.h * heightRatio;
        textItem.coordinate.x = textItem.coordinate.x * widthRatio;
        
        textItem.coordinate.y = textItem.coordinate.y * heightRatio;
        if(textItem.coordinate.y > (option.wSize.height - textItem.dimension.h)){
          textItem.coordinate.y = (option.wSize.height - textItem.dimension.h);
        }
        textItem.properties.font_size = textItem.properties.font_size  * widthRatio;

      }
    // }else{
    //   textItem.coordinate.x = textItem.coordinate.x * ((option.wSize.width - textItem.dimension.w) / (textItem.ratioWH.w - textItem.dimension.w));
    //   textItem.coordinate.y = textItem.coordinate.y * (option.wSize.height / textItem.ratioWH.h);
    //   // videoItem.coordinate.x = 0;
    // }
    
    textItem.ratioWH = { w: option.wSize.width , h: option.wSize.height };

  })

  return { videoitems, imageitems, textItems }
}

function textToUtf16(s) {
  let result = '';
  for (let i = 0; i < s.length; i++) {
    const code = s.charCodeAt(i);
    if (code <= 0xffff) {
      // Regular BMP character, encode as \uXXXX
      result += '\\u' + ('0000' + code.toString(16)).slice(-4);
    } else if (code <= 0x10ffff) {
      // Non-BMP character, encode as surrogate pair
      const high = Math.floor((code - 0x10000) / 0x400) + 0xd800;
      const low = ((code - 0x10000) % 0x400) + 0xdc00;
      result += '\\u' + ('0000' + high.toString(16)).slice(-4);
      result += '\\u' + ('0000' + low.toString(16)).slice(-4);
    } else {
      // Invalid Unicode code point, ignore
    }
  }
  return result;
}

export const convertStringtoWStringUTF16 = (string) => {
  return textToUtf16(string);
}

// change 0th second
export const changeZeroSecond = (item, dispatch) => {
  if (item?.timeline_start === 0) {
    setTimeout(() => {
      dispatch(thumbnailState_Store(true))
    }, 1000)
  }
}

export function convertMillisecondsToTimestamp(ms) {
  let hours = Math.floor(ms / 3600000);
  ms %= 3600000;
  let minutes = Math.floor(ms / 60000);
  ms %= 60000;
  let seconds = Math.floor(ms / 1000);
  let milliseconds = Math.floor(ms % 1000);

  // Pad hours, minutes, seconds, and milliseconds to always have the right format
  hours = String(hours).padStart(2, '0');
  minutes = String(minutes).padStart(2, '0');
  seconds = String(seconds).padStart(2, '0');
  milliseconds = String(milliseconds).padStart(3, '0');

  return `${hours}:${minutes}:${seconds},${milliseconds}`;
}