import React, {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase} from "@mui/material"
import {ArrowBack, ArrowForward} from '@mui/icons-material';
import { ClickAwayListener } from '@mui/base';
import VideocamIcon from '@mui/icons-material/Videocam';

import SearchIcon from '@mui/icons-material/Search';
import { SectionHeader } from './common/section.header';
import { 
  getStockSearchKeyword, 
  getStockData, 
  createStock, 
  updateStock, 
  UploadAssets, 
  getPopularPexelsData, 
  getPexelsData, 
  getPopularPexelsImages,
  getPexelsImageSearch,
  getPexelsImageData,
  getPixaBayStockSearch, 
  getPopularPixaBayStockData, 
  getPopularPixabayImageStocks,
  getPixabayImageSearchStocks,
  getPixabayImageStock,
  getFreeSoundSearch,
  getFreeSoundData,
  getProjectJSON} from '../../../axios/ApiProvider';
import { GetProjectUUID } from '../../../assets/script/commonFunction';
import { getNewStockInformation, getThumbnailsInfo, getImageInformation, getNewAudioInformation, getNewAudioStockInformation } from '../webGL/commonFunc';
import { VideoElements_Store, audioElements_Store, dataLoaded_Store, imageElements_Store } from '../../../store/actions/webgldata';
import { changedJSON_Store, ProjectJSON_Store, thumbNails_Store } from '../../../store/actions/projectdata';
import { getMaxZIndex } from '../commonFunction';
import { changeZeroSecond } from '../commonFunction';
import { localeForPexles, mimeTypes } from './tooltap.config';
import { v4 as uuidv4 } from 'uuid';
import {webGlSizeOptions } from "../../video-edit/videoEdit.config"
import pexelsVideoLogo from "../../../assets/image/Logos/pexelVideo.png"
import pexelsImageLogo from "../../../assets/image/Logos/pexelImage.png";
import pixabayVideoLogo from "../../../assets/image/Logos/pixabayVideo.png";
import pixabayImageLogo from "../../../assets/image/Logos/pixabayImage.png";
import freeSoundLogo from "../../../assets/image/Logos/freeSound.png";
import stockLogo from "../../../assets/image/Logos/stock_logo.png"
import { duration } from 'moment';

import { AdobeStock } from './stocks/adobeStock';
import { PexelsVideo } from './stocks/pexelsVideo';
import { PexelsImage } from './stocks/pexelsImage';
import { PixaBayVideo } from './stocks/pixabayVideo';
import { PixabayImage } from './stocks/pixabayImage';
import { FreeSound } from './stocks/freeSound';

export const TooltapStock = () => {
  const [stockMenu, setStockMenu] = useState("pexelsVideo")

  const languageStrs = useSelector((store) => store.editdata.languageStrs)
  const HeaderInfo = { title: languageStrs.stock, className: 'pl-3' };
  const handleclickPexelsVideoMenu = () => {
    setStockMenu("pexelsVideo")
  }

  const handleclickPexelsImageMenu = () => {
    setStockMenu("pexelsImage")
  }

  const handleClickStockMenu = () => {
    setStockMenu("stock")
  }

  const handleClickPixabayVideoMenu = () => {
    setStockMenu("pixabayVideo")
  }

  const handleClickPixabayImageMenu = () => {
    setStockMenu("pixabayImage")
  }

  const handleClickFreesoundMenu = () => {
    setStockMenu("freesound")
  }

  return (
    <>
      <Box className={`v-toolbar-header pl-3 d-block`} style={{height: 'max-content'}}>
        <Box width={'100%'} display={'block'}>
          <Typography sx={{lineHeight: "50px"}} className='v-header-right first-letter:uppercase'>{languageStrs.stock}</Typography>
        </Box>
        
        <Box display="flex" flexWrap="wrap">
          
          <Box className={stockMenu == "pexelsVideo"? 'activeSock': ''} paddingX={2} paddingY={1} onClick={handleclickPexelsVideoMenu}>
              <img className='stockMenu-img' src={pexelsVideoLogo} width={50} />
          </Box>
          <Box className={stockMenu == "pexelsImage"? 'activeSock': ''} paddingX={2} paddingY={1} onClick={handleclickPexelsImageMenu }>
              <img src={pexelsImageLogo} width={50} />
          </Box>
          <Box className={stockMenu == "stock"? 'activeSock': ''} paddingX={2} paddingY={1} onClick={handleClickStockMenu}>
              <img src={stockLogo} width={70} />
          </Box>
          <Box className={stockMenu == "pixabayVideo"? 'activeSock': ''} paddingX={2} paddingY={1} onClick={handleClickPixabayVideoMenu}>
              <img src={pixabayVideoLogo} width={50} />
          </Box>
          <Box className={stockMenu == "pixabayImage"? 'activeSock': ''} paddingX={2} paddingY={1} onClick={handleClickPixabayImageMenu}>
              <img src={pixabayImageLogo} width={50} />
          </Box>
          <Box className={stockMenu == "freesound"? 'activeSock': ''} paddingX={2} paddingY={1} onClick={handleClickFreesoundMenu}>
              <img src={freeSoundLogo} width={50} />
          </Box>
        </Box>
      </Box>
      {stockMenu === "pexelsVideo" && <PexelsVideo />}
      {stockMenu === "pexelsImage" && <PexelsImage />}
      {stockMenu === "stock" &&  <AdobeStock />}
      {stockMenu === "pixabayVideo" &&  <PixaBayVideo />}
      {stockMenu === "pixabayImage" &&  <PixabayImage />}
      {stockMenu === "freesound" &&  <FreeSound />}
    </>
  )
}

