import React, {useState, useEffect, useRef} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {Box, Typography, FormControl, OutlinedInput, InputAdornment, InputLabel, Grid, Popper , MenuItem, ImageListItem, ImageListItemBar, ImageList, CircularProgress, Select, Button, TextField, InputBase} from "@mui/material"
import {ArrowBack, ArrowForward} from '@mui/icons-material';
import { ClickAwayListener } from '@mui/base';
import VideocamIcon from '@mui/icons-material/Videocam';

import SearchIcon from '@mui/icons-material/Search';
import { SectionHeader } from '../common/section.header';
import { 
  getStockSearchKeyword, 
  getStockData, 
  createStock, 
  updateStock, 
  UploadAssets, 
  getPopularPexelsData, 
  getPexelsData, 
  getPopularPexelsImages,
  getPexelsImageSearch,
  getPexelsImageData,
  getPixaBayStockSearch, 
  getPopularPixaBayStockData, 
  getPopularPixabayImageStocks,
  getPixabayImageSearchStocks,
  getPixabayImageStock,
  getFreeSoundSearch,
  getFreeSoundData,
  getProjectJSON} from '../../../../axios/ApiProvider';
import { GetProjectUUID } from '../../../../assets/script/commonFunction';
import { getNewStockInformation, getNewPexelsStockInformation, getThumbnailsInfo, getImageInformation, getNewAudioInformation, getNewAudioStockInformation } from '../../webGL/commonFunc';
import { VideoElements_Store, audioElements_Store, dataLoaded_Store, imageElements_Store } from '../../../../store/actions/webgldata';
import { changedJSON_Store, ProjectJSON_Store, thumbNails_Store } from '../../../../store/actions/projectdata';
import { loading_Store } from '../../../../store/actions/mainAction';
import { getMaxZIndex, changeZeroSecond } from '../../commonFunction';
import { localeForPexles, mimeTypes } from '../tooltap.config';
import { v4 as uuidv4 } from 'uuid';
import {webGlSizeOptions } from "../../../video-edit/videoEdit.config"
import pexelsVideoLogo from "../../../../assets/image/Logos/pexelVideo.png"
import pexelsImageLogo from "../../../../assets/image/Logos/pexelImage.png";
import pixabayVideoLogo from "../../../../assets/image/Logos/pixabayVideo.png";
import pixabayImageLogo from "../../../../assets/image/Logos/pixabayImage.png";
import freeSoundLogo from "../../../../assets/image/Logos/freeSound.png";
import stockLogo from "../../../../assets/image/Logos/stock_logo.png"
import { duration } from 'moment';

import { StockModal } from './stockModal';

export const PixaBayVideo = () => {
    const [isLoadingStock, setIsLoadingStock] = useState(true);
    const [stockData, setStockData] = useState([]);
    const projectUUID = GetProjectUUID();
    const videoElements = useSelector(store => store.webgldata.videoElements);
    const Time_Count = useSelector((store) => store.videodata.Time_Count);
    const projectJSON = useSelector(store => store.projectdata.projectJSON);

    const dispatch = useDispatch()

    const [pageNum, setPageNum] = useState(1);
    const [pageTotal, setPageTotal] = useState(1);
    const [searchKey, setSearchKey] = useState('');
    const [locale, setLocale] = useState("en-US")
    const [orientation, setOrientation] = useState('landscape');
    const [openPopper, setOpenPopper] = useState(false)
    const anchorEl = useRef(null)
    const [keywords, setKeywords] = useState([]);

    const [isHoverId, setIsHoverId] = useState(null);

    const [selectedStock, setSelectedStock] = useState(null);
    const [openDetaiModal, setOpenDetailModal] = useState(false);

    const handleOpenPopover = (event) => {
      setOpenPopper(true);
    };
  
    const handleClosePopover = () => {
      setOpenPopper(false);
    };
  
    useEffect(() => {
        (async function() {
          if(searchKey) {
            // setPageNum(1);
            const keysRes = await getStockSearchKeyword(searchKey);
            let respStr = "";
            keysRes.map(st => respStr += st);
            const firstBracket = respStr.indexOf("(");
            respStr = respStr.slice(firstBracket + 1).slice(0, -1);
            const respJson = JSON.parse(respStr);
            console.log('gettingISSUE', respJson.hits);
            setKeywords(respJson.hits)
          }
        })()
      }, [searchKey])

    useEffect(() => {
      getPopularPixaBayData(pageNum);
    }, [pageNum]);
  
    const getPopularPixaBayData = async (pageId) => {
      let stockData = await getPopularPixaBayStockData(pageId);
      console.log('gettingStockData', stockData);
      if(stockData.state != "success") return;
      setStockData(stockData.data.hits);
      setPageTotal(Math.round(stockData.data.total / 20));
      setIsLoadingStock(false);
    }
  
    const getPixaBaySearchData = async (keyword) => {
      setIsLoadingStock(true);
      let searchedData = await getPixaBayStockSearch(keyword, pageNum, locale, orientation);
      console.log('searchedDat', searchedData);
      setStockData(searchedData.hits);
      setPageTotal(Math.round(searchedData.total / 20));
      setIsLoadingStock(false);
    }
  
    const handleChangePageNum = (e) => {
      if(e.target.value < 1 || e.target.value > pageTotal) return;
      setPageNum(e.target.value)
    }
    useEffect(() => {
      if(searchKey === ""){
        getPopularPixaBayData(pageNum);
      }else{
        if(pageNum > 1){
          setPageNum(1);
        }
        getPixaBaySearchData(searchKey);
      }
    }, [orientation, locale])
  
    const handleChangeInput = (e) => {
      setSearchKey(e.target.value);
      if(e.target.value === ""){
        handleClosePopover();
      } else {
        handleOpenPopover(e);
      }
    }
  
    const handleClickStock = (stockItem) => {
      setSelectedStock(stockItem);
      setOpenDetailModal(true);
    }

    const addStockData = async (stockData) => {
      const hVideo = stockData.videos.tiny.url;
      const video_pictures = stockData.videos.tiny.thumbnail;
      const duration = stockData.duration;

      dispatch(loading_Store(true));
      const stockUUID = uuidv4();
      const thumbnails = [];
      const extension = video_pictures.split("?")[0].split(".").reverse()[0];
      let timestamp = duration;
      thumbnails.push({
        url: video_pictures,
        timestamp,
        content_type: mimeTypes[extension]});
      
      let startTime = Time_Count.getTime()
      let maxZIndex = getMaxZIndex(projectJSON);
      let changedJSON = { videos: {}, audios: {}, images: {}, asset: {}, text: {} }
      const newProjectJson = {...projectJSON};
      let addingGroupId = stockUUID;
      const vKeys = projectJSON.videos? Object.keys(projectJSON.videos) : [];
      if(vKeys.length > 0){
        addingGroupId = projectJSON.videos[vKeys[0]].groups;
        vKeys.map(vKeyItem => {
          if(startTime < projectJSON.videos[vKeyItem].timeline_end){
            console.log('timelineEnd', projectJSON.videos[vKeyItem].timeline_end);
            startTime = projectJSON.videos[vKeyItem].timeline_end;
          }
          console.log('looping time');
        })
      }
  
      await getNewPexelsStockInformation(projectUUID, stockUUID, projectJSON, hVideo, hVideo, extension, duration * 1000, thumbnails, Object.keys(videoElements).length, maxZIndex,
      (videoPlayer, videoInfo, newAssets, assetUUID, thumbnail_info_json) => {
        const tempThumbnails = {};
        Object.values(thumbnail_info_json).map(th => {
          tempThumbnails[th.uuid] = {
            signed_url: th.external_url,
            timestamp: th.timestamp
          }
        })
  
        dispatch(VideoElements_Store({...videoElements, [stockUUID]: videoPlayer}));
        changedJSON.flag = true;
        changedJSON.videos = {
          [stockUUID]: {
            ...videoInfo,
            groups: addingGroupId,
            timeline_start: startTime,
            timeline_end: videoInfo.duration + startTime,
            asset_uuid: assetUUID,
            asset_filename: hVideo,
            asset_extension: hVideo.split("?")[0].split(".").reverse()[0]
          }
        }
        const tempAssets = {
          ...newAssets,
          [assetUUID]:{
            ...newAssets[assetUUID],
            thumbnail: tempThumbnails
          }
        }
  
        dispatch(changedJSON_Store(changedJSON))
        dispatch(ProjectJSON_Store({
          ...newProjectJson,
          asset: {
            ...newProjectJson.asset,
            ...tempAssets
          }
        }))
        changeZeroSecond(videoInfo, dispatch)
        dispatch(loading_Store(false));
      })
    }

    const handleKeyUp = (e) => {
      if(e.keyCode === 13) {
        getPixaBaySearchData(searchKey);
        handleClosePopover();
      }
    }
    const handleClickKeyword =  (keyword) => {
        setSearchKey(keyword);
        getPixaBaySearchData(keyword);
        handleClosePopover();
      }

    return (
      <>
        <Grid container sx={{p: 1}}>
          <Grid item xs={6} padding={2}>
            <FormControl fullWidth>
              <InputLabel id='pixaBayVideo-Orientation'>Orientation</InputLabel>
              <Select
                labelId="pixaBayVideo-Orientation"
                id="pixaBayVideo-Orientation"
                value={orientation}
                label="Orientation"
                onChange={(e) => {setOrientation(e.target.value)}}
              >
                <MenuItem value="landscape">Landscape</MenuItem>
                <MenuItem value="portrait">Portrait</MenuItem>
                <MenuItem value="square">Square</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} padding={2}>
            <FormControl fullWidth>
              <InputLabel id="pixabayVideo-Local" >Locale</InputLabel>
              <Select 
                labelId='pixabayVideo-Local'
                id="pixabayVideo-locale"
                value={locale}
                label={'Locale'}
                onChange={(e) => {setLocale(e.target.value)}}
              >
                {localeForPexles.map(lo => <MenuItem value={lo}>{lo}</MenuItem>)}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} >
            <Box display={'flex'} justifyContent={"center"}>
              <Button 
                variant='contained' 
                startIcon={<ArrowBack />}
                sx={{marginRight: "10px" }}
                onClick={() => {
                  if(pageNum === 1) return;
                  setPageNum(Number(pageNum) - 1);
                }}
                >
                  Prev
              </Button>
              <input 
                type='number'
                max={pageTotal}
                style={{width: "50px"}}
                className='stock-page-input'
                value={pageNum}
                onChange={handleChangePageNum}
              />
              <span style={{fontSize: "20px"}}>/{pageTotal}</span>
              <Button 
                variant='contained'
                endIcon={<ArrowForward />}
                sx={{marginLeft: "10px"}}
                onClick={() => {
                  if(pageNum == pageTotal) return;
                  setPageNum(Number(pageNum) + 1);
                }}
              >Next</Button>
            </Box>
          </Grid>
          <Grid item xs={12} paddingY={2}>
            <FormControl fullWidth ref={anchorEl}>
              <InputLabel htmlFor="outlined-pixabay-search">Search</InputLabel>
              <OutlinedInput 
                id="outlined-pixabay-search"
                startAdornment={<InputAdornment position="start"><SearchIcon /></InputAdornment>}
                label="Search"
                onChange={handleChangeInput}
                onKeyUp={handleKeyUp}
                value={searchKey}
              />
            </FormControl>
          </Grid>
          <ClickAwayListener onClickAway={handleClosePopover}>
            <Popper 
                open={openPopper}
                anchorEl={anchorEl.current}
                sx={{
                    width: `${anchorEl?.current?.clientWidth}px`,
                    border: "solid 1px #e5e7eb",
                    zIndex: 1000,
                    backgroundColor: "#FFF"
                  }}
                  placement="bottom-start"
            >
                {keywords.map(w => <MenuItem key={w.objectID}
                onClick={() =>{ handleClickKeyword(w.term)}}
              >{w.term}</MenuItem>)}
            </Popper>
          </ClickAwayListener>
        </Grid>
        <Grid container sx={{overflow: 'auto', position: 'relative'}}>
          <Grid item xs={12} sx={{position: "relative", minHeight: "300px"}}>
            <ImageList variant='masonry' cols={2} gap={8}>
              {stockData.map(stItem => {
                let subtitle = "";
                const minute = Math.floor(stItem.duration / 60);
                const second = Math.round((stItem.duration - (minute * 60)));
                subtitle += ` ${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}`
                return (
                  <ImageListItem key={stItem.id} sx={{cursor: "pointer"}}
                    onClick={() => {
                      //handleClickStock(stItem.videos.large.url,stItem.videos.large.thumbnail, stItem.duration )
                      handleClickStock(stItem);
                    }}
                    onMouseOver={() => {if(isHoverId != stItem.id){ setIsHoverId(stItem.id); } console.log('onMuseOver')}}
                    onMouseLeave={() => {setIsHoverId(null); console.log('onMuseLeave')}}
                  >
                    <img
                      src={`${stItem.videos.tiny.thumbnail}`}
                      alt={stItem.id}  
                      loading="lazy"
                      style={{
                        minWidth: "184px",
                        minHeight: "103px",
                        flexGrow: "unset"
                      }}
                    />
                    {isHoverId == stItem.id && 
                    <video className='stockPreview-video' src={stItem.videos.tiny.url} loop autoPlay style={{
                        top: '0px',
                        position: "absolute",
                        minWidth: "184px",
                        minHeight: "103px",
                        flexGrow: "unset"
                      }}></video>
                    }
                  <ImageListItemBar
                    subtitle={<><VideocamIcon/>{subtitle}</>}
                  />
                  </ImageListItem>
                )
              })}
            </ImageList>
          </Grid>
          {isLoadingStock && <Box sx={{position: "absolute", top: 0,  background: "rgba(0,0,0,0)", display: "flex", zIndex: 100000, flexDirection: "column", justifyContent: "center", height: "300px", width: "100%"}}>
              <Box sx={{ display: 'flex', position: "absolute", justifyContent: "center", width: "100%" }}>
                <CircularProgress />
              </Box>
            </Box>}
        </Grid>
        <StockModal 
            open={openDetaiModal} 
            setOpen={setOpenDetailModal} 
            stockType='pixabayVideo'
            stockData={selectedStock}
            addStockData={addStockData}
            />
      </>
    )
  }